import React from 'react';
import PropTypes from 'prop-types';
import Logo from '../../asset/logo.svg';
import './index.css';

const ErrorAlert = ({ errMsg, children }) => (
  <div className="error-alert">
    <div className="container">
      <div className="spinner">
        <img src={Logo} alt="logo" className="logo" />
      </div>
      <p className="msg-label">{errMsg}</p>
      <div>{children || false}</div>
    </div>
  </div>
);

ErrorAlert.propTypes = {
  errMsg: PropTypes.string,
  children: PropTypes.element
};

ErrorAlert.defaultProps = {
  errMsg: 'Erro Genérico',
  children: undefined
};

export default ErrorAlert;
