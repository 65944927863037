import * as actionUi from '../action/ui';

import { getScreenSize, getTextOptions, getProductType } from '../selector';
import { isNavTextDisabled } from '../utils/separadorProdutos';

import NavText from '../component/nav-text';
import { connect } from 'react-redux';

const mapStateToProps = state => ({
  screenSize: getScreenSize(state),
  textOptions: getTextOptions(state),
  prodType: getProductType(state),
  disabled: isNavTextDisabled(getProductType(state))
});

const mapDispatchToProps = dispatch => ({
  updateTextOptions: (option, value) =>
    dispatch(actionUi.updateTextOptions(option, value))
});

export default connect(mapStateToProps, mapDispatchToProps)(NavText);
