import './index.css';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import Button from '../button';
import { THEME_CLASS_ORANGE } from '../../constant/button';
import { NAV_IMAGE_SELECTED } from '../../constant/nav';
import { THEME_CLASS_BT_RIGHT_ARROW_WHITE_EXPAND_MODAL } from '../../constant/button';
import KeyboardEventHandler from 'react-keyboard-event-handler';

function ModalMask(
  {
    prodType,
    tutorialStatus, 
    maxPassos,
    titulos,
    mensagens,
    imagens,
    updateNav,
    setStateLegenda,
    updateTutorialStatus
  }) {

  const [ordemTutorial, setOrdemTutorial] = useState(0);

  useEffect(() => {
    const buttonFocus = document.getElementById('button-focus');
    if(buttonFocus)
      buttonFocus.focus();
  }, []);

  const mudaOrdemTutorial = (lado) => {
    if(lado === "LEFT" && ordemTutorial-1 < 0)
      return;
    if(lado === "RIGHT" && ordemTutorial+1 > maxPassos){
      setOrdemTutorial(0);
      if(tutorialStatus === "PASSO")
        setTimeout(() => updateNav(NAV_IMAGE_SELECTED), 300);
      setStateLegenda('NORMAL');
      return updateTutorialStatus("");
    }
    setOrdemTutorial((lado === "RIGHT") ? ordemTutorial+1: ordemTutorial-1);
  };

  if((tutorialStatus === "PASSO" || tutorialStatus === "PASSO-TUTORIAL" ) && prodType === "mask")
    return (
      <div className="ModalMask">
        <div className="container">
          <KeyboardEventHandler
            handleKeys={['left', 'right', 'enter', 'esc']}
            handleFocusableElements = {true}
            onKeyEvent={(key) => {
              if(key === 'left' )
                mudaOrdemTutorial("LEFT")
              else if(key === 'right' && ordemTutorial+1 <= maxPassos)
                mudaOrdemTutorial("RIGHT")
              else if(key === 'esc' && tutorialStatus === "PASSO-TUTORIAL")
                updateTutorialStatus("");
                setStateLegenda('NORMAL');
            }} 
          />
          <div className="imagens">
            <img src={imagens[ordemTutorial]} alt="Passo"></img>
            {
              (ordemTutorial < maxPassos) && 
              <Button
                style={{right: "48px"}}
                theme={THEME_CLASS_BT_RIGHT_ARROW_WHITE_EXPAND_MODAL}
                clickHandler={() => mudaOrdemTutorial("RIGHT")}
              />
            }
            {
              (ordemTutorial > 0) && 
              <Button
                style={{left: "48px", transform: "rotate(180deg)"}}
                theme={THEME_CLASS_BT_RIGHT_ARROW_WHITE_EXPAND_MODAL}
                clickHandler={() => mudaOrdemTutorial("LEFT")}
              />
            }
          </div>
          <div className="textos">
            <div className="titulo">{titulos[ordemTutorial]}</div>
            <div className="message">{mensagens[ordemTutorial]}</div>
            <Button
              id="button-focus"
              label={ordemTutorial === maxPassos ? "Pronto":"Continuar"}
              theme={THEME_CLASS_ORANGE}
              clickHandler={() => mudaOrdemTutorial("RIGHT")}
            /> 
          </div>
        </div>
      </div>
    );
  else
    return null;
}

ModalMask.propTypes = {
  prodType: PropTypes.string.isRequired,
  tutorialStatus: PropTypes.string.isRequired,
  updateTutorialStatus: PropTypes.func.isRequired,
  updateNav: PropTypes.func.isRequired,
  maxPassos: PropTypes.number.isRequired,
  titulos: PropTypes.array.isRequired,
  mensagens: PropTypes.array.isRequired,
  imagens: PropTypes.array.isRequired,
  setStateLegenda: PropTypes.func.isRequired
};

export default ModalMask;