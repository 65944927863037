import { combineReducers } from 'redux';
import item from './item';
import ui from './ui';
import library from './library';
import serverImages from './serverImages';

const reducer = combineReducers({
  item,
  ui,
  library,
  serverImages
});

export default reducer;
