import * as actionUI from '../constant/action-type/ui';

import {
  ACTIVE,
  ALIGN,
  ALL,
  BOLD,
  COLOR,
  FAMILY,
  FAMILY_SIZE,
  ITALIC,
  LEFT,
  SIZE,
  UNDERLINE
} from '../constant/textStyle';
import { calcCustomSize } from '../utils/calculosGerais';

import { FETCH_FROM_IDB } from '../constant/action-type/general';
import { NAV_NONE_SELECTED } from '../constant/nav';
import { POPUP_ID_NONE } from '../constant/popup';

const initialState = {
  screenSizeError: false,
  tutorial: process.env.NODE_ENV === 'development' ? '' : 'VIDEO',
  // tutorial: "VIDEO",
  // tutorial: "PASSO",
  // tutorial: "ESCOLHER-TAMANHO-COLEIRA",
  dataFetched: false,
  IDB: false,
  gallery: {
    expanded: false
  },
  bookOverview: {
    expanded: false
  },
  modoImpressao: false,
  legendaAberta: false,
  stateLegenda: 'NORMAL',
  bibliotecaTemas: false,
  modalConfig: null,
  nav: {
    selected: NAV_NONE_SELECTED,
    previous: ''
  },
  screen: {
    rate: 0,
    size: {}
  },
  warning: {
    active: false,
    text: ''
  },
  drag: {
    isDragging: false,
    id: '',
    start: {
      pos: {
        x: 0,
        y: 0
      },
      mousePos: {
        x: 0,
        y: 0
      }
    }
  },
  popup: {
    id: POPUP_ID_NONE,
    display: false,
    overlay: false,
    msg: ''
  },
  thumbExpand: {
    active: '',
    id: ''
  },
  photoEditor: {
    active: false,
    frameId: ''
  },
  toolbarPhoto: {
    maxThumbs: 0,
    thumbsLength: 0,
    prevThumbLenth: 0,
    thumbListLeft: {
      start: 0,
      end: 0
    },
    thumbListRight: {
      start: 0,
      end: 0
    },
    thumbsListExpanded: {
      start: 0,
      end: 0
    }
  },
  textOptions: {
    active: '',
    size: 16,
    family: 'Liberation Sans',
    color: 'default',
    textStyle: {
      bold: false,
      italic: false,
      underline: false,
      align: LEFT
    },
    sizeArray: [
      72,
      66,
      60,
      54,
      48,
      44,
      40,
      38,
      36,
      34,
      32,
      30,
      28,
      26,
      24,
      22,
      20,
      19,
      18,
      17,
      16,
      15,
      14,
      13,
      12,
      11,
      10,
      8,
      6
    ],
    familyArray: [
      'Abril Fatface',
      'Arvo',
      'Blokletters Balpen',
      'Cookie',
      'Gandhi Sans',
      'GoodDog',
      'Grand Hotel',
      'Great Vibes',
      'Josefin Sans',
      'Komika Axis',
      'Liberation Sans',
      'Liberation Serif',
      'Lobster Two',
      'Walkway'
    ],
    familyInvitationArray: [
      'Abril Fatface',
      'Arvo',
      'Blokletters Balpen',
      'Cookie',
      'Gandhi Sans',
      'GoodDog',
      'Grand Hotel',
      'Great Vibes',
      'Josefin Sans',
      'Komika Axis',
      'Liberation Sans',
      'Liberation Serif',
      'Lobster Two',
      'Walkway',
      'Amatic SC',
      'PlayFair Display',
      'Montserrat Black',
      'Montserrat Bold',
      'JulliaScript'
    ],
    familyArrayDogCollar: [
      'Arvo',
      'Blokletters Balpen',
      'Cookie',
      'Gandhi Sans',
      'GoodDog',
      'Grand Hotel',
      'Josefin Sans',
      'Liberation Sans',
      'Liberation Serif',
      'Lobster Two',
      'Walkway'
    ],
    colorArray: [
      'rgb(0, 0, 0)',
      'rgb(75, 75, 75)',
      'rgb(140, 140, 140)',
      'rgb(0, 0, 200)',
      'rgb(0, 0, 255)',
      'rgb(100, 149, 237)',
      'rgb(0, 206, 209)',
      'rgb(0, 255, 255)',
      'rgb(0, 95, 0)',
      'rgb(0, 128, 0)',
      'rgb(0, 255, 0)',
      'rgb(128, 0, 128)',
      'rgb(138, 43, 226)',
      'rgb(255, 20, 147)',
      'rgb(250, 128, 114)',
      'rgb(255, 182, 193)',
      'rgb(128, 0, 0)',
      'rgb(255, 0, 0)',
      'rgb(255, 69, 0)',
      'rgb(255, 140, 0)',
      'rgb(255, 165, 0)',
      'rgb(218, 165, 32)',
      'rgb(139, 69, 19)',
      'rgb(205, 133, 63)',
      'rgb(255, 215, 0)',
      'rgb(255, 255, 0)',
      'rgb(0, 0, 128)',
      'rgb(65, 105, 225)',
      'rgb(30, 144, 255)',
      'rgb(0, 191, 255)',
      'rgb(0, 128, 128)',
      'rgb(64, 224, 208)',
      'rgb(127, 255, 212)',
      'rgb(69, 179, 113)',
      'rgb(50, 205, 50)',
      'rgb(154, 205, 50)',
      'rgb(75, 0, 130)',
      'rgb(148, 0, 211)',
      'rgb(255, 0, 255)',
      'rgb(199, 21, 133)',
      'rgb(238, 130, 238)',
      'rgb(255, 105, 180)',
      'rgb(178, 34, 34)',
      'rgb(220, 20, 60)',
      'rgb(255, 99, 71)',
      'rgb(255, 127, 80)',
      'rgb(255, 160, 122)',
      'rgb(184, 134, 11)',
      'rgb(210, 105, 30)',
      'rgb(222, 184, 135)',
      'rgb(72, 61, 139)',
      'rgb(109, 90, 205)',
      'rgb(70, 130, 180)',
      'rgb(107, 142, 35)',
      'rgb(128, 128, 0)',
      'rgb(189, 183, 107)',
      'rgb(219, 112, 147)',
      'rgb(135, 206, 235)',
      'rgb(240, 230, 140)',
      'rgb(255, 222, 173)',
      'rgb(179, 196, 222)',
      'rgb(185, 185, 185)',
      'rgb(215, 215, 215)',
      'rgb(255, 255, 255)'
    ],
    colorArrayDogCollar: [
      'rgb(245,245,245)',
      'rgb(217,217,220)',
      'rgb(160,160,156)',
      'rgb(117,125,132)',
      'rgb(10,10,10)',
      'rgb(115,186,214)',
      'rgb(96,168,196)',
      'rgb(64,149,217)',
      'rgb(23,123,202)',
      'rgb(29,90,187)',
      'rgb(210,61,65)',
      'rgb(185,2,46)',
      'rgb(215,25,35)',
      'rgb(155,13,15)',
      'rgb(252,123,80)',
      'rgb(241,107,63)',
      'rgb(230,99,71)',
      'rgb(255,95,45)',
      'rgb(250,225,0)',
      'rgb(239,226,0)',
      'rgb(234,216,93)',
      'rgb(253,195,4)',
      'rgb(150,255,5)',
      'rgb(70,153,132)',
      'rgb(16,159,118)',
      'rgb(89,151,61)',
      'rgb(0,115,82)',
      'rgb(250,193,215)',
      'rgb(255,154,174)',
      'rgb(240,140,164)',
      'rgb(255,95,128)',
      'rgb(44,70,112)',
      'rgb(60,41,111)',
      'rgb(43,34,80)',
      'rgb(139,118,179)',
      'rgb(88,21,125)',
      'rgb(92,24,105)',
      'rgb(136,70,140)',
      'rgb(138,57,115)',
      'rgb(211,181,125)',
      'rgb(205,165,104)',
      'rgb(166,119,42)',
      'rgb(153,105,65)',
      'rgb(143,102,28)',
      'rgb(171,208,239)',
      'rgb(150,181,221)',
      'rgb(147,174,233)',
      'rgb(127,161,231)',
      'rgb(128,139,232)',
      'rgb(223,100,102)',
      'rgb(208,82,91)',
      'rgb(148,20,52)',
      'rgb(133,53,51)',
      'rgb(251,158,28)',
      'rgb(242,140,23)',
      'rgb(229,127,18)',
      'rgb(235,105,5)',
      'rgb(200,72,32)',
      'rgb(250,76,5)',
      'rgb(245,230,185)',
      'rgb(243,229,158)',
      'rgb(242,215,118)',
      'rgb(202,255,10)',
      'rgb(156,216,169)',
      'rgb(146,203,134)',
      'rgb(114,145,60)',
      'rgb(255,186,180)',
      'rgb(250,172,163)',
      'rgb(253,177,191)',
      'rgb(235,109,156)',
      'rgb(140,124,163)',
      'rgb(176,115,170)',
      'rgb(73,58,82)',
      'rgb(83,35,65)',
      'rgb(215,235,215)',
      'rgb(181,199,202)',
      'rgb(173,177,162)',
      'rgb(159,174,150)',
      'rgb(172,151,127)',
      'rgb(129,90,68)',
      'rgb(216,210,42)',
      'rgb(217,186,95)',
      'rgb(212,184,77)',
      'rgb(216,186,48)',
      'rgb(216,168,101)',
      'rgb(170,147,67)',
      'rgb(179,248,215)',
      'rgb(151,235,210)',
      'rgb(121,181,179)',
      'rgb(68,168,172)',
      'rgb(57,145,158)',
      'rgb(14,109,116)',
      'rgb(230,205,165)',
      'rgb(238,203,139)',
      'rgb(231,193,156)',
      'rgb(223,172,138)',
      'rgb(222,166,113)',
      'rgb(242,159,154)',
      'rgb(218,131,153)',
      'rgb(222,122,129)',
      'rgb(213,112,142)',
      'rgb(140,151,151)',
      'rgb(77,117,138)',
      'rgb(48,83,105)',
      'rgb(65,69,73)',
      'rgb(166,147,98)',
      'rgb(152,127,77)',
      'rgb(122,106,69)',
      'rgb(255,160,89)',
      'rgb(225,148,99)',
      'rgb(225,147,56)',
      'rgb(212,129,52)',
      'rgb(203,93,30)',
      'rgb(239,208,172)',
      'rgb(252,201,192)',
      'rgb(252,172,147)',
      'rgb(191,149,155)',
      'rgb(179,196,216)',
      'rgb(96,138,167)',
      'rgb(104,116,170)',
      'rgb(226,154,191)',
      'rgb(225,97,133)',
      'rgb(216,77,137)',
      'rgb(138,38,99)',
      'rgb(185,40,96)'
    ]
  }
};

const updateGalleryExpanded = (state, { payload: { expanded } }) => ({
  ...state,
  gallery: {
    ...state.gallery,
    expanded: expanded
  }
});

const updateBookOverviewExpanded = (state, { payload: { expanded } }) => ({
  ...state,
  bookOverview: {
    ...state.bookOverview,
    expanded: expanded
  }
});

const toggleModoImpressao = state => ({
  ...state,
  modoImpressao: !state.modoImpressao
});

const toggleLegenda = state => ({
  ...state,
  legendaAberta: !state.legendaAberta
});

const setStateLegenda = (state, { payload }) => ({
  ...state,
  stateLegenda: payload.state
});

const toggleBibliotecaTemas = state => ({
  ...state,
  bibliotecaTemas: !state.bibliotecaTemas
});

const setModalConfig = (state, { payload }) => ({
  ...state,
  modalConfig: payload.modalConfig
});

const resizeScreen = (
  state,
  {
    payload: {
      width,
      height,
      headerHeight,
      photobookHeight,
      layoutsContainerWidth
    }
  }
) => {
  return {
    ...state,
    screen: {
      ...state.screen,
      size: {
        width,
        height,
        headerHeight,
        photobookHeight,
        layoutsContainerWidth
      }
    }
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_FROM_IDB:
      if (action.payload.ui) {
        return {
          ...state,
          tutorial: action.payload.ui.tutorial,
          toolbarPhoto: action.payload.ui.toolbarPhoto,
          textOptions: action.payload.ui.textOptions
        };
      }
      return state;
    case actionUI.UPDATE_SCREEN_SIZE:
      return resizeScreen(state, action);
    case actionUI.UPDATE_SCREEN_SIZE_ERROR:
      return {
        ...state,
        screenSizeError: action.payload.status
      };
    case actionUI.UPDATE_SCREEN_RATE:
      return {
        ...state,
        screen: { ...state.screen, rate: action.payload.rate }
      };
    case actionUI.UPDATE_GALLERY_EXPANDED:
      return updateGalleryExpanded(state, action);
    case actionUI.UPDATE_BOOK_OVERVIEW_EXPANDED:
      return updateBookOverviewExpanded(state, action);
    case actionUI.TOGGLE_MODO_IMPRESSAO:
      return toggleModoImpressao(state, action);
    case actionUI.TOGGLE_LEGENDA:
      return toggleLegenda(state, action);
    case actionUI.SET_STATE_LEGENDA:
      return setStateLegenda(state, action);
    case actionUI.TOGGLE_BIBLIOTECA_TEMAS:
      return toggleBibliotecaTemas(state, action);
    case actionUI.SET_MODAL_CONFIG:
      return setModalConfig(state, action);
    case actionUI.UPDATE_POPUP:
      return {
        ...state,
        popup: {
          ...state.popup,
          id: action.payload.id,
          overlay: action.payload.overlay,
          msg: action.payload.msg
        }
      };
    case actionUI.UPDATE_FETCH_STATUS:
      return {
        ...state,
        dataFetched: action.payload.status
      };
    case actionUI.UPDATE_IDB_STATUS:
      return {
        ...state,
        IDB: action.payload.status
      };
    case actionUI.UPDATE_TUTORIAL_STATUS:
      return {
        ...state,
        tutorial: action.payload.status
      };
    case actionUI.UPDATE_THUMB_EXPAND:
      return {
        ...state,
        thumbExpand: {
          active: action.payload.status || '',
          id: action.payload.id || ''
        }
      };
    case actionUI.SET_START_DRAG:
      return {
        ...state,
        drag: {
          ...state.drag,
          isDragging: true,
          id: action.payload.id,
          start: {
            pos: {
              x: action.payload.pos.x,
              y: action.payload.pos.y
            },
            mousePos: {
              x: action.payload.mousePos.x,
              y: action.payload.mousePos.y
            }
          }
        }
      };
    case actionUI.SET_STOP_DRAG:
      return {
        ...state,
        drag: {
          ...state.drag,
          isDragging: false
        }
      };
    case actionUI.UPDATE_NAV_SELECTED:
      return {
        ...state,
        nav: {
          ...state.nav,
          selected: action.payload.selected,
          previous: state.nav.selected
        }
      };
    case actionUI.UPDATE_PHOTO_EDITOR:
      return {
        ...state,
        photoEditor: {
          active: !state.photoEditor.active,
          frameId: action.payload.id
        }
      };
    case actionUI.UPDATE_TOOLBAR_PHOTO:
      return {
        ...state,
        toolbarPhoto: {
          maxThumbs: action.payload.maxThumbs,
          thumbsLength: action.payload.thumbsLength,
          prevThumbLenth: action.payload.prevThumbLenth,
          thumbListLeft: action.payload.thumbListLeft,
          thumbListRight: action.payload.thumbListRight,
          thumbsListExpanded: action.payload.thumbsListExpanded
        }
      };
    case actionUI.UPDATE_TEXT_OPTION:
      switch (action.payload.option) {
        case FAMILY:
          return {
            ...state,
            textOptions: {
              ...state.textOptions,
              family: action.payload.value
            }
          };
        case FAMILY_SIZE:
          return {
            ...state,
            textOptions: {
              ...state.textOptions,
              family: action.payload.value,
              size: calculaSizeFamily(action.payload.value, state.textOptions),
              sizeArray: calculaSizeArrayFamily(
                'dog-collar',
                action.payload.value
              )
            }
          };
        case SIZE:
          return {
            ...state,
            textOptions: {
              ...state.textOptions,
              size: action.payload.value
            }
          };
        case BOLD:
          return {
            ...state,
            textOptions: {
              ...state.textOptions,
              textStyle: {
                ...state.textOptions.textStyle,
                bold: !state.textOptions.textStyle.bold
              }
            }
          };
        case ITALIC:
          return {
            ...state,
            textOptions: {
              ...state.textOptions,
              textStyle: {
                ...state.textOptions.textStyle,
                italic: !state.textOptions.textStyle.italic
              }
            }
          };
        case UNDERLINE:
          return {
            ...state,
            textOptions: {
              ...state.textOptions,
              textStyle: {
                ...state.textOptions.textStyle,
                underline: !state.textOptions.textStyle.underline
              }
            }
          };
        case ALIGN:
          return {
            ...state,
            textOptions: {
              ...state.textOptions,
              textStyle: {
                ...state.textOptions.textStyle,
                align: action.payload.value
              }
            }
          };
        case COLOR:
          return {
            ...state,
            textOptions: {
              ...state.textOptions,
              color: action.payload.value
            }
          };
        case ACTIVE:
          return {
            ...state,
            textOptions: {
              ...state.textOptions,
              active: action.payload.value,
              pageId: action.payload.pageId
            }
          };
        case ALL:
          return {
            ...state,
            textOptions: {
              ...state.textOptions,
              color: action.payload.value.color,
              family: action.payload.value.family,
              size: action.payload.value.size,
              sizeArray: calculaSizeArrayFamily(
                action.payload.value.prodType,
                action.payload.value
              ),
              textStyle: {
                bold: action.payload.value.bold,
                italic: action.payload.value.italic,
                underline: action.payload.value.underline,
                align: action.payload.value.align
              }
            }
          };
        default:
          return state;
      }
    case actionUI.UPDATE_WARNING:
      return {
        ...state,
        warning: {
          active: action.payload.active,
          text: action.payload.text.length
            ? action.payload.text
            : state.warning.text
        }
      };
    default:
      return state;
  }
};

export const calculaSizeFamily = (family, textOptions) => {
  let size = textOptions.size;

  switch (family) {
    case 'Abril Fatface':
      size = 26;
      break;
    case 'Arvo':
      size = 25;
      break;
    case 'Blokletters Balpen':
      size = 19;
      break;
    case 'Cookie':
      size = 27;
      break;
    case 'Gandhi Sans':
      size = 27;
      break;
    case 'GoodDog':
      size = 30;
      break;
    case 'Grand Hotel':
      size = 23;
      break;
    case 'Josefin Sans':
      size = 26;
      break;
    case 'Liberation Sans':
      size = 28;
      break;
    case 'Liberation Serif':
      size = 28;
      break;
    case 'Lobster Two':
      size = 24;
      break;
    case 'Walkway':
      size = 29;
      break;
    default:
      break;
  }

  return size;
};

export const calculaSizeArrayFamily = (prodType, textOptions) => {
  const family = textOptions.family;
  let array = initialState.textOptions.sizeArray;

  if (prodType === 'dog-collar') {
    switch (family) {
      case 'Abril Fatface':
        array = [26, 28];
        break;
      case 'Arvo':
        array = [25, 27];
        break;
      case 'Blokletters Balpen':
        array = [19, 21];
        break;
      case 'Cookie':
        array = [27, 29];
        break;
      case 'Gandhi Sans':
        array = [27, 29];
        break;
      case 'GoodDog':
        array = [30, 33];
        break;
      case 'Grand Hotel':
        array = [23, 24];
        break;
      case 'Josefin Sans':
        array = [26, 28];
        break;
      case 'Liberation Sans':
        array = [28, 30];
        break;
      case 'Liberation Serif':
        array = [28, 31];
        break;
      case 'Lobster Two':
        array = [24, 26];
        break;
      case 'Walkway':
        array = [29, 31];
        break;
      default:
        break;
    }
  } else if (prodType === 'invitation') {
    const size = calcCustomSize(textOptions.size);
    array = [size - 1, size, size + 1];
  }
  return array;
};

export default reducer;
