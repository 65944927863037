import './index.css';

import {
  NAV_BOOK_SELECTED,
  NAV_IMAGE_SELECTED,
  NAV_LAYOUTS_SELECTED,
  NAV_NONE_SELECTED,
  NAV_TEXT_SELECTED,
  NAV_FUNDOS_SELECTED
} from '../../constant/nav';
import React, { Component } from 'react';

import NavBook from '../../container/nav-book';
import NavImage from '../../container/nav-image';
import NavLayouts from '../../container/nav-layouts';
import NavFundos from '../../container/nav-fundos';
import NavText from '../../container/nav-text';
import PropTypes from 'prop-types';

class NavManager extends Component {
  closeNav = ({ target }) => {
    const { current, setNavNone } = this.props;
    
    if (current !== NAV_NONE_SELECTED) {
      const navManager = document.querySelector('.nav-manager');
      const textBoxes = document.querySelectorAll('.text-box');
      const menuPhotoBtn =
        document.querySelector('.bt-menu-photo') ||
        document.querySelector('.bt-menu-photo-pressed');
      const menuBookBtn =
        document.querySelector('.bt-menu-book') ||
        document.querySelector('.bt-menu-book-pressed');
      const menuStyleManagerBtns = document.querySelectorAll(
        '.bt-menu-style-manager, .bt-menu-style-manager-pressed, .bt-fundo, .bt-fundo-active'
      );

      let containTextBox = 0;
      let containMenuStyleBtn = 0;

      menuStyleManagerBtns.forEach(btn => {
        if (btn.contains(target)) {
          containMenuStyleBtn += 1;
        }
      });

      textBoxes.forEach(textBox => {
        if (
          target.classList.contains(
            textBox.classList[textBox.classList.length - 1]
          )
        ) {
          containTextBox += 1;
        }
      });

      ( !navManager || !navManager.contains(target) ) &&
      ( !menuPhotoBtn ||  !menuPhotoBtn.contains(target) ) &&
      ( !menuBookBtn || !menuBookBtn.contains(target) ) &&
        !containMenuStyleBtn &&
        !containTextBox &&
        setNavNone();
    }
  };

  componentDidMount() {
    window.addEventListener('click', this.closeNav);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.closeNav);
  }

  render() {
    const { current } = this.props;
    return (
      <div className="nav-manager">
        <div className={`bar${current === NAV_NONE_SELECTED ? '' : ' active'}`}>
          <div className="container">
            {current === NAV_IMAGE_SELECTED ? (
              <NavImage />
            ) : current === NAV_BOOK_SELECTED ? (
              <NavBook />
            ) : current === NAV_LAYOUTS_SELECTED ? (
              <NavLayouts />
            ) : current === NAV_FUNDOS_SELECTED ? (
              <NavFundos />
            ) : current === NAV_TEXT_SELECTED ? (
              <NavText />
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

NavManager.propTypes = {
  current: PropTypes.string.isRequired,
  setNavNone: PropTypes.func.isRequired
};

NavManager.defaultProps = {
  current: ''
};

export default NavManager;
