import PropTypes from 'prop-types';
import React from 'react';
import { screenSizePropTypes } from '../../proptypes';
import { poupInvalidas } from '../../constant/popup';

import {
  THEME_CLASS_LEGENDA,
  THEME_CLASS_LEGENDA_PRESSED
} from '../../constant/button';

const BotaoLegenda = ({
  isBookOverviewExpanded,
  toggleLegenda,
  isLegendaAberta,
  screenSize,
  popupId,
  tutorialStatus
}) => {
  const headerElement = document.querySelector('#product-header');
  let headerPadding = 0;
  if (headerElement)
    headerPadding = parseFloat(
      getComputedStyle(headerElement).paddingRight.substring(
        0,
        getComputedStyle(headerElement).paddingRight.length - 2
      )
    );

  return (
    <input
      className={`button botaoLegenda ${isLegendaAberta ? THEME_CLASS_LEGENDA_PRESSED : THEME_CLASS_LEGENDA
        }`}
      data-tip={
        isBookOverviewExpanded
          ? ''
          : 'Comandos pelo teclado: Exibe as funções de cada tecla, em determinada visualização'
      }
      data-place="bottom"
      type="button"
      style={{
        position: 'absolute',
        top: '11px',
        right: `${40 + 126 + headerPadding + screenSize.width * 0.012}px`,
        width: '42px',
        height: '38px',
        zIndex: 888,
        visibility:
          !poupInvalidas.includes(popupId) &&
            tutorialStatus !== 'ESCOLHER-TAMANHO-COLEIRA'
            ? ''
            : 'hidden'
      }}
      onClick={() => toggleLegenda()}
    />
  );
};

BotaoLegenda.propTypes = {
  isBookOverviewExpanded: PropTypes.bool.isRequired,
  currentNav: PropTypes.string.isRequired,
  toggleLegenda: PropTypes.func.isRequired,
  isLegendaAberta: PropTypes.bool.isRequired,
  screenSize: screenSizePropTypes,
  popupId: PropTypes.string.isRequired,
  tutorialStatus: PropTypes.string.isRequired
};

export default BotaoLegenda;
