import './modalConfig.css';
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Button from '../../button';
import { THEME_CLASS_BT_EDITOR_CLOSE } from '../../../constant/button';
import { 
  THEME_CLASS_ORANGE,
  THEME_CLASS_ORANGE_DISABLED 
} from '../../../constant/button';

function ModalConfig(
  {
    prodType,
    setModalConfig,
    modalConfig,
  })
{

  const [selected, setSelected] = useState(null);

  const options = [
    {
      title: 'Alterar o tipo de produto',
      subTitle: 'Inicia um novo produto, mantendo suas imagens na Mesa',
      modalConfig: 'product-type',
    },
    {
      title: 'Alterar o formato',
      subTitle: 'Possibilita diminuir ou aumentar, o tamanho do seu produto',
      modalConfig: 'format',
    },
    {
      title: 'Alterar a orientação',
      subTitle: 'Muda seu produto para a orientação paisagem ou retrato',
      modalConfig: 'orientation',
    },
    {
      title: 'Alterar o acabamento',
      subTitle: 'Modifica as opções de acabamento existentes para seu produto',
      modalConfig: 'acabamento',
    },
  ]

  if(prodType === 'poster' && modalConfig === 'config')
    return (
      <div className="modal-config">
        <div className="container">
          <div className="btn-close-modal">
            <Button
              padding={false}
              theme={THEME_CLASS_BT_EDITOR_CLOSE}
              clickHandler={() => {setModalConfig();setSelected(null);}}
            />
          </div>
          <div className="options">
            <div className="title">Configurações</div>
            <div className="subtitle">Altere o tipo de produto, o formato, a orientação e o acabamento</div>
            <div className="select-option">
              {options.map((item, index) =>
                <div className={`item-option ${selected === item.modalConfig ? 'active':''}`}
                  key={index}
                  onClick={() => setSelected(item.modalConfig)}>
                  <div className='title'>{item.title}</div>
                  <div className='subtitle'>{item.subTitle}</div>
                </div>
              )}
            </div>
            <Button
              label={"Selecionar"}
              style={{marginTop: "22px"}}
              theme={selected != null ? THEME_CLASS_ORANGE: THEME_CLASS_ORANGE_DISABLED}
              clickHandler={() => {setModalConfig(selected);setSelected(null);}}
            /> 
          </div>
        </div>
      </div>
    );
  else
    return null;
}

ModalConfig.propTypes = {
  prodType: PropTypes.string.isRequired,
  setModalConfig: PropTypes.func.isRequired,
  modalConfig: PropTypes.string,
  pageFormat: PropTypes.string.isRequired,
  productFormat: PropTypes.string.isRequired,
  postMetadataFormat: PropTypes.func.isRequired,
  postMetadataOrientation: PropTypes.func.isRequired,
  itemId: PropTypes.number,
};

export default ModalConfig;