import PropTypes from 'prop-types';

export const maxMinPropTypes = PropTypes.shape({
  max: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired
}).isRequired;

export const thumbListPropTypes = PropTypes.shape({
  start: PropTypes.number.isRequired,
  end: PropTypes.number.isRequired
}).isRequired;

export const sourceImagePropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  blobUrl: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired
}).isRequired;

export const shapePropTypes = PropTypes.shape({
  id: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  left: PropTypes.number.isRequired,
  top: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  mask: PropTypes.string,
  type: PropTypes.string.isRequired
}).isRequired;

const imagePropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  metadata: PropTypes.shape({
    id: PropTypes.string.isRequired,
    lastModified: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    size: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired
  }).isRequired,
  source: PropTypes.shape({
    image: sourceImagePropTypes,
    thumb: sourceImagePropTypes
  }).isRequired,
  staged: PropTypes.bool.isRequired,
  uploaded: PropTypes.bool.isRequired,
  used: PropTypes.number.isRequired
}).isRequired;

export const textareaPropTypes = PropTypes.shape({
  id: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  align: PropTypes.string,
  color: PropTypes.string.isRequired,
  default: PropTypes.string.isRequired,
  enabled: PropTypes.bool.isRequired,
  fill: PropTypes.bool.isRequired,
  format: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  left: PropTypes.number.isRequired,
  rotation: PropTypes.number.isRequired,
  size: PropTypes.number.isRequired,
  top: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired
}).isRequired;

export const positionPropTypes = PropTypes.shape({
  bottom: PropTypes.number.isRequired,
  top: PropTypes.number.isRequired,
  left: PropTypes.number.isRequired,
  right: PropTypes.number.isRequired
}).isRequired;

export const framePropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  image: imagePropTypes,
  shape: shapePropTypes
}).isRequired;

const pageFormatImagePropTypes = PropTypes.shape({
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  top: PropTypes.number.isRequired,
  left: PropTypes.number.isRequired
});

export const stylePropTypes = PropTypes.shape({
  cover: PropTypes.bool.isRequired,
  page: PropTypes.bool.isRequired,
  lastpage: PropTypes.bool.isRequired,
  back_cover: PropTypes.bool.isRequired,
  spine: PropTypes.bool.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  opacity: PropTypes.number,
  sequence: PropTypes.number,
  shape: PropTypes.arrayOf(shapePropTypes),
  textarea: PropTypes.arrayOf(textareaPropTypes)
});

const textOptionsRichTextPropTypes = PropTypes.shape({
  align: PropTypes.string,
  bold: PropTypes.bool,
  color: PropTypes.string,
  family: PropTypes.string,
  italic: PropTypes.bool,
  size: PropTypes.number,
  underline: PropTypes.bool,
  x: PropTypes.number,
  y: PropTypes.number,
  l: PropTypes.number,
  a: PropTypes.number
}).isRequired;

export const textPropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  richTextState: PropTypes.string.isRequired,
  textOptions: textOptionsRichTextPropTypes
}).isRequired;

const pageFormatPropTypes = PropTypes.shape({
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  top: PropTypes.number.isRequired,
  left: PropTypes.number.isRequired,
  logo: pageFormatImagePropTypes.isRequired,
  ean: pageFormatImagePropTypes,
  url: pageFormatImagePropTypes,
  spread: PropTypes.bool,
  debru: PropTypes.number
}).isRequired;

export const pagePropTypes = PropTypes.shape({
  frames: PropTypes.arrayOf(framePropTypes).isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  index: PropTypes.number.isRequired,
  label: PropTypes.string,
  position: PropTypes.string,
  style: stylePropTypes,
  texts: PropTypes.arrayOf(textPropTypes).isRequired
});

export const pagesFormatPropTypes = PropTypes.shape({
  page: pageFormatPropTypes,
  cover: PropTypes.oneOfType([pageFormatPropTypes, PropTypes.array]),
  spine: PropTypes.oneOfType([pageFormatPropTypes, PropTypes.array])
}).isRequired;

export const printOptionsPropTypes = PropTypes.shape({
  bleed: PropTypes.number.isRequired,
  dpi: PropTypes.number.isRequired,
  ean: PropTypes.string.isRequired,
  format: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  pdfWidth: PropTypes.number.isRequired,
  pdfHeight: PropTypes.number.isRequired,
  pdfBleed: PropTypes.number.isRequired,
  pdfCoverWidth: PropTypes.number.isRequired,
  pdfCoverHeight: PropTypes.number.isRequired,
  pdfSpineWidth: PropTypes.number.isRequired,
  debru: PropTypes.shape({
    cover: positionPropTypes,
    back_cover: positionPropTypes
  })
}).isRequired;

export const photoEditorPropTypes = PropTypes.shape({
  active: PropTypes.bool.isRequired,
  frameId: PropTypes.string
}).isRequired;

export const screenSizePropTypes = PropTypes.shape({
  headerHeight: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  layoutsContainerWidth: PropTypes.number.isRequired,
  photobookHeight: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired
}).isRequired;

export const textOptionsPropTypes = PropTypes.shape({
  active: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  color: PropTypes.string.isRequired,
  family: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  colorArray: PropTypes.arrayOf(PropTypes.string).isRequired,
  familyArray: PropTypes.arrayOf(PropTypes.string).isRequired,
  sizeArray: PropTypes.arrayOf(PropTypes.number).isRequired,
  textStyle: PropTypes.shape({
    align: PropTypes.string.isRequired,
    bold: PropTypes.bool,
    italic: PropTypes.bool,
    underline: PropTypes.bool
  }).isRequired
}).isRequired;

export const navigatorPropTypes = PropTypes.shape({
  cols: PropTypes.number.isRequired,
  lines: PropTypes.number.isRequired,
  pages: PropTypes.arrayOf(pagePropTypes).isRequired,
  endpapers: PropTypes.shape({
    after: PropTypes.shape({
      pages: PropTypes.arrayOf(pagePropTypes)
    }),
    before: PropTypes.shape({
      pages: PropTypes.arrayOf(pagePropTypes)
    })
  }),
  covers: PropTypes.shape({
    back_cover: pagePropTypes,
    spine: pagePropTypes,
    cover: pagePropTypes
  })
}).isRequired;

export const imagemPropTypes = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  src: PropTypes.shape({
    image: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    size: PropTypes.number.isRequired
  })
});

export const bibliotecaTemaPropTypes = PropTypes.shape({
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  thumb: PropTypes.string.isRequired,
  images: PropTypes.arrayOf(imagemPropTypes)
}).isRequired;

export const windowSizePropTypes = PropTypes.shape({
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired
});
