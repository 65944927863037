import { getProductType } from '../selector';
import store from '../store';

function getTitle() {
  const state = store.getState();
  const type = state.library.product ? getProductType(state) : '';

  switch (type) {
    case 'book':
      return 'FotoLivro';
    case 'calendar':
      return 'FotoCalendário';
    case 'mask':
      return 'Máscara';
    case 'dog-collar':
      return 'Coleira';
    case 'poster':
      return 'Pôster';
    case 'deck-cards':
      return 'Baralho';
    case 'invitation':
      return 'Convite';
    default:
      return 'FotoLivro';
  }
}

export default getTitle;
