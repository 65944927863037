import './index.css';

import { screenSizePropTypes } from '../../proptypes';
import PropTypes from 'prop-types';
import React from 'react';
import PageStepper from './page-stepper';

const formatNumber = number => (number > 9 ? number : `0${number}`);

const NumericStepper = ({
  value,
  valueId,
  nextValue,
  previousValue,
  increaseHandler,
  decreaseHandler,
  mouseUpHandler,
  increaseClickHandler,
  decreaseClickHandler,
  screenSize,
  layoutsFilter,
  maxMinShapes,
  layouts,
  type,
  array,
  index,
  updateTextOptions,
  vertical,
  increaseLayoutFilterPhotoNumber,
  decreaseLayoutFilterPhotoNumber,
  disabled
}) => {
  const { min, max } = maxMinShapes;

  return (
    <div
      className="numeric-stepper"
      onMouseUp={mouseUpHandler}
      onMouseOut={mouseUpHandler}
      onMouseLeave={mouseUpHandler}
    >
      {vertical ? (
        <div className="stepper-container vertical">
          <button
            type="button"
            title="Anterior"
            className={`increase${disabled ? ' disabled' : ''}`}
            style={{ marginBottom: '11px' }}
            onClick={() => {
              if (!disabled)
                index === 0
                  ? updateTextOptions(type, array[array.length - 1])
                  : updateTextOptions(type, array[index - 1]);
            }}
          />
          <button
            type="button"
            title="proximo"
            className={`decrease${disabled ? ' disabled' : ''}`}
            style={{ marginTop: '11px' }}
            onClick={() => {
              if (!disabled)
                index === array.length - 1
                  ? updateTextOptions(type, array[0])
                  : updateTextOptions(type, array[index + 1]);
            }}
          />
        </div>
      ) : layouts ? (
        <div className="stepper-container">
          <button
            type="button"
            title="Diminuir quantidade de imagens"
            className={`decrease${layoutsFilter === min ? ' disabled' : ''}`}
            data-tip="Diminuir quantidade de imagens"
            data-place="bottom"
            onClick={() =>
              layoutsFilter !== min && decreaseLayoutFilterPhotoNumber()
            }
            style={{ margin: `0 ${screenSize.width * 0.006}px 0 0` }}
          />
          <span className="number-value">{formatNumber(layoutsFilter)}</span>
          <button
            type="button"
            title="Aumentar quantidade de imagens"
            className={`increase${layoutsFilter === max ? ' disabled' : ''}`}
            data-tip="Aumentar quantidade de imagens"
            data-place="bottom"
            onClick={() =>
              layoutsFilter !== max && increaseLayoutFilterPhotoNumber()
            }
            style={{ margin: `0 ${screenSize.width * 0.006}px` }}
          />
        </div>
      ) : (
        <PageStepper
          value={value}
          valueId={valueId}
          nextValue={nextValue}
          previousValue={previousValue}
          increaseHandler={increaseHandler}
          decreaseHandler={decreaseHandler}
          mouseUpHandler={mouseUpHandler}
          increaseClickHandler={increaseClickHandler}
          decreaseClickHandler={decreaseClickHandler}
          screenSize={screenSize}
        />
      )}
    </div>
  );
};

NumericStepper.propTypes = {
  value: PropTypes.string.isRequired,
  valueId: PropTypes.string.isRequired,
  nextValue: PropTypes.string.isRequired,
  previousValue: PropTypes.string.isRequired,
  increaseHandler: PropTypes.func.isRequired,
  decreaseHandler: PropTypes.func.isRequired,
  mouseUpHandler: PropTypes.func.isRequired,
  increaseClickHandler: PropTypes.func.isRequired,
  decreaseClickHandler: PropTypes.func.isRequired,
  screenSize: screenSizePropTypes,
  layoutsFilter: PropTypes.number.isRequired,
  styleType: PropTypes.string,
  maxMinShapes: PropTypes.shape({
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired
  }).isRequired,
  layouts: PropTypes.bool,
  type: PropTypes.string,
  array: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
  index: PropTypes.number,
  updateTextOptions: PropTypes.func,
  vertical: PropTypes.bool,
  increaseLayoutFilterPhotoNumber: PropTypes.func.isRequired,
  decreaseLayoutFilterPhotoNumber: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

NumericStepper.defaultProps = {
  increaseHandler: null,
  decreaseHandler: null,
  mouseUpHandler: null,
  increaseClickHandler: null,
  decreaseClickHandler: null,
  disabled: false
};

export default NumericStepper;
