import * as actionGeneral from '../action/general';
import * as actionItem from '../action/item';
import * as actionUI from '../action/ui';
import store from '../store';

import { 
  bookHaveImage,
  bookHaveText,
  getScreenSize,
  getProductType,
  getIsModoSobFivela,
 } from '../selector';

import { CLEAR_BOOK } from '../constant/popup-message';
import NavBook from '../component/nav-book';
import { POPUP_ID_CLEAR_BOOK } from '../constant/popup';
import { connect } from 'react-redux';

const mapStateToProps = state => ({
  screenSize: getScreenSize(state),
  bookHaveImage: bookHaveImage(state),
  bookHaveText: bookHaveText(state),
  prodType: getProductType(state),
});

const sagaInsertPagesHandler = (dispatch) => {
  const state = store.getState();
  const prodType = getProductType(state);
  const isModoSobFivela = getIsModoSobFivela(state);

  if(prodType === 'deck-cards' && isModoSobFivela)
    dispatch(actionItem.toggleModoSobFivela());

  dispatch(actionGeneral.sagaInsertPages());
};

const mapDispatchToProps = dispatch => ({
  insertImagesToBook: () => sagaInsertPagesHandler(dispatch),
  removeImagesFromBook: () => {
    dispatch(actionUI.setStateLegenda('POPUP'));
    return dispatch(actionUI.updatePopup(POPUP_ID_CLEAR_BOOK, true, CLEAR_BOOK()));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(NavBook);
