export function calculoY(y, isPrint, productType, prodFormat)
{
    if(isPrint && productType === 'mask')
    {
        // mascara antiga (2785-1359)+3;
        // return 1429;
        // 2283-1112 = 1171;
        return 1172;
    }

    return y;
}
