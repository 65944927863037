import { connect } from 'react-redux';
import Coupon from '../../component/coupon';
import coupons from '../../constant/coupon';
import { getProductCode } from '../../selector';

const mapStateToProps = state => {
  const productCode = getProductCode(state);

  return {
    mockupImg: coupons[productCode].mockup
  };
};

export default connect(mapStateToProps)(Coupon);
