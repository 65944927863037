import React from 'react';

export function imprimirTexto(texto) {
    if (typeof texto === "object" && texto.length > 0){
        return texto.map(
            (texto, i) => <div key={i}>{texto}</div>
        );
    }
    else
        return texto;
}