import PropTypes from 'prop-types';
import React from 'react';
import {
  checaFundoComLogo
} from '../../utils/calculosFundos';

const Logo = ({
  prodType,
  isModoSobFivela,
  fundoColor,
  bleed
}) => {
    if(prodType === 'deck-cards' && checaFundoComLogo(isModoSobFivela, fundoColor))
      return(
        <image
            x={30+bleed}
            y={71+bleed}
            width={30}
            height={290}
            pointerEvents= "none"
            href={require(`../../asset/logo/${prodType}/logo.png`)}/>
      );
  return null;
};

Logo.propTypes = {
  prodType: PropTypes.string.isRequired,
  isModoSobFivela: PropTypes.bool.isRequired,
  fundoColor: PropTypes.string.isRequired,
  bleed: PropTypes.number.isRequired,
};

export default Logo;
