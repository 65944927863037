import './index.css';

import { ACTIVE, ALL } from '../../constant/textStyle';
import React, { Component } from 'react';

import PropTypes from 'prop-types';
import WarningIcon from '../../asset/icone_atencao.png';
import { textOptionsPropTypes } from '../../proptypes';
import {
  haveBarCodeFunc,
  notBreakWord,
  isCorCaixaTextoPadrao,
  disablePasteText
} from '../../utils/separadorProdutos';

import { fundoTexto } from '../../utils/calculosFundos';

import { getWarnMsgTexto } from '../../utils/textosVariaveis';
import {
  fontSpineOpt,
  paddingTopColeira,
  lineHeight,
  fontSize,
  calcularDimensaoTextoColeira,
  haveAdjustment
} from '../../utils/calculoRichText';

class RichText extends Component {
  state = {
    active: false,
    warningIcon: false
  };

  componentDidMount() {
    const {
      pageId,
      richTextId,
      textChangeHandler,
      editorState,
      family,
      isBookOverviewExpanded
    } = this.props;
    this.checkTextOverflow();

    !family &&
      !isBookOverviewExpanded &&
      textChangeHandler(
        richTextId,
        pageId,
        editorState.length ? editorState : '',
        true
      );
  }

  componentDidUpdate(prevProps) {
    const {
      id,
      pageId,
      richTextId,
      textChangeHandler,
      textOptions
    } = this.props;

    const textBoxActive = document.querySelector(
      `.text-box.active.${richTextId}`
    );

    this.checkTextOverflow();

    if (textBoxActive) textBoxActive.focus();

    if (
      prevProps.textOptions !== textOptions &&
      textBoxActive &&
      textOptions.active === id &&
      textOptions.pageId === pageId
    ) {
      textChangeHandler(richTextId, pageId, textBoxActive.value);
    }
  }

  toggleActive = () => {
    this.setState({ active: !this.state.active });
  };

  checkNavText = () => {
    let isNavText = false;
    Array.from(document.querySelectorAll(':hover')).forEach(el => {
      if (el.classList.contains('nav-text')) isNavText = true;
    });
    return isNavText;
  };

  checkTextOverflow = () => {
    const { richTextId } = this.props;
    const { warningIcon } = this.state;
    const textBox =
      document.querySelector(`div.text-box.${richTextId}`) || false;
    const spanBox = document.querySelector(`span.${richTextId}`);
    let warningShow =
      textBox && spanBox && spanBox.offsetHeight > textBox.offsetHeight;

    if (this.props.prodType === 'dog-collar')
      warningShow = textBox && textBox.scrollWidth > textBox.clientWidth;

    warningIcon !== warningShow && this.setState({ warningIcon: !warningIcon });
  };

  render() {
    const {
      id,
      espelhado,
      pageId,
      richTextId,
      editorState,
      x,
      xAdjustment,
      y,
      width,
      widthAdjustment,
      height,
      pageWidth,
      pageHeight,
      color,
      family,
      size,
      align,
      bold,
      italic,
      underline,
      active,
      defaultText,
      isMenuTextSelected,
      textOptions,
      rate,
      toggleNavText,
      textChangeHandler,
      updateTextOptions,
      photoEditorActive,
      format,
      orientation,
      screenRate,
      isBookOverviewExpanded,
      isPrint,
      isLastPage,
      isScodix,
      updateWarning,
      isModoSobFivela,
      prodType,
      prodCode,
      tamanhoSelecionado,
      setStateLegenda
    } = this.props;

    const customWidth =
      isModoSobFivela && haveAdjustment(prodType)
        ? width + widthAdjustment
        : width;
    const customX =
      isModoSobFivela && haveAdjustment(prodType) ? x - xAdjustment : x;

    const { active: stateActive, warningIcon } = this.state;
    const fontFamilySpine =
      family === undefined
        ? textOptions.family.split(' ').join('')
        : family.split(' ').join('');
    const shouldBlink =
      isScodix &&
      (pageId === 'cover' || pageId === 'spine' || pageId === 'back_cover');

    const warnSize =
      width / screenRate < 24 || height / screenRate < 24
        ? Math.min(width, height)
        : screenRate * 24;

    const paddingTop = (
      prodType,
      stateActive,
      pageId,
      fontSpineOpt,
      format,
      orientation,
      family,
      size
    ) => {
      let aux = 0;
      if (pageId === 'spine' && !stateActive)
        aux = `${fontSpineOpt[fontFamilySpine].top[format][orientation]}pt`;
      else if (prodType === 'dog-collar') {
        aux = paddingTopColeira(family, size);
      }
      return aux;
    };

    const textStyles = (editMode = false) => {
      return {
        fontFamily: `${family || textOptions.family}`,
        fontSize: `${pageId === 'spine'
            ? fontSpineOpt[fontFamilySpine].size
            : fontSize(
              editorState.length <= 0,
              editMode,
              prodType,
              family,
              size ? size : textOptions.size
            )
          }px`,
        textAlign: `${!stateActive && !editorState.length ? 'center' : align}`,
        backgroundColor: fundoTexto(prodType, !editorState.length, isPrint),
        fontWeight: `${bold ? `bold` : `normal`}`,
        fontStyle: `${italic ? `italic` : `normal`}`,
        textDecoration: `${underline ? `underline` : `none`}`,
        color: `${color}`,
        borderWidth: `${isPrint || (pageId === 'spine' && !stateActive)
            ? 0
            : isMenuTextSelected
              ? rate * 2
              : rate
          }px`,
        cursor: isBookOverviewExpanded ? 'pointer' : 'text',
        wordBreak: notBreakWord.includes(prodType) ? 'keep-all' : 'break-word',
        whiteSpace: notBreakWord.includes(prodType) ? 'pre' : 'pre-wrap',
        opacity: photoEditorActive ? 0.25 : 1,
        lineHeight:
          pageId === 'spine'
            ? 1
            : lineHeight(
              prodType,
              family || textOptions.family,
              size ? size : textOptions.size
            ),
        paddingTop: paddingTop(
          prodType,
          stateActive,
          pageId,
          fontSpineOpt,
          format,
          orientation,
          family || textOptions.family,
          size ? size : textOptions.size
        )
      };
    };

    const isRight = x + width >= pageWidth - 277 - 69;
    const isBottom = y + height >= pageHeight - 79 - 69;
    const haveBarcode = isRight && isBottom;
    const maskY = 79 + 14 - (pageHeight - 69 - y - height);
    const maskX = pageWidth - 69 - x - width;

    if (espelhado && (editorState == null || editorState.length <= 0))
      return null;
    else {
      return (
        <svg
          x={customX}
          y={y}
          width={pageId === 'spine' ? height : customWidth}
          height={pageId === 'spine' ? customWidth : height}
          viewBox={`0 0 ${pageId === 'spine' ? height : customWidth} ${pageId === 'spine' ? customWidth : height
            }`}
          className={`rich-text ${active ? 'active' : ''}`}
        >
          <foreignObject
            x="0"
            y={pageId === 'spine' ? -height : 0}
            width={customWidth}
            height={height}
            transform={pageId === 'spine' ? `rotate(90)` : null}
          >
            <div
              className={`photoEditorLayer ${photoEditorActive ? 'visible' : ''
                }`}
            ></div>
            {!stateActive ? (
              <div
                name="text-box"
                className={`${!editorState.length && !isCorCaixaTextoPadrao(prodCode)
                    ? 'text-box-white'
                    : 'text-box'
                  } ${editorState.length && !stateActive ? 'have-content' : ''} ${shouldBlink && !isPrint ? 'text-blink' : ''
                  } ${richTextId}`}
                data-text={editorState.length || isPrint ? '' : defaultText}
                style={textStyles()}
                onClick={() => {
                  this.toggleActive();
                  if (!isBookOverviewExpanded) {
                    updateTextOptions(ACTIVE, id, pageId);
                    family !== undefined &&
                      updateTextOptions(ALL, {
                        color,
                        family,
                        size,
                        align,
                        bold,
                        italic,
                        underline,
                        prodType
                      });

                    toggleNavText();
                  }
                }}
              >
                {haveBarcode && isLastPage && haveBarCodeFunc(prodType) && (
                  <div
                    className="shape-barcode"
                    style={{
                      height: `${height}px`,
                      shapeOutside: `polygon(${maskX}px ${height -
                        maskY}px, 346px ${height -
                        maskY}px, 346px ${height}px, ${maskX}px ${height}px)`
                    }}
                  ></div>
                )}
                <span id="rich-text-span-id" className={richTextId}>
                  {editorState}
                </span>
              </div>
            ) : (
              <textarea
                name="text-box"
                disabled={espelhado}
                className={`text-box active ${richTextId}`}
                style={textStyles(true)}
                defaultValue={editorState}
                rows="1"
                onPaste={e => disablePasteText(prodType) && e.preventDefault()}
                onKeyDown={e => {
                  setStateLegenda('MESA');
                  if (e.keyCode === 13) e.preventDefault();
                }}
                onBlur={e => {
                  if (!this.checkNavText()) {
                    this.toggleActive();
                    if (!isBookOverviewExpanded) {
                      const textoMudado = e.target.value;
                      setTimeout(() => {
                        const result = calcularDimensaoTextoColeira(
                          prodType,
                          pageWidth,
                          pageHeight,
                          tamanhoSelecionado,
                          textoMudado,
                          family || textOptions.family,
                          size ? size : textOptions.size,
                          fontSize(
                            editorState.length <= 0,
                            false,
                            prodType,
                            family,
                            size
                          )
                        );
                        if (result)
                          textChangeHandler(
                            richTextId,
                            pageId,
                            textoMudado,
                            false,
                            result.x,
                            result.y,
                            result.l,
                            result.a
                          );
                        else
                          textChangeHandler(
                            richTextId,
                            pageId,
                            textoMudado,
                            false
                          );
                      }, 1);
                    }
                  }
                }}
              ></textarea>
            )}
          </foreignObject>
          {warningIcon && !isPrint && !espelhado && (
            <image
              href={WarningIcon}
              className={`warning-icon cursor-help ${richTextId}`}
              style={{
                width: `${warnSize}px`,
                height: `${warnSize}px`,
                opacity: photoEditorActive ? 0.25 : 1
              }}
              onMouseEnter={() =>
                !photoEditorActive && updateWarning(true, getWarnMsgTexto())
              }
              onMouseLeave={() => updateWarning()}
            ></image>
          )}
        </svg>
      );
    }
  }
}

RichText.propTypes = {
  id: PropTypes.number.isRequired,
  espelhado: PropTypes.bool,
  pageId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  richTextId: PropTypes.string.isRequired,
  editorState: PropTypes.string.isRequired,
  x: PropTypes.number.isRequired,
  xAdjustment: PropTypes.number,
  y: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  widthAdjustment: PropTypes.number,
  height: PropTypes.number.isRequired,
  rotation: PropTypes.number.isRequired,
  pageWidth: PropTypes.number.isRequired,
  pageHeight: PropTypes.number.isRequired,
  color: PropTypes.string,
  family: PropTypes.string,
  size: PropTypes.number,
  align: PropTypes.string,
  bold: PropTypes.bool,
  italic: PropTypes.bool,
  underline: PropTypes.bool,
  defaultText: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  isMenuTextSelected: PropTypes.bool.isRequired,
  textOptions: textOptionsPropTypes,
  rate: PropTypes.number.isRequired,
  toggleNavText: PropTypes.func.isRequired,
  textChangeHandler: PropTypes.func.isRequired,
  updateTextOptions: PropTypes.func.isRequired,
  photoEditorActive: PropTypes.bool.isRequired,
  format: PropTypes.string,
  orientation: PropTypes.string,
  screenRate: PropTypes.number.isRequired,
  isBookOverviewExpanded: PropTypes.bool.isRequired,
  isPrint: PropTypes.bool.isRequired,
  isLastPage: PropTypes.bool.isRequired,
  isScodix: PropTypes.bool,
  isModoSobFivela: PropTypes.bool.isRequired,
  prodType: PropTypes.string.isRequired,
  prodCode: PropTypes.string.isRequired,
  tamanhoSelecionado: PropTypes.number,
  setStateLegenda: PropTypes.func.isRequired
};

RichText.defaultProps = {
  x: 0,
  y: 0,
  active: false,
  rotation: 0
};

export default RichText;
