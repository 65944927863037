export const FETCH_FROM_IDB = 'ACTION_FETCH_FROM_IDB';
export const UPDATE_BLOB_URL = 'ACTION_UPDATE_BLOB_URL';
export const DELETE_ALL_IMAGES = 'ACTION_DELETE_ALL_IMAGES';
export const REMOVE_PAGES = 'ACTION_REMOVE_PAGES';
export const SAGA_UNSTAGE_STAGE_IMAGE = 'ACTION_SAGA_UNSTAGE_STAGE_IMAGE';
export const SET_STAGE_IMAGE = 'ACTION_SET_STAGE_IMAGE';
export const SET_UPLOAD_IMAGE = 'ACTION_SET_UPLOAD_IMAGE';
export const UPDATE_USED_IMAGE = 'ACTION_UPDATE_USED_IMAGE';
export const MOVE_IMAGE = 'ACTION_MOVE_IMAGE';
export const MERGE = 'ACTION_LIBRARY_MERGE';
export const UPDATE_ZOOM = 'ACTION_SAGA_UPDATE_ZOOM';
export const ROTATE_IMAGE = 'ACTION_SAGA_ROTATE_IMAGE';
export const DUPLICATE_PAGE = 'ACTION_SAGA_DUPLICATE_PAGE';
export const SAGA_FETCH_DATA = 'ACTION_SAGA_FETCH_DATA';
export const SAGA_START_METADATA_LOOP = 'ACTION_SAGA_START_METADATA_LOOP';
export const SAGA_POST_METADATA = 'ACTION_SAGA_POST_METADATA';
export const SAGA_START_IMAGE_LOOP = 'ACTION_SAGA_START_IMAGE_LOOP';
export const SAGA_POST_IMAGE = 'ACTION_SAGA_POST_IMAGE';
export const SAGA_STORE_SERVER_IMAGE = 'ACTION_SAGA_STORE_SERVER_IMAGE';
export const SAGA_REMOVE_STORED_SERVER_IMAGE =
  'ACTION_SAGA_REMOVE_STORED_SERVER_IMAGE';
export const SAGA_ADD_SERVER_IMAGE_UPLOADING =
  'ACTION_SAGA_ADD_SERVER_IMAGE_UPLOADING';
export const SAGA_REMOVE_SERVER_IMAGE_UPLOADING =
  'ACTION_SAGA_REMOVE_SERVER_IMAGE_UPLOADING';
export const SAGA_ADD_SERVER_IMAGE_UPLOAD_ENABLE =
  'ACTION_SAGA_ADD_SERVER_IMAGE_UPLOAD_ENABLE';
export const SAGA_SUBMIT = 'ACTION_SAGA_SUBMIT';
export const SAGA_INPUT_FILE_CHANGED = 'ACTION_SAGA_INPUT_FILE_CHANGED';
export const SAGA_ADD_THEME_IMAGE = 'ACTION_SAGA_ADD_THEME_IMAGE';
export const SAGA_THUMB_DROP_IMAGE = 'ACTION_SAGA_THUMB_DROP_IMAGE';
export const SAGA_INSERT_PAGES = 'ACTION_SAGA_INSERT_PAGES';
export const SAGA_REMOVE_PAGES = 'ACTION_SAGA_REMOVE_PAGES';
export const SAGA_REMOVE_TEXTS = 'ACTION_SAGA_REMOVE_TEXTS';
export const SAGA_MOVE_PAGE = 'ACTION_SAGA_MOVE_PAGE';
export const SAGA_INCREASE_PAGE_INDEX = 'ACTION_SAGA_INCREASE_PAGE_INDEX';
export const SAGA_DECREASE_PAGE_INDEX = 'ACTION_SAGA_DECREASE_PAGE_INDEX';
export const SAGA_SET_PAGE_INDEX = 'ACTION_SAGA_SET_PAGE_INDEX';
export const SAGA_FIRST_PAGE_INDEX = 'ACTION_SAGA_FIRST_PAGE_INDEX';
export const SAGA_LAST_PAGE_INDEX = 'ACTION_SAGA_LAST_PAGE_INDEX';
export const SAGA_SET_RICH_TEXT = 'ACTION_SAGA_SET_RICH_TEXT';
export const SAGA_REMOVE_FRAME = 'ACTION_SAGA_REMOVE_FRAME';
export const REMOVE_TEXTS_FROM_PAGE = 'ACTION_REMOVE_TEXTS_FROM_PAGE';
export const REMOVE_FRAME_FROM_PAGE = 'ACTION_REMOVE_FRAME_FROM_PAGE';
export const SAGA_DELETE_IMAGE_FROM_BOOK = 'ACTION_SAGA_DELETE_IMAGE_FROM_BOOK';
export const SAGA_SHUFFLE_LAYOUT = 'ACTION_SAGA_SHUFFLE_LAYOUT';
export const SAGA_SET_LAYOUT = 'ACTION_SAGA_SET_LAYOUT';
export const SAGA_OVERVIEW_REMOVE_PAGES = 'ACTION_SAGA_OVERVIEW_REMOVE_PAGES';
export const SAGA_UPDATE_THUMB_EXPAND = 'ACTION_SAGA_UPDATE_THUMB_EXPAND';
export const SET_CUSTOM_PRODUCTS = 'ACTION_SET_CUSTOM_PRODUCTS';
export const SAGA_SWAP_POSTER = 'ACTION_SAGA_SWAP_POSTER';
export const SAGA_POST_METADATA_FORMAT = 'ACTION_SAGA_POST_METADATA_FORMAT';
export const SAGA_POST_METADATA_ORIENTATION =
  'ACTION_SAGA_POST_METADATA_ORIENTATION';
export const TOGGLE_REVISAR_MUDANCA_ORIENTACAO =
  'ACTION_TOGGLE_REVISAR_MUDANCA_ORIENTACAO';
export const SAGA_ADD_FAIL_UPLOAD_IMAGE = 'SAGA_ADD_FAIL_UPLOAD_IMAGE';
