import { getProductType, getProductSource } from '../selector';
import { getTipoQuantidade } from './textosVariaveis';
import getTitle from '../constant/title';
import store from '../store';

/////// IMAGE_USED ///////
export const getBtnRightImageUsed = () => {
    const state = store.getState();
    const prodType = state.library.product ? getProductType(state) : '';

    if (prodType === 'mask') {
        return 'Retirar da Máscara';
    }

    return 'Remover conteúdo';
};

/////// FILL_PAGES_PHOTOS_NEEDED ///////
export function getTitleFillPagesPhotosNeeded() {
    const state = store.getState();
    const prodType = state.library.product ? getProductType(state) : '';

    if (prodType === 'mask') {
        return 'Faltam imagens para preencher todas as suas Máscaras';
    } else if (prodType === 'poster') {
        return 'Faltam imagens para preencher todos os seus Pôsteres';
    }

    return `Faltam imagens para preencher totalmente o seu ${getTitle()}`;
}

export const getContentFillPagesPhotosNeeded = () => {
    const state = store.getState();
    const prodType = state.library.product ? getProductType(state) : '';

    if (prodType === 'mask') {
        return [
            'A quantidade de imagens adicionadas à Mesa, não foi suficiente para preencher todas as suas Máscaras. Você tem as seguintes opções:',
            '- Adicionar mais imagens e preencher novamente, suas Máscaras;',
            '- Voltar à Mesa, para escolher quais Máscaras você deseja duplicar. Para isso, clique sobre "Editar" ao passar o mouse sobre uma Máscara. Logo após, clique em "Duplicar".'
        ];
    } else if (prodType === 'calendar') {
        return [
            `A quantidade de imagens adicionadas à Mesa, não foi suficiente para preencher totalmente o ${getTitle()} com o layout atual. Você tem as seguintes opções:`,
            `- Adicionar mais imagens e preencher novamente, o ${getTitle()} com o layout atual;`,
            `- Voltar à Mesa, para escolher layouts que contenham menor quantidade de imagens.`
        ];
    } else if (prodType === 'poster') {
        return [
            `A quantidade de imagens adicionadas à Mesa, não foi suficiente para preencher todos os seus Pôsteres com o layout atual. Você tem as seguintes opções:`,
            `- Adicionar mais imagens e preencher novamente, os Pôsteres com o layout atual;`,
            `- Voltar à Mesa, para escolher layouts que contenham menor quantidade de imagens, ou ainda, reduzir a quantidade de Pôsteres (quando possível).`
        ];
    } else if (prodType === 'deck-cards') {
        return [
            `A quantidade de imagens adicionadas à Mesa, não foi suficiente para preencher totalmente o Baralho com o layout atual. Você tem as seguintes opções:`,
            `- Adicionar mais imagens e preencher novamente, o Baralho com o layout atual;`,
            `- Voltar à Mesa, para escolher layouts que contenham menor quantidade de imagens.`
        ];
    } else if (prodType === 'invitation') {
        return [
            `A quantidade de imagens adicionadas à Mesa, não foi suficiente para preencher totalmente o Convite. Você tem a seguinte opção:`,
            `- Adicionar mais imagens e preencher novamente, o Convite.`
        ];
    }

    return [
        `A quantidade de imagens adicionadas à Mesa, não foi suficiente para preencher totalmente o ${getTitle()} com o layout atual. Você tem as seguintes opções:`,
        `- Adicionar mais imagens e preencher novamente, o ${getTitle()} com o layout atual;`,
        `- Voltar à Mesa, para escolher layouts que contenham menor quantidade de imagens, ou ainda, reduzir o número de páginas de seu ${getTitle()} (quando possível).`
    ];
};

/////// FILL_PAGES_PAGES_NEEDED ///////
export const getTitleFillPagesPagesNeeded = () => {
    const state = store.getState();
    const prodType = state.library.product ? getProductType(state) : '';

    if (prodType === 'mask') {
        return 'Sobraram imagens fora das Máscaras';
    } else if (prodType === 'poster') {
        return 'Sobraram imagens fora dos seus Pôsteres';
    }

    return `Sobraram imagens fora do seu ${getTitle()}`;
};

export function getContentFillPagesPagesNeeded() {
    const state = store.getState();
    const prodType = state.library.product ? getProductType(state) : '';
    const prodSource = state.library.product ? getProductSource(state) : '';

    if (prodType === 'mask') {
        if (prodSource === 'ML') {
            return [
                'A quantidade de imagens adicionadas à Mesa, foi maior que a quantidade de imagens suportadas nas Máscaras. Como a quantidade de Máscaras é fixa, você deve revisar as Máscaras e escolher quais imagens serão impressas.'
            ];
        }
        return [
            'A quantidade de imagens adicionadas à Mesa, foi maior que a quantidade de imagens suportadas nas Máscaras. Para usar todas as imagens, você deve:',
            '- Adicionar mais Máscaras e preencher novamente.'
        ];
    } else if (prodType === 'calendar') {
        return [
            `A quantidade de imagens adicionadas à Mesa, foi maior que a quantidade de imagens suportadas no ${getTitle()} com o layout atual. Você tem as seguintes opções:`,
            `- Voltar à Mesa, para escolher layouts que contenham maior quantidade de imagens.`
        ];
    } else if (prodType === 'poster') {
        return [
            `A quantidade de imagens adicionadas à Mesa, foi maior que a quantidade de imagens suportadas nos Pôsteres com o layout atual. Você tem as seguintes opções:`,
            `- Adicionar mais Pôsteres e preencher novamente, com o layout atual;`,
            `- Voltar à Mesa, para escolher layouts que contenham maior quantidade de imagens.`
        ];
    }

    if (prodSource === 'ML') {
        return [
            `A quantidade de imagens adicionadas à Mesa, foi maior que a quantidade de imagens suportadas no ${getTitle()} com o layout atual. Você tem a seguinte opção:`,
            `- Voltar à Mesa, para escolher layouts que contenham maior quantidade de imagens.`
        ];
    }

    if (prodType === 'deck-cards') {
        return [
            `A quantidade de imagens adicionadas à Mesa, foi maior que a quantidade de imagens suportadas no Baralho com o layout atual. Você tem a seguinte opção:`,
            `- Voltar à Mesa, para escolher layouts que contenham maior quantidade de imagens.`
        ];
    }

    if (prodType === 'invitation') {
        return [
            `A quantidade de imagens adicionadas à Mesa, foi maior que a quantidade de imagens suportadas no Convite.`
        ];
    }

    return [
        `A quantidade de imagens adicionadas à Mesa, foi maior que a quantidade de imagens suportadas no ${getTitle()} com o layout atual. Você tem as seguintes opções:`,
        `- Adicionar mais páginas e preencher novamente, o ${getTitle()} com o layout atual;`,
        `- Voltar à Mesa, para escolher layouts que contenham maior quantidade de imagens.`
    ];
}

/////// CLEAR_BOOK ///////
export function getTitleFillClearBook() {
    const state = store.getState();
    const prodType = state.library.product ? getProductType(state) : '';

    if (prodType === 'mask') {
        return 'Deseja limpar suas Máscaras?';
    } else if (prodType === 'dog-collar') {
        return 'Deseja limpar suas Coleiras?';
    } else if (prodType === 'poster') {
        return 'Deseja limpar seus Pôsteres?';
    }

    return `Deseja limpar seu ${getTitle()}?`;
}

export function getContentClearBook() {
    const state = store.getState();
    const prodType = state.library.product ? getProductType(state) : '';

    if (prodType === 'mask') {
        return [
            'Confirmando você irá remover todas as imagens e ajustes feitos em suas Máscaras. Esta ação não poderá ser desfeita.',
            'Suas imagens permanecem na Mesa e você poderá incluí-las novamente nas Máscaras, arrastando cada imagem individualmente, ou através do preenchimento automático clicando no botão "Preencher" no Menu Máscara.'
        ];
    } else if (prodType === 'dog-collar') {
        return [
            'Confirmando você irá remover todos os textos das',
            'suas Coleiras. Esta ação não poderá ser desfeita.'
        ];
    } else if (prodType === 'poster') {
        return [
            'Confirmando você irá remover todas as imagens e ajustes feitos em seus Pôsteres. Esta ação não poderá ser desfeita.',
            'Suas imagens permanecem na Mesa e você poderá incluí-las novamente nos Pôsteres, arrastando cada imagem individualmente, ou através do preenchimento automático clicando no botão "Preencher" no Menu Pôster.'
        ];
    }

    return [
        `Confirmando você irá remover todas as imagens e ajustes feitos em seu ${getTitle()}. Esta ação não poderá ser desfeita.`,
        `Suas imagens permanecem na Mesa e você poderá incluí-las novamente no ${getTitle()}, arrastando cada imagem individualmente, ou através do preenchimento automático clicando no botão "Preencher" no Menu ${getTitle()}.`
    ];
}

export function getBtnRightClearBook() {
    const state = store.getState();
    const prodType = state.library.product ? getProductType(state) : '';

    if (prodType === 'mask') {
        return 'Limpar Máscaras';
    } else if (prodType === 'dog-collar') {
        return 'Limpar Coleiras';
    } else if (prodType === 'poster') {
        return 'Limpar Pôsteres';
    }

    return `Limpar ${getTitle()}`;
}

/////// REMOVE_IMAGES ///////
export function getContentRemoveImages() {
    const state = store.getState();
    const prodType = state.library.product ? getProductType(state) : '';

    if (prodType === 'mask') {
        return [
            'Confirmando você irá remover todas as imagens da Mesa, que não foram usadas nas Máscaras.',
            'Para escolher novas imagens, você deve usar o botão "Adicionar" no Menu Imagens.',
            'Para remover todas as imagens das Máscaras, você deve usar o botão "Limpar" no Menu Máscara.'
        ];
    } else if (prodType === 'poster') {
        return [
            `Confirmando você irá remover todas as imagens da Mesa, que não foram usadas nos Pôsteres.`,
            `Para escolher novas imagens, você deve usar o botão "Adicionar" no Menu Imagens.`,
            `Para remover todas as imagens dos Pôsteres, você deve usar o botão "Limpar" no Menu Pôster.`
        ];
    }

    return [
        `Confirmando você irá remover todas as imagens da Mesa, que não foram usadas no ${getTitle()}.`,
        `Para escolher novas imagens, você deve usar o botão "Adicionar" no Menu Imagens.`,
        `Para remover todas as imagens do ${getTitle()}, você deve usar o botão "Limpar" no Menu ${getTitle()}.`
    ];
}

/////// REMOVE_PAGES ///////
export function getTitleRemovePages() {
    const state = store.getState();
    const prodType = state.library.product ? getProductType(state) : '';

    if (prodType === 'mask') {
        return 'A Máscara selecionada tem conteúdo';
    } else if (prodType === 'dog-collar') {
        return 'A Coleira selecionada tem conteúdo';
    } else if (prodType === 'poster') {
        return 'O Pôster selecionado tem conteúdo';
    }

    return 'As páginas selecionadas têm conteúdo';
}

export function getContentRemovePages(string, pages) {
    const state = store.getState();
    const prodType = state.library.product ? getProductType(state) : '';

    if (prodType === 'mask') {
        const paginaMascara = fixNumber(pages[0]);
        return [
            `Para remover a Máscara ${paginaMascara}, você deve remover a imagem presente nela e clicar novamente no botão "Remover Máscara".`
        ];
    } else if (prodType === 'dog-collar') {
        const paginaMascara = fixNumber(pages[0]);
        return [
            `Para remover a Coleira ${paginaMascara}, você deve remover o texto presente nela e clicar novamente no botão "Remover Coleira".`
        ];
    } else if (prodType === 'poster') {
        const paginaMascara = fixNumber(pages[0]);
        return [
            `Para remover o Pôster ${paginaMascara}, você deve remover as imagens e textos presentes nele e clicar novamente no botão "Remover Pôster".`
        ];
    }

    return [
        `Para remover as ${string} do seu ${getTitle()}, você deve remover as imagens e textos presentes nelas e clicar novamente no botão "Remover páginas".`
    ];
}

/////// PAGE_NOT_COMPLETE_FILLED ///////
export function getTitlePageNotCompleteFilled(tudoVazio, faltaImagem) {
    const state = store.getState();
    const prodType = state.library.product ? getProductType(state) : '';

    if (prodType === 'mask') {
        return 'Suas Máscaras apresentam janelas de imagem não preenchidas';
    } else if (prodType === 'dog-collar') {
        return 'Suas Coleiras apresentam caixas de texto não preenchidas';
    } else if (prodType === 'calendar') {
        return `Seu ${getTitle()} apresenta janelas de imagem não preenchidas`;
    } else if (prodType === 'poster') {
        return 'Seus Pôsteres apresentam janelas de imagem e/ou caixas de texto não preenchidas';
    } else if (prodType === 'deck-cards') {
        if (tudoVazio) return 'Seu Baralho não foi personalizado';
        else
            return 'Seu Baralho apresenta janelas de imagem e/ou caixas de texto não preenchidas';
    } else if (prodType === 'invitation') {
        if (faltaImagem)
            return 'Seu Convite apresenta janelas de imagem não preenchidas';
        return 'Seu Convite apresenta caixas de texto não preenchidas';
    }

    return `Seu ${getTitle()} apresenta janelas de imagem e/ou caixas de texto não preenchidas`;
}

export function getTitlePosterNotCompleteFilled() {
    return 'Alguns Pôsteres não foram preenchidos';
}

export function getContentPosterNotCompleteFilled(pagesMsg) {
    return [
        'Os Pôsteres a seguir, estão em branco (sem nenhum',
        'conteúdo para serem impressos):',
        `- ${pagesMsg}.`,
        'Para conseguir finalizar, revise e preencha os',
        'Pôsteres citados acima. Logo após, clique em',
        '"Pronto" novamente.'
    ];
}

export function getContentPageNotCompleteFilled(
    pagesMsg,
    tudoVazio,
    faltaImagem
) {
    const state = store.getState();
    const prodType = state.library.product ? getProductType(state) : '';

    if (prodType === 'mask') {
        return [
            'As Máscaras a seguir, estão em branco (sem',
            'nenhum conteúdo para serem impressas):',
            `- ${pagesMsg}.`,
            'Para conseguir finalizar, revise e preencha as',
            'Máscaras citadas acima: duplicando Máscaras já',
            'preenchidas, inserindo as imagens que estão na',
            'Mesa ou adicionando novas imagens. Logo após,',
            'clique em "Pronto" novamente.'
        ];
    } else if (prodType === 'dog-collar') {
        return [
            'As Coleiras a seguir, estão em branco (sem nenhum',
            'conteúdo para serem bordadas):',
            `- ${pagesMsg}.`,
            'Para conseguir finalizar, revise e preencha as',
            'Coleiras citadas acima. Logo após, clique em',
            '"Pronto" novamente.'
        ];
    } else if (prodType === 'calendar') {
        return [
            `Deixar janelas de imagem em branco, pode desalinhar o layout do seu ${getTitle()}. Pensando nisso, sugerimos que você revise as páginas a seguir:`,
            `- ${pagesMsg}.`
        ];
    } else if (prodType === 'poster') {
        return [
            `Deixar janelas de imagem e caixas de texto em branco, pode desalinhar o layout dos seus Pôsteres. Pensando nisso, sugerimos que você revise os Pôsteres a seguir:`,
            `- ${pagesMsg}.`
        ];
    } else if (prodType === 'deck-cards') {
        if (tudoVazio)
            return [
                'Notamos que você não preencheu seu Baralho com',
                'nenhuma imagem e nenhum texto. Caso seja',
                'proposital e queira continuar dessa maneira, clique',
                'em "Ignorar". Caso contrário, sugerimos que você',
                'revise seu Baralho.'
            ];
        else
            return [
                `Deixar janelas de imagem e caixas de texto em branco, pode desalinhar o layout do seu Baralho. Pensando nisso, sugerimos que você revise as cartas a seguir:`,
                `- ${pagesMsg}.`
            ];
    } else if (prodType === 'invitation') {
        if (faltaImagem) {
            return [
                'O Convite apresenta janelas de imagem em branco.',
                'Para conseguir finalizar, revise e preencha: ',
                'inserindo as imagens que estão na Mesa ou ',
                'adicionando novas imagens. Logo após, clique em ',
                '"Pronto" novamente.'
            ];
        }
        return [
            'O Convite apresenta caixas de texto em branco',
            '(sem nenhum conteúdo preenchido por você,',
            'apenas com o "texto de exemplo" padrão).',
            'Para conseguir finalizar, preencha as caixas de',
            'texto. Logo após, clique em "Pronto" novamente.'
        ];
    }

    return [
        `Deixar janelas de imagem e caixas de texto em branco, pode desalinhar o layout do seu ${getTitle()}. Pensando nisso, sugerimos que você revise as páginas a seguir:`,
        `- ${pagesMsg}.`
    ];
}

/////// FINISH_BOOK ///////
export function getTitleFinishBook() {
    const state = store.getState();
    const prodType = state.library.product ? getProductType(state) : '';

    if (prodType === 'mask') {
        return 'Deseja finalizar suas Máscaras?';
    } else if (prodType === 'dog-collar') {
        return 'Deseja finalizar suas Coleiras?';
    } else if (prodType === 'poster') {
        return 'Deseja finalizar seus Pôsteres?';
    }

    return `Deseja finalizar seu ${getTitle()}?`;
}

export function getContentFinishBook() {
    const state = store.getState();
    const prodType = state.library.product ? getProductType(state) : '';

    if (prodType === 'mask') {
        return [
            'Ao confirmar, suas Máscaras serão finalizadas e não poderão mais ser alteradas.'
        ];
    } else if (prodType === 'dog-collar') {
        return [
            'Ao confirmar, suas Coleiras serão finalizadas e não poderão mais ser alteradas.'
        ];
    } else if (prodType === 'poster') {
        return [
            'Ao confirmar, seus Pôsteres serão finalizados e não poderão mais ser alterados.'
        ];
    }

    return [
        `Ao confirmar, seu ${getTitle()} será finalizado e não poderá mais ser alterado.`
    ];
}

/////// POPUP_ID_SENDING_IMAGES ///////

export function getContentPopupIdSendingImages() {
    const state = store.getState();
    const prodType = state.library.product ? getProductType(state) : '';

    if (prodType === 'mask') {
        return 'Finalizando o envio das suas Máscaras...';
    } else if (prodType === 'dog-collar') {
        return 'Finalizando o envio das suas Coleiras...';
    } else if (prodType === 'poster') {
        return 'Finalizando o envio dos seus Pôsteres...';
    }

    return `Finalizando o envio do seu ${getTitle()}...`;
}

/////// POPUP_TOGGLE_REMOVE_PAGES ///////

const fixNumber = n => (n < 10 ? `0${n}` : n);

export function getTitleToggleRemovePages() {
    const state = store.getState();
    const prodType = state.library.product ? getProductType(state) : '';

    if (prodType === 'mask') {
        return 'Deseja remover alguma Máscara?';
    } else if (prodType === 'dog-collar') {
        return 'Deseja remover alguma Coleira?';
    } else if (prodType === 'poster') {
        return 'Deseja remover algum Pôster?';
    }

    return `Deseja remover páginas do seu ${getTitle()}?`;
}

export function getContentToggleRemovePages(setDif) {
    const state = store.getState();
    const prodType = state.library.product ? getProductType(state) : '';

    if (prodType === 'mask')
        return [`Para remover, você deve selecionar ${fixNumber(setDif)} Máscara.`];
    else if (prodType === 'dog-collar')
        return [`Para remover, você deve selecionar ${fixNumber(setDif)} Coleira.`];
    else if (prodType === 'poster')
        return [`Para remover, você deve selecionar ${fixNumber(setDif)} Pôster.`];

    return [
        `Para remover, você deve selecionar ${fixNumber(
            setDif
        )} páginas simples (${fixNumber(
            setDif / 2
        )} páginas duplas) do seu ${getTitle()}.`
    ];
}

export function getBtnRightToggleRemovePages(setDif) {
    const state = store.getState();
    const prodType = state.library.product ? getProductType(state) : '';
    let s = prodType === 'mask' && setDif > 1 ? 's' : '';

    if (prodType === 'mask') {
        return `Remover Máscara${s}`;
    } else if (prodType === 'dog-collar') {
        return `Remover Coleira${s}`;
    } else if (prodType === 'poster') {
        s = setDif > 1 ? 'es' : '';
        return `Remover Pôster${s}`;
    }

    return `Remover páginas`;
}

/////// CONFIRM_REMOVE_PAGES ///////

export function getTitleConfirmRemovePages() {
    const state = store.getState();
    const prodType = state.library.product ? getProductType(state) : '';

    if (prodType === 'mask') {
        return 'Tem certeza que deseja remover sua Máscara?';
    } else if (prodType === 'dog-collar') {
        return 'Tem certeza que deseja remover sua Coleira?';
    } else if (prodType === 'poster') {
        return 'Tem certeza que deseja remover seu Pôster?';
    }

    return `Tem certeza que deseja remover páginas do seu ${getTitle()}?`;
}

export function getContentConfirmRemovePages() {
    const state = store.getState();
    const prodType = state.library.product ? getProductType(state) : '';

    if (prodType === 'mask') {
        return [
            'Confirmando, você irá remover a Máscara selecionada anteriormente. Esta ação não poderá ser desfeita.'
        ];
    } else if (prodType === 'dog-collar') {
        return [
            'Confirmando, você irá remover a Coleira selecionada anteriormente. Esta ação não poderá ser desfeita.'
        ];
    } else if (prodType === 'poster') {
        return [
            'Confirmando, você irá remover o Pôster selecionado anteriormente. Esta ação não poderá ser desfeita.'
        ];
    }

    return [
        `Confirmando, você irá remover as ${getTipoQuantidade(
            false,
            true
        )} selecionadas anteriormente. Esta ação não poderá ser desfeita.`
    ];
}

export function getBtnRightConfirmRemovePages() {
    const state = store.getState();
    const prodType = state.library.product ? getProductType(state) : '';

    if (prodType === 'mask') {
        return 'Remover Máscara';
    } else if (prodType === 'dog-collar') {
        return 'Remover Coleira';
    } else if (prodType === 'poster') {
        return 'Remover Pôster';
    }

    return 'Remover páginas';
}

export function getTitleAlterarLayoutTodo(title) {
    const state = store.getState();
    const prodType = state.library.product ? getProductType(state) : '';

    if (prodType === 'poster') {
        return 'Deseja alterar o layout de todos os Pôsteres?';
    }

    return `Deseja alterar o layout ${title}?`;
}

export function getContentAlterarLayoutTodo(content) {
    const state = store.getState();
    const prodType = state.library.product ? getProductType(state) : '';

    if (prodType === 'poster') {
        return [
            `Confirmando você irá alterar o layout de todos os seus Pôsteres. Esta ação não poderá ser desfeita.`
        ];
    }

    return [
        `Confirmando você irá alterar o layout de todas as páginas ${content}do seu ${getTitle()}. Esta ação não poderá ser desfeita.`
    ];
}

/////// POPUP_ID_LAYOUT ///////
export function getTextPopupIdLayout(msgPhoto, msgText) {
    const state = store.getState();
    const prodType = state.library.product ? getProductType(state) : '';

    if (prodType === 'poster')
        return [
            'Sua troca de layouts dos Pôsteres, necessita da remoção de conteúdo em alguns Pôsteres',
            `O número de janelas de imagem escolhido, é menor do que a quantidade de imagens atual nos Pôsteres: ${msgPhoto}.`,
            'Para remover o conteúdo, basta clicar no botão abaixo e remover as imagens dos Pôsteres citados.',
            `O número de caixas de texto escolhido, é menor do que a quantidade de caixas de texto preenchidas atualmente nos Pôsteres: ${msgText}.`,
            'Para remover o conteúdo, basta clicar no botão abaixo e remover os textos dos Pôsteres citados.'
        ];
    else if (prodType === 'deck-cards')
        return [
            'Sua troca de layouts do Baralho, necessita da remoção de conteúdo de algumas cartas',
            `O número de janelas de imagem escolhido, é menor do que a quantidade de imagens atual nas cartas: ${msgPhoto} (Carta Verso).`,
            'Para remover o conteúdo, basta clicar no botão abaixo e remover as imagens das cartas citadas.',
            `O número de caixas de texto escolhido, é menor do que a quantidade de caixas de texto preenchidas atualmente nas cartas: ${msgText} (Carta Verso).`,
            'Para remover o conteúdo, basta clicar no botão abaixo e remover os textos das cartas citadas.'
        ];

    return [
        `Sua troca de layouts do ${getTitle()}, necessita da remoção de conteúdo de algumas páginas`,
        `O número de janelas de imagem escolhido, é menor do que a quantidade de imagens atual nas páginas: ${msgPhoto}.`,
        'Para remover o conteúdo, basta clicar no botão abaixo e remover as imagens das páginas citadas.',
        `O número de caixas de texto escolhido, é menor do que a quantidade de caixas de texto preenchidas atualmente nas páginas: ${msgText}.`,
        'Para remover o conteúdo, basta clicar no botão abaixo e remover os textos das páginas citadas.'
    ];
}

export function getContentMaxUserSpace() {
    const content = [
        'Suas imagens enviadas até o momento, ocuparam',
        'todo o nosso armazenamento.',
        'Para continuar, por favor entre em contato conosco',
        'através do email rf@printastico.com.br.',
        'Enquanto não retornamos, deixe essa janela aberta',
        'e não faça nenhuma alteração no seu FotoProduto.'
    ];
    return content;
}
