// Thumb sizes
export const MAX_WIDTH = 84;
export const MAX_HEIGHT = 60;

// Thumb sizes
export const MAX_WIDTH_REDUCED = 1024;
export const MAX_HEIGHT_REDUCED = 1024;

// Thumb Expand
export const NEXT = 'NEXT';
export const PREVIOUS = 'PREVIOUS';
