import * as actionUI from '../constant/action-type/ui';

export const updateScreenSizeError = status => ({
  type: actionUI.UPDATE_SCREEN_SIZE_ERROR,
  payload: { status }
});

export const updateFetchStatus = status => ({
  type: actionUI.UPDATE_FETCH_STATUS,
  payload: { status }
});

export const updateIDBStatus = status => ({
  type: actionUI.UPDATE_IDB_STATUS,
  payload: { status }
});

export const updateTutorialStatus = status => ({
  type: actionUI.UPDATE_TUTORIAL_STATUS,
  payload: { status }
});

export const updatePopup = (id, overlay, msg) => ({
  type: actionUI.UPDATE_POPUP,
  payload: { id, overlay, msg }
});

export const updateThumbExpand = (status, id) => ({
  type: actionUI.UPDATE_THUMB_EXPAND,
  payload: { status, id }
});

export const updateThumbExpandIDB = () => ({
  type: actionUI.UPDATE_THUMB_EXPAND_IDB
});

export const updatePhotoEditor = id => ({
  type: actionUI.UPDATE_PHOTO_EDITOR,
  payload: { id }
});

export const updateToolbarPhoto = (
  maxThumbs,
  thumbsLength,
  prevThumbLenth,
  thumbListLeft,
  thumbListRight,
  thumbsListExpanded
) => ({
  type: actionUI.UPDATE_TOOLBAR_PHOTO,
  payload: {
    maxThumbs,
    thumbsLength,
    prevThumbLenth,
    thumbListLeft,
    thumbListRight,
    thumbsListExpanded
  }
});

export const updateScreenSize = (
  width,
  height,
  headerHeight,
  photobookHeight,
  layoutsContainerWidth
) => ({
  type: actionUI.UPDATE_SCREEN_SIZE,
  payload: {
    width,
    height,
    headerHeight,
    photobookHeight,
    layoutsContainerWidth
  }
});

export const updateScreenRate = rate => ({
  type: actionUI.UPDATE_SCREEN_RATE,
  payload: {
    rate
  }
});

export const updateSelectedNav = selected => ({
  type: actionUI.UPDATE_NAV_SELECTED,
  payload: { selected }
});

export const updateGalleryExpanded = expanded => ({
  type: actionUI.UPDATE_GALLERY_EXPANDED,
  payload: { expanded }
});

export const updateBookOverviewExpanded = expanded => ({
  type: actionUI.UPDATE_BOOK_OVERVIEW_EXPANDED,
  payload: { expanded }
});

export const toggleModoImpressao = () => ({
  type: actionUI.TOGGLE_MODO_IMPRESSAO
});

export const toggleBibliotecaTemas = () => ({
  type: actionUI.TOGGLE_BIBLIOTECA_TEMAS
});

export const startDrag = (id, pos, mousePos) => ({
  type: actionUI.SET_START_DRAG,
  payload: { id, pos, mousePos }
});

export const stopDrag = () => ({
  type: actionUI.SET_STOP_DRAG
});

export const updateTextOptions = (option, value, pageId) => ({
  type: actionUI.UPDATE_TEXT_OPTION,
  payload: { option, value, pageId }
});

export const updateWarning = (active = false, text = '') => ({
  type: actionUI.UPDATE_WARNING,
  payload: { active, text }
});

export const toggleLegenda = () => ({
  type: actionUI.TOGGLE_LEGENDA
});

export const setStateLegenda = (state = 'NORMAL') => ({
  type: actionUI.SET_STATE_LEGENDA,
  payload: { state }
});

export const setModalConfig = (modalConfig) => ({
  type: actionUI.SET_MODAL_CONFIG,
  payload: { modalConfig }
});
