import './index.css';

import {
  ALIGN,
  BOLD,
  CENTER,
  COLOR,
  FAMILY,
  FAMILY_SIZE,
  ITALIC,
  JUSTIFY,
  LEFT,
  RIGHT,
  SIZE,
  UNDERLINE
} from '../../constant/textStyle';
import React, { Component } from 'react';
import {
  THEME_CLASS_BT_LEFT_ARROW_BLACK,
  THEME_CLASS_BT_LEFT_ARROW_BLACK_DISABLED,
  THEME_CLASS_BT_RIGHT_ARROW_BLACK,
  THEME_CLASS_BT_RIGHT_ARROW_BLACK_DISABLED,
  THEME_CLASS_ICON_TEXT_ALIGN_CENTER,
  THEME_CLASS_ICON_TEXT_ALIGN_CENTER_ACTIVE,
  THEME_CLASS_ICON_TEXT_ALIGN_JUSTIFY,
  THEME_CLASS_ICON_TEXT_ALIGN_JUSTIFY_ACTIVE,
  THEME_CLASS_ICON_TEXT_ALIGN_LEFT,
  THEME_CLASS_ICON_TEXT_ALIGN_LEFT_ACTIVE,
  THEME_CLASS_ICON_TEXT_ALIGN_RIGHT,
  THEME_CLASS_ICON_TEXT_ALIGN_RIGHT_ACTIVE,
  THEME_CLASS_ICON_TEXT_DECORATION_BOLD,
  THEME_CLASS_ICON_TEXT_DECORATION_BOLD_ACTIVE,
  THEME_CLASS_ICON_TEXT_DECORATION_ITALIC,
  THEME_CLASS_ICON_TEXT_DECORATION_ITALIC_ACTIVE,
  THEME_CLASS_ICON_TEXT_DECORATION_UNDERLINE,
  THEME_CLASS_ICON_TEXT_DECORATION_UNDERLINE_ACTIVE
} from '../../constant/button';
import { screenSizePropTypes, textOptionsPropTypes } from '../../proptypes';
import { calcCustomSize, getFamilyArray } from '../../utils/calculosGerais';

import Button from '../button';
import NumericStepper from '../../container/page-stepper';
import PropTypes from 'prop-types';

class NavText extends Component {
  state = {
    colorsStart: 0,
    colorsEnd: 0,
    colorsNumber: 0 // Number of colors in one line
  };

  getColorsNumber = (screenWidth, colorsBoxWidth) => {
    return Math.floor(colorsBoxWidth / (26 + screenWidth * 0.004));
  };

  prepareColorsBox = () => {
    const { width } = this.props.screenSize;
    const colorsBoxWidth = width - (width * 0.109 + 230 + 80 + 149 + 24);
    const colors = document.querySelectorAll('.color-box');

    const colorsNumber = this.getColorsNumber(width, colorsBoxWidth);
    const marginColors =
      (colorsBoxWidth - colorsNumber * 26) / (colorsNumber - 1);

    const colorArray =
      this.props.prodType === 'dog-collar'
        ? this.props.textOptions.colorArrayDogCollar
        : this.props.textOptions.colorArray;
    let index = colorArray.indexOf(this.props.textOptions.color, 0);
    if (index < 0) {
      colorArray.unshift(this.props.textOptions.color);
      index = 0;
    }
    let colorsStart =
      Math.floor(index / (colorsNumber * 2)) * (colorsNumber * 2);
    const colorsEnd =
      colorsStart + colorsNumber * 2 > colorArray.length
        ? colorArray.length
        : colorsStart + colorsNumber * 2;

    if (colorsEnd === colorArray.length && colorsEnd - colorsNumber * 2 > 0)
      colorsStart = colorsEnd - colorsNumber * 2;

    if (colorsNumber !== this.state.colorsNumber) {
      this.setState({
        colorsStart: colorsStart,
        colorsEnd: colorsEnd,
        colorsNumber
      });
    }

    colors.forEach((color, index) =>
      colorsNumber - 1 !== index && colorsNumber * 2 - 1 !== index
        ? (color.style.marginRight = `${marginColors}px`)
        : (color.style.marginRight = `0px`)
    );
  };

  updateColorState = () => {
    const { width } = this.props.screenSize;
    const colorsBoxWidth = width - (width * 0.109 + 230 + 80 + 149 + 24);
    const colorsNumber = this.getColorsNumber(width, colorsBoxWidth);
    this.setState({
      colorsEnd: this.state.colorsStart + colorsNumber * 2,
      colorsNumber
    });
  };

  scrollColorsContainer = side => {
    const { colorsStart, colorsEnd, colorsNumber } = this.state;
    const colorArray =
      this.props.prodType === 'dog-collar'
        ? this.props.textOptions.colorArrayDogCollar
        : this.props.textOptions.colorArray;

    if (side === LEFT) {
      if (colorsStart - colorsNumber * 2 < 0) {
        this.setState({
          colorsStart: 0,
          colorsEnd: colorsNumber * 2
        });
      } else {
        this.setState({
          colorsStart: colorsStart - colorsNumber * 2,
          colorsEnd: colorsEnd - colorsNumber * 2
        });
      }
    } else {
      if (colorsEnd + colorsNumber * 2 > colorArray.length) {
        this.setState({
          colorsStart: colorArray.length - colorsNumber * 2,
          colorsEnd: colorArray.length
        });
      } else {
        this.setState({
          colorsStart: colorsStart + colorsNumber * 2,
          colorsEnd: colorsEnd + colorsNumber * 2
        });
      }
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const { colorsStart, colorsEnd } = this.state;

    if (
      prevState.colorsStart !== colorsStart ||
      prevState.colorsEnd !== colorsEnd
    ) {
      this.prepareColorsBox();
    }
  }

  fontSizeHandler = font => {
    switch (font) {
      case 'Abril Fatface':
        return 15.5;
      case 'Arvo':
        return 16;
      case 'Blokletters Balpen':
        return 13;
      case 'Cookie':
        return 19.5;
      case 'Gandhi Sans':
        return 16;
      case 'GoodDog':
        return 20.5;
      case 'Grand Hotel':
        return 17;
      case 'Great Vibes':
        return 17.5;
      case 'Josefin Sans':
        return 15;
      case 'Komika Axis':
        return 13;
      case 'Liberation Sans':
        return 15.5;
      case 'Liberation Serif':
        return 17;
      case 'Lobster Two':
        return 16.5;
      case 'Walkway':
        return 16.5;
      default:
        break;
    }
  };

  componentDidMount() {
    this.updateColorState();
    this.prepareColorsBox();
    window.addEventListener('resize', this.prepareColorsBox);
  }

  render() {
    const {
      textOptions,
      screenSize,
      updateTextOptions,
      prodType,
      disabled
    } = this.props;
    const { size, family, color, textStyle, sizeArray, pageId } = textOptions;

    const colorArray =
      prodType === 'dog-collar'
        ? textOptions.colorArrayDogCollar
        : textOptions.colorArray;
    const familyArray = getFamilyArray(prodType, textOptions);
    const { width } = screenSize;
    const { colorsStart, colorsEnd, colorsNumber } = this.state;

    const fontFamilyIndex = familyArray.indexOf(family);
    let fontSizeIndex = sizeArray.indexOf(size);
    if (prodType === 'invitation')
      fontSizeIndex = sizeArray.indexOf(calcCustomSize(size));

    return (
      <div className="nav-text">
        <div
          className="font-family-container border-container"
          style={{ marginRight: `${width * 0.015}px` }}
        >
          <div className="boxes-container">
            <div
              className={`font-option-box${disabled ? ' disabled' : ''}`}
              style={{
                fontFamily: `${fontFamilyIndex === 0
                    ? familyArray[familyArray.length - 1]
                    : familyArray[fontFamilyIndex - 1]
                  }`,
                fontSize: `${this.fontSizeHandler(
                  fontFamilyIndex === 0
                    ? familyArray[familyArray.length - 1]
                    : familyArray[fontFamilyIndex - 1]
                )}px`
              }}
              onClick={() => {
                !disabled &&
                  updateTextOptions(
                    prodType === 'dog-collar' ? FAMILY_SIZE : FAMILY,
                    fontFamilyIndex === 0
                      ? familyArray[familyArray.length - 1]
                      : familyArray[fontFamilyIndex - 1]
                  );
              }}
            >
              {fontFamilyIndex === 0 ? (
                <span>{familyArray[familyArray.length - 1]}</span>
              ) : (
                <span>{familyArray[fontFamilyIndex - 1]}</span>
              )}
            </div>
            <div
              className="font-option-box active"
              style={{
                fontFamily: `${familyArray[fontFamilyIndex]}`,
                fontSize: `${this.fontSizeHandler(
                  familyArray[fontFamilyIndex]
                )}px`
              }}
            >
              <span>{familyArray[fontFamilyIndex]}</span>
              <div className="checked icon-check"></div>
            </div>
            <div
              className={`font-option-box${disabled ? ' disabled' : ''}`}
              style={{
                fontFamily: `${fontFamilyIndex === familyArray.length - 1
                    ? familyArray[0]
                    : familyArray[fontFamilyIndex + 1]
                  }`,
                fontSize: `${this.fontSizeHandler(
                  fontFamilyIndex === familyArray.length - 1
                    ? familyArray[0]
                    : familyArray[fontFamilyIndex + 1]
                )}px`
              }}
              onClick={() => {
                !disabled &&
                  updateTextOptions(
                    prodType === 'dog-collar' ? FAMILY_SIZE : FAMILY,
                    fontFamilyIndex === familyArray.length - 1
                      ? familyArray[0]
                      : familyArray[fontFamilyIndex + 1]
                  );
              }}
            >
              {fontFamilyIndex === familyArray.length - 1 ? (
                <span>{familyArray[0]}</span>
              ) : (
                <span>{familyArray[fontFamilyIndex + 1]}</span>
              )}
            </div>
          </div>
          <NumericStepper
            screenSize={screenSize}
            type={prodType === 'dog-collar' ? FAMILY_SIZE : FAMILY}
            array={familyArray}
            index={fontFamilyIndex}
            updateTextOptions={updateTextOptions}
            disabled={disabled}
            vertical
          ></NumericStepper>
        </div>
        <div
          className="font-size-container border-container"
          style={{ marginRight: `${width * 0.015}px` }}
        >
          {pageId === 'spine' && <div className="disable-font-size"></div>}
          <div className="boxes-container">
            <div
              className={`font-option-box${disabled ? ' disabled' : ''}`}
              onClick={() => {
                !disabled &&
                  updateTextOptions(
                    SIZE,
                    fontSizeIndex === 0
                      ? sizeArray[sizeArray.length - 1]
                      : sizeArray[fontSizeIndex - 1]
                  );
              }}
            >
              {fontSizeIndex === 0 ? (
                <span>{sizeArray[sizeArray.length - 1]}</span>
              ) : (
                <span>{sizeArray[fontSizeIndex - 1]}</span>
              )}
            </div>
            <div className="font-option-box active">
              <span>{sizeArray[fontSizeIndex]}</span>
              <div className="checked icon-check"></div>
            </div>
            <div
              className={`font-option-box${disabled ? ' disabled' : ''}`}
              onClick={() => {
                !disabled &&
                  updateTextOptions(
                    SIZE,
                    fontSizeIndex === sizeArray.length - 1
                      ? sizeArray[0]
                      : sizeArray[fontSizeIndex + 1]
                  );
              }}
            >
              {fontSizeIndex === sizeArray.length - 1 ? (
                <span>{sizeArray[0]}</span>
              ) : (
                <span>{sizeArray[fontSizeIndex + 1]}</span>
              )}
            </div>
          </div>
          <NumericStepper
            screenSize={screenSize}
            type={SIZE}
            array={sizeArray}
            index={fontSizeIndex}
            updateTextOptions={updateTextOptions}
            disabled={disabled}
            vertical
          ></NumericStepper>
        </div>
        <div className="text-style-container">
          <div className="text-decoration-container">
            <Button
              padding={false}
              theme={
                textStyle.bold
                  ? THEME_CLASS_ICON_TEXT_DECORATION_BOLD_ACTIVE
                  : THEME_CLASS_ICON_TEXT_DECORATION_BOLD
              }
              clickHandler={() => updateTextOptions(BOLD)}
            />
            <Button
              padding={false}
              theme={
                textStyle.italic
                  ? THEME_CLASS_ICON_TEXT_DECORATION_ITALIC_ACTIVE
                  : THEME_CLASS_ICON_TEXT_DECORATION_ITALIC
              }
              clickHandler={() => updateTextOptions(ITALIC)}
            />
            <Button
              padding={false}
              theme={
                textStyle.underline
                  ? THEME_CLASS_ICON_TEXT_DECORATION_UNDERLINE_ACTIVE
                  : THEME_CLASS_ICON_TEXT_DECORATION_UNDERLINE
              }
              clickHandler={() => updateTextOptions(UNDERLINE)}
            />
          </div>
          <div className="text-align-container">
            <Button
              padding={false}
              theme={
                textStyle.align === LEFT
                  ? THEME_CLASS_ICON_TEXT_ALIGN_LEFT_ACTIVE
                  : THEME_CLASS_ICON_TEXT_ALIGN_LEFT
              }
              clickHandler={() => updateTextOptions(ALIGN, LEFT)}
            />
            <Button
              padding={false}
              theme={
                textStyle.align === CENTER
                  ? THEME_CLASS_ICON_TEXT_ALIGN_CENTER_ACTIVE
                  : THEME_CLASS_ICON_TEXT_ALIGN_CENTER
              }
              clickHandler={() => updateTextOptions(ALIGN, CENTER)}
            />
            <Button
              padding={false}
              theme={
                textStyle.align === RIGHT
                  ? THEME_CLASS_ICON_TEXT_ALIGN_RIGHT_ACTIVE
                  : THEME_CLASS_ICON_TEXT_ALIGN_RIGHT
              }
              clickHandler={() => updateTextOptions(ALIGN, RIGHT)}
            />
            <Button
              padding={false}
              theme={
                textStyle.align === JUSTIFY
                  ? THEME_CLASS_ICON_TEXT_ALIGN_JUSTIFY_ACTIVE
                  : THEME_CLASS_ICON_TEXT_ALIGN_JUSTIFY
              }
              clickHandler={() =>
                pageId !== 'spine' && updateTextOptions(ALIGN, JUSTIFY)
              }
              style={pageId === 'spine' ? { opacity: 0.25 } : {}}
              disabled={pageId === 'spine'}
            />
          </div>
        </div>
        <div
          className="colors-container"
          style={{ marginLeft: `${width * 0.019}px` }}
        >
          <Button
            padding={false}
            theme={
              disabled
                ? THEME_CLASS_BT_LEFT_ARROW_BLACK_DISABLED
                : THEME_CLASS_BT_LEFT_ARROW_BLACK
            }
            clickHandler={() => this.scrollColorsContainer(LEFT)}
            style={
              colorArray.length <= colorsNumber * 2 || colorsStart === 0
                ? { opacity: 0.05 }
                : {}
            }
            disabled={
              colorArray.length <= colorsNumber * 2 ||
              colorsStart === 0 ||
              disabled
            }
          />
          <div
            className="colors-box"
            style={{ margin: `0 ${width * 0.015}px` }}
          >
            {colorArray.map((colorEl, index) =>
              index >= colorsStart && index < colorsStart + colorsNumber ? (
                <div
                  className={`color-box${color !== colorEl && disabled ? ' disabled' : ''
                    }`}
                  key={index}
                >
                  <div
                    className={`checked icon-check ${color === colorEl ? 'top' : 'none'
                      }`}
                  ></div>
                  <div
                    className={color === colorEl ? `color active` : `color`}
                    style={{ backgroundColor: `${colorEl}` }}
                    onClick={() =>
                      !disabled && updateTextOptions(COLOR, colorEl)
                    }
                  />
                </div>
              ) : index >= colorsStart + colorsNumber && index < colorsEnd ? (
                <div
                  className={`color-box${color !== colorEl && disabled ? ' disabled' : ''
                    }`}
                  key={index}
                >
                  <div
                    className={color === colorEl ? `color active` : `color`}
                    style={{ backgroundColor: `${colorEl}` }}
                    onClick={() =>
                      !disabled && updateTextOptions(COLOR, colorEl)
                    }
                  />
                  <div
                    className={`checked icon-check ${color === colorEl ? 'bottom' : 'none'
                      }`}
                  ></div>
                </div>
              ) : null
            )}
          </div>
          <Button
            padding={false}
            theme={
              disabled
                ? THEME_CLASS_BT_RIGHT_ARROW_BLACK_DISABLED
                : THEME_CLASS_BT_RIGHT_ARROW_BLACK
            }
            clickHandler={() => this.scrollColorsContainer(RIGHT)}
            style={
              colorArray.length <= colorsNumber * 2 ||
                colorsEnd === colorArray.length
                ? { opacity: 0.05 }
                : {}
            }
            disabled={
              colorArray.length <= colorsNumber * 2 ||
              colorsEnd === colorArray.length ||
              disabled
            }
          />
        </div>
      </div>
    );
  }
}

NavText.propType = {
  screenSize: screenSizePropTypes,
  textOptions: textOptionsPropTypes,
  updateTextOptions: PropTypes.func.isRequired,
  prodType: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

NavText.defaultProps = { disabled: false };

export default NavText;
