import PropTypes from 'prop-types';
import React from 'react';
import CustomRichText from '../rich-text/customRichText';
import cuid from 'cuid';

const MockupInvitation = ({
  id,
  navigationIndex,
  width,
  isPrint,
  textareasProduct,
  texts,
  textOptions,
  textChangeHandler,
  toggleNavText,
  bleedPrint,
  rate,
  updateTextOptions,
  photoEditorActive,
  isBookOverviewExpanded,
  isMenuTextSelected
}) => {
  return (
    <React.Fragment>
      {navigationIndex === 2 && (
        <image
          id="mockup-invitation-verso"
          width={width}
          opacity={photoEditorActive ? 0.25 : 1}
          pointerEvents="none"
          href={require(`../../asset/mockup/invitation/convite_verso.png`)}
        />
      )}
      {textareasProduct &&
        textareasProduct.map((textarea, i) => (
          <CustomRichText
            key={textarea.id}
            id={textarea.id}
            pageId={id}
            richTextId={texts && texts[i] ? texts[i].id : cuid()}
            editorState={texts && texts[i] ? texts[i].richTextState : ''}
            textChangeHandler={textChangeHandler}
            x={textarea.left + bleedPrint}
            y={textarea.top + bleedPrint}
            width={textarea.width}
            height={textarea.height}
            color={textarea.color}
            family={textarea.family}
            size={textarea.size}
            align={
              texts && texts[i] ? texts[i].textOptions.align : textarea.align
            }
            bold={texts && texts[i] ? texts[i].textOptions.bold : undefined}
            italic={texts && texts[i] ? texts[i].textOptions.italic : undefined}
            underline={
              texts && texts[i] ? texts[i].textOptions.underline : undefined
            }
            defaultText={textarea.default_text}
            textOptions={textOptions}
            toggleNavText={toggleNavText}
            updateTextOptions={updateTextOptions}
            photoEditorActive={photoEditorActive}
            isBookOverviewExpanded={isBookOverviewExpanded}
            isPrint={isPrint}
            isMenuTextSelected={isMenuTextSelected}
            rate={rate}
            prodType="invitation"
            enabled={textarea.enabled}
          />
        ))}
    </React.Fragment>
  );
};

MockupInvitation.propTypes = {
  id: PropTypes.number.isRequired,
  navigationIndex: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  isPrint: PropTypes.bool.isRequired,
  textareasProduct: PropTypes.array,
  texts: PropTypes.array,
  textOptions: PropTypes.object.isRequired,
  textChangeHandler: PropTypes.func.isRequired,
  toggleNavText: PropTypes.func.isRequired,
  bleedPrint: PropTypes.number.isRequired,
  rate: PropTypes.number.isRequired,
  updateTextOptions: PropTypes.func.isRequired,
  photoEditorActive: PropTypes.bool.isRequired,
  isBookOverviewExpanded: PropTypes.bool.isRequired,
  isMenuTextSelected: PropTypes.bool.isRequired
};

export default MockupInvitation;
