import 
{ 
  getFrames, 
  getPhotoEditor, 
  getProductType, 
  getNextEmptyPage,
} from '../selector';
import 
{
  rotateImage,
  duplicatePage,
  sagaDeleteImageFromBook,
  updateZoom,
  sagaSwapPoster
} from '../action/general';

import PhotoEditor from '../component/photo-editor';
import { connect } from 'react-redux';
import { updatePhotoEditor } from '../action/ui';

const mapStateToProps = state => ({
  photoEditor: getPhotoEditor(state),
  zoom: getFrames(state)[getPhotoEditor(state).frameId].zoom || 1,
  prodType: getProductType(state),
  hasEmptyPage: getNextEmptyPage(state) != null,
});

const mapDispatchToProps = dispatch => ({
  onZoomIn: id => dispatch(updateZoom(id, 'INCREASE')),
  onZoomOut: id => dispatch(updateZoom(id, 'DECREASE')),
  onRotate: (id, quantidade) => dispatch(rotateImage(id, quantidade)),
  onDuplicate: id => dispatch(duplicatePage(id)),
  onDelete: id => {
    dispatch(sagaDeleteImageFromBook(id)); 
    dispatch(sagaSwapPoster('LEFT'));
  },
  updatePhotoEditor: id => dispatch(updatePhotoEditor(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(PhotoEditor);
