import './index.css';
import React, { useState } from 'react';

import PropTypes from 'prop-types';
import Button from '../../button';
import { 
  THEME_CLASS_ORANGE,
  THEME_CLASS_ORANGE_DISABLED 
} from '../../../constant/button';
import imagemColeira from '../../../asset/modal/collar-measure.jpg';
import TamanhoColeira from './tamanhoColeira';
import { POPUP_ID_LOADING, POPUP_ID_NONE } from '../../../constant/popup';

function ModalCollar(
  {
    prodType, 
    tutorialStatus, 
    setStateLegenda, 
    updatePopup, 
    setTamanhoColeira, 
    updateTutorialStatus
  })
{
  const [tamanhoSelecionado, setTamanhoSelecionado] = useState(null);
  const [tamanhos] = useState([18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54]);

  const confirmar = () => {
    setStateLegenda('NORMAL');
    updatePopup(POPUP_ID_LOADING, true, "Carregando");
    setTamanhoColeira(tamanhoSelecionado);
    updateTutorialStatus("");
    return setTimeout(() => updatePopup(POPUP_ID_NONE, false, ''), 700);
  };

  if(tutorialStatus === "ESCOLHER-TAMANHO-COLEIRA" && prodType === "dog-collar")
    return (
      <div className="ModalCollar">
        <div className="container">
          <div className="imagens">
            <img src={imagemColeira} alt="Escolher coleira"></img>
          </div>
          <div className="textos">
            <div className="titulo">{"Selecione a medida do pescoço do seu pet"}</div>
            <div className="message">{"Para saber a medida correta, meça conforme a imagem acima"}</div>
            <div className="tamanhoColeira">
              <TamanhoColeira
                tamanhos={tamanhos}
                tamanhoSelecionado={tamanhoSelecionado}
                setTamanhoSelecionado={setTamanhoSelecionado}
              />
            </div>
            <Button
              label={"Confirmar"}
              style={{marginTop: "22px"}}
              theme={tamanhoSelecionado != null ? THEME_CLASS_ORANGE: THEME_CLASS_ORANGE_DISABLED}
              clickHandler={() => confirmar()}
            /> 
          </div>
        </div>
      </div>
    );
  else
    return null;
}

ModalCollar.propTypes = {
  prodType: PropTypes.string.isRequired,
  tutorialStatus: PropTypes.string.isRequired,
  updateTutorialStatus: PropTypes.func.isRequired,
  updateNav: PropTypes.func.isRequired,
  setStateLegenda: PropTypes.func.isRequired,
  setTamanhoColeira: PropTypes.func.isRequired,
  updatePopup: PropTypes.func.isRequired
};

export default ModalCollar;