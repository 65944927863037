import './index.css';

import PropTypes from 'prop-types';
import React from 'react';
import { THEME_CLASS_BLUE } from '../../constant/button';

const Button = ({
  input,
  label,
  theme,
  padding,
  clickHandler,
  dataTip,
  style,
  disabled,
  id
}) => {
  const classStrName = `button ${theme} ${padding ? '' : 'no-padding'}`;

  if (input) {
    return (
      <input
        id={id}
        type="button"
        className={classStrName}
        value={label}
        onClick={clickHandler}
      />
    );
  }

  return (
    <button
      id={id}
      type="button"
      className={classStrName}
      onClick={clickHandler}
      style={style}
      data-tip={dataTip}
      disabled={disabled}
    >
      {label}
    </button>
  );
};

Button.propTypes = {
  input: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
  padding: PropTypes.bool.isRequired,
  clickHandler: PropTypes.func,
  dataTip: PropTypes.string,
  style: PropTypes.object,
  disabled: PropTypes.bool.isRequired,
  id: PropTypes.string
};

Button.defaultProps = {
  input: false,
  label: '',
  padding: true,
  theme: THEME_CLASS_BLUE,
  clickHandler: undefined,
  disabled: false
};

export default Button;
