//////////////////////////////// CALENDAR P /////////////////////////////////////

export const FATOR_AJUSTE_Y_SOMBRA_GALLERY_EXPANDED_CALENDAR = -28.0999908447266;
export const RATIO_SOMBRA_INFERIOR = 2328.0/373;
export const RATIO_CALENDARIO_P = 2156.0/965;
export const FATOR_AUMENTO_WIDTH_SOMBRA = 2328.0/2156;

//Tem que estar 38px abaixo 38/965
export const FATOR_AJUSTE_Y_SOMBRA_LATERAL_CALENDARIO_P = 38.0/965;
export const FATOR_AJUSTE_HEIGHT_SOMBRA_LATERAL_CALENDARIO_P = 1 - FATOR_AJUSTE_Y_SOMBRA_LATERAL_CALENDARIO_P;

//////////////////////////////// CALENDAR A3-P /////////////////////////////////////
export const FATOR_AUMENTO_WIDTH_SOMBRA_A3_P = 1.04;
export const RATIO_SOMBRA_INFERIOR_A3_P = 4168.0/372;

//Tem que estar 38px abaixo 38
export const FATOR_AJUSTE_Y_SOMBRA_LATERAL_CALENDARIO_A3_P = 38.0/3002;
export const FATOR_AJUSTE_HEIGHT_SOMBRA_LATERAL_CALENDARIO_A3_P = 1 - FATOR_AJUSTE_Y_SOMBRA_LATERAL_CALENDARIO_A3_P;

//////////////////////////////// BOOK A4 ////////////////////////////////////

//BOOK A4 P 

    //SOMBRA
    export const FATOR_AJUSTE_Y_SOMBRA_LATERAL_CAPA_TIPO1 = 0.03;
    export const FATOR_AJUSTE_Y_SOMBRA_LATERAL_TIPO1 = 0.00638;
    export const FATOR_AJUSTE_Y_SOMBRA_LATERAL_TIPO2 = 0.0021893;

    //CAPA
    export const FATOR_AJUSTE_Y_SOMBRA_GALLERY_EXPANDED_A4 = -58.0999908447266;
    export const FATOR_AJUSTE_Y_SOMBRA_LATERAL_BOOK_A4P = 75.0/2018;
    export const RATIO_SOMBRA_INFERIOR_BOOK_A4P = 2849.0/372;
    export const FATOR_AJUSTE_Y_SOMBRA_INFERIOR_BOOK_A4P = 0.026;
    export const FATOR_AJUSTE_HEIGHT_SOMBRA_LATERAL_BOOK_A4P = 1 - FATOR_AJUSTE_Y_SOMBRA_LATERAL_BOOK_A4P;

    //MIOLO
    export const FATOR_AJUSTE_HEIGHT_DEBRU_BOOK_A4P = 0.025;
    export const FATOR_AJUSTE_DEBRU_LOMBADA_BOOK_A4P = 0.02;
    export const FATOR_AJUSTE_SOMBRA_LATERAL_MIOLO_BOOK_A4P = 0.02;
    export const FATOR_AJUSTE_SOMBRA_LATERAL_SOMBRA_BOOK_A4P = 0.035;
    export const FATOR_AJUSTE_Y_SOMBRA_LATERAL_MIOLO_BOOK_A4P = 0.965;
    export const FATOR_AUMENTO_HEIGHT_LINHA_CANTO = 0.16;
    export const FATOR_AJUSTE_HEIGHT_LINHA_CANTO = FATOR_AJUSTE_HEIGHT_DEBRU_BOOK_A4P * (1+FATOR_AUMENTO_HEIGHT_LINHA_CANTO);
    
    export const FATOR_AJUSTE_HEIGHT_SOMBRA_DEBRU_BOOK_A4P = 1.04;
    export const RATIO_SOMBRA_MIOLO_DEBRU_TODO_BOOK_A4P = 5290/2090;

    //LOMBADA
    export const PROPORCAO_LOMBADA_WIDTH_CAPA = 20.369 //(550.17/27.01)

//BOOK A4 R

    //SOMBRA
    export const RATIO_SOMBRA_INFERIOR_BOOK_A4R = 2210.0/372;

    //DEBRU
    export const FATOR_AJUSTE_DEBRU_HEIGHT_BOOK_A4R = 0.01875;
    export const FATOR_AJUSTE_DEBRU_WIDTH_BOOK_A4R = 0.025;

    //MIOLO
    export const FATOR_AJUSTE_HEIGHT_LINHA_CANTO_A4R = FATOR_AJUSTE_DEBRU_HEIGHT_BOOK_A4R * (1+FATOR_AUMENTO_HEIGHT_LINHA_CANTO);

///////////////////////////////////////////////////////////////////////////////

//////////////////////////////// BARALHO ////////////////////////////////////

export const FATOR_AUMENTO_WIDTH_SOMBRA_DECK_CARDS = 1.132;