import './index.css';

import { MAX_HEIGHT, MAX_WIDTH } from '../../constant/thumb';

import PropTypes from 'prop-types';
import React from 'react';
import loading from '../../asset/thumb-loader.gif';
import IconeUtilizacaoImagem from './IconeUtilizacaoImagem';

const Thumb = ({
  source,
  imgWidth,
  imgHeight,
  isManualSort,
  isPair,
  canDrop,
  isDragging,
  isOver,
  thumbId,
  deleteThumb,
  usedImage,
  imageId,
  toggleThumbExpand,
  updateThumbExpandIDB,
  thumbExpand,
  stackThumb,
  connectDragSource,
  connectDropTarget,
  prodType
}) => {
  const rate = imgHeight / MAX_HEIGHT;
  const fImgWidth = imgWidth / rate;
  const fImgHeight = imgHeight / rate;

  return connectDropTarget(
    connectDragSource(
      <div
        className="thumb"
        onMouseEnter={() =>
          thumbExpand.active !== 'CLICK' &&
          source &&
          toggleThumbExpand('HOVER', imageId)
        }
        onMouseLeave={() =>
          thumbExpand.active !== 'CLICK' && toggleThumbExpand()
        }
        onDragStart={() =>
          thumbExpand.active !== 'CLICK' && toggleThumbExpand()
        }
        onDoubleClick={() =>
          source &&
          toggleThumbExpand('CLICK', imageId) &&
          updateThumbExpandIDB()
        }
      >
        {stackThumb && stackThumb.blobUrl ? (
          <img
            src={stackThumb.blobUrl}
            alt={`Miniatura de foto ${stackThumb.id}`}
            className={`stack ${isPair ? 'pair' : 'odd'}`}
            width={`${stackThumb.width}px`}
            height={`${stackThumb.height}px`}
            style={{
              maxWidth: `${MAX_WIDTH}px`,
              maxHeight: `${MAX_HEIGHT}px`,
              left: `${(96 - stackThumb.width) / 2}px`
            }}
          />
        ) : null}
        <div
          className={`${source ? 'image-container' : 'loading-container'}${isPair ? ' pair' : ' odd'
            }${isManualSort ? ' manual-sort' : ''}${isOver ? ' isOver' : ''}${isDragging ? ' isDragging' : ''
            }${canDrop ? ' canDrop' : ''}${thumbExpand.id === imageId ? ' active' : ''
            }`}
        >
          <div className="white-layer"></div>
          {source ? (
            <img
              src={source}
              alt={`Miniatura de foto ${thumbId}`}
              width={fImgWidth}
              height={fImgHeight}
              style={{
                maxWidth: `${MAX_WIDTH}px`,
                maxHeight: `${MAX_HEIGHT}px`
              }}
            />
          ) : (
            <div
              className="loading"
              style={{
                width: `${MAX_WIDTH}px`,
                height: `${MAX_HEIGHT}px`
              }}
            >
              <img src={loading} alt={'Carregando miniatura'} />
            </div>
          )}
          <IconeUtilizacaoImagem
            source={source}
            prodType={prodType}
            usedImage={usedImage}
            fImgWidth={fImgWidth}
            fImgHeight={fImgHeight}
          />
          {source && (
            <div
              className="remove-btn bt-menu-delete"
              onClick={() => deleteThumb(imageId)}
            />
          )}
        </div>
      </div>
    )
  );
};

Thumb.propTypes = {
  source: PropTypes.string.isRequired,
  imgWidth: PropTypes.number.isRequired,
  imgHeight: PropTypes.number.isRequired,
  isManualSort: PropTypes.bool.isRequired,
  isPair: PropTypes.bool.isRequired,
  canDrop: PropTypes.bool.isRequired,
  isDragging: PropTypes.bool.isRequired,
  isOver: PropTypes.bool.isRequired,
  thumbId: PropTypes.string.isRequired,
  deleteThumb: PropTypes.func.isRequired,
  usedImage: PropTypes.number.isRequired,
  imageId: PropTypes.string.isRequired,
  toggleThumbExpand: PropTypes.func.isRequired,
  updateThumbExpandIDB: PropTypes.func.isRequired,
  thumbExpand: PropTypes.shape({
    active: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired
  }).isRequired,
  stackThumb: PropTypes.shape({
    blobUrl: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    id: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired
  }),
  connectDragSource: PropTypes.func.isRequired,
  connectDropTarget: PropTypes.func.isRequired,
  prodType: PropTypes.string.isRequired
};

export default Thumb;
