export const SET_FINISHED = 'ACTION_SET_FINISHED';
export const SET_EAN_URL = 'ACTION_SET_EAN_URL';
export const SET_IMAGES_UPLOADED = 'ACTION_SET_IMAGES_UPLOADED';
export const SET_METADATAS_UPLOADED = 'ACTION_SET_METADATAS_UPLOADED';
export const SET_PRINT_PAGE = 'ACTION_SET_PRINT_PAGE';
export const UPDATE_ORDER_ID = 'ACTION_UPDATE_ORDER_ID';
export const TOGGLE_IS_3D = 'ACTION_TOGGLE_IS_3D';
export const UPDATE_PAGE_SET_ID = 'ACTION_UPDATE_PAGE_SET_ID';
export const UPDATE_PAGE_FORMAT = 'ACTION_UPDATE_PAGE_FORMAT';
export const UPDATE_PAGE_IDB = 'ACTION_UPDATE_PAGE_IDB';
export const UPDATE_IS_COUPON = 'ACTION_UPDATE_IS_COUPON';
export const UPDATE_IS_PRINT = 'ACTION_UPDATE_IS_PRINT';
export const TOGGLE_MODO_SOB_FIVELA = 'ACTION_TOGGLE_MODO_SOB_FIVELA';
export const SET_MODO_SOB_FIVELA = 'ACTION_SET_MODO_SOB_FIVELA';
export const UPDATE_IS_THUMB = 'ACTION_UPDATE_IS_THUMB';
export const UPDATE_IS_BACK = 'ACTION_UPDATE_IS_BACK';
export const UPDATE_IS_SPINE_MODIFIED = 'ACTION_UPDATE_IS_SPINE_MODIFIED';
export const ADD_IMAGES = 'ITEM_ACTION_ADD_IMAGES';
export const UPDATE_SORT_METHOD = 'ITEM_ACTION_UPDATE_SORT_METHOD';
export const UPDATE_FILTER_METHOD = 'ITEM_ACTION_UPDATE_FILTER_METHOD';
export const ADD_PAGES = 'ITEM_ACTION_ADD_PAGES';
export const REMOVE_PAGE = 'ITEM_ACTION_REMOVE_PAGE';
export const REMOVE_PAGES = 'ITEM_ACTION_REMOVE_PAGES';
export const SET_PAGE_INDEX = 'ACTION_SET_PAGE_INDEX';
export const TOGGLE_MENU_LAYOUTS = 'ACTION_TOGGLE_MENU_LAYOUTS';
export const UPDATE_LAYOUT_FILTER_PHOTO_NUMBER =
  'ACTION_UPDATE_LAYOUT_FILTER_PHOTO_NUMBER';
export const UPDATE_LAYOUT_FILTER_TEXT = 'ACTION_UPDATE_LAYOUT_FILTER_TEXT';
export const UPDATE_LAYOUT_FILTER_APPLY_METHOD =
  'ACTION_UPDATE_LAYOUT_FILTER_APPLY_METHOD';
export const RESET_LAYOUT_FILTER_APPLY_METHOD =
  'ACTION_RESET_LAYOUT_FILTER_APPLY_METHOD';
export const UPDATE_LAYOUT_FILTER_SIDE = 'ACTION_UPDATE_LAYOUT_FILTER_SIDE';
export const UPDATE_LAYOUT_FILTER_PAGES = 'ACTION_UPDATE_LAYOUT_FILTER_PAGES';
export const SAGA_ADJUST_LAYOUTS_FILTER = 'ACTION_SAGA_ADJUST_LAYOUTS_FILTER';
export const SAGA_UPDATE_LAYOUT_FILTER_PHOTO_NUMBER =
  'ACTION_SAGA_UPDATE_LAYOUT_FILTER_PHOTO_NUMBER';
export const ADJUST_LAYOUTS_FILTER = 'ACTION_ADJUST_LAYOUTS_FILTER';
export const TOGGLE_REMOVE_PAGES = 'ACTION_TOGGLE_REMOVE_PAGES';
export const SET_PAGES_TO_REMOVE = 'ACTION_SET_PAGES_TO_REMOVE';
export const UNSET_PAGES_TO_REMOVE = 'ACTION_UNSET_PAGES_TO_REMOVE';
export const SET_TAMANHO_COLEIRA = 'ACTION_SET_TAMANHO_COLEIRA';
export const SET_ALL_USER_PRODUCTS = 'ACTION_SET_ALL_USER_PRODUCTS';
export const SET_USER_PRODUCT = 'ACTION_SET_USER_PRODUCT';
export const SET_SELECTED_PRODUCT = 'ACTION_SET_SELECTED_PRODUCT';
export const SET_FUNDO_COLOR_FRONT = 'ACTION_SET_FUNDO_COLOR_FRONT';
export const SET_FUNDO_COLOR_BACK = 'ACTION_SET_FUNDO_COLOR_BACK';
export const SET_TIPO_FUNDO_FRONT = 'ACTION_SET_TIPO_FUNDO_FRONT';
export const SET_TIPO_FUNDO_BACK = 'ACTION_SET_TIPO_FUNDO_BACK';
