import {
  bookHaveCover,
  getAllBookPagesData,
  getCurrentNav,
  getNavigationIndex,
  getOverviewRemovePages,
  getProductType,
  getScreenSize
} from '../selector';
import {
  getNextSetId,
  getPreviousSetId,
  getSetDif,
  getSetId
} from '../selector/set';
import { toggleRemovePages, updatePageSetId } from '../action/item';

import BookOverviewOptions from '../component/book-overview-options';
import { connect } from 'react-redux';
import { sagaMovePage } from '../action/general';
import store from '../store';
import { 
  updatePopup,
  setStateLegenda
 } from '../action/ui';

const mapStateToProps = state => ({
  allPagesData: getAllBookPagesData(state),
  productType: getProductType(state),
  bookHaveCover: bookHaveCover(state),
  currentNav: getCurrentNav(state),
  setId: getSetId(state),
  nextSetId: getNextSetId(state),
  overviewRemovePages: getOverviewRemovePages(state),
  pageIndex: getNavigationIndex(state),
  previousSetId: getPreviousSetId(state),
  setDif: getSetDif(state),
  screenSize: getScreenSize(state)
});

const addPagesAux = dispatch => {
  const state = store.getState();
  dispatch(updatePageSetId(getNextSetId(state)));
};

const mapDispatchToProps = dispatch => ({
  addPages: () => addPagesAux(dispatch),
  movePage: side => dispatch(sagaMovePage(side)),
  updatePopup: (id, overlay, msg) => {
    dispatch(setStateLegenda('POPUP'));
    return dispatch(updatePopup(id, overlay, msg));
  },
  toggleRemovePages: () => dispatch(toggleRemovePages())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BookOverviewOptions);
