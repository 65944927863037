import './selectProductFormat.css';
import PropTypes from 'prop-types';
import React, {useState, useEffect} from 'react';
import Button from '../../button';
import {
  THEME_CLASS_BT_LEFT_ARROW_BLACK,
  THEME_CLASS_BT_RIGHT_ARROW_BLACK,
  THEME_CLASS_BT_LEFT_ARROW_BLACK_DISABLED,
  THEME_CLASS_BT_RIGHT_ARROW_BLACK_DISABLED
} from '../../../constant/button';

function SelectProductFormat(
  {
    formats,
    selectedFormat,
    setSelectedFormat
  }) {    

  const [arrowLeftDisabled, setArrowLeftDisabled] = useState(true);
  const [arrowRightDisabled, setArrowRightDisabled] = useState(false);
  const ajustesGap = 0;

  useEffect(() => {
    const productFormatScroll = document.getElementById('select-product-scroll');
    setArrowRightDisabled((productFormatScroll.scrollLeft+ajustesGap) >= (productFormatScroll.scrollWidth-productFormatScroll.clientWidth));
    setArrowLeftDisabled(productFormatScroll.scrollLeft <= 0);
  }, [selectedFormat]);

  const scroll = (direction) => {
    const productFormatScroll = document.getElementById('select-product-scroll');
    productFormatScroll.scrollLeft+= (direction === 'RIGHT') ? 80.2 : -80.2;
    setArrowRightDisabled((productFormatScroll.scrollLeft+ajustesGap) >= (productFormatScroll.scrollWidth-productFormatScroll.clientWidth));
    setArrowLeftDisabled(productFormatScroll.scrollLeft <= 0);
  }

  const setFormat = (format) => {
    if(format !== selectedFormat)
      setSelectedFormat(format)
  }

  return (
    <div className="container-select-format">
      <div className="left-button">
        <Button
            style={{margin: '2px 2px 2px 0', width: '12px', visibility: formats.length > 4 ? 'visible':'hidden'}}
            padding={false}
            theme={arrowLeftDisabled? THEME_CLASS_BT_LEFT_ARROW_BLACK_DISABLED:THEME_CLASS_BT_LEFT_ARROW_BLACK}
            clickHandler={() => scroll('LEFT')}
            disabled={arrowLeftDisabled}
        />
      </div>
      <div id="select-product-scroll" className="select-format"
          style={{display: `${formats.length > 4 ? '-webkit-inline-box':'inline-flex'}`}}
        >
        {formats.map((item, index) =>
          <div className={`item-rectangle ${selectedFormat === item.comparator ? 'active':''}`}
            key={index}
            onClick={() => setFormat(item.comparator)}>
            <div className='format'>{item.title}</div>
            <div className='size'>{item.size}</div>
            <div className='price'>{item.price}</div>
          </div>
        )}
      </div>
      <div className="right-button">
        <Button
          style={{margin: '2px 0 2px 2px', width: '12px', visibility: formats.length > 4 ? 'visible':'hidden'}}
            padding={false}
            theme={arrowRightDisabled? THEME_CLASS_BT_RIGHT_ARROW_BLACK_DISABLED:THEME_CLASS_BT_RIGHT_ARROW_BLACK}
            clickHandler={() => scroll('RIGHT')}
            disabled={arrowRightDisabled}
        />
      </div>
    </div>
  );
};

SelectProductFormat.propTypes = {
  formats: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedFormat: PropTypes.string.isRequired,
  setSelectedFormat: PropTypes.func.isRequired
};

SelectProductFormat.defaultProps = {
  formats: [],
};

export default SelectProductFormat;
