import * as actionItem from '../action/item';
import * as actionUI from '../action/ui';
// import { POPUP_ID_LOADING, POPUP_ID_NONE } from '../constant/popup';

import {
  bookHaveCover,
  bookHaveImage,
  getBookNavigatorData,
  getFormats,
  getFundoColor,
  getTipoFundo,
  getScreenSize,
  getProductType,
  getIsModoSobFivela,
} from '../selector';

import { CONFIRM_UPDATE_APPLY_METHOD } from '../constant/popup-message';
import { NAV_NONE_SELECTED } from '../constant/nav';
import NavFundos from '../component/nav-fundos';
import { POPUP_CONFIRM_UPDATE_APPLY_METHOD } from '../constant/popup';
import { connect } from 'react-redux';
import { getSetNumber } from '../selector/set';
import store from '../store';
import { updateScreenSize } from '../action/ui';
import {getTipoPag} from '../utils/textosVariaveis';

const mapStateToProps = state => ({
  screenSize: getScreenSize(state),
  navigatorData: getBookNavigatorData(state),
  pagesFormats: getFormats(state),
  maxPage: getSetNumber(state),
  haveCover: bookHaveCover(state),
  prodType: getProductType(state),
  fundoColor: getFundoColor(state),
  tipoFundo: getTipoFundo(state),
  isModoSobFivela: getIsModoSobFivela(state)
});

const updateApplyMethod = (type, dispatch) =>
  type === `somente ${(getTipoPag())} atual` ||
  type === `${getTipoPag()} dupla` ||
  !bookHaveImage(store.getState())
    ? dispatch(actionItem.updateLayoutFilterApplyMethod(type))
    : (dispatch(actionUI.setStateLegenda('POPUP')), dispatch(
        actionUI.updatePopup(
          POPUP_CONFIRM_UPDATE_APPLY_METHOD,
          true,
          CONFIRM_UPDATE_APPLY_METHOD(type)
        )
      ))

  const setFundoColor = (fundo, dispatch) => {
    // dispatch(actionUI.updatePopup(POPUP_ID_LOADING, true, 'Aplicando'));
    // setTimeout(()=> {dispatch(actionUI.updatePopup(POPUP_ID_NONE, false, ''))}, 500)

    if(getIsModoSobFivela(store.getState()))
      dispatch(actionItem.setFundoColorFront(fundo))
    // return getIsModoSobFivela(store.getState())
    //   ? dispatch(actionItem.setFundoColorFront(fundo))
    //   : dispatch(actionItem.setFundoColorBack(fundo))
  }

  const setTipoFundo = (tipoFundo, dispatch) =>
    getIsModoSobFivela(store.getState())
      ? dispatch(actionItem.setTipoFundoFront(tipoFundo))
      : dispatch(actionItem.setTipoFundoBack(tipoFundo))


const mapDispatchToProps = dispatch => ({
  updateLayoutFilterText: value => dispatch(actionItem.updateLayoutFilterText(value)),
  updateLayoutFilterApplyMethod: value => updateApplyMethod(value, dispatch),
  setFundoColor: fundoColor => setFundoColor(fundoColor, dispatch),
  setTipoFundo: tipoFundo => setTipoFundo(tipoFundo, dispatch),
  setNavNone: () => dispatch(actionUI.updateSelectedNav(NAV_NONE_SELECTED)),
  updateScreenSize: (
    width,
    height,
    headerHeight,
    photobookHeight,
    layoutsContainerWidth
  ) =>
    dispatch(
      updateScreenSize(
        width,
        height,
        headerHeight,
        photobookHeight,
        layoutsContainerWidth
      )
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(NavFundos);
