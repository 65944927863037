import * as actionItem from '../action/item';
import * as actionUI from '../action/ui';

import {
  FINISH_BOOK,
  PAGE_NOT_COMPLETE_FILLED,
  POSTER_NOT_COMPLETE_FILLED
} from '../constant/popup-message';
import {
  POPUP_ID_FINISH_BOOK,
  POPUP_ID_PAGE_NOT_COMPLETE_FILLED,
  POPUP_ID_POSTER_NOT_COMPLETE_FILLED
} from '../constant/popup';
import {
  bookHaveImage,
  bookHaveText,
  getCurrentNav,
  getHasScodix,
  getIsBookOverviewExpanded,
  getPhotoEditor,
  getIsGalleryExpanded,
  getItemIs3D,
  getPagesNotCompleteFilled,
  getPosterNotCompleteFilled,
  getProductName,
  getScreenSize,
  getBookNavigatorVisiblePagesLength,
  bookHaveCover,
  getProductType,
  getIsLegendaAberta,
  getSelectedProduct,
  getNavigationIndex,
  getCustomProducts,
  isFinished
} from '../selector';

import Header from '../component/header';
import { connect } from 'react-redux';
import getFormatedPrice from '../selector/price';
import store from '../store';
import { geraPagesMsgDeckCards } from '../utils/calculosGerais';

const mapStateToProps = state => ({
  name: getProductName(state),
  price: getFormatedPrice(state),
  hasScodix: getHasScodix(state),
  isOn: getItemIs3D(state),
  screenSize: getScreenSize(state),
  bookHaveImage: bookHaveImage(state),
  bookHaveText: bookHaveText(state),
  galleryExpanded: getIsGalleryExpanded(state),
  bookOverviewExpanded: getIsBookOverviewExpanded(state),
  isPhotoEditorActive: getPhotoEditor(state).active,
  currentNav: getCurrentNav(state),
  prodType: getProductType(state),
  isLegendaAberta: getIsLegendaAberta(state),
  selectedProduct: getSelectedProduct(state),
  isFinished: isFinished(state)
});

const fixLabel = label =>
  label > 9 || typeof label === 'string' ? label : `0${label}`;

const primeiraPaginaVaziaIndex = (pageLength, haveCover, firstEmptyPage) => {
  let firstPageIndex = 0;
  try {
    const cover = ['Capa verso', 'Lombada', 'Capa frente'];
    if (!cover.includes(firstEmptyPage)) {
      if (pageLength === 2)
        firstPageIndex =
          Math.floor(Number(firstEmptyPage) / 2) + (haveCover ? 1 : 0);
      else firstPageIndex = Number(firstEmptyPage) - 1;
    }
  } finally {
    return firstPageIndex <= 0 ? 0 : firstPageIndex;
  }
};

const submitHandler = (event, dispatch) => {
  event.preventDefault();
  const state = store.getState();
  const {
    pagesNotCompleteFilled,
    faltaTexto,
    faltaImagem,
    tudoVazio
  } = getPagesNotCompleteFilled(state);
  const prodType = getProductType(state);
  const posteresVazios = getPosterNotCompleteFilled(state);
  const pageLength = getBookNavigatorVisiblePagesLength(state);
  const haveCover = bookHaveCover(state);

  if (prodType === 'deck-cards') {
    if (faltaTexto || faltaImagem || tudoVazio) {
      dispatch(
        actionUI.updatePopup(
          POPUP_ID_PAGE_NOT_COMPLETE_FILLED,
          true,
          PAGE_NOT_COMPLETE_FILLED(
            geraPagesMsgDeckCards(pagesNotCompleteFilled),
            pagesNotCompleteFilled[0],
            tudoVazio
          )
        )
      );
    } else {
      dispatch(actionUI.setStateLegenda('POPUP'));
      dispatch(actionUI.updatePopup(POPUP_ID_FINISH_BOOK, true, FINISH_BOOK()));
    }
  } else {
    if (pagesNotCompleteFilled.length) {
      let pagesMsg = '';
      if (prodType === 'invitation') {
        pagesMsg = 'Convite Frente e Convite Verso';
        if (JSON.stringify(pagesNotCompleteFilled) === JSON.stringify([1]))
          pagesMsg = 'Convite Frente';
        if (JSON.stringify(pagesNotCompleteFilled) === JSON.stringify([2]))
          pagesMsg = 'Convite Verso';
      } else {
        pagesNotCompleteFilled.forEach((page, i) =>
          i === pagesNotCompleteFilled.length - 1
            ? (pagesMsg += `${fixLabel(page)}`)
            : i === pagesNotCompleteFilled.length - 2
              ? (pagesMsg += `${fixLabel(page)} e `)
              : (pagesMsg += `${fixLabel(page)}, `)
        );
      }
      dispatch(actionUI.setStateLegenda('POPUP'));

      if (prodType === 'poster' && posteresVazios.length > 0) {
        dispatch(
          actionUI.updatePopup(
            POPUP_ID_POSTER_NOT_COMPLETE_FILLED,
            true,
            POSTER_NOT_COMPLETE_FILLED(
              pagesMsg,
              primeiraPaginaVaziaIndex(pageLength, false, posteresVazios[0])
            )
          )
        );
      } else {
        dispatch(
          actionUI.updatePopup(
            POPUP_ID_PAGE_NOT_COMPLETE_FILLED,
            true,
            PAGE_NOT_COMPLETE_FILLED(
              pagesMsg,
              primeiraPaginaVaziaIndex(
                pageLength,
                haveCover,
                pagesNotCompleteFilled[0]
              ),
              tudoVazio,
              faltaImagem
            )
          )
        );
      }
    } else {
      dispatch(actionUI.setStateLegenda('POPUP'));
      dispatch(actionUI.updatePopup(POPUP_ID_FINISH_BOOK, true, FINISH_BOOK()));
    }
  }
};

const setUserProductEvent = (productId, command, dispatch) => {
  const state = store.getState();
  const index = getNavigationIndex(state);
  const products = getCustomProducts(state);
  dispatch(actionItem.setUserProduct(index, productId, products, command));
};

const mapDispatchToProps = dispatch => ({
  submitHandler: event => submitHandler(event, dispatch),
  onSwitch: () => dispatch(actionItem.toggleIs3D()),
  updateTutorialStatus: status =>
    dispatch(actionUI.updateTutorialStatus(status)),
  toggleLegenda: () => dispatch(actionUI.toggleLegenda()),
  setStateLegenda: state => dispatch(actionUI.setStateLegenda(state)),
  setSelectedProduct: product =>
    dispatch(actionItem.setSelectedProduct(product)),
  setUserProduct: (productId, command) =>
    setUserProductEvent(productId, command, dispatch),
  setModalConfig: modalConfig => dispatch(actionUI.setModalConfig(modalConfig))
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
