import './index.css';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

import {
  getTipoLegendaMesa,
  produtosEditaveis,
  getTipoGeneroSubProduto,
  anteriorLegendaMesa,
  proximaLegendaMesa,
  overviewLegendaMesa
} from '../../utils/textosVariaveis';

import { haveDuplicar, hasNavegacao } from '../../utils/separadorProdutos';

class Legenda extends Component {
  constructor(props) {
    super(props);
    this.state = {
      prevMockpageCoordenates: null,
      prevIsGalleryExpanded: false,
      prevEditMode: false,
      firstTimeEdit: true,
      firstTimeMesa: true,
      firstTimeTutorial: true,
      firstTimeOverview: true,
      firstTimePopup: true
    };
  }

  componentDidUpdate() {
    const {
      isGalleryExpanded,
      isPhotoEditorActive,
      isBookOverviewExpanded,
      toggleLegenda,
      isLegendaAberta,
      stateLegenda,
      setStateLegenda
    } = this.props;
    if (isGalleryExpanded && !this.state.prevIsGalleryExpanded) {
      this.setState({ prevIsGalleryExpanded: true });
    } else if (!isGalleryExpanded && this.state.prevIsGalleryExpanded) {
      this.setState({ prevIsGalleryExpanded: false });
    } else if (isPhotoEditorActive && !this.state.prevEditMode) {
      this.setState({ prevEditMode: true });
    } else if (!isPhotoEditorActive && this.state.prevEditMode) {
      this.setState({ prevEditMode: false });
    } else if (
      !isLegendaAberta &&
      isPhotoEditorActive &&
      this.state.firstTimeEdit
    ) {
      setTimeout(() => {
        const legenda = document.getElementById('legenda');
        if (legenda && legenda.classList.contains('animacaoEntrada')) {
          toggleLegenda();
        }
      }, 60000);
      toggleLegenda();
      this.setState({ firstTimeEdit: false });
    } else if (this.state.firstTimeMesa && stateLegenda === 'MESA') {
      if (!isLegendaAberta) toggleLegenda();

      setTimeout(() => {
        const legenda = document.getElementById('legenda');
        if (legenda && legenda.classList.contains('animacaoEntrada')) {
          toggleLegenda();
        }
      }, 60000);
      this.setState({ firstTimeMesa: false });
      setStateLegenda('NORMAL');
    } else if (this.state.firstTimeTutorial && stateLegenda === 'TUTORIAL') {
      if (!isLegendaAberta) toggleLegenda();

      setTimeout(() => {
        const legenda = document.getElementById('legenda');
        if (legenda && legenda.classList.contains('animacaoEntrada')) {
          toggleLegenda();
        }
        setStateLegenda('NORMAL');
      }, 60000);
      this.setState({ firstTimeTutorial: false });
    } else if (this.state.firstTimePopup && stateLegenda === 'POPUP') {
      if (!isLegendaAberta) toggleLegenda();

      setTimeout(() => {
        const legenda = document.getElementById('legenda');
        if (legenda && legenda.classList.contains('animacaoEntrada')) {
          toggleLegenda();
        }
        // setStateLegenda('NORMAL');
      }, 60000);
      this.setState({ firstTimePopup: false });
    } else if (
      !isLegendaAberta &&
      isBookOverviewExpanded &&
      this.state.firstTimeOverview
    ) {
      setTimeout(() => {
        const legenda = document.getElementById('legenda');
        if (legenda && legenda.classList.contains('animacaoEntrada')) {
          toggleLegenda();
        }
      }, 60000);
      toggleLegenda();
      this.setState({ firstTimeOverview: false });
    }
  }

  componentDidMount() {
    const mockpageElement = document.getElementById('product-page');
    const {
      isBookOverviewExpanded,
      isGalleryExpanded,
      isPhotoEditorActive
    } = this.props;

    if (
      !isBookOverviewExpanded &&
      !isGalleryExpanded &&
      !isPhotoEditorActive &&
      mockpageElement != null &&
      this.state.prevMockpageCoordenates !==
      mockpageElement.getBoundingClientRect()
    )
      this.setState({
        prevMockpageCoordenates: mockpageElement.getBoundingClientRect()
      });
  }

  render() {
    const {
      isBookOverviewExpanded,
      isPhotoEditorActive,
      toggleLegenda,
      isLegendaAberta,
      stateLegenda,
      prodType
    } = this.props;

    //Constants

    const legendaMesa = getTipoLegendaMesa();
    const artigoProduto = getTipoGeneroSubProduto();
    const legendaHeightInv = prodType === 'invitation' ? 100 : 244;

    let tipoLegenda = 'Mesa';
    let cabecalhoLegenda = 'Mesa';
    let legendaHeight = produtosEditaveis() ? legendaHeightInv : 196;
    let legendaWidth = 370;

    if (isPhotoEditorActive) {
      tipoLegenda = 'Editar';
      cabecalhoLegenda = 'Editar';
      legendaHeight = haveDuplicar(prodType) ? 436 : 388;
    } else if (isBookOverviewExpanded && stateLegenda !== 'POPUP') {
      tipoLegenda = 'OVERVIEW';
      cabecalhoLegenda = overviewLegendaMesa(prodType);
      legendaHeight = 196;
      if (prodType === 'invitation') legendaWidth = 400;
    } else {
      if (stateLegenda === 'INTRODUCAO') {
        tipoLegenda = 'INTRODUCAO';
        cabecalhoLegenda = 'Passo a passo';
        legendaHeight = 148;
      } else if (stateLegenda === 'TUTORIAL') {
        tipoLegenda = 'TUTORIAL';
        cabecalhoLegenda = 'Passo a passo';
        legendaHeight = 196;
      } else if (
        stateLegenda === 'POPUP' ||
        stateLegenda === 'POPUP_IDENTIFY'
      ) {
        tipoLegenda = 'POPUP';
        cabecalhoLegenda = 'Pop-ups';
        legendaHeight = 196;
        legendaWidth = 300;
      }
    }

    const legenda = document.getElementById('legenda');

    if (legenda) {
      if (isLegendaAberta) {
        legenda.classList.remove('animacaoSaida');
        legenda.classList.add('animacaoEntrada');
      } else {
        legenda.classList.remove('animacaoEntrada');
        legenda.classList.add('animacaoSaida');
      }
    }

    const imgHeight = '40px';
    const imgWidth = '44px';
    const marginTop = -legendaHeight / 2;

    return (
      <div
        id="legenda"
        className="legenda"
        style={{ marginTop: marginTop, width: legendaWidth }}
        onClick={() => toggleLegenda()}
      >
        <div className="header-text">
          <div className="text">Comandos pelo teclado: {cabecalhoLegenda}</div>
        </div>
        {tipoLegenda === 'Mesa' && (
          <div className="content">
            {hasNavegacao(prodType) && (
              <>
                <div className="content-line">
                  <img
                    className="content-line-img"
                    alt=""
                    height={imgHeight}
                    width={imgWidth}
                    src={require(`../../asset/legenda/Icone-Seta-Esquerda-Editar-Teclado.png`)}
                  />
                  <div className="button-text">
                    {anteriorLegendaMesa(prodType)}
                  </div>
                </div>
                <div className="content-line">
                  <img
                    className="content-line-img"
                    alt=""
                    height={imgHeight}
                    width={imgWidth}
                    src={require(`../../asset/legenda/Icone-Seta-Direita-Editar-Teclado.png`)}
                  />
                  <div className="button-text">
                    {proximaLegendaMesa(prodType)}
                  </div>
                </div>
                <div className="content-line">
                  <img
                    className="content-line-img"
                    alt=""
                    height={imgHeight}
                    width={imgWidth}
                    src={require(`../../asset/legenda/Icone-Seta-Cima-Editar-Teclado.png`)}
                  />
                  <div className="button-text">
                    {overviewLegendaMesa(prodType)}
                  </div>
                </div>
              </>
            )}
            {produtosEditaveis() && (
              <div className="content-line">
                <img
                  className="content-line-img"
                  alt=""
                  height={imgHeight}
                  width={imgWidth}
                  src={require(`../../asset/legenda/Icone-E-Voltar-Editar-Teclado.png`)}
                />
                {prodType === 'invitation' ? (
                  <div className="button-text">{`Edita a imagem inserida no Convite`}</div>
                ) : (
                  <div className="button-text">{`Edita a imagem inserida na ${legendaMesa} atual`}</div>
                )}
              </div>
            )}
          </div>
        )}
        {tipoLegenda === 'Editar' && (
          <div className="content">
            <div className="content-line">
              <img
                className="content-line-img"
                alt=""
                height={imgHeight}
                width={imgWidth}
                src={require(`../../asset/legenda/Icone-Menos-Zoom-Editar-Teclado.png`)}
              />
              <div className="button-text">Menos zoom</div>
            </div>
            <div className="content-line">
              <img
                className="content-line-img"
                alt=""
                height={imgHeight}
                width={imgWidth}
                src={require(`../../asset/legenda/Icone-Mais-Zoom-Editar-Teclado.png`)}
              />
              <div className="button-text">Mais zoom</div>
            </div>
            <div className="content-line">
              <img
                className="content-line-img"
                alt=""
                height={imgHeight}
                width={imgWidth}
                src={require(`../../asset/legenda/Icone-Girar-Editar-Teclado.png`)}
              />
              <div className="button-text">Girar +90º</div>
            </div>
            {haveDuplicar(prodType) && (
              <div className="content-line">
                <img
                  className="content-line-img"
                  alt=""
                  height={imgHeight}
                  width={imgWidth}
                  src={require(`../../asset/legenda/Icone-Duplicar-Editar-Teclado.png`)}
                />
                <div className="button-text">Duplicar</div>
              </div>
            )}
            <div className="content-line">
              <img
                className="content-line-img"
                alt=""
                height={imgHeight}
                width={imgWidth}
                src={require(`../../asset/legenda/Icone-Remover-Editar-Teclado.png`)}
              />
              <div className="button-text">Remover</div>
            </div>
            <div className="content-line">
              <img
                className="content-line-img"
                alt=""
                height={imgHeight}
                width={imgWidth}
                src={require(`../../asset/legenda/Icone-Seta-Esquerda-Editar-Teclado.png`)}
              />
              <img
                className="content-line-img"
                alt=""
                height={imgHeight}
                width={imgWidth}
                src={require(`../../asset/legenda/Icone-Seta-Cima-Editar-Teclado.png`)}
              />
              <img
                className="content-line-img"
                alt=""
                height={imgHeight}
                width={imgWidth}
                src={require(`../../asset/legenda/Icone-Seta-Direita-Editar-Teclado.png`)}
              />
              <img
                className="content-line-img"
                alt=""
                height={imgHeight}
                width={imgWidth}
                src={require(`../../asset/legenda/Icone-Seta-Baixo-Editar-Teclado.png`)}
              />
              <div className="button-text">Mover</div>
            </div>
            <div className="content-line">
              <img
                className="content-line-img"
                alt=""
                height={imgHeight}
                width={imgWidth}
                src={require(`../../asset/legenda/Icone-Shift-Editar-Teclado.png`)}
              />
              <div className="button-plus-separator">+</div>
              <img
                className="content-line-img"
                alt=""
                height={imgHeight}
                width={imgWidth}
                src={require(`../../asset/legenda/Icone-Seta-Esquerda-Editar-Teclado.png`)}
              />
              <img
                className="content-line-img"
                alt=""
                height={imgHeight}
                width={imgWidth}
                src={require(`../../asset/legenda/Icone-Seta-Cima-Editar-Teclado.png`)}
              />
              <img
                className="content-line-img"
                alt=""
                height={imgHeight}
                width={imgWidth}
                src={require(`../../asset/legenda/Icone-Seta-Direita-Editar-Teclado.png`)}
              />
              <img
                className="content-line-img"
                alt=""
                height={imgHeight}
                width={imgWidth}
                src={require(`../../asset/legenda/Icone-Seta-Baixo-Editar-Teclado.png`)}
              />
              <div className="button-text">Ajuste fino</div>
            </div>
            <div className="content-line">
              <img
                className="content-line-img"
                alt=""
                height={imgHeight}
                width={imgWidth}
                src={require(`../../asset/legenda/Icone-E-Voltar-Editar-Teclado.png`)}
              />
              <div className="button-slash-separator">/</div>
              <img
                className="content-line-img"
                alt=""
                height={imgHeight}
                width={imgWidth}
                src={require(`../../asset/legenda/Icone-Esc-Voltar-Editar-Teclado.png`)}
              />
              <div className="button-slash-separator">/</div>
              <img
                className="content-line-img"
                alt=""
                height={imgHeight}
                width={imgWidth}
                src={require(`../../asset/legenda/Icone-Enter-Voltar-Editar-Teclado.png`)}
              />
              <div className="button-text">Voltar para Mesa</div>
            </div>
          </div>
        )}
        {tipoLegenda === 'INTRODUCAO' && (
          <div className="content">
            <div className="content-line">
              <img
                className="content-line-img"
                alt=""
                height={imgHeight}
                width={imgWidth}
                src={require(`../../asset/legenda/Icone-Seta-Esquerda-Editar-Teclado.png`)}
              />
              <div className="button-text">Passo anterior</div>
            </div>
            <div className="content-line">
              <img
                className="content-line-img"
                alt=""
                height={imgHeight}
                width={imgWidth}
                src={require(`../../asset/legenda/Icone-Seta-Direita-Editar-Teclado.png`)}
              />
              <div className="button-slash-separator">/</div>
              <img
                className="content-line-img"
                alt=""
                height={imgHeight}
                width={imgWidth}
                src={require(`../../asset/legenda/Icone-Enter-Voltar-Editar-Teclado.png`)}
              />
              <div className="button-text">Próximo passo</div>
            </div>
          </div>
        )}
        {tipoLegenda === 'TUTORIAL' && (
          <div className="content">
            <div className="content-line">
              <img
                className="content-line-img"
                alt=""
                height={imgHeight}
                width={imgWidth}
                src={require(`../../asset/legenda/Icone-Seta-Esquerda-Editar-Teclado.png`)}
              />
              <div className="button-text">Passo anterior</div>
            </div>
            <div className="content-line">
              <img
                className="content-line-img"
                alt=""
                height={imgHeight}
                width={imgWidth}
                src={require(`../../asset/legenda/Icone-Seta-Direita-Editar-Teclado.png`)}
              />
              <div className="button-slash-separator">/</div>
              <img
                className="content-line-img"
                alt=""
                height={imgHeight}
                width={imgWidth}
                src={require(`../../asset/legenda/Icone-Enter-Voltar-Editar-Teclado.png`)}
              />
              <div className="button-text">Próximo passo</div>
            </div>
            <div className="content-line">
              <img
                className="content-line-img"
                alt=""
                height={imgHeight}
                width={imgWidth}
                src={require(`../../asset/legenda/Icone-Esc-Voltar-Editar-Teclado.png`)}
              />
              <div className="button-text">Fechar o passo a passo</div>
            </div>
          </div>
        )}
        {tipoLegenda === 'OVERVIEW' && prodType !== 'dog-collar' && (
          <div className="content">
            {prodType !== 'invitation' && (
              <div className="content-line">
                <img
                  className="content-line-img"
                  alt=""
                  height={imgHeight}
                  width={imgWidth}
                  src={require(`../../asset/legenda/Icone-Seta-Esquerda-Editar-Teclado.png`)}
                />
                <div className="button-text">
                  {anteriorLegendaMesa(prodType)}
                </div>
              </div>
            )}
            {prodType !== 'invitation' && (
              <div className="content-line">
                <img
                  className="content-line-img"
                  alt=""
                  height={imgHeight}
                  width={imgWidth}
                  src={require(`../../asset/legenda/Icone-Seta-Direita-Editar-Teclado.png`)}
                />
                <div className="button-text">
                  {proximaLegendaMesa(prodType)}
                </div>
              </div>
            )}
            <div className="content-line">
              <img
                className="content-line-img"
                alt=""
                height={imgHeight}
                width={imgWidth}
                src={require(`../../asset/legenda/Icone-Seta-Baixo-Editar-Teclado.png`)}
              />
              <div className="button-slash-separator">/</div>
              <img
                className="content-line-img"
                alt=""
                height={imgHeight}
                width={imgWidth}
                src={require(`../../asset/legenda/Icone-Esc-Voltar-Editar-Teclado.png`)}
              />
              <div className="button-slash-separator">/</div>
              <img
                className="content-line-img"
                alt=""
                height={imgHeight}
                width={imgWidth}
                src={require(`../../asset/legenda/Icone-Enter-Voltar-Editar-Teclado.png`)}
              />
              <div className="button-text">Voltar para Mesa</div>
            </div>
          </div>
        )}
        {tipoLegenda === 'OVERVIEW' && prodType === 'dog-collar' && (
          <div className="content">
            <div className="content-line">
              <img
                className="content-line-img"
                alt=""
                height={imgHeight}
                width={imgWidth}
                src={require(`../../asset/legenda/Icone-Seta-Cima-Editar-Teclado.png`)}
              />
              <div className="button-text">{`${legendaMesa} Anterior`}</div>
            </div>
            <div className="content-line">
              <img
                className="content-line-img"
                alt=""
                height={imgHeight}
                width={imgWidth}
                src={require(`../../asset/legenda/Icone-Seta-Baixo-Editar-Teclado.png`)}
              />
              <div className="button-text">{`Próxim${artigoProduto} ${legendaMesa}`}</div>
            </div>
            <div className="content-line">
              <img
                className="content-line-img"
                alt=""
                height={imgHeight}
                width={imgWidth}
                src={require(`../../asset/legenda/Icone-Esc-Voltar-Editar-Teclado.png`)}
              />
              <div className="button-slash-separator">/</div>
              <img
                className="content-line-img"
                alt=""
                height={imgHeight}
                width={imgWidth}
                src={require(`../../asset/legenda/Icone-Enter-Voltar-Editar-Teclado.png`)}
              />
              <div className="button-text">Voltar para Mesa</div>
            </div>
          </div>
        )}
        {tipoLegenda === 'POPUP' && (
          <div className="content">
            <div className="content-line">
              <img
                className="content-line-img"
                alt=""
                height={imgHeight}
                width={imgWidth}
                src={require(`../../asset/legenda/Icone-Seta-Esquerda-Editar-Teclado.png`)}
              />
              <div className="button-text">Seleciona o botão da esquerda</div>
            </div>
            <div className="content-line">
              <img
                className="content-line-img"
                alt=""
                height={imgHeight}
                width={imgWidth}
                src={require(`../../asset/legenda/Icone-Seta-Direita-Editar-Teclado.png`)}
              />
              <div className="button-text">Seleciona o botão da direita</div>
            </div>
            <div className="content-line">
              <img
                className="content-line-img"
                alt=""
                height={imgHeight}
                width={imgWidth}
                src={require(`../../asset/legenda/Icone-Enter-Voltar-Editar-Teclado.png`)}
              />
              <div className="button-text">Aciona o botão selecionado</div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

Legenda.propTypes = {
  isBookOverviewExpanded: PropTypes.bool.isRequired,
  isGalleryExpanded: PropTypes.bool.isRequired,
  isPhotoEditorActive: PropTypes.bool.isRequired,
  currentNav: PropTypes.string.isRequired,
  toggleLegenda: PropTypes.func.isRequired,
  isLegendaAberta: PropTypes.bool.isRequired,
  setStateLegenda: PropTypes.func.isRequired,
  stateLegenda: PropTypes.string.isRequired,
  prodType: PropTypes.string.isRequired
};

export default Legenda;
