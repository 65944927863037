import './index.css';
import { ACTIVE, ALL } from '../../constant/textStyle';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { notBreakWord } from '../../utils/separadorProdutos';
import { fundoTexto } from '../../utils/calculosFundos';
import { textOptionsPropTypes } from '../../proptypes';

class CustomRichText extends Component {
  state = { active: false };

  componentDidUpdate(prevProps) {
    const {
      id,
      pageId,
      richTextId,
      textChangeHandler,
      textOptions,
      enabled
    } = this.props;

    const textBoxActive = document.querySelector(
      `.text-box.active.${richTextId}`
    );

    if (textBoxActive) textBoxActive.focus();

    if (
      prevProps.textOptions !== textOptions &&
      textBoxActive &&
      textOptions.active === id &&
      textOptions.pageId === pageId
    ) {
      textChangeHandler(richTextId, pageId, enabled ? textBoxActive.value : '');
    }
  }

  toggleActive = () => {
    this.setState({ active: !this.state.active });
  };

  checkNavText = () => {
    let isNavText = false;
    Array.from(document.querySelectorAll(':hover')).forEach(el => {
      if (el.classList.contains('nav-text')) isNavText = true;
    });
    return isNavText;
  };

  render() {
    const {
      id,
      pageId,
      richTextId,
      editorState,
      x,
      y,
      width,
      height,
      color,
      family,
      size,
      align,
      bold,
      italic,
      underline,
      defaultText,
      toggleNavText,
      textChangeHandler,
      updateTextOptions,
      photoEditorActive,
      isBookOverviewExpanded,
      isPrint,
      isMenuTextSelected,
      rate,
      prodType,
      enabled
    } = this.props;

    const { active: stateActive } = this.state;

    const textStyles = () => ({
      fontFamily: family,
      fontSize: size,
      textAlign: align,
      backgroundColor: fundoTexto(prodType, !editorState.length, isPrint),
      fontWeight: `${bold ? `bold` : `normal`}`,
      fontStyle: `${italic ? `italic` : `normal`}`,
      textDecoration: `${underline ? `underline` : `none`}`,
      color: `${color}`,
      borderWidth: `${isPrint ? 0 : isMenuTextSelected ? rate * 2 : rate}px`,
      cursor: isBookOverviewExpanded ? 'pointer' : 'text',
      wordBreak: notBreakWord.includes(prodType) ? 'keep-all' : 'break-word',
      whiteSpace: notBreakWord.includes(prodType) ? 'pre' : 'pre-wrap',
      opacity: photoEditorActive ? 0.25 : 1,
      lineHeight: 'auto',
      paddingTop: 0
    });

    return (
      <svg
        x={x}
        y={y}
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        className={`rich-text}`}
      >
        <foreignObject
          x="0"
          y={pageId === 'spine' ? -height : 0}
          width={width}
          height={height}
          transform={pageId === 'spine' ? `rotate(90)` : null}
        >
          <div
            className={`photoEditorLayer ${photoEditorActive ? 'visible' : ''}`}
          ></div>
          {!stateActive ? (
            <div
              name="text-box"
              className={`text-box ${richTextId}`}
              style={textStyles()}
              onClick={() => {
                this.toggleActive();
                if (!isBookOverviewExpanded) {
                  updateTextOptions(ACTIVE, id, pageId);
                  updateTextOptions(ALL, {
                    color,
                    family,
                    size,
                    align,
                    bold,
                    italic,
                    underline,
                    prodType
                  });

                  setTimeout(() => toggleNavText(), 1);
                }
              }}
            >
              <span id="rich-text-span-id" className={richTextId}>
                {enabled && editorState ? editorState : defaultText}
              </span>
            </div>
          ) : (
            <textarea
              readOnly={!enabled}
              name="text-box"
              disabled={false}
              className={`text-box active ${richTextId}`}
              style={textStyles(true)}
              defaultValue={enabled ? editorState : defaultText}
              rows="1"
              onPaste={e => e.preventDefault()}
              onBlur={e => {
                if (!this.checkNavText()) {
                  this.toggleActive();
                  if (!isBookOverviewExpanded && enabled) {
                    const textoMudado = e.target.value;
                    setTimeout(() => {
                      textChangeHandler(richTextId, pageId, textoMudado, false);
                    }, 1);
                  }
                }
              }}
            ></textarea>
          )}
        </foreignObject>
      </svg>
    );
  }
}

CustomRichText.propTypes = {
  id: PropTypes.number.isRequired,
  pageId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  richTextId: PropTypes.string.isRequired,
  editorState: PropTypes.string.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  color: PropTypes.string,
  family: PropTypes.string,
  size: PropTypes.number,
  align: PropTypes.string,
  bold: PropTypes.bool,
  italic: PropTypes.bool,
  underline: PropTypes.bool,
  defaultText: PropTypes.string.isRequired,
  toggleNavText: PropTypes.func.isRequired,
  textChangeHandler: PropTypes.func.isRequired,
  updateTextOptions: PropTypes.func.isRequired,
  photoEditorActive: PropTypes.bool.isRequired,
  isBookOverviewExpanded: PropTypes.bool.isRequired,
  isPrint: PropTypes.bool.isRequired,
  isMenuTextSelected: PropTypes.bool.isRequired,
  textOptions: textOptionsPropTypes,
  rate: PropTypes.number.isRequired,
  prodType: PropTypes.string.isRequired,
  enabled: PropTypes.bool.isRequired
};

CustomRichText.defaultProps = {
  x: 0,
  y: 0,
  bold: false,
  italic: false,
  underline: false
};

export default CustomRichText;
