import React from 'react';
import PropTypes from 'prop-types';
import Mockup from './mockup';
import './index.css';

const Coupon = ({ mockupImg, width, height }) => (
  <div className="coupon" style={{ width, height }}>
    <Mockup img={mockupImg} />
  </div>
);

Coupon.propTypes = {
  mockupImg: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

export default Coupon;
