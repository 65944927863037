import * as R from 'ramda';
import {
  FILTER_ALL,
  FILTER_MULTI_USED,
  FILTER_NOT_USED,
  FILTER_USED
} from '../constant/filter';
import {
  NAV_BOOK_SELECTED,
  NAV_IMAGE_SELECTED,
  NAV_LAYOUTS_SELECTED,
  NAV_TEXT_SELECTED,
  NAV_FUNDOS_SELECTED
} from '../constant/nav';
import { SORT_ALFABETIC, SORT_DATE } from '../constant/sort';
import { createSelector } from 'reselect';
import { denormalizeImg } from '../schema/image';
import { denormalizePage } from '../schema/page';
import {
  noEndpapers,
  oneCols,
  singlePageStyles
} from '../utils/separadorProdutos';
import {
  findEmptyStyle,
  selectLayoutsFrente,
  verificaLayoutVazio,
  verificaTextoVazio,
  isFix
} from '../utils/calculosGerais';

export const getItem = state => state.item;
export const getLibrary = state => state.library;
export const getUI = state => state.ui;
export const getImageData = state => state.library.image;
export const getNavigation = createSelector(getItem, item => item.navigation);

export const getPageData = state => state.page;
export const getShapeData = state => state.shapeData;

export const getItemId = createSelector(getItem, item => item.id);

export const getItemIs3D = createSelector(getItem, item => item.is3D);

export const isCoupon = createSelector(getItem, item => item.isCoupon);

export const isPrint = createSelector(getItem, item => item.isPrint);

export const isThumb = createSelector(getItem, item => item.isThumb);

export const isBack = createSelector(getItem, item => item.isBack);

export const isFinished = createSelector(getItem, item => {
  return item.finished && !isFix();
});

export const getEanUrl = createSelector(getItem, item => item.ean);

export const isMetadataUploaded = createSelector(
  getItem,
  item => item.metadatasUploaded
);

export const isSpineModified = createSelector(
  getItem,
  item => item.isSpineModified
);

export const getLayoutsFilter = createSelector(
  getItem,
  item => item.layoutsFilter
);

export const getProduct = createSelector(
  getItem,
  getLibrary,
  ({ id }, library) => id && library.product[id]
);

export const getCustomProducts = createSelector(
  getLibrary,
  library => library.products
);

export const getUserProducts = createSelector(getItem, item => item.products);

export const getSelectedProduct = createSelector(
  getItem,
  item => item.selectedProduct
);

export const getProductType = createSelector(
  getProduct,
  product => product && product.subtype
);

export const getPaperSize = createSelector(
  getProduct,
  product => product && product.pageFormat
);

export const getMaxUserDiskSpace = createSelector(
  getProduct,
  product => product && product.maxUserDiskSpace
);

export const getProductSource = createSelector(
  getProduct,
  product => product && product.source
);

export const getFormats = createSelector(getProduct, product => product.format);

export const getSettings = createSelector(getProduct, product =>
  product ? product.settings : null
);

export const getBibliotecaTemasOpened = createSelector(
  getUI,
  ui => ui.bibliotecaTemas
);

export const getModalConfig = createSelector(getUI, ui => ui.modalConfig);

export const getBibliotecaTemas = createSelector(getProduct, product =>
  product && product.themes ? product.themes : []
);

export const getPrintOptions = createSelector(
  getSettings,
  settings => settings.print
);

export const getUrls = createSelector(getSettings, settings => settings.urls);

const getDescription = createSelector(
  getSettings,
  getLibrary,
  (settings, library) =>
    library && settings ? library.description[settings.description] : null
);

export const getDescriptionName = createSelector(getDescription, description =>
  description ? description.short : null
);

export const getProductFormat = createSelector(getDescriptionName, name =>
  name && name.split(' | ')[1] ? name.split(' | ')[1] : ''
);

export const getProductName = createSelector(
  isCoupon,
  getDescriptionName,
  (isCoupon, name) => `${isCoupon ? 'Cupom' : ''} ${name}`
);

export const getProductCode = createSelector(
  getDescription,
  description => description.code
);

export const getHasScodix = createSelector(
  getSettings,
  settings => settings.options3D.enabled
);

export const getIDBStatus = createSelector(getUI, ui => ui.IDB);

export const getTutorialStatus = createSelector(getUI, ui => ui.tutorial);

export const getScreenSizeError = createSelector(
  getUI,
  ui => ui.screenSizeError
);

export const getThumbExpand = createSelector(getUI, ui => ui.thumbExpand);

export const getPhotoEditor = createSelector(getUI, ui => ui.photoEditor);

export const getToolbarPhoto = createSelector(getUI, ui => ui.toolbarPhoto);

export const getOverviewRemovePages = createSelector(
  getItem,
  item => item.removePages
);

export const getScreenSize = createSelector(getUI, ui => ui.screen.size);

export const getScreenRate = createSelector(getUI, ui => ui.screen.rate);

export const getWarning = createSelector(getUI, ui => ui.warning);

export const getTextOptions = createSelector(getUI, ui => ui.textOptions);

export const getTextOptionsColeira = createSelector(
  getItem,
  getLibrary,
  ({ navigation: { index } }, { text, page }) => {
    const textId = page[index].texts[0];
    const textOptions = text[textId].textOptions;
    return textOptions;
  }
);

export const getProductCanvasSize = createSelector(
  getScreenSize,
  isCoupon,
  (screenSize, isCoupon) => {
    const { width: sWidth, height: sHeight, headerHeight } = screenSize;
    const padding = 10;
    let height = sHeight;
    height -= headerHeight;
    if (!isCoupon) {
      height -= 104;
      height -= padding * 2;
      height -= 46;
    }

    if (height < 0) {
      height = 0;
    }

    return { width: sWidth, height };
  }
);

export const getPhotobookHeight = createSelector(getScreenSize, screenSize => {
  const { photobookHeight } = screenSize;
  return photobookHeight;
});

export const getToolbarPhotoSize = createSelector(getScreenSize, screenSize => {
  const { width, height, headerHeight, photobookHeight } = screenSize;
  const thumbListWidth = width * 0.97;
  const thumbListMargin = width * 0.025;
  const arrowContainerMargin = width * 0.015;
  const thumbExpandContainerHeight = photobookHeight + height * 0.03;
  const thumbExpandContainerTopAux = headerHeight + 94 + width * 0.016;
  const thumbExpandContainerTop =
    thumbExpandContainerTopAux +
    (height -
      thumbExpandContainerTopAux -
      photobookHeight -
      56 -
      width * 0.016) /
    2;
  return {
    thumbListWidth,
    thumbListMargin,
    arrowContainerMargin,
    thumbExpandContainerHeight,
    thumbExpandContainerTop
  };
});

export const getCurrentNav = createSelector(getUI, ui => ui.nav.selected);
export const getPreviousNav = createSelector(getUI, ui => ui.nav.previous);

export const getIsMenuPhotoSelected = createSelector(
  getCurrentNav,
  currentNav => currentNav === NAV_IMAGE_SELECTED
);

export const getIsMenuTextSelected = createSelector(
  getCurrentNav,
  currentNav => currentNav === NAV_TEXT_SELECTED
);

export const getIsMenuBookSelected = createSelector(
  getCurrentNav,
  currentNav => currentNav === NAV_BOOK_SELECTED
);

export const getIsMenuLayoutsSelected = createSelector(
  getCurrentNav,
  currentNav => currentNav === NAV_LAYOUTS_SELECTED
);

export const getIsMenuFundosSelected = createSelector(
  getCurrentNav,
  currentNav => currentNav === NAV_FUNDOS_SELECTED
);

const filterStaged = R.filter(R.prop('staged'));
const filterUsed = R.filter(R.prop('used'));
const sortByIndex = R.sortBy(data => data.index);
const sortByMetaName = R.sortBy(
  R.compose(R.toLower, R.path(['metadata', 'name']))
);
const sortByMetaDate = R.sortBy(R.path(['metadata', 'lastModified']));

export const getSortMethod = createSelector(getItem, item => item.sortMethod);

export const getFilterMethod = createSelector(
  getItem,
  item => item.filterMethod
);

// DEPRECATED
// export const getImageDataLength = createSelector(
//   getImageData,
//   imgData => R.length(R.values(imgData))
// );

const getFilteredImgList = (filterMethod, data) => {
  switch (filterMethod) {
    case FILTER_ALL:
      return data;
    case FILTER_USED:
      return data.filter(image => (image.used >= 1 ? image : null));
    case FILTER_NOT_USED:
      return data.filter(image => (image.used === 0 ? image : null));
    case FILTER_MULTI_USED:
      return data.filter(image => (image.used > 1 ? image : null));
    default:
      return data;
  }
};

const getSortedImgList = (sortMethod, data) => {
  switch (sortMethod) {
    case SORT_ALFABETIC:
      return sortByMetaName(data);
    case SORT_DATE:
      return sortByMetaDate(data);
    default:
      return sortByIndex(data);
  }
};

const getImage = createSelector(getItem, item => item.image);

const getImages = createSelector(getLibrary, getImage, (lib, image) => {
  const denormalized = denormalizeImg(image, lib);
  return denormalized;
});

export const getNotUploaded = createSelector(getImages, images =>
  R.map(
    i => i.id,
    R.filter(i => !i.uploaded, filterStaged(images))
  )
);

export const getNotUploadedAndUsed = createSelector(getImages, images =>
  R.map(
    i => i.id,
    R.filter(i => !i.uploaded && i.used, filterStaged(images))
  )
);

export const getImagesId = createSelector(getImages, images =>
  R.map(({ id }) => id, filterStaged(images))
);

export const getImagesData = createSelector(getImages, images =>
  R.map(
    ({ id, metadata, source }) => ({ id, metadata, source }),
    filterStaged(images)
  )
);

export const getTotalImagesSize = createSelector(getImages, images =>
  R.reduce(
    (total, { metadata }) => (total += metadata.size),
    0,
    filterStaged(images)
  )
);

export const getThumbExpandImage = createSelector(
  getUI,
  getImages,
  ({ thumbExpand: { id } }, images) =>
    R.length(id)
      ? R.path(
        ['source', 'image', 'blobUrl'],
        R.find(R.propEq('id', id))(images)
      )
      : id
);

export const getFilteredSortedImgs = createSelector(
  getImages,
  getSortMethod,
  getFilterMethod,
  (images, sortMethod, filterMethod) => {
    const imgStaged = filterStaged(images);

    const filtered = getFilteredImgList(filterMethod, imgStaged);
    const sorted = getSortedImgList(sortMethod, filtered);

    return sorted;
  }
);

export const getUsedImage = createSelector(getFilteredSortedImgs, images => {
  const staged = filterStaged(images);

  let array = [];
  staged.forEach(image => {
    array.push({
      id: image.id,
      used: image.used
    });
  });

  return array;
});

const mapImgToThumb = R.map(R.path(['source', 'thumb']));

export const getAllImagesThumb = createSelector(
  getFilteredSortedImgs,
  images => {
    const staged = R.filter(R.propEq('staged', true), images);
    const list = mapImgToThumb(staged);

    return list;
  }
);

export const getIsGalleryExpanded = createSelector(
  getUI,
  ui => ui.gallery.expanded
);

export const getIsBookOverviewExpanded = createSelector(
  getUI,
  ui => ui.bookOverview.expanded
);

export const getIsModoImpressao = createSelector(getUI, ui => ui.modoImpressao);

export const getIsRevisarMudancaOrientacao = createSelector(
  getLibrary,
  lib => lib.revisarMudancaOrientacao
);

export const getIsLegendaAberta = createSelector(getUI, ui => ui.legendaAberta);

export const getStateLegenda = createSelector(getUI, ui => ui.stateLegenda);

export const getBookNavigatorLines = () => 1;

export const getBookNavigatorColumns = createSelector(
  isPrint,
  getProductType,
  (isPrint, type) => (isPrint || oneCols.includes(type) ? 1 : 2)
);

export const getBookNavigatorVisiblePagesLength = createSelector(
  getBookNavigatorLines,
  getBookNavigatorColumns,
  (lineLength, colLength) => lineLength * colLength
);

export const getSetPageNumber = createSelector(
  getItem,
  getLibrary,
  getProductType,
  ({ pageSetId }, { set }, prodType) => {
    if (prodType === 'invitation') return 1;
    return set[pageSetId].number;
  }
);

export const getTamanhoColeira = createSelector(
  getItem,
  item => item.tamanhoColeira
);

export const getFundoColor = createSelector(getItem, item =>
  item.modoSobFivela ? item.fundoColorFront : item.fundoColorBack
);

export const getTipoFundo = createSelector(getItem, item =>
  item.modoSobFivela ? item.tipoFundoFront : item.tipoFundoBack
);

export const getIsModoSobFivela = createSelector(
  getItem,
  item => item.modoSobFivela
);

export const getSetSpineMargin = createSelector(
  getItem,
  getLibrary,
  (item, library) => {
    const setId = item.pageSetId;
    const set = library.set[setId];

    return set.spine;
  }
);

const getStyleId = createSelector(
  getProduct,
  product => product.template.styles
);

const getStyles = createSelector(getLibrary, library => library.style);

const getShapes = createSelector(getLibrary, library => library.shape);

const getTextArea = createSelector(getLibrary, library => library.textarea);

export const getProductStyles = createSelector(
  getStyleId,
  getStyles,
  getShapes,
  getTextArea,
  getProductType,
  getIsModoSobFivela,
  (styleIds, library, shapes, textareas, prodType, isModoSobFivela) => {
    const styles = R.map(id => library[id], styleIds);

    const shapedStyles = R.map(
      style => ({
        ...style,
        shape: style.shape
          ? R.map(shapeId => shapes[shapeId], style.shape)
          : undefined,
        textarea: style.textarea
          ? R.map(textId => textareas[textId], style.textarea)
          : undefined
      }),
      styles
    );

    return selectLayoutsFrente(prodType, isModoSobFivela, shapedStyles);
  }
);

const isText = (l, text) =>
  (text === 'com' && l.textarea !== undefined) ||
  (text === 'sem' && l.textarea === undefined);

const getMaxMinShapes = (type, styles, text) => {
  const shapesLength = R.map(
    s => R.length(R.prop('shape', s)),
    R.filter(
      s => R.prop(type, s) && R.prop('shape', s) && isText(s, text),
      styles
    )
  );
  return {
    max: Math.max(...shapesLength),
    min: Math.min(...shapesLength)
  };
};

export const getMaxMinShapeNumbers = createSelector(
  getProductStyles,
  getLayoutsFilter,
  (styles, { layoutSelected, text }) => {
    let minMax = getMaxMinShapes('page', styles, text);
    // const lastpage = getMaxMinShapes('lastpage', styles, text);
    const currentStyle = R.find(R.propEq('id', layoutSelected))(styles);

    if (currentStyle) {
      if (currentStyle.back_cover) {
        minMax = getMaxMinShapes('back_cover', styles, text);
      } else if (currentStyle.cover) {
        minMax = getMaxMinShapes('cover', styles, text);
      }
    }

    return minMax;
  }
);

export const getPageSummary = createSelector(getItem, item => item.page);

export const bookHaveCover = createSelector(getProductStyles, styles =>
  styles.some(s => s.cover)
);

export const getCoverStyles = createSelector(getProductStyles, styles =>
  R.filter(s => s.cover, styles)
);

export const getBackCoverStyles = createSelector(getProductStyles, styles =>
  R.filter(s => s.back_cover, styles)
);

export const getSpineStyles = createSelector(getProductStyles, styles =>
  R.filter(s => s.spine, styles)
);

export const getSpineIndex = createSelector(
  getBookNavigatorColumns,
  bookHaveCover,
  (colLength, bookHaveCover) => colLength % (bookHaveCover ? 2 : 0)
);

export const bookHaveImage = createSelector(getImages, images =>
  R.length(filterUsed(images)) ? true : false
);

const reduceArrToIndexedObj = R.reduce((acc, current) => {
  acc[current.id] = current;
  return acc;
}, {});

const filterLayouts = (styles, lib, idb) =>
  R.head(R.filter(R.propEq('id', R.path([idb, 'style'], lib)), styles));

export const getEndpapers = createSelector(bookHaveCover, bookHaveCover => {
  const endpapers = {
    before: {
      pages: [
        {
          id: 'endpaper-0',
          style: {
            id: 'endpaper-0-style',
            cover: false,
            back_cover: false,
            spine: false,
            page: false,
            lastpage: false,
            opacity: bookHaveCover ? 1 : 0.5,
            sequence: 0,
            shape: [],
            textarea: []
          },
          index: 0,
          frames: [],
          texts: [],
          position: 'before-content',
          label: 'Guarda'
        }
      ]
    },
    after: {
      pages: [
        {
          id: 'endpaper-1',
          style: {
            id: 'endpaper-1-style',
            cover: false,
            back_cover: false,
            spine: false,
            page: false,
            lastpage: false,
            opacity: bookHaveCover ? 1 : 0.5,
            sequence: 0,
            shape: [],
            textarea: []
          },
          index: 0,
          frames: [],
          texts: [],
          position: 'after-content',
          label: 'Guarda'
        }
      ]
    }
  };

  return endpapers;
});

export const getCovers = (
  indexedPages,
  library,
  coverStyles,
  spineStyles,
  backCoverStyles,
  bookHaveCover
) => {
  const covers = {
    back_cover: R.has('back_cover', indexedPages)
      ? R.prop('back_cover', indexedPages)
      : {
        id: 'back_cover',
        style: R.prop('back_cover', library.page)
          ? filterLayouts(backCoverStyles, library.page, 'back_cover')
          : R.head(backCoverStyles),
        index: 0,
        frames: [],
        texts: [],
        position: 'left',
        label: 'Capa verso'
      },
    spine: R.has('spine', indexedPages)
      ? R.prop('spine', indexedPages)
      : {
        id: 'spine',
        style: R.head(spineStyles),
        index: 0,
        frames: [],
        texts: [],
        position: 'middle',
        label: 'Lombada'
      },
    cover: R.has('cover', indexedPages)
      ? R.prop('cover', indexedPages)
      : {
        id: 'cover',
        style: R.prop('cover', library.page)
          ? filterLayouts(coverStyles, library.page, 'cover')
          : R.head(coverStyles),
        index: 0,
        frames: [],
        texts: [],
        position: 'right',
        label: 'Capa frente'
      }
  };

  return bookHaveCover ? covers : {};
};
export const getEndpapersLength = createSelector(
  getEndpapers,
  getProductType,
  (endpapers, type) =>
    noEndpapers.includes(type)
      ? 0
      : endpapers.before.pages.length + endpapers.after.pages.length
);

const getVisiblePageLength = createSelector(
  getSetPageNumber,
  getBookNavigatorVisiblePagesLength,
  getEndpapersLength,
  (setPageNumber, pageLength, endpapersLength) =>
    (setPageNumber + endpapersLength) / pageLength - 1
);

export const getNavigationIndex = createSelector(
  getNavigation,
  getVisiblePageLength,
  bookHaveCover,
  (navigation, maxPages, bookHaveCover) => {
    const index = navigation.index;
    const cover = bookHaveCover ? 1 : 0;

    const normalizedIndex = index > maxPages + cover ? maxPages + cover : index;
    return normalizedIndex;
  }
);

export const getPDFCoverLength = createSelector(
  getPaperSize,
  getPrintOptions,
  bookHaveCover,
  (paperSizer, print, bookHaveCover) => {
    if (!bookHaveCover) return 0;
    const { width, height } = print;
    switch (paperSizer) {
      case 'A5':
        return 1;
      case 'A4':
        return width > height ? 2 : 1;
      default:
        break;
    }
  }
);

const getRandomLastPageStyle = styles => {
  const lastpageStyles = R.filter(R.propEq('lastpage', true), styles);
  const random = parseInt(Math.random() * R.length(lastpageStyles));
  return R.prop(random, lastpageStyles);
};

export const getBookNavigatorData = createSelector(
  getBookNavigatorLines,
  getBookNavigatorColumns,
  getBookNavigatorVisiblePagesLength,
  getNavigationIndex,
  getProductStyles,
  getProductType,
  getPageSummary,
  getLibrary,
  getEndpapers,
  getEndpapersLength,
  getCoverStyles,
  getSpineStyles,
  getBackCoverStyles,
  getSetPageNumber,
  bookHaveCover,
  getSpineIndex,
  getProductType,
  getIsModoSobFivela,
  (
    lineLength,
    colLength,
    visiblePagesLength,
    startIndex,
    styles,
    type,
    summary,
    library,
    endpapers,
    endpapersLength,
    coverStyles,
    spineStyles,
    backCoverStyles,
    maxPages,
    bookHaveCover,
    spineIndex,
    prodType,
    isModoSobFivela
  ) => {
    const pages = denormalizePage(summary, library);
    const indexedPages = reduceArrToIndexedObj(pages);
    const beforeCoreLength = endpapers.before.pages.length;
    const afterCoreLength = endpapers.after.pages.length;
    const coversLength = bookHaveCover ? 2 : 0;
    const covers = getCovers(
      indexedPages,
      library,
      coverStyles,
      spineStyles,
      backCoverStyles,
      bookHaveCover
    );

    const generatePages = count => {
      const index = startIndex * visiblePagesLength + count;

      if (bookHaveCover && startIndex <= spineIndex) {
        if (index === 0) {
          return covers.back_cover;
        }
        if (index === 1) {
          return covers.spine;
        }
        if (index === 2) {
          return covers.cover;
        }
      }

      let pageIndex;

      if (!!endpapersLength) {
        pageIndex = index - beforeCoreLength;
        if (index - coversLength < beforeCoreLength) {
          return endpapers.before.pages[index - coversLength];
        }

        const afterCoreIndex =
          index - (maxPages + endpapersLength + coversLength) + afterCoreLength;

        if (afterCoreIndex === 0) {
          return endpapers.after.pages[afterCoreIndex];
        }
      } else {
        pageIndex = index;
      }

      if (prodType === 'deck-cards') {
        const fakeIndex = isModoSobFivela ? 1 : 0;
        if (R.has(fakeIndex, indexedPages)) {
          return {
            ...R.prop(fakeIndex, indexedPages),
            id: fakeIndex,
            index: fakeIndex + 1
          };
        }
        const customStyle = isModoSobFivela
          ? styles[0]
          : findEmptyStyle(styles);
        return {
          id: fakeIndex,
          style: customStyle,
          index: fakeIndex + 1,
          frames: [],
          texts: []
        };
      }

      if (prodType === 'invitation' && pageIndex === 1) {
        if (R.has(pageIndex, indexedPages)) {
          return {
            ...R.prop(pageIndex, indexedPages),
            id: pageIndex,
            index: pageIndex + 1
          };
        }
        return {
          id: pageIndex,
          style: styles[pageIndex],
          index: pageIndex + 1,
          frames: [],
          texts: []
        };
      }

      if (R.has(pageIndex - coversLength, indexedPages)) {
        return R.prop(pageIndex - coversLength, indexedPages);
      }

      return {
        id: pageIndex - coversLength,
        style: R.path(['page', pageIndex - coversLength], library)
          ? filterLayouts(styles, library.page, pageIndex - coversLength)
          : singlePageStyles.includes(type)
            ? styles[0]
            : !bookHaveCover && pageIndex === maxPages - 1
              ? getRandomLastPageStyle(styles)
              : R.prop(pageIndex - coversLength, styles),
        index: index - coversLength + (!endpapersLength ? 1 : 0),
        frames: [],
        texts: []
      };
    };

    const pageData = R.times(
      generatePages,
      bookHaveCover && startIndex === spineIndex
        ? visiblePagesLength + 1
        : visiblePagesLength
    );

    const result = {
      lines: lineLength,
      cols:
        bookHaveCover && startIndex === spineIndex ? colLength + 1 : colLength,
      pages: pageData,
      endpapers,
      covers
    };

    return result;
  }
);

export const getAllBookPagesData = createSelector(
  getBookNavigatorLines,
  getBookNavigatorColumns,
  getProductStyles,
  getProductType,
  getPageSummary,
  getLibrary,
  getEndpapers,
  getEndpapersLength,
  getCoverStyles,
  getSpineStyles,
  getBackCoverStyles,
  getSetPageNumber,
  bookHaveCover,
  getProductType,
  getIsModoSobFivela,
  (
    lineLength,
    colLength,
    styles,
    type,
    summary,
    library,
    endpapers,
    endpapersLength,
    coverStyles,
    spineStyles,
    backCoverStyles,
    maxPages,
    bookHaveCover,
    prodType,
    isModoSobFivela
  ) => {
    const pages = denormalizePage(summary, library);
    const indexedPages = reduceArrToIndexedObj(pages);
    const beforeCoreLength = endpapers.before.pages.length;
    const afterCoreLength = endpapers.after.pages.length;
    const covers = getCovers(
      indexedPages,
      library,
      coverStyles,
      spineStyles,
      backCoverStyles,
      bookHaveCover
    );

    const generatePages = count => {
      let pageIndex;

      if (!!endpapersLength) {
        pageIndex = count - beforeCoreLength;

        if (count < beforeCoreLength) {
          return endpapers.before.pages[count];
        }

        const afterCoreIndex =
          count - (maxPages + endpapersLength) + afterCoreLength;

        if (afterCoreIndex === 0) {
          return endpapers.after.pages[afterCoreIndex];
        }
      } else {
        pageIndex = count;
      }

      if (prodType === 'deck-cards') {
        const fakeIndex = isModoSobFivela ? 1 : 0;
        if (R.has(fakeIndex, indexedPages)) {
          return {
            ...R.prop(fakeIndex, indexedPages),
            id: fakeIndex,
            index: fakeIndex + 1
          };
        }

        const customStyle = isModoSobFivela
          ? styles[0]
          : findEmptyStyle(styles);
        return {
          id: fakeIndex,
          style: customStyle,
          index: fakeIndex + 1,
          frames: [],
          texts: []
        };
      }

      if (prodType === 'invitation' && pageIndex === 1) {
        if (R.has(pageIndex, indexedPages)) {
          return {
            ...R.prop(pageIndex, indexedPages),
            id: pageIndex,
            index: pageIndex + 1
          };
        }
        return {
          id: pageIndex,
          style: styles[pageIndex],
          index: pageIndex + 1,
          frames: [],
          texts: []
        };
      }

      if (R.has(pageIndex, indexedPages)) {
        return R.prop(pageIndex, indexedPages);
      }

      return {
        id: pageIndex,
        style: R.prop(pageIndex, library.page)
          ? filterLayouts(styles, library.page, pageIndex)
          : singlePageStyles.includes(type)
            ? styles[0]
            : !bookHaveCover && pageIndex === maxPages - 1
              ? getRandomLastPageStyle(styles)
              : R.prop(pageIndex, styles),
        index: count + (!endpapersLength ? 1 : 0),
        frames: [],
        texts: []
      };
    };

    const pageData = R.times(generatePages, endpapersLength + maxPages);

    const result = {
      lines: lineLength,
      cols: colLength,
      pages: pageData,
      endpapers,
      covers: bookHaveCover ? covers : {}
    };

    return result;
  }
);

export const getDeckCardsPagesData = createSelector(
  getProductStyles,
  getPageSummary,
  getLibrary,
  (styles, summary, library) => {
    const pages = denormalizePage(summary, library);
    const indexedPages = reduceArrToIndexedObj(pages);

    const generatePages = fakeIndex => {
      if (R.has(fakeIndex, indexedPages)) {
        return {
          ...R.prop(fakeIndex, indexedPages),
          id: fakeIndex,
          index: fakeIndex + 1
        };
      }

      const customStyle = fakeIndex ? styles[0] : findEmptyStyle(styles);
      return {
        id: fakeIndex,
        style: customStyle,
        index: fakeIndex + 1,
        frames: [],
        texts: []
      };
    };

    return R.times(generatePages, 2);
  }
);

export const getNextEmptyPage = createSelector(
  getAllBookPagesData,
  getNavigationIndex,
  bookHaveCover,
  ({ pages }, navIndex, bookHaveCover) => {
    const pageId = navIndex - (bookHaveCover ? 1 : 0);
    return pages.find(
      page =>
        page.index > pageId &&
        page.frames.length <= 0 &&
        (page.texts == null ||
          page.texts.length <= 0 ||
          page.texts.find(t => t.richTextState.length > 0) == null)
    );
  }
);

export const getFirstEmptyPage = createSelector(
  getAllBookPagesData,
  ({ pages }) =>
    pages.find(
      page =>
        page.frames.length <= 0 &&
        (page.texts == null ||
          page.texts.length <= 0 ||
          page.texts.find(t => t.richTextState.length > 0) == null) &&
        !['endpaper-0'].includes(page.id)
    )
);

export const getFirstEmptyTextPage = createSelector(
  getAllBookPagesData,
  ({ pages }) =>
    pages.find(
      ({ texts }) =>
        texts == null ||
        texts.length <= 0 ||
        texts.find(t => t.richTextState.length > 0) == null
    )
);

export const getShapesInBook = createSelector(
  getAllBookPagesData,
  bookHaveCover,
  getProductType,
  getDeckCardsPagesData,
  ({ pages, covers }, bookHaveCover, getProductType, deckCardsPagesData) => {
    pages = getProductType === 'deck-cards' ? deckCardsPagesData : pages;
    const sumPages = R.reduce(
      (sum, { style: { shape } }) => sum + shape.length,
      0,
      pages
    );

    const sumCover = bookHaveCover
      ? R.reduce(
        (sum, { style: { shape } }) => (shape ? sum + shape.length : sum),
        0,
        R.compose(
          R.values,
          R.map(i => i)
        )(covers)
      )
      : 0;

    const sum = sumPages + sumCover;
    return sum;
  }
);

export const getPagesNotCompleteFilled = createSelector(
  getAllBookPagesData,
  getProductType,
  getProductStyles,
  getDeckCardsPagesData,
  ({ pages, covers }, prodType, deckCardsPage) => {
    let tudoVazio = false;
    if (prodType === 'deck-cards') {
      pages = deckCardsPage;
      tudoVazio = pages.every(page =>
        verificaLayoutVazio(page.style.id)
          ? true
          : (page.frames == null || page.frames.length === 0) &&
          verificaTextoVazio(page)
      );
    }

    const p = [];
    let faltaTexto = false;
    let faltaImagem = false;

    const setFaltaTexto = () => (faltaTexto = true);
    const setFaltaImagem = () => (faltaImagem = true);

    if (prodType === 'invitation') {
      R.forEach(
        ({ index, label, frames, style: { shape } }) =>
          shape &&
          shape.length > frames.length &&
          p.push(label || index) &&
          setFaltaImagem(),
        [
          ...R.compose(
            R.values,
            R.map(i => i)
          )(covers),
          ...pages
        ]
      );
      if (!faltaImagem) {
        R.forEach(
          ({ index, label, frames, texts, style: { textarea, shape } }) =>
            textarea &&
            ((shape && shape.length > frames.length) ||
              textarea.filter(item => item.enabled == null || item.enabled)
                .length >
              R.reduce(
                (v, p) => (p.richTextState.length ? v + 1 : v),
                0,
                texts
              )) &&
            p.push(label || index) &&
            setFaltaTexto(),
          [
            ...R.compose(
              R.values,
              R.map(i => i)
            )(covers),
            ...pages
          ]
        );
      }
    } else {
      R.forEach(
        ({ index, label, frames, texts, style: { textarea, shape } }) =>
          textarea
            ? ((shape && shape.length > frames.length) ||
              textarea.filter(item => item.enabled == null || item.enabled)
                .length >
              R.reduce(
                (v, p) => (p.richTextState.length ? v + 1 : v),
                0,
                texts
              )) &&
            p.push(label || index) &&
            setFaltaTexto()
            : shape &&
            shape.length > frames.length &&
            p.push(label || index) &&
            setFaltaImagem(),
        [
          ...R.compose(
            R.values,
            R.map(i => i)
          )(covers),
          ...pages
        ]
      );
    }
    return {
      pagesNotCompleteFilled: p,
      faltaTexto,
      faltaImagem,
      tudoVazio
    };
  }
);

export const getPosterNotCompleteFilled = createSelector(
  getAllBookPagesData,
  ({ pages, covers }) => {
    const p = [];
    R.forEach(
      ({ index, label, frames, texts }) =>
        texts &&
        texts.find(t => t.richTextState.length > 0) == null &&
        frames.length === 0 &&
        p.push(label || index),
      [
        ...R.compose(
          R.values,
          R.map(i => i)
        )(covers),
        ...pages
      ]
    );
    return p;
  }
);

export const getIsDragging = createSelector(getUI, ui => ui.drag.isDragging);

export const getDragId = createSelector(getUI, ui => ui.drag.id);

export const getFrames = createSelector(getLibrary, library => library.frame);

export const getIsPopupOpened = createSelector(
  getUI,
  ui => ui.popup.id !== 'POPUP_ID_NONE' || ui.popup.overlay || ui.popup.display
);

export const bookHaveText = createSelector(getAllBookPagesData, ({ pages }) => {
  let aux = false;
  if (pages != null && pages.length > 0) {
    pages.forEach(({ texts }) => {
      if (
        texts != null &&
        texts.length > 0 &&
        texts.find(t => t.richTextState.length > 0) != null
      )
        aux = true;
    });
  }
  return aux;
});

export const findFirstFilledPage = createSelector(
  getAllBookPagesData,
  ({ pages }) => {
    let aux = false;
    if (pages != null && pages.length > 0)
      aux = pages.find(
        ({ id, frames, texts }) =>
          (texts != null &&
            texts.length > 0 &&
            texts.find(t => t.richTextState.length > 0) != null) ||
          frames.length > 0
      );
    return aux ? true : false;
  }
);

export const getPrintPage = createSelector(
  getItem,
  getProductType,
  (item, prodType) => {
    let printPageIndex = item.printPage;
    if (prodType === 'deck-cards') printPageIndex = item.isBack ? 2 : 1;
    return printPageIndex;
  }
);
