import * as actionGeneral from '../constant/action-type/general';

const initialState = {
  uploading: [],
  imagesToUpload: {},
  failToUpload: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionGeneral.SAGA_STORE_SERVER_IMAGE:
      return {
        ...state,
        imagesToUpload: {
          ...state.imagesToUpload,
          [action.payload.img.id]: action.payload.img
        }
      };
    case actionGeneral.SAGA_REMOVE_STORED_SERVER_IMAGE:
      delete state.imagesToUpload[action.payload.id];
      return {
        ...state,
        failToUpload: state.failToUpload.filter(id => id !== action.payload.id)
      };
    case actionGeneral.SAGA_ADD_SERVER_IMAGE_UPLOADING:
      state.uploading.push(action.payload.id);
      return state;
    case actionGeneral.SAGA_ADD_FAIL_UPLOAD_IMAGE:
      if (state.failToUpload.includes(action.payload.id)) return state;
      return {
        ...state,
        failToUpload: [...state.failToUpload, action.payload.id]
      };
    case actionGeneral.SAGA_REMOVE_SERVER_IMAGE_UPLOADING:
      return {
        ...state,
        uploading: state.uploading.filter(id => id !== action.payload.id)
      };
    default:
      return state;
  }
};

export default reducer;
