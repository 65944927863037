import './index.css';

import {
  THEME_CLASS_BT_EDITOR_CLOSE_SAVE,
  THEME_CLASS_BT_EDITOR_REMOVE,
  THEME_CLASS_BT_EDITOR_DUPLICATE,
  THEME_CLASS_BT_EDITOR_ZOOM_IN,
  THEME_CLASS_BT_EDITOR_ZOOM_OUT,
  // THEME_CLASS_BT_GIRAR_MAIS_5,
  // THEME_CLASS_BT_GIRAR_MENOS_5,
  THEME_CLASS_BT_GIRAR_90,
} from '../../constant/button';

import Button from '../button';
import PropTypes from 'prop-types';
import React from 'react';
import { photoEditorPropTypes } from '../../proptypes';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import { haveDuplicar } from '../../utils/separadorProdutos'

const PhotoEditor = ({
  onDelete,
  onRotate,
  onDuplicate,
  onZoomIn,
  onZoomOut,
  photoEditor,
  zoom,
  updatePhotoEditor,
  prodType,
  hasEmptyPage
}) => (
  <div id="photoEditor">
    <Button
      padding={false}
      theme={THEME_CLASS_BT_EDITOR_CLOSE_SAVE}
      clickHandler={() => updatePhotoEditor()}
    />
    <div className="editor-options-container">
      <KeyboardEventHandler
        handleKeys={["-", "=", "+", "g", "d", "enter", "delete", "backspace", "esc", "e"]}
        handleFocusableElements = {true}
        onKeyEvent={(key) => {
          if(photoEditor)
          {
            if(key === '=' || key === '+')
              onZoomIn(photoEditor.frameId);
            else if(key === '-')
              onZoomOut(photoEditor.frameId)
            else if(key === 'g')
              onRotate(photoEditor.frameId, 90);
            else if(key === 'enter' || key === 'esc' || key === 'e')
              updatePhotoEditor();
            else if(key === 'd' && haveDuplicar(prodType) && hasEmptyPage)
              onDuplicate(photoEditor.frameId);
            else if(key === 'delete' || key === 'backspace'){
              const removeButton = document.getElementById('button-remove-image');
              removeButton.click();
            }
          }
        }} 
      />
      <div className="editor-options">
        <Button
          padding={false}
          theme={THEME_CLASS_BT_EDITOR_ZOOM_OUT}
          clickHandler={() => onZoomOut(photoEditor.frameId)}
          style={zoom <= 1 ? { opacity: 0.25 } : {}}
          disabled={zoom <= 1}
        />
        <span style={zoom <= 1 ? { opacity: 0.25 } : {}}>Menos zoom</span>
      </div>
      <div className="editor-options">
        <Button
          padding={false}
          theme={THEME_CLASS_BT_EDITOR_ZOOM_IN}
          clickHandler={() => onZoomIn(photoEditor.frameId)}
          style={zoom >= 2 ? { opacity: 0.25 } : {}}
          disabled={zoom >= 2}
        />
        <span style={zoom >= 2 ? { opacity: 0.25 } : {}}>Mais zoom</span>
      </div>
      {/* {
        prodType === 'mask' && 
          <div className="editor-options">
            <Button
              padding={false}
              theme={THEME_CLASS_BT_GIRAR_MENOS_5}
              clickHandler={() => onRotate(photoEditor.frameId, -5)}
            />
            <span>Girar -5°</span>
          </div>
      } */}
      {/* {
        prodType === 'mask' && 
          <div className="editor-options">
            <Button
              padding={false}
              theme={THEME_CLASS_BT_GIRAR_MAIS_5}
              clickHandler={() => onRotate(photoEditor.frameId, 5)}
            />
            <span>Girar +5º</span>
          </div>
      } */}
      <div className="editor-options">
        <Button
          padding={false}
          theme={THEME_CLASS_BT_GIRAR_90}
          clickHandler={() => onRotate(photoEditor.frameId, 90)}
        />
        <span>Girar +90º</span>
      </div>
      {
        haveDuplicar(prodType) && 
        <div className="editor-options">
          <Button
            padding={false}
            theme={THEME_CLASS_BT_EDITOR_DUPLICATE}
            clickHandler={() => onDuplicate(photoEditor.frameId)}
            style={!hasEmptyPage ? { opacity: 0.25 } : {}}
            disabled={!hasEmptyPage}
          />
          <span>Duplicar</span>
        </div>
      }
      <div className="editor-options">
        <Button
          id="button-remove-image"
          padding={false}
          theme={THEME_CLASS_BT_EDITOR_REMOVE}
          clickHandler={() => onDelete(photoEditor.frameId)}
        />
        <span>Remover</span>
      </div>
    </div>
  </div>
);

PhotoEditor.propTypes = {
  onDelete: PropTypes.func.isRequired,
  onRotate: PropTypes.func.isRequired,
  onZoomIn: PropTypes.func.isRequired,
  onZoomOut: PropTypes.func.isRequired,
  photoEditor: photoEditorPropTypes,
  zoom: PropTypes.number.isRequired,
  updatePhotoEditor: PropTypes.func.isRequired,
  prodType: PropTypes.string.isRequired,
  hasEmptyPage: PropTypes.bool.isRequired
};

export default PhotoEditor;
