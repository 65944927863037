import * as actionGeneral from './action/general';
import * as actionItem from './action/item';
import * as actionUI from './action/ui';
import { isMobile } from 'react-device-detect';
import { getItemId, isFinished } from './selector';
import {
  POPUP_ID_MUTIPLE_TABS,
  POPUP_ID_NONE,
  POPUP_SCREEN_SIZE_ERROR
  // POPUP_ID_ABANDON_PROJECT
} from './constant/popup';

// import { ABANDON_PROJECT } from './constant/popup-message';
import React, { useEffect } from 'react';
import Product from './container/product';
import PropTypes from 'prop-types';
import store from './store';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { sizeErrorEnabled } from './utils/separadorProdutos';

export function warnClose(e) {
  const isProductFromMetadata = window.location.search.includes('?itemId=');

  if (isProductFromMetadata) {
    const state = store.getState();
    const itemId = getItemId(state);
    const finished = isFinished(state);
    if (itemId && !finished) {
      store.dispatch(actionGeneral.sagaPostMetadata(itemId));
    }
  } else {
    e.preventDefault();
    e.returnValue = '';
  }

  // return store.dispatch(actionUI.updatePopup(POPUP_ID_ABANDON_PROJECT, true, ABANDON_PROJECT()));
}

function App({
  productType,
  page,
  print,
  haveCover,
  thumb,
  productID,
  itemID,
  inactive,
  pages,
  back
}) {
  if (productType === 'coupon') {
    store.dispatch(actionItem.updateIsCoupon(true));
  }

  store.dispatch(actionItem.updateIsPrint(!!print || !!thumb));
  store.dispatch(actionItem.updateIsThumb(!!thumb));
  store.dispatch(actionItem.updateIsBack(!!back));

  if (page || thumb) {
    const pageIndex =
      page === 'cover' || thumb
        ? 1
        : page === 'back_cover'
          ? 0
          : parseInt(page) + (haveCover ? 2 : 0);
    const printPage = !parseInt(page) ? page : parseInt(page);
    const thumbPage = haveCover ? 'cover' : 1;
    store.dispatch(actionItem.setPageIndex(pageIndex));
    store.dispatch(actionItem.setPrintPage(thumb ? thumbPage : printPage));
  }

  const resizeHandler = () => {
    const { innerWidth, innerHeight } = window;
    const {
      ui: { popup }
    } = store.getState();

    if (sizeErrorEnabled(popup.id) || process.env.NODE_ENV !== 'development') {
      const screenSizeError =
        isMobile || innerWidth <= 771 || innerHeight <= 532;
      store.dispatch(actionUI.updateScreenSizeError(screenSizeError));
      if (screenSizeError && !print && !thumb)
        store.dispatch(actionUI.updatePopup(POPUP_SCREEN_SIZE_ERROR, true, ''));
      else if (popup.id === POPUP_SCREEN_SIZE_ERROR)
        store.dispatch(actionUI.updatePopup(POPUP_ID_NONE, false, ''));
    }

    const headerEl = document.getElementById('product-header');
    const filterLayouts = document.querySelector('.filter-layouts');

    const width = innerWidth - 17;
    const height = innerHeight;

    const headerHeight = headerEl ? headerEl.clientHeight : 64;
    const toolbarPhotoHeight = 97;
    const menuBookHeight = 56 + height * 0.016;

    const photobookHeight =
      height -
      headerHeight -
      toolbarPhotoHeight -
      menuBookHeight -
      height * 0.03 -
      1;

    const layoutsContainerWidth = filterLayouts
      ? width - width * 0.058 - filterLayouts.clientWidth
      : width - width * 0.1 - 223;

    store.dispatch(
      actionUI.updateScreenSize(
        width,
        height,
        headerHeight,
        photobookHeight,
        layoutsContainerWidth
      )
    );
  };

  const getIsFirefox = () => {
    try {
      return (
        typeof InstallTrigger !== 'undefined' &&
        'MozAppearance' in document.documentElement.style
      );
    } catch {
      return false;
    }
  };

  useEffect(() => {
    window.addEventListener('beforeunload', warnClose);

    if (inactive && process.env.NODE_ENV !== 'development') {
      store.dispatch(
        actionUI.updatePopup(POPUP_ID_MUTIPLE_TABS, true, {
          title: 'A Mesa já está aberta em outra aba/guia',
          content: [
            'A Mesa já está aberta em outra aba/guia, nesse mesmo navegador. Feche esta aba/guia atual.',
            'Logo após, identifique a guia com a Mesa aberta e atualize a página. Ou, feche todas as abas/guias e acesse o link novamente.'
          ]
        })
      );
    } else {
      window.onresize = () => resizeHandler();
      resizeHandler();
      store.dispatch(
        actionGeneral.sagaFetchData(
          productID || itemID,
          pages,
          productID ? true : false,
          getIsFirefox()
        )
      );
    }
  }, []);

  return (
    <DndProvider backend={HTML5Backend}>
      <Product />
    </DndProvider>
  );
}

App.propTypes = {
  itemID: PropTypes.string,
  productID: PropTypes.string,
  productType: PropTypes.string.isRequired
};

export default App;
