import './index.css';

import { NEXT, PREVIOUS } from '../../constant/thumb';
import React, { Component } from 'react';
import {
  THEME_CLASS_BT_BOTTOM_ARROW_WHITE,
  THEME_CLASS_BT_EDITOR_CLOSE,
  THEME_CLASS_BT_LEFT_ARROW_WHITE,
  THEME_CLASS_BT_LEFT_ARROW_WHITE_EXPAND,
  THEME_CLASS_BT_LEFT_DOUBLE_ARROW_WHITE,
  THEME_CLASS_BT_RIGHT_ARROW_WHITE,
  THEME_CLASS_BT_RIGHT_ARROW_WHITE_EXPAND,
  THEME_CLASS_BT_RIGHT_DOUBLE_ARROW_WHITE,
  THEME_CLASS_BT_TOP_ARROW_WHITE,
  THEME_CLASS_MENU_PHOTO,
  THEME_CLASS_MENU_PHOTO_PRESSED
} from '../../constant/button';
import {
  screenSizePropTypes,
  sourceImagePropTypes,
  thumbListPropTypes
} from '../../proptypes';

import Button from '../button';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import Thumb from '../../container/thumb';
import { getVoltarOverviewImagem } from '../../utils/textosVariaveis'

//Format 01 - 09
const formatNumber = number => (number < 10 ? `0${number}` : `${number}`);

const thumbIndex = (index, range, thumbsLength, side, maxThumbs) => {
  if (side === 'LEFT' && index === range.start) {
    if (thumbsLength < maxThumbs * 2) {
      return `${formatNumber(index + 1)} de ${formatNumber(thumbsLength)}`;
    } else {
      return `${formatNumber(index + 1)} de ${formatNumber(
        maxThumbs * 2 + range.start
      )}`;
    }
  } else if (side === 'RIGHT' && index === range.end) {
    return `${formatNumber(index + 1)} de ${formatNumber(thumbsLength)}`;
  } else {
    return formatNumber(index + 1);
  }
};

const ThumbList = ({
  thumbs,
  isManualSort,
  updateIncMoveIndex,
  expanded,
  deleteThumb,
  getUsedImage,
  toggleThumbExpand,
  updateThumbExpandIDB,
  thumbExpand,
  range,
  side,
  thumbsLength,
  maxThumbs,
  isLeft,
  prodType,
}) => (
  <div
    className="thumb-list-container"
    style={expanded && isLeft ? { display: 'none' } : null}
  >
    <ul className={`thumb-list ${expanded ? 'expanded' : ''}`}>
      {thumbs.map((thumb, index) =>
        index >= range.start && index <= range.end ? (
          <li
            key={thumb.id}
            className="thumb-li"
            style={
              thumbExpand.id === thumb.image ? { zIndex: 6 } : { zIndex: 0 }
            }
          >
            <Thumb
              source={thumb.blobUrl}
              imgWidth={thumb.width}
              imgHeight={thumb.height}
              isPair={index % 2 === 0}
              isManualSort={isManualSort}
              thumbId={thumb.id}
              imageId={thumb.image}
              updateIncMoveIndex={updateIncMoveIndex}
              deleteThumb={deleteThumb}
              usedImage={
                getUsedImage.find(image => thumb.image === image.id).used
              }
              stackThumb={
                isLeft
                  ? range.start > 0 && index === range.start
                    ? thumbs[index - 1]
                    : null
                  : range.end < thumbsLength && index === range.end
                  ? thumbs[index + 1]
                  : null
              }
              toggleThumbExpand={toggleThumbExpand}
              updateThumbExpandIDB={updateThumbExpandIDB}
              thumbExpand={thumbExpand}
              prodType={prodType}
            />
            <span>
              {thumbIndex(index, range, thumbsLength, side, maxThumbs)}
            </span>
          </li>
        ) : null
      )}
    </ul>
  </div>
);

ThumbList.propTypes = {
  thumbs: PropTypes.arrayOf(sourceImagePropTypes).isRequired,
  isManualSort: PropTypes.bool.isRequired,
  updateIncMoveIndex: PropTypes.func.isRequired,
  expanded: PropTypes.bool.isRequired,
  deleteThumb: PropTypes.func.isRequired,
  getUsedImage: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      used: PropTypes.number.isRequired
    })
  ).isRequired,
  toggleThumbExpand: PropTypes.func.isRequired,
  updateThumbExpandIDB: PropTypes.func.isRequired,
  thumbExpand: PropTypes.shape({
    active: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired
  }).isRequired,
  range: thumbListPropTypes,
  side: PropTypes.string.isRequired,
  thumbsLength: PropTypes.number.isRequired,
  maxThumbs: PropTypes.number.isRequired,
  isLeft: PropTypes.bool,
  prodType: PropTypes.string.isRequired
};

ThumbList.defaultProps = {
  isManualSort: true,
  expanded: false
};

const BtContainer = ({
  clickHandler,
  theme,
  margin,
  direction,
  disabled,
  dataTip
}) => (
  <div
    className="bt-container"
    data-tip={disabled ? '' : dataTip}
    style={
      direction === 'LEFT'
        ? { marginTop: '3px', marginLeft: `${margin - 6}px` }
        : direction === 'RIGHT'
        ? { marginTop: '3px', marginRight: `${margin - 6}px` }
        : direction === 'BOTTOM'
        ? { marginTop: '-4px' }
        : null
    }
  >
    <Button
      padding={false}
      theme={theme}
      clickHandler={clickHandler}
      style={disabled ? { opacity: 0.25 } : {}}
      disabled={disabled}
    />
  </div>
);

const BtPhotoContainer = ({ toogleMenuPhoto, isMenuPhotoSelected, margin }) => (
  <div
    data-tip="Menu Imagens: Funções relacionadas às imagens"
    style={{ margin: `0 ${margin - 6}px 0 ${margin - 6}px` }}
  >
    <Button
      padding={false}
      theme={
        isMenuPhotoSelected
          ? THEME_CLASS_MENU_PHOTO_PRESSED
          : THEME_CLASS_MENU_PHOTO
      }
      clickHandler={function() {
        toogleMenuPhoto(isMenuPhotoSelected);
      }}
    />
  </div>
);

class ToolbarPhoto extends Component {
  PrepareThumbList = (isGalleryExpanded, mountResize) => {
    // Elements
    const content = document.querySelector('.content');
    const controls = document.querySelector('.controls');
    const listContainer = document.querySelectorAll('.thumb-list-container');
    const thumbs = document.querySelectorAll('.thumb-li');

    const { updateToolbarPhoto, toolbarPhoto } = this.props;

    // Properties
    const listContainerWidth = content
      ? isGalleryExpanded
        ? content.offsetWidth
        : (content.offsetWidth - controls.offsetWidth) / 2
      : 0;
    const thumbMinMargin = listContainerWidth * 0.011;
    const maxThumbs = parseInt(
      (listContainerWidth - thumbMinMargin) / (96 + thumbMinMargin)
    );
    const marginRightThumb =
      (listContainerWidth - maxThumbs * 96) / (maxThumbs - 1);

    // Set list containers width
    listContainer.forEach(
      container => (container.style.width = `${listContainerWidth}px`)
    );

    // Set thumbs margins
    let i = 0;
    isGalleryExpanded
      ? thumbs.forEach((thumb, index) => {
          if (index >= thumbs.length - toolbarPhoto.thumbsLength - 1) {
            if (i % maxThumbs === 0) {
              thumb.style.margin = `0`;
            } else {
              thumb.style.margin = `0 ${marginRightThumb}px 0 0`;
            }
            i++;
          }
        })
      : thumbs.forEach((thumb, index) => {
          if (index !== maxThumbs - 1 && index !== maxThumbs * 2 - 1) {
            thumb.style.margin = `0 ${marginRightThumb}px 0 0`;
          } else {
            thumb.style.margin = `0`;
          }
        });

    if (mountResize) {
      updateToolbarPhoto(
        maxThumbs,
        toolbarPhoto.thumbsLength,
        toolbarPhoto.prevThumbLenth,
        {
          start: toolbarPhoto.thumbListLeft.start,
          end: toolbarPhoto.thumbListLeft.start + maxThumbs - 1
        },
        {
          start: toolbarPhoto.thumbListLeft.start + maxThumbs,
          end: toolbarPhoto.thumbListLeft.start + maxThumbs * 2 - 1
        },

        toolbarPhoto.thumbsListExpanded
      );
    }
  };

  navigationThumbList = (side, lastPage, isClick) => {
    const { updateToolbarPhoto, toolbarPhoto } = this.props;
    const {
      maxThumbs,
      thumbsLength,
      prevThumbLenth,
      thumbListLeft,
      thumbListRight,
      thumbsListExpanded
    } = toolbarPhoto;

    const isThumbScroll = maxThumbs * 2 >= thumbsLength;

    if (side === 'LEFT') {
      if (thumbListLeft.start !== 0) {
        lastPage
          ? updateToolbarPhoto(
              maxThumbs,
              thumbsLength,
              prevThumbLenth,
              {
                start: 0,
                end: maxThumbs - 1
              },
              {
                start: maxThumbs,
                end: maxThumbs * 2 - 1
              },
              thumbsListExpanded
            )
          : updateToolbarPhoto(
              maxThumbs,
              thumbsLength,
              prevThumbLenth,
              {
                start: thumbListLeft.start - 1,
                end: thumbListLeft.end - 1
              },
              {
                start: thumbListRight.start - 1,
                end: thumbListRight.end - 1
              },
              thumbsListExpanded
            );
      } else {
        !isThumbScroll && isClick && this.navigationThumbList('RIGHT', true);
      }
    } else {
      if (
        thumbListRight.end !== thumbsLength - 1 &&
        thumbsLength > maxThumbs * 2 - 1
      ) {
        lastPage
          ? updateToolbarPhoto(
              maxThumbs,
              thumbsLength,
              prevThumbLenth,
              {
                start: thumbsLength - maxThumbs * 2,
                end: thumbsLength - maxThumbs - 1
              },
              {
                start: thumbsLength - maxThumbs,
                end: thumbsLength - 1
              },
              thumbsListExpanded
            )
          : updateToolbarPhoto(
              maxThumbs,
              thumbsLength,
              prevThumbLenth,
              {
                start: thumbListLeft.start + 1,
                end: thumbListLeft.end + 1
              },
              {
                start: thumbListRight.start + 1,
                end: thumbListRight.end + 1
              },
              thumbsListExpanded
            );
      } else {
        !isThumbScroll && isClick && this.navigationThumbList('LEFT', true);
      }
    }
  };

  updateThumbLength = (prevPropsLength) => {
    const { updateToolbarPhoto, toolbarPhoto, thumbs } = this.props;
    let {
      maxThumbs,
      prevThumbLenth,
      thumbListLeft,
      thumbListRight
    } = toolbarPhoto;

    if((prevPropsLength - thumbs.length) > 1){
      thumbListLeft = {
          start: 0,
          end: maxThumbs - 1
      };

      thumbListRight= {
        start: maxThumbs,
        end: maxThumbs * 2 - 1
      };
    }

    updateToolbarPhoto(
      maxThumbs,
      thumbs.length,
      prevThumbLenth,
      thumbListLeft,
      thumbListRight,
      {
        start: 0,
        end: thumbs.length
      }
    );
  };

  preventWrongScroll = () => {
    const { toolbarPhoto } = this.props;
    const { maxThumbs, thumbListRight, thumbsLength } = toolbarPhoto;

    if (
      thumbListRight.end >= thumbsLength &&
      thumbsLength > maxThumbs * 2 - 1
    ) {
      this.navigationThumbList('RIGHT', true);
    }
  };

  updateThumbList = (id, thumbs, side) => {
    let thumbIndex;
    const { toolbarPhoto } = this.props;
    const { thumbListLeft, thumbListRight } = toolbarPhoto;

    // eslint-disable-next-line
    thumbs.map((thumb, index) => {
      if (thumb.image === id) {
        thumbIndex = index;
      }
    });

    switch (side) {
      case 'LEFT':
        if (thumbIndex - 1 < thumbListLeft.start) {
          this.navigationThumbList(side);
        }
        if (thumbIndex === 0) {
          this.navigationThumbList('RIGHT', true);
        }
        break;
      case 'RIGHT':
        if (thumbIndex + 1 > thumbListRight.end) {
          this.navigationThumbList(side);
        }
        if (thumbIndex + 1 === thumbs.length) {
          this.navigationThumbList('LEFT', true);
        }
        break;
      default:
        break;
    }
  };

  controlThumbExpand = ({ keyCode }) => {
    const {
      toggleThumbExpand,
      updateThumbExpand,
      updateThumbExpandIDB,
      thumbExpand,
      thumbs
    } = this.props;
    switch (keyCode) {
      case 27:
        toggleThumbExpand();
        updateThumbExpandIDB();
        break;
      case 37:
        updateThumbExpand(PREVIOUS);
        this.updateThumbList(thumbExpand.id, thumbs, 'LEFT');
        break;
      case 39:
        updateThumbExpand(NEXT);
        this.updateThumbList(thumbExpand.id, thumbs, 'RIGHT');
        break;
      default:
        break;
    }
  };

  componentDidUpdate(prevProps) {
    const {
      updateToolbarPhoto,
      toolbarPhoto,
      thumbExpand,
      toggleThumbExpand,
      thumbs,
      isGalleryExpanded
    } = this.props;
    const {
      maxThumbs,
      thumbsLength,
      prevThumbLenth,
      thumbListLeft,
      thumbListRight,
      thumbsListExpanded
    } = toolbarPhoto;

    if (
      prevProps.thumbExpand.active !== 'CLICK' &&
      thumbExpand.active === 'CLICK'
    ) {
      window.addEventListener('keydown', this.controlThumbExpand);
    }

    if (
      prevProps.thumbExpand.active === 'CLICK' &&
      thumbExpand.active !== 'CLICK'
    ) {
      window.removeEventListener('keydown', this.controlThumbExpand);
    }

    if (prevProps.thumbs.length !== thumbs.length) {
      updateToolbarPhoto(
        maxThumbs,
        thumbsLength,
        prevProps.thumbs.length,
        thumbListLeft,
        thumbListRight,
        thumbsListExpanded
      );

      this.updateThumbLength(prevProps.thumbs.length);
      
      if (thumbExpand.active === 'HOVER' && !isGalleryExpanded) {
        toggleThumbExpand();
      }
    }

    if (prevThumbLenth < thumbsLength) {
      updateToolbarPhoto(
        maxThumbs,
        thumbsLength,
        thumbsLength,
        thumbListLeft,
        thumbListRight,
        thumbsListExpanded
      );
      this.navigationThumbList('RIGHT', true);
    }

    this.PrepareThumbList(isGalleryExpanded);
    this.preventWrongScroll();
  }

  componentDidMount() {
    const { isGalleryExpanded } = this.props;
    this.PrepareThumbList(isGalleryExpanded, true);
  }

  render() {
    const {
      isMenuPhotoSelected,
      isBookOverviewExpanded,
      isPhotoEditorActive,
      toogleMenuPhoto,
      thumbs,
      toggleGalleryExpanded,
      isManualSort,
      isGalleryExpanded,
      updateIncMoveIndex,
      deleteThumb,
      getUsedImage,
      toolbarPhoto,
      toolbarPhotoSize,
      toggleThumbExpand,
      updateThumbExpandIDB,
      thumbExpand,
      thumbExpandImage,
      updateThumbExpand,
      screenSize,
      prodType
    } = this.props;
    const {
      maxThumbs,
      thumbListLeft,
      thumbListRight,
      thumbsListExpanded,
      thumbsLength
    } = toolbarPhoto;
    const isThumbScroll = maxThumbs * 2 >= thumbsLength;

    return (
      <div
        className="toolbar-photo"
        style={
          isGalleryExpanded
            ? { display: 'flex', flexGrow: '1' }
            : isBookOverviewExpanded || isPhotoEditorActive
            ? { visibility: 'hidden' }
            : null
        }
      >
        <div
          className={isGalleryExpanded ? `content content-expanded` : `content`}
        >
          <ReactTooltip />
          <ThumbList
            thumbs={thumbs}
            isManualSort={isManualSort}
            updateIncMoveIndex={updateIncMoveIndex}
            expanded={isGalleryExpanded}
            deleteThumb={deleteThumb}
            getUsedImage={getUsedImage}
            updateThumbExpandIDB={updateThumbExpandIDB}
            toggleThumbExpand={toggleThumbExpand}
            thumbExpand={thumbExpand}
            range={thumbListLeft}
            side={'LEFT'}
            thumbsLength={thumbsLength}
            maxThumbs={maxThumbs}
            isLeft={true}
            prodType={prodType}
          />
          <div className="controls menu-container-outside">
            <div className="menu-container">
              <BtContainer
                clickHandler={() => this.navigationThumbList('LEFT', true)}
                theme={THEME_CLASS_BT_LEFT_DOUBLE_ARROW_WHITE}
                margin={Math.round(screenSize.width * 0.015)}
                direction={'LEFT'}
                dataTip={'Primeira Imagem'}
                disabled={isThumbScroll || thumbListLeft.start === 0}
              />
              <BtContainer
                clickHandler={() =>
                  this.navigationThumbList('LEFT', false, true)
                }
                theme={THEME_CLASS_BT_LEFT_ARROW_WHITE}
                margin={Math.round(screenSize.width * 0.006)}
                direction={'LEFT'}
                dataTip={'Imagem Anterior'}
                disabled={isThumbScroll}
              />
              <BtPhotoContainer
                toogleMenuPhoto={toogleMenuPhoto}
                isMenuPhotoSelected={isMenuPhotoSelected}
                margin={Math.round(screenSize.width * 0.006)}
              />
              <BtContainer
                clickHandler={() =>
                  this.navigationThumbList('RIGHT', false, true)
                }
                theme={THEME_CLASS_BT_RIGHT_ARROW_WHITE}
                margin={Math.round(screenSize.width * 0.006)}
                direction={'RIGHT'}
                dataTip={'Próxima Imagem'}
                disabled={isThumbScroll}
              />
              <BtContainer
                clickHandler={() => this.navigationThumbList('RIGHT', true)}
                theme={THEME_CLASS_BT_RIGHT_DOUBLE_ARROW_WHITE}
                margin={Math.round(screenSize.width * 0.015)}
                direction={'RIGHT'}
                dataTip={'Última Imagem'}
                disabled={
                  isThumbScroll || thumbListRight.end === thumbs.length - 1
                }
              />
            </div>
            <BtContainer
              theme={
                isGalleryExpanded
                  ? THEME_CLASS_BT_TOP_ARROW_WHITE
                  : THEME_CLASS_BT_BOTTOM_ARROW_WHITE
              }
              dataTip={
                isGalleryExpanded
                  ? getVoltarOverviewImagem()
                  : 'Espalha as imagens na Mesa inteira'
              }
              clickHandler={() => {
                toggleGalleryExpanded(isGalleryExpanded);
              }}
              direction={'BOTTOM'}
              disabled={isThumbScroll && !isGalleryExpanded}
            />
          </div>
          <ThumbList
            thumbs={thumbs}
            isManualSort={isManualSort}
            updateIncMoveIndex={updateIncMoveIndex}
            expanded={isGalleryExpanded}
            deleteThumb={deleteThumb}
            getUsedImage={getUsedImage}
            toggleThumbExpand={toggleThumbExpand}
            updateThumbExpandIDB={updateThumbExpandIDB}
            thumbExpand={thumbExpand}
            range={isGalleryExpanded ? thumbsListExpanded : thumbListRight}
            side={'RIGHT'}
            thumbsLength={thumbsLength}
            maxThumbs={maxThumbs}
            prodType={prodType}
          />
          <div
            className="thumb-expand"
            style={
              thumbExpand.active === 'HOVER' || thumbExpand.active === 'CLICK'
                ? {
                    display: 'block',
                    zIndex:
                      thumbExpand.active === 'CLICK' && isGalleryExpanded
                        ? 6
                        : 5
                  }
                : { display: 'none' }
            }
          >
            {thumbExpand.active === 'CLICK' && (
              <div>
                <div className="btn-close">
                  <Button
                    padding={false}
                    theme={THEME_CLASS_BT_EDITOR_CLOSE}
                    clickHandler={() => {
                      toggleThumbExpand();
                      updateThumbExpandIDB();
                    }}
                  />
                </div>
                <div className="btn-navigation">
                  <Button
                    padding={false}
                    theme={THEME_CLASS_BT_LEFT_ARROW_WHITE_EXPAND}
                    clickHandler={() => {
                      updateThumbExpand(PREVIOUS);
                      this.updateThumbList(thumbExpand.id, thumbs, 'LEFT');
                    }}
                  />
                  <Button
                    padding={false}
                    theme={THEME_CLASS_BT_RIGHT_ARROW_WHITE_EXPAND}
                    clickHandler={() => {
                      updateThumbExpand(NEXT);
                      this.updateThumbList(thumbExpand.id, thumbs, 'RIGHT');
                    }}
                  />
                </div>
              </div>
            )}
            <div
              className="thumb-expand-container"
              style={{
                height: `${screenSize.photobookHeight}px`,
                top: `${toolbarPhotoSize.thumbExpandContainerTop}px`,
                backgroundImage: `url(${thumbExpandImage})`
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

ToolbarPhoto.propTypes = {
  isMenuPhotoSelected: PropTypes.bool.isRequired,
  isBookOverviewExpanded: PropTypes.bool.isRequired,
  isPhotoEditorActive: PropTypes.bool.isRequired,
  toogleMenuPhoto: PropTypes.func.isRequired,
  thumbs: PropTypes.arrayOf(sourceImagePropTypes).isRequired,
  toggleGalleryExpanded: PropTypes.func.isRequired,
  isManualSort: PropTypes.bool.isRequired,
  isGalleryExpanded: PropTypes.bool.isRequired,
  updateIncMoveIndex: PropTypes.func.isRequired,
  deleteThumb: PropTypes.func.isRequired,
  getUsedImage: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      used: PropTypes.number.isRequired
    })
  ).isRequired,
  toolbarPhoto: PropTypes.shape({
    maxThumbs: PropTypes.number.isRequired,
    prevThumbLenth: PropTypes.number.isRequired,
    thumbsLength: PropTypes.number.isRequired,
    thumbListLeft: thumbListPropTypes,
    thumbListRight: thumbListPropTypes
  }).isRequired,
  toolbarPhotoSize: PropTypes.shape({
    arrowContainerMargin: PropTypes.number.isRequired,
    thumbExpandContainerTop: PropTypes.number.isRequired,
    thumbExpandContainerHeight: PropTypes.number.isRequired,
    thumbListMargin: PropTypes.number.isRequired,
    thumbListWidth: PropTypes.number.isRequired
  }).isRequired,
  toggleThumbExpand: PropTypes.func.isRequired,
  updateThumbExpandIDB: PropTypes.func.isRequired,
  thumbExpand: PropTypes.shape({
    active: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired
  }).isRequired,
  thumbExpandImage: PropTypes.string.isRequired,
  updateThumbExpand: PropTypes.func.isRequired,
  screenSize: screenSizePropTypes,
  prodType: PropTypes.string.isRequired
};

ToolbarPhoto.defaultProps = {
  isMenuPhotoSelected: false,
  isManualSort: true,
  isGalleryExpanded: false
};

export default ToolbarPhoto;
