//Calendar A5 P
    //Desce 50px para um height de 965 -> 50/965
    export const FATOR_AJUSTE_Y_MOCKUP_CALENDARIO_P = 50/965.0;

    // A espiral ocupa 50px para um height de 965 -> (aplicando regra de 3) 1-(50/965)
    export const FATOR_DIMINUICAO_HEIGHT_MOCKUP_CALENDARIO_P = 1-FATOR_AJUSTE_Y_MOCKUP_CALENDARIO_P;

//Calendar A3 P
    //Desce 150px
    export const FATOR_AJUSTE_Y_MOCKUP_CALENDARIO_A3_P = 150;
    // A espiral ocupa 150px para um height de 3002 -> (3002-150)/3002
    export const FATOR_DIMINUICAO_HEIGHT_MOCKUP_CALENDARIO_A3_P = 2852/3002;

//BOOK A4 P 
export const FATOR_AJUSTE_WIDTH_LINHA_MIOLO = 0.0025;
export const AJUSTE_TOP_LINHA_MIOLO = 42.8;
export const AJUSTE_HEIGHT_LINHA_MIOLO = 0.9959540942;

//BOOK A4 P 
export const FATOR_AJUSTE_WIDTH_LINHA_MIOLO_A4R = 0.0015;

//Mask 69
export const FATOR_AJUSTE_WIDTH_MODO_IMPRESSAO = 1822/1821;
