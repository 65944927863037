import './index.css';

import PropTypes from 'prop-types';
import React from 'react';
import { screenSizePropTypes } from '../../proptypes';

const Select = ({
  options,
  name,
  label,
  cover,
  checkedId,
  changeHandler,
  selectedIcon,
  screenSize,
  layoutsFilter,
  type,
  menu,
  togleMenu
}) => (
  <div
    className={layoutsFilter ? 'select layouts' : 'select images'}
    style={
      !layoutsFilter ? { marginRight: `${screenSize.width * 0.019}px` } : null
    }
  >
    <div
      className={layoutsFilter ? `cover menu-layouts` : `cover menu-images`}
      onClick={() =>
        options.length > 1 && layoutsFilter
          ? togleMenu(type.slice(8).toUpperCase())
          : null
      }
    >
      <div className={cover} />
      <span className={layoutsFilter ? `layouts-cover` : null}>{label}</span>
      {layoutsFilter ? (
        <button
          type="button"
          className="decrease"
          style={{
            margin: `0 ${screenSize.width * 0.006}px`,
            opacity: options.length > 1 ? 1 : 0.25
          }}
          disabled={options.length <= 1}
        />
      ) : null}
    </div>
    <div
      className={`${type} ${menu ? 'menu' : null} option-container ${
        layoutsFilter ? `option-layouts` : `option-images`
      }`}
    >
      {options.map(option => (
        <div key={option.id} className="option">
          <input
            type="radio"
            name={name}
            value={option.id}
            id={option.id}
            checked={checkedId === option.id}
            onChange={function() {
              changeHandler(option.id);
              if (layoutsFilter) {
                togleMenu(type.slice(8).toUpperCase());
              }
            }}
          />
          <label htmlFor={option.id}>
            <div
              className={`${
                checkedId === option.id ? `${option.img}-checked` : option.img
              }`}
            />
            <span className={checkedId === option.id ? 'label-checked' : null}>
              {layoutsFilter ? option.id : option.label}
            </span>
            {checkedId === option.id && (
              <div className={`checked ${selectedIcon}`} />
            )}
          </label>
        </div>
      ))}
    </div>
  </div>
);

Select.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired
  ).isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  cover: PropTypes.string,
  checkedId: PropTypes.string.isRequired,
  changeHandler: PropTypes.func.isRequired,
  selectedIcon: PropTypes.string.isRequired,
  screenSize: screenSizePropTypes,
  layoutsFilter: PropTypes.bool,
  type: PropTypes.string.isRequired,
  menu: PropTypes.bool,
  togleMenu: PropTypes.func
};

export default Select;
