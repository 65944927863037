import './index.css';

import LoadSpinner from '../../asset/load-spinner.svg';
import Logo from '../../asset/logo.svg';
import React from 'react';

const Loader = ({ min }) => (
  <div className={`loader${min ? ' loader-min' : ''}`}>
    <div className="container">
      <div className="spinner">
        <img src={LoadSpinner} alt="carregando" className="load-spinner" />
        <img src={Logo} alt="logo" className="logo" />
      </div>
    </div>
  </div>
);

export default Loader;
