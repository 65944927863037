import { POPUP_ID_NONE } from '../constant/popup';
import { createSelector } from 'reselect';
import { getUI } from './';

const getPopup = createSelector(getUI, ui => ui.popup);

export const getPopupId = createSelector(getPopup, popup => popup.id);

export const getPopupDisplay = createSelector(
  getPopupId,
  id => id !== POPUP_ID_NONE
);

export const getPopupOverlay = createSelector(getPopup, popup => popup.overlay);

export const getPopupMsg = createSelector(getPopup, popup => popup.msg);
