import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef } from 'react';
import { 
  calculoYSombraLateral,
  calculoYSombraInferior,
  calculoHeightSombraLateral,
  calculoWidthSombra,
  calculoWidthSombraInferior,
  // calculoHeightSombraInferior,
  calculoXSombraInferior
}
from '../../utils/calculosSombra';

import * as s from '../../constant/sombra';
import {
  LISTA_PRODUTOS_PERMITIDOS_TIPO1,
  LISTA_PRODUTOS_PERMITIDOS_TIPO2,
  buscaAssets
} from '../../utils/buscaPermissoes';

import { windowSizePropTypes } from '../../proptypes';

function SombraLateral(
  {
    isBookOverviewExpanded,
    isGalleryExpanded,
    isPhotoEditorActive,
    prodCode,
    prodType,
    prodFormat,
    isCover,
    currentNav,
    windowSize,
  }) {

  const [prevMockpageCoordenates, setPrevMockpageCoordenates] = useState(null);
  const [prevIsGalleryExpanded, setPrevIsGalleryExpanded] = useState(false);

  const mounted = useRef();

  useEffect(() => {
    const mockpageElement = document.getElementById('product-page');
    if (!mounted.current && !isBookOverviewExpanded && !isGalleryExpanded && !isPhotoEditorActive && 
          (mockpageElement != null && prevMockpageCoordenates !== mockpageElement.getBoundingClientRect()))
    {
      setPrevMockpageCoordenates(mockpageElement.getBoundingClientRect());
      mounted.current = true;
    } else{
      if(mockpageElement != null && prevMockpageCoordenates !== mockpageElement.getBoundingClientRect())
        setPrevMockpageCoordenates(mockpageElement.getBoundingClientRect());
      setPrevIsGalleryExpanded(isGalleryExpanded);
    }
  },[isGalleryExpanded, isBookOverviewExpanded, isPhotoEditorActive, isCover, currentNav, windowSize]);
    
  const mockpageElement = document.getElementById('product-page');
  const caminhoAsset = buscaAssets(prodCode, prodType, prodFormat);

  if(prodType === 'calendar' && (prodFormat ==='P' || prodFormat === 'A3-P')  && mockpageElement != null && !isBookOverviewExpanded && !isGalleryExpanded && !isPhotoEditorActive)
  {
    const prodFormatSrc = prodFormat === 'A3-P' ? 'A3_P':prodFormat;
    const pageCoordenates = mockpageElement.getBoundingClientRect();
    const scrollY = prevIsGalleryExpanded ? s.FATOR_AJUSTE_Y_SOMBRA_GALLERY_EXPANDED_CALENDAR : window.scrollY;
    return(
      <div
        style={{
            top: calculoYSombraLateral(pageCoordenates.top+scrollY, pageCoordenates.height, prodCode, prodType, prodFormat, isCover),
            height: calculoHeightSombraLateral(pageCoordenates.height, prodCode, prodType, prodFormat, isCover),
            overflow: 'hidden',
            position: 'absolute',
            zIndez:-2,
        }}>
        <img
          alt=""
          width={calculoWidthSombra(pageCoordenates.width, prodCode, prodType, prodFormat, isCover, null)}
          height='auto'
          src={require(`../../asset/sombra/${prodType}/${prodFormatSrc}/sombra-lateral.svg`)}
        />
      </div>
    );
  }
  else if((LISTA_PRODUTOS_PERMITIDOS_TIPO1.includes(prodCode) || LISTA_PRODUTOS_PERMITIDOS_TIPO2.includes(prodCode)) && 
          mockpageElement != null && !isBookOverviewExpanded && !isGalleryExpanded && !isPhotoEditorActive)
  {
    const pageCoordenates = mockpageElement.getBoundingClientRect();
    const scrollY = prevIsGalleryExpanded ? s.FATOR_AJUSTE_Y_SOMBRA_GALLERY_EXPANDED_A4 : window.scrollY;
    const mockpageCoverElement = document.getElementById('mock-page-id');

    let top = pageCoordenates.top;
    let height = pageCoordenates.height;
    let width = pageCoordenates.width;
    if(isCover && mockpageCoverElement)
    {
      const pageCoverCoordenates = mockpageCoverElement.getBoundingClientRect();
      top = pageCoverCoordenates.top;
      height = pageCoverCoordenates.height;
      width = pageCoverCoordenates.width;
    }
    return(
      <div
        style={{
            top: calculoYSombraLateral(top+scrollY, height, prodCode, prodType, prodFormat, isCover),
            height: calculoHeightSombraLateral(height, prodCode, prodType, prodFormat, isCover),
            overflow: 'hidden',
            position: 'absolute',
            zIndez:-2,
        }}>
        <img
          alt=""
          width={calculoWidthSombra(width, prodCode, prodType, prodFormat, isCover)}
          height='auto'
          src={require(`../../asset/sombra/${caminhoAsset}/sombra-lateral-livro-todo.svg`)}
        />
      </div>
    );
  }
   else if(prodType === 'deck-cards' && mockpageElement != null && !isBookOverviewExpanded && !isGalleryExpanded && !isPhotoEditorActive)
  {
    const pageCoordenates = mockpageElement.getBoundingClientRect();
    let width = pageCoordenates.width;
    const top = pageCoordenates.top;
    const left = pageCoordenates.left;
    const padding = width*11/621;

    return(
      <div
        style={{
            top: top + Math.abs(padding)+window.scrollY,
            left: left - Math.abs(padding),
            overflow: 'hidden',
            position: 'absolute',
            zIndez:-2,
        }}>
        <img
          alt=""
          width={calculoWidthSombra(width, prodCode, prodType, prodFormat, isCover)}
          height='auto'
          src={require(`../../asset/sombra/${caminhoAsset}/sombra-deck-cards.svg`)}
        />
      </div>
    );
  }
  return null;
}

SombraLateral.propTypes = {
  isBookOverviewExpanded: PropTypes.bool.isRequired,
  isGalleryExpanded: PropTypes.bool.isRequired,
  isPhotoEditorActive: PropTypes.bool.isRequired,
  prodCode: PropTypes.string.isRequired,
  prodType: PropTypes.string.isRequired,
  prodFormat: PropTypes.string.isRequired,
  currentNav: PropTypes.string.isRequired,
  isCover: PropTypes.bool.isRequired,
  windowSize: windowSizePropTypes,
};

function SombraInferior(
  {
    isBookOverviewExpanded,
    isGalleryExpanded,
    isPhotoEditorActive,
    prodCode,
    prodType,
    prodFormat,
    isCover,
    currentNav,
    windowSize,
  }){

  const [prevMockpageCoordenates, setPrevMockpageCoordenates] = useState(null);
  const [prevIsGalleryExpanded, setPrevIsGalleryExpanded] = useState(false);

  const mounted = useRef();

  useEffect(() => {
    const mockpageElement = document.getElementById('product-page');
    if (!mounted.current && !isBookOverviewExpanded && !isGalleryExpanded && !isPhotoEditorActive && 
          (mockpageElement != null && prevMockpageCoordenates !== mockpageElement.getBoundingClientRect()))
    {
      setPrevMockpageCoordenates(mockpageElement.getBoundingClientRect());
      mounted.current = true;
    } else{
      if(mockpageElement != null && prevMockpageCoordenates !== mockpageElement.getBoundingClientRect())
        setPrevMockpageCoordenates(mockpageElement.getBoundingClientRect());
      setPrevIsGalleryExpanded(isGalleryExpanded);
    }
  },[isGalleryExpanded, isBookOverviewExpanded, isPhotoEditorActive, isCover, currentNav, windowSize]);

  const mockpageElement = document.getElementById('product-page');
  const caminhoAsset = buscaAssets(prodCode, prodType, prodFormat);

  if(prodType === 'calendar' && (prodFormat ==='P' || prodFormat === 'A3-P') && mockpageElement != null && !isBookOverviewExpanded && !isGalleryExpanded && !isPhotoEditorActive)
  {
    const prodFormatSrc = prodFormat === 'A3-P' ? 'A3_P':prodFormat;
    const pageCoordenates = mockpageElement.getBoundingClientRect();
    const scrollY = prevIsGalleryExpanded ? s.FATOR_AJUSTE_Y_SOMBRA_GALLERY_EXPANDED_CALENDAR : window.scrollY;
    
    return(
      <div
        style={{
            top: calculoYSombraInferior(pageCoordenates.bottom+scrollY, pageCoordenates, null, prodCode, prodType, prodFormat, isCover),
            position: 'absolute',
            zIndez:-2,
          }}>
        <img
          alt=""
          width={calculoWidthSombra(pageCoordenates.width, prodCode, prodType, prodFormat, isCover)}
          height='auto'
          src={require(`../../asset/sombra/${prodType}/${prodFormatSrc}/sombra-inferior.svg`)}
        />
      </div>
    );
  }
  else if((LISTA_PRODUTOS_PERMITIDOS_TIPO1.includes(prodCode) || LISTA_PRODUTOS_PERMITIDOS_TIPO2.includes(prodCode)) && 
          mockpageElement != null && !isBookOverviewExpanded && !isGalleryExpanded && !isPhotoEditorActive)
  {
    let pageCoordenates = mockpageElement.getBoundingClientRect();
    const scrollY = prevIsGalleryExpanded ? s.FATOR_AJUSTE_Y_SOMBRA_GALLERY_EXPANDED_CALENDAR : window.scrollY;

    const mockpageCoverElement = document.getElementById('mock-page-id');

    if(isCover && mockpageCoverElement)
      pageCoordenates = mockpageCoverElement.getBoundingClientRect();

    const widthSombraInferior = calculoWidthSombraInferior(pageCoordenates.width, prodCode, prodType, prodFormat, isCover);
    const ySombraInferior = calculoYSombraInferior(pageCoordenates.bottom+scrollY, pageCoordenates, widthSombraInferior, prodCode, prodType, prodFormat, isCover);
    const xSombraInferior = calculoXSombraInferior(pageCoordenates, prodCode, prodType, prodFormat, isCover);

    return(
      <div>
        <div
          style={{
              top: ySombraInferior,
              left: xSombraInferior,
              position: 'absolute',
              display: 'flex'
            }}>
          <img
            alt=""
            height="auto"
            width={widthSombraInferior}
            src={require(`../../asset/sombra/${caminhoAsset}/sombra-inferior-capa.svg`)}
          />
        </div>
        <div
          style={{
              top: ySombraInferior,
              right: xSombraInferior,
              position: 'absolute',
              display: 'flex'
            }}>
          <img
            alt=""
            height="auto"
            width={widthSombraInferior}
            src={require(`../../asset/sombra/${caminhoAsset}/sombra-inferior-capa.svg`)}
          />
        </div>
      </div>
    );
  }
  return null
}

SombraInferior.propTypes = {
  isBookOverviewExpanded: PropTypes.bool.isRequired,
  isGalleryExpanded: PropTypes.bool.isRequired,
  isPhotoEditorActive: PropTypes.bool.isRequired,
  prodCode: PropTypes.string.isRequired,
  prodType: PropTypes.string.isRequired,
  prodFormat: PropTypes.string.isRequired,
  currentNav: PropTypes.string.isRequired,
  isCover: PropTypes.bool.isRequired,
  windowSize: windowSizePropTypes,
};

export {SombraLateral, SombraInferior};
