import './index.css';

import * as R from 'ramda';

import React, { Component } from 'react';
import {
  navigatorPropTypes,
  pagesFormatPropTypes,
  screenSizePropTypes,
  stylePropTypes
} from '../../proptypes';

import LayoutsBox from '../layouts-box';
import NumericStepper from '../../container/page-stepper';
import PropTypes from 'prop-types';
import Select from '../select';
import { THEME_CLASS_ICON_CHECK } from '../../constant/button';
import {samePageLayout} from '../../utils/separadorProdutos';
import {
  getTipoPag, 
  getTipoPagina,
  applyMethodTodos,
} from '../../utils/textosVariaveis';

const textOptions = (styles, { photos }, type) => {
  const result = [];
  const filteredStyles = R.filter(
    s => s.shape && s.shape.length === photos && s[type],
    styles
  );
  if (R.find(R.propEq('textarea', undefined))(filteredStyles)) {
    result.push({ id: 'sem' });
  }
  if (R.find(R.prop('textarea'))(filteredStyles)) {
    result.push({ id: 'com' });
  }
  return result;
};

const applyMethodOptions = (type, cols, prodType, isModoSobFivela) => {
  let pag = getTipoPag();
  let pags = getTipoPag(true);
  const todosId = applyMethodTodos();

  if(prodType === 'deck-cards'){
    if(isModoSobFivela)
      return [{ id: 'a todas as cartas verso' }];
    else
      return [{ id: 'a todas as cartas frente' }];
  }

  return cols === 1
    ? [{ id: `somente ${pag} atual` }, { id: todosId }]
    : type === 'page' || type === 'lastpage'
    ? [
        { id: `somente ${pag} atual` },
        { id: `${pag} dupla` },
        { id: `a todas ${pags} da esquerda` },
        { id: `a todas ${pags} da direita` },
        { id: todosId }
      ]
    : [{ id: `somente ${pag} atual` }];
}

const filterStyles = ({ pages }, styles, { side }, maxPage, haveCover, prodType) => {
  const page = side === 'LEFT' ? R.head(pages) : pages[pages.length - 1];
  const isLastPageFlex = !haveCover && page.index === maxPage && samePageLayout(prodType);

  const styleType = isLastPageFlex
    ? 'lastpage'
    : page.style.page
    ? 'page'
    : page.style.cover
    ? 'cover'
    : page.style.back_cover
    ? 'back_cover'
    : 'spine';

  return [styleType, R.filter(R.propEq(styleType, true), styles)];
};

class NavLayouts extends Component {
  state = {
    applyMethod: this.props.layoutsFilter.applyMethod
  };

  closeNav = e => {
    const selects = document.querySelectorAll('.select');
    const selectMenu =
      document.querySelector('.layouts-applyMethod.menu') ||
      document.querySelector('.layouts-text.menu');

    if (selectMenu) {
      if (!selects[0].contains(e.target) && !selects[1].contains(e.target)) {
        this.props.toggleMenuLayouts(
          selectMenu.classList[0].slice(8).toUpperCase()
        );
      }
    }
  };

  setLayoutContainerWidth = () => {
    const { screenSize, updateScreenSize } = this.props;
    const { width, height, headerHeight, photobookHeight } = screenSize;
    const filterLayouts = document.querySelector('.filter-layouts').clientWidth;

    updateScreenSize(
      width,
      height,
      headerHeight,
      photobookHeight,
      width - width * 0.058 - filterLayouts
    );
  };

  componentDidUpdate(prevProps) {
    const { applyMethod, text } = this.props.layoutsFilter;
    if (
      prevProps.layoutsFilter.applyMethod !== applyMethod ||
      prevProps.layoutsFilter.text !== text
    ) {
      this.setLayoutContainerWidth();
    }
  }

  componentDidMount() {
    window.addEventListener('click', this.closeNav);
    this.setLayoutContainerWidth();
  }

  render() {
    const {
      layoutsFilter,
      screenSize,
      styles,
      navigatorData,
      pagesFormats,
      maxPage,
      haveCover,
      updateLayoutFilterText,
      updateLayoutFilterApplyMethod,
      toggleMenuLayouts,
      setLayout,
      setNavNone,
      prodType,
      isModoSobFivela
    } = this.props;
    const [styleType, filteredStyles] = filterStyles(
      navigatorData,
      styles,
      layoutsFilter,
      maxPage,
      haveCover,
      prodType
    );

    return (
      <div className="nav-layouts">
        <div className="filter-layouts">
          <h3 className="title">Layouts</h3>
          <div className="line">
            <span>
              <div className="filter-item">
                <NumericStepper
                  screenSize={screenSize}
                  styleType={styleType}
                  layouts
                ></NumericStepper>
              </div>
              imagens por {getTipoPagina()}{' '}
              <Select
                name="group"
                label={layoutsFilter.text}
                checkedId={layoutsFilter.text}
                options={textOptions(styles, layoutsFilter, styleType)}
                screenSize={screenSize}
                changeHandler={updateLayoutFilterText}
                selectedIcon={THEME_CLASS_ICON_CHECK}
                layoutsFilter
                type={'layouts-text'}
                menu={layoutsFilter.menuText}
                togleMenu={toggleMenuLayouts}
              />{' '}
              texto
            </span>
          </div>
          <div className="line">
            <span>
              Aplicar{' '}
              <Select
                name="group"
                label={layoutsFilter.applyMethod}
                checkedId={layoutsFilter.applyMethod}
                options={applyMethodOptions(styleType, navigatorData.cols, prodType, isModoSobFivela)}
                screenSize={screenSize}
                changeHandler={updateLayoutFilterApplyMethod}
                selectedIcon={THEME_CLASS_ICON_CHECK}
                layoutsFilter
                type={'layouts-applyMethod'}
                menu={layoutsFilter.menuApplyMethod}
                togleMenu={toggleMenuLayouts}
              />
            </span>
          </div>
        </div>
        <div
          style={{
            margin: `18px ${screenSize.width * 0.014}px 0 ${screenSize.width *
              0.014}px`
          }}
          className="line-divisor"
        ></div>
        <div className="layouts-box-container">
          <LayoutsBox
            styles={filteredStyles}
            screenSize={screenSize}
            layoutsFilter={layoutsFilter}
            setLayout={setLayout}
            setNavNone={setNavNone}
            pagesFormats={pagesFormats}
            prodType={prodType}
          ></LayoutsBox>
        </div>
      </div>
    );
  }
}

NavLayouts.propType = {
  layoutsFilter: PropTypes.object.required,
  screenSize: screenSizePropTypes,
  styles: PropTypes.arrayOf(stylePropTypes).isRequired,
  navigatorData: navigatorPropTypes,
  pagesFormats: pagesFormatPropTypes,
  maxPage: PropTypes.number.isRequired,
  haveCover: PropTypes.bool.isRequired,
  updateLayoutFilterText: PropTypes.func,
  updateLayoutFilterApplyMethod: PropTypes.func,
  toggleMenuLayouts: PropTypes.func,
  setLayout: PropTypes.func.isRequired,
  setNavNone: PropTypes.func.isRequired,
  prodType: PropTypes.string.isRequired,
  isModoSobFivela: PropTypes.bool.isRequired,
};

export default NavLayouts;
