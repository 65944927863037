import { denormalize, normalize, schema } from 'normalizr';
import { shape, style } from './server-item';

import { image } from './image';

const text = new schema.Entity('text');
const frame = new schema.Entity('frame', {
  image,
  shape
});

const page = new schema.Entity('page', {
  style,
  frames: [frame],
  texts: [text]
});

const pageSchema = [page];

export const normalizePage = data => normalize(data, pageSchema);
export const denormalizePage = (result, entities) =>
  denormalize(result, pageSchema, entities);
