import './index.css';
import './subcategoria.css';
import PropTypes from 'prop-types';
import { bibliotecaTemaPropTypes } from '../../proptypes';
import React, { useState } from 'react';
import Button from '../button';
import BibliotecaImagens from './bibliotecaImagens';
import {
  THEME_CLASS_BT_FECHAR_BIBLIOTECA_TEMAS,
  THEME_CLASS_BT_DESSELECIONAR_BIBLIOTECA_TEMAS,
  THEME_CLASS_BT_SELECIONAR_BIBLIOTECA_TEMAS,
  THEME_CLASS_ORANGE,
  THEME_CLASS_ORANGE_DISABLED,
} from '../../constant/button';

import {habilitarBiblioteca} from '../../utils/separadorProdutos';

function BibliotecaTemas({
  prodType,
  toggleBibliotecaTemas,
  bibliotecaTemas,
  addThemeImages,
  setStateLegenda
}) {
  const [category, setCategory] = useState(null);
  const [selectedImages, setSelectedImages] = useState([]);

  const changeSelected = (selected, imagem) => {
    if(selected)
    {
      if(imagem === 'all')
      {
        const newSelected = bibliotecaTemas.find(item => category === item.id).images
          .filter((item) => !selectedImages.includes(item));
        setSelectedImages([...selectedImages, ...newSelected]);
      }
      else if(!selectedImages.includes(imagem))
        setSelectedImages([...selectedImages, imagem]);
    }
    else{
      if(imagem === 'all')
        setSelectedImages([]);
      else
        setSelectedImages(selectedImages.filter((item) => item !== imagem));
    }
  }

  if (habilitarBiblioteca(prodType))
    return (
      <div className="BibliotecaTemas">
        <div className="popup">
          <div className="header-text">
            <div>TEMAS</div>
          </div>
          <div className="header-content">
            <div className = "header-category">
              {category != null && <div className = "text-interactive" onClick={() => setCategory(null)}>Temas</div>}
              {category != null && <div style={{fontWeight: 400, padding: '0 3px 0 3px'}}>{'>'}</div>}
              {category != null && <div className = "text-interactive">{bibliotecaTemas.find(item => category === item.id).title}</div>}
            </div>
            <div className="header-desselecionar">
              { category != null && 
                <Button
                  style={{margin: '4px 2px 2px 2px'}}
                  padding={false}
                  theme={THEME_CLASS_BT_DESSELECIONAR_BIBLIOTECA_TEMAS}
                  clickHandler={() => changeSelected(false, 'all')}
                />
              }
              { category != null && 
                <div 
                  style={{fontFamily: 'lato', fontWeight: 400, fontSize: '14px', paddingRight:"4px", alignSelf:'center', cursor: 'pointer'}} 
                  onClick={() => changeSelected(false, 'all')}>
                  Desselecionar todas
                </div>
              }
            </div>
            <div className="header-selecionar">
              { category != null && 
                <Button
                  style={{margin: '4px 2px 2px 2px'}}
                  padding={false}
                  theme={THEME_CLASS_BT_SELECIONAR_BIBLIOTECA_TEMAS}
                  clickHandler={() => changeSelected(true, 'all')}
                />
              }
              { category != null && 
                <div 
                  className="header-selecionar-todas"
                  onClick={() => changeSelected(true, 'all')}>
                  Selecionar todas
                </div>
              }
            </div>
            <div className="header-button">
              <Button
                padding={false}
                theme={THEME_CLASS_BT_FECHAR_BIBLIOTECA_TEMAS}
                clickHandler={() => toggleBibliotecaTemas()}
            />
            </div>
          </div>
          <div className="side-menu">
            {bibliotecaTemas.map((item, index) =>
              <div 
                key={index} 
                className={category === item.id ? "item-menu-active":"item-menu"}
                onClick={() => setCategory(item.id)}
              >
                {item.title}
              </div>
            )}
          </div>
          {category == null ?
            (<div className="main-container">
              {bibliotecaTemas.map((item, index) =>
                <div 
                  key={index} 
                  className="item-card"
                  onClick={() => setCategory(item.id)}
                >
                  <div className="item-rectangle">
                    <div className="item-img">
                      <img
                        alt="item-thumb"
                        height="100%"
                        width="100%"
                        src={item.thumb}
                      />
                    </div>
                  </div>
                  <div className="item-card-title">{item.title}</div>
                  <div className="item-card-subtitle">{item.numberImages} {item.numberImages <= 1 ? 'imagem':'imagens'}</div>
                </div>
              )}
            </div>) :
            (<div className="main-container-category" 
              style={{justifyContent: (bibliotecaTemas.find(item => category === item.id).images.length) < 4 ? "start": "space-around"}}>
              {bibliotecaTemas.find(item => category === item.id).images.map(subItem =>
              <div 
                key={subItem.id} 
                className="item-card"
                onClick={() => changeSelected(true, subItem)}
              >
                <div className="item-rectangle">
                  <div 
                    className="item-img"
                    style={{outline: selectedImages.includes(subItem) ? '2px solid rgb(255,106,4)' : 'none'}}
                    >
                    <img
                      alt="subitem-thumb"
                      height={subItem.thumb.height > subItem.thumb.width ? "116px":"auto"}
                      width={subItem.thumb.height > subItem.thumb.width ? "auto":"116px"}
                      src={subItem.thumb.image}
                    />
                    {selectedImages.includes(subItem) && <div className="bt-menu-selected"/>}
                    {selectedImages.includes(subItem) && 
                      <div className="bt-menu-delete" onClick={() => changeSelected(false, subItem)}/>}
                  </div>
                </div>
              </div>)}
              </div>
            )
          }
          <div className="footer">
            <div className="footer-left">
              <div className="footer-label">{selectedImages.length} {selectedImages.length <= 1 ? 'imagem':'imagens'}</div>
                <Button
                    style={{
                      fontSize: "22px", 
                      width: "156px", 
                      height:"36px",
                      position: "absolute",
                      left: "18px",
                      bottom: "16px",
                    }}
                    label="Adicionar"
                    theme={selectedImages.length <= 0 ? THEME_CLASS_ORANGE_DISABLED: THEME_CLASS_ORANGE}
                    disabled={selectedImages.length <= 0}
                    clickHandler={() =>  {
                      toggleBibliotecaTemas(); 
                      addThemeImages(selectedImages);
                      setStateLegenda('MESA');
                    }}
                  />
              </div>
              <div className="footer-selected">
                {(selectedImages.length > 0) && <BibliotecaImagens
                  selectedImages={selectedImages}
                  changeSelected={changeSelected}
                />}
              </div>
            </div>
          </div>
      </div>
    );
  else
    return null;
}

BibliotecaTemas.propTypes = {
  prodType: PropTypes.string.isRequired,
  toggleBibliotecaTemas: PropTypes.func.isRequired,
  bibliotecaTemas: PropTypes.arrayOf(bibliotecaTemaPropTypes),
  addThemeImages: PropTypes.func.isRequired,
  toggleLegenda: PropTypes.func.isRequired,
  isLegendaAberta: PropTypes.bool.isRequired,
  setStateLegenda: PropTypes.func.isRequired
};

export default BibliotecaTemas;