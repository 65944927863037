import * as R from 'ramda';
import * as actionGeneral from '../action/general';
import * as actionUI from '../action/ui';
import { 
    all,
    put,
    select
  } from 'redux-saga/effects';

import { POPUP_ID_LOADING, POPUP_ID_NONE } from '../constant/popup';

import {
    mapFramesToRemove,
    addPage,
    idbUpdateMetadata,
    setPageIndex,
} from './index';

import {
    bookHaveCover,
    getAllBookPagesData,
    getIDBStatus,
    getNavigationIndex,
    getProductType,
    getFirstEmptyPage,
  } from '../selector';

import { getSetNumber } from '../selector/set';
import { movePageCircular, movePageCircularLayout } from '../utils/separadorProdutos';
import { deleteImageFramePoster } from './deleteImageFromBook';

export function* movePage({ payload: { side } }) {
    const state = yield select();
    const IDBStatus = getIDBStatus(state);
    const productType = getProductType(state);

    yield put(actionUI.updatePopup(POPUP_ID_LOADING, true, 'Movendo'));

    if(movePageCircular.includes(productType))
        yield moveMask(state, side);
    else if(movePageCircularLayout.includes(productType))
        yield movePoster(state, side);
    else
        yield movePhotoBookPage(state, side);

    yield put(actionUI.updatePopup(POPUP_ID_NONE, false, ''));
    if (IDBStatus) {
        yield idbUpdateMetadata();
    }
}

function* moveMask(state, side){
    const pageIndex = getNavigationIndex(state) - (bookHaveCover(state) ? 1 : 0);
    const maxIndex = getSetNumber(state)-1;
    const allPages = R.prop('pages', getAllBookPagesData(state));
    const mapPageIndexFrame = [];
    
    const newIndex = side === 'LEFT' ? (pageIndex === 0 ? maxIndex : pageIndex-1) : (pageIndex === maxIndex ? 0 : pageIndex+1);

    allPages.forEach((page, id) => {
        if(id === pageIndex || id === newIndex){
            page.frames.map(frame =>
                mapPageIndexFrame.push({ pageIndex: page.id, frameId: frame.id })
            );
            page.id = (id === pageIndex) ? newIndex : pageIndex;
            page.index = (id === pageIndex) ? newIndex+1 : pageIndex+1;
        }
    });

    const pagesAux = [
        allPages[newIndex],
        allPages[pageIndex],
    ];

    yield all(pagesAux.map(page => addPage(page)));
    yield setPageIndex({ payload: { index: newIndex } });

    yield put(actionUI.updatePopup(POPUP_ID_NONE, false, ''));
}

function* movePhotoBookPage(state, side){
    const pageIndex = getNavigationIndex(state) - (bookHaveCover(state) ? 1 : 0);
    const maxSetNumber = getSetNumber(state) / 2;
    const allPages = R.prop('pages', getAllBookPagesData(state));
    const mapPageIndexFrame = [];

    if (side === 'LEFT') {
        if (pageIndex > 1 && pageIndex < maxSetNumber) {
            allPages.forEach((page, id) => {
            if (id === pageIndex * 2 || id === pageIndex * 2 + 1) {
                page.frames.map(frame =>
                    mapPageIndexFrame.push({ pageIndex: page.id, frameId: frame.id })
                );
                page.id -= 2;
                page.index -= 2;
            } else if (id === pageIndex * 2 - 2 || id === pageIndex * 2 - 1) {
                page.frames.map(frame =>
                    mapPageIndexFrame.push({ pageIndex: page.id, frameId: frame.id })
                );
                page.id += 2;
                page.index += 2;
            }
            });
            const pagesAux = [
                allPages[pageIndex * 2 - 2],
                allPages[pageIndex * 2 - 1],
                allPages[pageIndex * 2],
                allPages[pageIndex * 2 + 1]
            ];
            yield all(
                pagesAux.map(
                    ({ frames }) => frames.length > 0 && mapFramesToRemove(frames, mapPageIndexFrame)
                )
            );
            yield all(pagesAux.map(page => addPage(page)));
            yield all(
                pagesAux.map(
                    ({ texts, id }) =>
                    !texts.length && put(actionGeneral.removeTextsFromPage(id))
                )
            );
            yield put(actionGeneral.sagaDecreasePageIndex());
        }
    } else {
        if (pageIndex !== 0 && pageIndex < maxSetNumber - 1) {
            allPages.forEach((page, id) => {
            if (id === pageIndex * 2 || id === pageIndex * 2 + 1) {
                page.frames.map(frame =>
                    mapPageIndexFrame.push({ pageIndex: page.id, frameId: frame.id })
                );
                page.id += 2;
                page.index += 2;
            } else if (id === pageIndex * 2 + 2 || id === pageIndex * 2 + 3) {
                page.frames.map(frame =>
                    mapPageIndexFrame.push({ pageIndex: page.id, frameId: frame.id })
                );
                page.id -= 2;
                page.index -= 2;
            }
            });
            const pagesAux = [
                allPages[pageIndex * 2],
                allPages[pageIndex * 2 + 1],
                allPages[pageIndex * 2 + 2],
                allPages[pageIndex * 2 + 3]
            ];
            yield all(
                pagesAux.map(
                    ({ frames }) => frames.length > 0 && mapFramesToRemove(frames, mapPageIndexFrame)
                )
            );
            yield all(pagesAux.map(page => addPage(page)));
            yield all(
                pagesAux.map(
                    ({ texts, id }) =>
                    !texts.length && put(actionGeneral.removeTextsFromPage(id))
                )
            );
            yield put(actionGeneral.sagaIncreasePageIndex());
        }
    }
}

function* movePoster(state, side){
    const pageIndex = getNavigationIndex(state) - (bookHaveCover(state) ? 1 : 0);
    const firstEmptyPage = getFirstEmptyPage(state);

    if(firstEmptyPage != null && firstEmptyPage.id < pageIndex){
        const newIndex = firstEmptyPage.id;
        yield swapPosterAct(state, pageIndex, newIndex);
    }
    else if(side === 'LEFT' || side === 'RIGHT'){
        const maxIndex = getSetNumber(state)-1;
        const newIndex = side === 'LEFT' ? (pageIndex === 0 ? maxIndex : pageIndex-1) : (pageIndex === maxIndex ? 0 : pageIndex+1);
        yield movePosterAct(state, pageIndex, newIndex);
    }
}

function* movePosterAct(state, pageIndex, newIndex){
    const allPages = R.prop('pages', getAllBookPagesData(state));
    const mapPageIndexFrame = [];
    const pagesAux = [];

    allPages.forEach((page, id) => {
        if(id === pageIndex){
            page.frames.map(frame => mapPageIndexFrame.push({ pageIndex: page.id, frameId: frame.id }));
            page.id = newIndex;
            page.index = newIndex+1;
            pagesAux.unshift({...page});
            page.frames = [];
            page.texts = [];
        }
        else if(id === newIndex){
            page.frames.map(frame => mapPageIndexFrame.push({ pageIndex: page.id, frameId: frame.id }));
            page.id = pageIndex;
            page.index = pageIndex+1;
            pagesAux.unshift({...page});
            page.frames = [];
            page.texts = [];
        }
    });

    yield all(mapPageIndexFrame.map(({ frameId, pageIndex }) => deleteImageFramePoster(frameId, pageIndex)));
    yield all(pagesAux.map(page => addPage(page)));
    yield all(pagesAux.map(({ texts, id }) =>!texts.length && put(actionGeneral.removeTextsFromPage(id))));
    yield put(actionGeneral.sagaSetPageIndex(newIndex)); 
}

function* swapPosterAct(state, pageIndex, newIndex){
    const allPages = R.prop('pages', getAllBookPagesData(state));
    const mapPageIndexFrame = [];

    allPages.forEach((page, id) => {
        if(id === pageIndex || id === newIndex){
            page.frames.map(frame =>
                mapPageIndexFrame.push({ pageIndex: page.id, frameId: frame.id })
            );
            page.id = (id === pageIndex) ? newIndex : pageIndex;
            page.index = (id === pageIndex) ? newIndex+1 : pageIndex+1;
        }
    });

    const pagesAux = [
        allPages[newIndex],
        allPages[pageIndex],
    ];

    yield all(pagesAux.map(({ frames }) => frames.length > 0 && mapFramesToRemove(frames, mapPageIndexFrame)));
    yield all(pagesAux.map(page => addPage(page)));
    yield all(pagesAux.map(({ texts, id }) =>!texts.length && put(actionGeneral.removeTextsFromPage(id))));
    yield put(actionGeneral.sagaSetPageIndex(newIndex)); 
}