import './index.css';
import React, { useState } from 'react';

import {
  THEME_CLASS_BT_MENU_STYLE_MANAGER,
  THEME_CLASS_BT_MENU_STYLE_MANAGER_PRESSED
} from '../../constant/button';
import { pagePropTypes } from '../../proptypes';

import {
  getTipoQuantidade,
  getTipoQuantidadePadrao,
  getGeneroProduto
} from '../../utils/textosVariaveis';

import Button from '../button';
import PropTypes from 'prop-types';

const toogleMenuLayoutsPropTypes = {
  toogleMenuLayouts: PropTypes.func.isRequired
};

const isMenuLayoutsSelectedPropTypes = {
  isMenuLayoutsSelected: PropTypes.bool
};

const formatNumber = number => (number > 9 ? number : `0${number}`);

function MenuBookDireito({
  pages,
  toogleMenuLayouts,
  isMenuLayoutsSelected,
  visiblePages,
  screenSize,
  suffleLayout,
  adjustLayoutsFilter,
  layoutsFilter,
  updateLayoutFilterSide,
  prodType,
  toggleModoImpressao,
  toggleModoSobFivela,
  isModoImpressao,
  isModoSobFivela,
  tamanhoSelecionado,
  isBookOverviewExpanded,
  pageIndex,
  firstPage,
  setPage
}) {
  const [lastPage, setLastPage] = useState();

  if (prodType === 'mask') {
    return (
      <div className="btn-label-container">
        <div
          className="padd-num"
          data-tip={
            isModoImpressao
              ? 'Visualiza as medidas do rosto, para posicionamento correto de sua imagem'
              : 'Visualiza sua Máscara pronta para impressão'
          }
          onClick={() => toggleModoImpressao()}
        >
          <span className="label-modo-visualizacao">
            {isModoImpressao ? 'Ver medidas do rosto' : 'Ver Máscara impressa'}
          </span>
        </div>
      </div>
    );
  } else if (prodType === 'dog-collar') {
    if (tamanhoSelecionado !== 54) {
      return (
        <div className="btn-label-container">
          <div
            className="padd-num"
            data-tip={
              isModoSobFivela
                ? 'A caixa de texto não fica sob a fivela'
                : 'A caixa de texto fica sob a fivela'
            }
            onClick={() => toggleModoSobFivela()}
          >
            <span className="label-modo-visualizacao">
              {isModoSobFivela
                ? 'Modo texto ao redor da fivela'
                : 'Modo texto sob fivela'}
            </span>
          </div>
        </div>
      );
    } else {
      return <div className="btn-label-container"></div>;
    }
  } else if (prodType === 'deck-cards') {
    return (
      <div className="btn-label-container">
        <div
          className="padd-num"
          data-tip={
            isModoSobFivela
              ? 'Visualiza as Cartas da Frente'
              : 'Visualiza as Cartas do Verso'
          }
          onClick={() => {
            if (!isBookOverviewExpanded) {
              if (!isModoSobFivela) {
                setLastPage(pageIndex);
                firstPage();
              } else if (lastPage) setPage(lastPage);
              toggleModoSobFivela();
            }
          }}
        >
          <span className="label-modo-visualizacao">
            {isModoSobFivela ? 'Ver Cartas Frente' : 'Ver Cartas Verso'}
          </span>
        </div>
      </div>
    );
  } else if (prodType === 'invitation') return <div />;
  return (
    <div className="btn-label-container">
      <div
        className="page-btns"
        style={{
          display:
            pages.length > 0 && pages[pages.length - 1].label !== 'Guarda'
              ? 'block'
              : 'none',
          paddingRight: '7px'
        }}
      >
        <button
          type="button"
          className="bt-menu-shuffle-previous button"
          data-tip="Layout Anterior: Quantidade de imagens igual ou menor"
          style={{ marginRight: `${screenSize.width * 0.008 - 9}px` }}
          onClick={function () {
            suffleLayout(pages[pages.length - 1].id, 'DECREASE');
          }}
        />
        <Button
          theme={
            isMenuLayoutsSelected && layoutsFilter.side === 'RIGHT'
              ? THEME_CLASS_BT_MENU_STYLE_MANAGER_PRESSED
              : THEME_CLASS_BT_MENU_STYLE_MANAGER
          }
          dataTip={`Escolher Layout: Exibe todos os layouts e ${getGeneroProduto(
            prodType,
            true
          )} ${getTipoQuantidadePadrao()} que deseja aplicar`}
          clickHandler={function () {
            toogleMenuLayouts(isMenuLayoutsSelected);
            updateLayoutFilterSide('RIGHT');
            adjustLayoutsFilter();
          }}
          style={{ marginRight: `${screenSize.width * 0.008 - 9}px` }}
        />
        <button
          type="button"
          className="bt-menu-shuffle-next button"
          data-tip="Próximo Layout: Quantidade de imagens igual ou maior"
          onClick={function () {
            suffleLayout(pages[pages.length - 1].id, 'INCREASE');
          }}
        />
      </div>
      {visiblePages > 1 && (
        <div className="padd-num" data-tip={`${getTipoQuantidade(true)} atual`}>
          <span className="label">
            {pages[pages.length - 1].label ||
              formatNumber(pages[pages.length - 1].index)}
          </span>
        </div>
      )}
    </div>
  );
}

MenuBookDireito.propTypes = {
  pages: PropTypes.arrayOf(pagePropTypes).isRequired,
  ...toogleMenuLayoutsPropTypes,
  ...isMenuLayoutsSelectedPropTypes,
  visiblePages: PropTypes.number.isRequired,
  suffleLayout: PropTypes.func.isRequired,
  adjustLayoutsFilter: PropTypes.func.isRequired,
  layoutsFilter: PropTypes.shape({
    layoutSelected: PropTypes.number,
    side: PropTypes.string
  }).isRequired,
  updateLayoutFilterSide: PropTypes.func.isRequired,
  prodType: PropTypes.string.isRequired,
  toggleModoImpressao: PropTypes.func.isRequired,
  toggleModoSobFivela: PropTypes.func.isRequired,
  isModoImpressao: PropTypes.bool.isRequired,
  isModoSobFivela: PropTypes.bool.isRequired,
  tamanhoSelecionado: PropTypes.number,
  isBookOverviewExpanded: PropTypes.bool,
  pageIndex: PropTypes.number.isRequired,
  firstPage: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired
};

export default MenuBookDireito;
