import { DropTarget } from 'react-dnd';

const imageDropTarget = {
  drop(props, monitor) {
    if (!props.isBookOverviewExpanded) {
      const item = monitor.getItem();
      props.onDrop(
        item.thumbId,
        props.shapeId,
        props.pageIndex,
        props.pageId,
        props.styleId
      );
    }
  }
};

const imageDropCollect = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  canDrop: monitor.canDrop(),
  isOver: monitor.isOver()
});

const dropTarget = DropTarget('THUMB', imageDropTarget, imageDropCollect);

export default dropTarget;
