import './index.css';

import { screenSizePropTypes } from '../../proptypes';
import { 
  getTipoQuantidade,
  getTipoQuantidadePadraoMascara
 } from '../../utils/textosVariaveis';

import PropTypes from 'prop-types';
import React from 'react';

const PageStepper = ({
  value,
  valueId,
  nextValue,
  previousValue,
  increaseHandler,
  decreaseHandler,
  mouseUpHandler,
  increaseClickHandler,
  decreaseClickHandler,
  screenSize,
}) => {  
  return(
    <div
      className="numeric-stepper"
      onMouseUp={mouseUpHandler}
      onMouseOut={mouseUpHandler}
      onMouseLeave={mouseUpHandler}
    >
      <div className="stepper-container">
        <button
          type="button"
          title={`Remover ${getTipoQuantidade()}`}
          className={`decrease${
            valueId === previousValue
              ? ' disabled'
              : ''
          }`}
          data-tip={
            valueId !== previousValue
              ? `Remover ${getTipoQuantidadePadraoMascara(true)}`
              : ''
          }
          data-place="bottom"
          onMouseDown={() => valueId !== previousValue && decreaseHandler()}
          onClick={() => valueId !== previousValue && decreaseClickHandler()}
          style={{ margin: `0 ${screenSize.width * 0.006}px` }}
        />
        <span className="number-value">{value}</span>
        <button
          type="button"
          title={`Adicionar ${getTipoQuantidade()}`}
          className={`increase${
            valueId === nextValue ? ' disabled' : ''
          }`}
          data-tip={valueId !== nextValue ? `Adicionar ${getTipoQuantidadePadraoMascara(true)}` : ''}
          data-place="bottom"
          onMouseDown={() =>
            valueId !== nextValue && increaseHandler()
          }
          onClick={() => valueId !== nextValue && increaseClickHandler()}
          style={{ margin: `0 ${screenSize.width * 0.006}px` }}
        />
      </div>
    </div>
  );
}

PageStepper.propTypes = {
  value: PropTypes.string.isRequired,
  valueId: PropTypes.string.isRequired,
  nextValue: PropTypes.string.isRequired,
  previousValue: PropTypes.string.isRequired,
  increaseHandler: PropTypes.func.isRequired,
  decreaseHandler: PropTypes.func.isRequired,
  mouseUpHandler: PropTypes.func.isRequired,
  increaseClickHandler: PropTypes.func.isRequired,
  decreaseClickHandler: PropTypes.func.isRequired,
  screenSize: screenSizePropTypes,
};

PageStepper.defaultProps = {
  increaseHandler: null,
  decreaseHandler: null,
  mouseUpHandler: null,
  increaseClickHandler: null,
  decreaseClickHandler: null
};

export default PageStepper;
