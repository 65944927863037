import * as actionGeneral from '../action/general';
import * as actionItem from '../action/item';
import * as actionUI from '../action/ui';

import {
  bookHaveCover,
  getImagesId,
  getItemId,
  getNotUploadedAndUsed,
  isMetadataUploaded,
  getProductType,
  getNavigationIndex,
  getSelectedProduct,
  getCustomProducts,
  getIsModoSobFivela,
  getFrames
} from '../selector/index';
import {
  getPopupDisplay,
  getPopupId,
  getPopupMsg,
  getPopupOverlay
} from '../selector/popup';
import {
  updateBookOverviewExpanded,
  updatePopup,
  updateSelectedNav,
  setStateLegenda
} from '../action/ui';

import Popup from '../component/popup';
import { connect } from 'react-redux';
import { getNextSetId } from '../selector/set';
import store from '../store';

const addPagesAux = dispatch => {
  const state = store.getState();
  const prodType = getProductType(state);
  if (prodType === 'poster') setUserProductEvent(state, dispatch);
  dispatch(actionItem.updatePageSetId(getNextSetId(state)));
};

const setUserProductEvent = (state, dispatch) => {
  let index = getNavigationIndex(state);
  const product = getSelectedProduct(state);
  if (product) {
    const products = getCustomProducts(state);
    dispatch(actionItem.setUserProduct(index, product.id, products, 'ADD'));
  }
};

const removeImagesText = dispatch => {
  const state = store.getState();
  const prodType = getProductType(state);
  if (prodType === 'dog-collar') dispatch(actionGeneral.sagaRemoveTexts());
  else dispatch(actionGeneral.sagaRemovePages());
};

const imageLoadingFunc = state => {
  const frames = getFrames(state);

  if (!frames || (frames && Object.keys(frames).length === 0)) return 100;

  return (
    Math.round(
      (getNotUploadedAndUsed(state).length / getImagesId(state).length) * -100
    ) + 100
  );
};

const mapStateToProps = state => ({
  itemId: getItemId(state),
  bookHaveCover: getItemId(state) ? bookHaveCover(state) : false,
  display: getPopupDisplay(state),
  overlay: getPopupOverlay(state),
  id: getPopupId(state),
  msg: getPopupMsg(state),
  isMetadataUploaded: isMetadataUploaded(state),
  imageLoading: imageLoadingFunc(state),
  prodType: getProductType(state),
  isModoSobFivela: getIsModoSobFivela(state)
});

const toggleModoSobFivelaHandler = dispatch => {
  const state = store.getState();
  const prodType = getProductType(state);
  if (prodType === 'deck-cards') {
    const isModoSobFivela = getIsModoSobFivela(state);
    dispatch(
      actionItem.resetLayoutFilterApplyMethod(
        !isModoSobFivela
          ? 'a todas as cartas verso'
          : 'a todas as cartas frente'
      )
    );
  }
  dispatch(actionItem.toggleModoSobFivela());
};

const mapDispatchToProps = dispatch => ({
  updatePopup: (id, overlay, msg) => {
    dispatch(setStateLegenda(msg.length <= 0 || !overlay ? 'NORMAL' : 'POPUP'));
    dispatch(updatePopup(id, overlay, msg));
  },
  setPageIndex: index => dispatch(actionGeneral.sagaSetPageIndex(index)),
  setLastPage: () => dispatch(actionGeneral.sagaLastPageIndex(true)),
  updateNav: value => dispatch(updateSelectedNav(value)),
  addPages: () => addPagesAux(dispatch),
  inputFileChangeHandler: e =>
    dispatch(actionGeneral.sagaInputFileChange(e.currentTarget.files)),
  removeImagesFromBook: () => removeImagesText(dispatch),
  deleteAllImages: () => dispatch(actionGeneral.deleteAllImages()),
  toggleRemovePages: () => dispatch(actionItem.toggleRemovePages()),
  removePages: () => dispatch(actionGeneral.sagaOverviewRemovePages([], true)),
  setLayout: (layout, keepGoing) =>
    dispatch(actionGeneral.sagaSetLayout(layout, keepGoing)),
  updateLayoutFilterApplyMethod: value =>
    dispatch(actionItem.updateLayoutFilterApplyMethod(value)),
  updateLayoutFilterSide: value =>
    dispatch(actionItem.updateLayoutFilterSide(value)),
  updateBookOverviewExpanded: isExpanded =>
    dispatch(updateBookOverviewExpanded(isExpanded)),
  submitOrder: page => dispatch(actionGeneral.sagaSubmitOrder(page)),
  updateTutorialStatus: status =>
    dispatch(actionUI.updateTutorialStatus(status)),
  setStateLegenda: state => dispatch(actionUI.setStateLegenda(state)),
  toggleModoSobFivela: () => toggleModoSobFivelaHandler(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Popup);
