import {
    getProductType,
    getIsModoSobFivela,
    isPrint,
    getDeckCardsPagesData
} from '../selector';

import store from '../store';
const FUNDOS_SEM_LOGO = ['abstrato-1', 'abstrato-2'];

const arrayCores = [
    { color: '#000000' },
    { color: '#4B4B4B' },
    { color: '#8C8C8C' },
    { color: '#0000C8' },
    { color: '#0000FF' },
    { color: '#6495ED' },
    { color: '#00CED1' },
    { color: '#00FFFF' },
    { color: '#005F00' },
    { color: '#008000' },
    { color: '#00FF00' },
    { color: '#800080' },
    { color: '#8A2BE2' },
    { color: '#FF1493' },
    { color: '#FA8072' },
    { color: '#FFB6C1' },
    { color: '#800000' },
    { color: '#FF0000' },
    { color: '#FF4500' },
    { color: '#FF8C00' },
    { color: '#FFA500' },
    { color: '#DAA520' },
    { color: '#8B4513' },
    { color: '#CD853F' },
    { color: '#FFD700' },
    { color: '#FFFF00' },
    { color: '#000080' },
    { color: '#4169E1' },
    { color: '#1E90FF' },
    { color: '#00BFFF' },
    { color: '#008080' },
    { color: '#40E0D0' },
    { color: '#7FFFD4' },
    { color: '#3CB371' },
    { color: '#32CD32' },
    { color: '#9ACD32' },
    { color: '#4B0082' },
    { color: '#9400D3' },
    { color: '#FF00FF' },
    { color: '#C71585' },
    { color: '#EE82EE' },
    { color: '#FF69B4' },
    { color: '#B22222' },
    { color: '#DC143C' },
    { color: '#FF6347' },
    { color: '#FF7F50' },
    { color: '#FFA07A' },
    { color: '#B8860B' },
    { color: '#D2691E' },
    { color: '#DEB887' },
    { color: '#483D8B' },
    { color: '#6A5ACD' },
    { color: '#4682B4' },
    { color: '#6B8E23' },
    { color: '#808000' },
    { color: '#BDB76B' },
    { color: '#DB7093' },
    { color: '#87CEEB' },
    { color: '#F0E68C' },
    { color: '#FFDEAD' },
    { color: '#B3C4DE' },
    { color: '#B9B9B9' },
    { color: '#D7D7D7' },
    { color: '#FFFFFF' }
];

const arrayAbstrato = [
    { color: 'abstrato-1' },
    { color: 'abstrato-2' },
    { color: 'abstrato-3' },
    { color: 'abstrato-4' },
    { color: 'abstrato-5' },
    { color: 'abstrato-6' }
];

export function setDefaultFundoColor(setFundoColor, tipoFundo, fundoColor) {
    if (tipoFundo === 'abstrato' && !fundoColor.includes('abstrato'))
        setFundoColor('abstrato-1');
    else if (tipoFundo === 'cores' && !checkColorFundo(fundoColor))
        setFundoColor('#FFFFFF');
}

export const opcoesFundo = isModoSobFivela => {
    if (isModoSobFivela)
        return [
            { id: 'todos' },
            { id: 'abstrato' },
            { id: 'cores' }
            // {id: "logotipo"}
        ];
    else return [{ id: 'todos' }, { id: 'cores' }];
};

export function getArrayFundo(tipoFundo, isModoSobFivela) {
    switch (tipoFundo) {
        case 'cores':
            return arrayCores;
        case 'abstrato':
            return arrayAbstrato;
        case 'todos':
            if (isModoSobFivela) return [...arrayAbstrato, ...arrayCores];
            else return arrayCores;
        default:
            return arrayCores;
    }
}

export function checkColorFundo(fundoColor) {
    return /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i.test(fundoColor);
}

export const getFundoBackgroundColor = fundoColor => {
    let fundo = '#FFFFFF';
    if (checkColorFundo(fundoColor)) fundo = fundoColor;
    return fundo;
};

export const getFundoThumbImage = fundoColor => {
    let fundo = '';
    const notColor = !checkColorFundo(fundoColor);
    try {
        if (notColor && fundoColor.includes('abstrato')) {
            fundo = `url(${require(`../asset/fundos/abstrato/${fundoColor}-thumb.jpg`)})`;
        }
    } catch { }
    return fundo;
};

export const checaFundoAbstrato = (isModoSobFivela, fundoColor) => {
    let abstrato = false;
    const notColor = !checkColorFundo(fundoColor);
    if (isModoSobFivela && notColor && fundoColor.includes('abstrato'))
        abstrato = true;
    return abstrato;
};

export const getFundoOpacity = () => {
    const state = store.getState();
    const prodType = state.library.product ? getProductType(state) : '';
    const isModoSobFivela = getIsModoSobFivela(state);
    const isPrintPage = isPrint(state);
    const deckCardBackPage = getDeckCardsPagesData(state)[1];

    let opacity = 1;

    if (prodType === 'deck-cards') {
        if (!isModoSobFivela) opacity = 0.3;
        else if (
            prodType === 'deck-cards' &&
            isPrintPage &&
            isModoSobFivela &&
            deckCardBackPage &&
            deckCardBackPage.frames &&
            deckCardBackPage.frames.length <= 0
        )
            opacity = 0;
    }

    return opacity;
};

export const fundoTexto = (prodType, textoVazio, isPrint) => {
    let backgroundColor = '';
    const productList = ['deck-cards'];

    if (productList.includes(prodType) && textoVazio && !isPrint)
        backgroundColor = 'white';

    return backgroundColor;
};

export const checaFundoComLogo = (isModoSobFivela, fundoColor) => {
    let fundoComLogo = isModoSobFivela;

    if (checaFundoAbstrato(isModoSobFivela, fundoColor))
        fundoComLogo = !FUNDOS_SEM_LOGO.includes(fundoColor);

    return fundoComLogo;
};
