import './index.css';

import PropTypes from 'prop-types';
import React from 'react';

const Switch = ({ isOn, label, onSwitch }) => (
  <div className="switch">
    <span className={`switch-span ${isOn ? 'switch-active' : ''}`}>
      {isOn ? 'com' : 'sem'} 3D
    </span>
    <label>
      <span className={`description ${isOn ? 'highlight' : ''}`}>{label}</span>
      <div
        className="container"
        data-tip={`${isOn ? 'Desativa' : 'Ativa'} o efeito 3D`}
      >
        <input type="checkbox" checked={isOn} onChange={onSwitch || null} />
        <span className="slider" />
      </div>
    </label>
  </div>
);

Switch.propTypes = {
  isOn: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  onSwitch: PropTypes.func
};

Switch.defaultProps = {
  isOn: false,
  label: '',
  onSwitch: undefined
};

export default Switch;
