import './modalChangeFormat.css';
import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import Button from '../../button';
import { 
  THEME_CLASS_ORANGE,
  THEME_CLASS_ORANGE_DISABLED 
} from '../../../constant/button';
import imagemChangeFormat from '../../../asset/modal/change-product-format.jpg';
import SelectProductFormat from './selectProductFormat';
import { POPUP_ID_LOADING } from '../../../constant/popup';
import { 
  getHeaderChangeFormat,
  getHeaderChangeOrientation,
  getListChangeFormat,
  getListChangeOrientation
 } from '../../../utils/textosVariaveis';

import { THEME_CLASS_BT_EDITOR_CLOSE } from '../../../constant/button';
import { REVISAR_MUDACA_ORIENTACAO } from '../../../constant/popup-message';
import { POPUP_ID_REVISAR_MUDACA_ORIENTACAO } from '../../../constant/popup';
import store from '../../../store';
import { findFirstFilledPage } from '../../../selector'; 

function ModalChangeFormat(
  {
    prodType,
    setModalConfig,
    modalConfig,
    pageFormat,
    productFormat,
    updatePopup,
    postMetadataFormat,
    postMetadataOrientation,
    itemId,
    isRevisarMudancaOrientacao,
    toggleRevisarMudancaOrientacao
  })
{
  const [selectedItem, setSelectedItem] = useState(null);
  const [tamanhos] = useState(getListChangeFormat());
  const [orientations] = useState(getListChangeOrientation(pageFormat));

  const actualItem = productFormat && modalConfig === 'orientation' ? productFormat.split('-')[1].trim(): pageFormat;

  useEffect(() => {
    setSelectedItem(productFormat && modalConfig === 'orientation' ? productFormat.split('-')[1].trim(): pageFormat);
  }, [modalConfig]);

  useEffect(() => {
    if(isRevisarMudancaOrientacao)
      revisarConteudo();
  }, [isRevisarMudancaOrientacao]);

  const revisarConteudo = () => {
    const state = store.getState();
    const hasContent = findFirstFilledPage(state);

    if(hasContent){
      setTimeout(() => {
        updatePopup(
          POPUP_ID_REVISAR_MUDACA_ORIENTACAO,
          true,
          REVISAR_MUDACA_ORIENTACAO(0),
        );
      }, 1);      
    }
    toggleRevisarMudancaOrientacao();
  };

  const selectFormat = () => {
    setModalConfig();
    updatePopup(POPUP_ID_LOADING, true, "Carregando");
    postMetadataFormat(itemId, productFormat, pageFormat);
  };

  const selectOrientation = () => {
    setModalConfig();
    updatePopup(POPUP_ID_LOADING, true, "Carregando");
    postMetadataOrientation(itemId, productFormat, pageFormat);
  };

  if(prodType === 'poster' && modalConfig === 'format')
    return (
      <div className="modal-change-format">
        <div className="container">
          <div className="btn-close-modal">
            <Button
              padding={false}
              theme={THEME_CLASS_BT_EDITOR_CLOSE}
              clickHandler={() => setModalConfig()}
            />
          </div>
          <div className="image">
            <img src={imagemChangeFormat} alt="Escolher formato"></img>
          </div>
          <div className="formats">
            <div className="title">{getHeaderChangeFormat()}</div>
            <div className="select-product">
              <SelectProductFormat
                formats={tamanhos}
                selectedFormat={selectedItem}
                setSelectedFormat={setSelectedItem}
              />
            </div>
            <Button
              label={"Selecionar"}
              style={{marginTop: "22px"}}
              theme={selectedItem !== actualItem ? THEME_CLASS_ORANGE: THEME_CLASS_ORANGE_DISABLED}
              clickHandler={() => selectFormat()}
            /> 
          </div>
        </div>
      </div>
    );
  else if(prodType === 'poster' && modalConfig === 'orientation')
    return (
      <div className="modal-change-format">
        <div className="container">
          <div className="btn-close-modal">
            <Button
              padding={false}
              theme={THEME_CLASS_BT_EDITOR_CLOSE}
              clickHandler={() => setModalConfig()}
            />
          </div>
          <div className="image">
            <img src={imagemChangeFormat} alt="Escolher orientação"></img>
          </div>
          <div className="formats">
            <div className="title">{getHeaderChangeOrientation()}</div>
            <div className="select-product">
              <SelectProductFormat
                formats={orientations}
                selectedFormat={selectedItem}
                setSelectedFormat={setSelectedItem}
              />
            </div>
            <Button
              label={"Selecionar"}
              style={{marginTop: "22px"}}
              theme={selectedItem !== actualItem ? THEME_CLASS_ORANGE: THEME_CLASS_ORANGE_DISABLED}
              clickHandler={() => selectOrientation()}
            /> 
          </div>
        </div>
      </div>
    );
  else
    return null;
}

ModalChangeFormat.propTypes = {
  prodType: PropTypes.string.isRequired,
  setModalConfig: PropTypes.func.isRequired,
  modalConfig: PropTypes.string,
  pageFormat: PropTypes.string.isRequired,
  productFormat: PropTypes.string.isRequired,
  postMetadataFormat: PropTypes.func.isRequired,
  postMetadataOrientation: PropTypes.func.isRequired,
  itemId: PropTypes.number,
  isRevisarMudancaOrientacao: PropTypes.bool.isRequired,
  toggleRevisarMudancaOrientacao: PropTypes.func.isRequired
};

export default ModalChangeFormat;