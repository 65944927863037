import './index.css';
import '../../../node_modules/video-react/dist/video-react.css';
import { BigPlayButton, LoadingSpinner, Player } from 'video-react';
import PropTypes from 'prop-types';
import React, { useState, useRef } from 'react';
import ReactModal from 'react-modal';
import {videoTutorial, capaVideoTutorial} from '../../utils/separadorProdutos';
import ModalMask from './modalMask';
import ModalCollar from './collar/modalCollar';
import { 
  TITULOS_MODAL_MASK,
  MENSAGENS_MODAL_MASK,
  IMAGENS_MODAL_MASK,
  MAX_PASSOS_MODAL_MASK
 } from '../../constant/modal';
import store from '../../store';
import { getProductType, getProductSource } from '../../selector';
import KeyboardEventHandler from 'react-keyboard-event-handler';

const ModalMesa1 = (
  { 
    tutorialStatus, 
    prodType, 
    updateTutorialStatus,
    updateNav,
    setStateLegenda,
    setTamanhoColeira,
    updatePopup,
  }) => 
  {
    if(tutorialStatus === 'VIDEO' || tutorialStatus === 'VIDEO-TUTORIAL')
    {
      const state = store.getState();
      const prodType = state.library.product ? getProductType(state) : '';
      const productSource = state.library.product ? getProductSource(state):'';
      const stepOnClose = productSource === 'ML' && prodType === 'mask' && tutorialStatus === 'VIDEO' ? 'PASSO':'';

      const vidRef = useRef(null);
      const [playing, setPlaying] = useState(false);

      const handlePlayVideo = () => {
        vidRef.current.play();
        setPlaying(true);
      }
      
      return(
        <ReactModal
          isOpen={tutorialStatus === "VIDEO" || tutorialStatus === 'VIDEO-TUTORIAL'}
          contentLabel="ModalMesa1"
          className="ModalMesa1"
          ariaHideApp={false}
          style={{ overlay: { background: 'transparent', position: 'initial' } }}
        >
          <KeyboardEventHandler
            handleKeys={['esc']}
            handleFocusableElements = {true}
            onKeyEvent={(key) => {
              if(key === 'esc')
              {
                updateTutorialStatus(stepOnClose);
              }
            }} 
          />

          <button className="closeModal" onClick={() => updateTutorialStatus(stepOnClose)}></button>

          {!playing && <button className="playModal" onClick={()=> handlePlayVideo()}></button>}

          <Player ref={vidRef} poster={capaVideoTutorial(prodType)} src={videoTutorial(prodType)} preload="auto">
            <LoadingSpinner />
            <BigPlayButton disabled position="center"/>
          </Player>
        </ReactModal>
      );
    }
    else if(tutorialStatus === "PASSO" || tutorialStatus === "PASSO-TUTORIAL")
      return(
        <ModalMask
          prodType = {prodType}
          tutorialStatus = {tutorialStatus}
          updateTutorialStatus = {updateTutorialStatus}
          updateNav = {updateNav}
          maxPassos = {MAX_PASSOS_MODAL_MASK}
          titulos={TITULOS_MODAL_MASK}
          mensagens={MENSAGENS_MODAL_MASK}
          imagens={IMAGENS_MODAL_MASK}
          setStateLegenda={setStateLegenda}
        />
      );
    else if(tutorialStatus === "ESCOLHER-TAMANHO-COLEIRA")
      return(
        <ModalCollar
          prodType = {prodType}
          tutorialStatus = {tutorialStatus}
          updateTutorialStatus = {updateTutorialStatus}
          updateNav = {updateNav}
          setStateLegenda={setStateLegenda}
          setTamanhoColeira={setTamanhoColeira}
          updatePopup={updatePopup}
        />
      );
    else
      return null;
}

ModalMesa1.propTypes = {
  tutorialStatus: PropTypes.string.isRequired,
  prodType: PropTypes.string.isRequired,
  updateTutorialStatus: PropTypes.func.isRequired,
  updateNav: PropTypes.func.isRequired,
  setStateLegenda: PropTypes.func.isRequired,
  setTamanhoColeira: PropTypes.func.isRequired,
  updatePopup: PropTypes.func.isRequired
};

export default ModalMesa1;