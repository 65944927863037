import { getItem, getLibrary, getSettings, getProductType } from './';

import { createSelector } from 'reselect';

import { diferencaStepper } from '../utils/separadorProdutos';

export const getSetId = createSelector(getItem, item => item.pageSetId);

export const getLastSetId = createSelector(getItem, item => item.lastPageSetId);

export const getSet = createSelector(
  getSetId,
  getLibrary,
  (setId, library) => library.set[setId]
);

export const getLastSet = createSelector(
  getLastSetId,
  getLibrary,
  (setId, library) => library.set[setId]
);

export const getDefaultSetID = createSelector(
  getSettings,
  settings => settings.pages.set.default
);

const sortPropNumber = (val1, val2) => val1.number > val2.number;
const ordenateSets = set => set.sort(sortPropNumber);

const getSetIds = createSelector(
  getSettings,
  settings => settings.pages.set.entries
);

const getSets = createSelector(getSetIds, getLibrary, (setIds, library) =>
  setIds.map(id => library.set[id])
);

export const getPageOrdenatedSetArr = createSelector(getSets, sets =>
  ordenateSets(sets)
);

export const getSetValueIndex = (set, value) => {
  for (let i = 0; i < set.length; i++) {
    if (value.id === set[i].id) {
      return i;
    }
  }
  return -1;
};

export const getSetNumber = createSelector(getSet, set => set.number);

export const getLastSetNumber = createSelector(getLastSet, set =>
  set ? set.number : 0
);

const getSetIndex = createSelector(
  getSet,
  getPageOrdenatedSetArr,
  (set, ordenatedSetArr) => getSetValueIndex(ordenatedSetArr, set)
);

const getNextPageSet = createSelector(
  getSetIndex,
  getPageOrdenatedSetArr,
  (index, sortedSet) => {
    const isInvalid = index < 0;
    const isSumInvalid = sortedSet.length < index + 2;
    const sumValue = isInvalid || isSumInvalid ? 0 : 1;

    return sortedSet[index + sumValue];
  }
);

const getPreviousPageSet = createSelector(
  getSetIndex,
  getPageOrdenatedSetArr,
  (index, sortedSet) => {
    const isSubInvalid = index < 1;
    const subValue = isSubInvalid ? 0 : 1;

    return sortedSet[index - subValue];
  }
);

export const getPreviousSetId = createSelector(
  getPreviousPageSet,
  set => set.id
);

export const getSetDif = createSelector(
  getProductType,
  getPageOrdenatedSetArr,
  getSet,
  getPreviousPageSet,
  (prodType, sets, {number: quantidadeAtual}, {number: proximaQuantidade}) => 
  {
    if(diferencaStepper.includes(prodType))
    {
      return quantidadeAtual - proximaQuantidade;
    }
    return sets.length > 1 && sets[1].number - sets[0].number;
  }
);

export const getNextSetId = createSelector(getNextPageSet, set => set.id);

export const getTotalPrice = createSelector(getItem, item => item.totalPrice);
