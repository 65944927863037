import './tamanhoColeira.css';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import Button from '../../button';
import {
  THEME_CLASS_BT_LEFT_ARROW_BLACK,
  THEME_CLASS_BT_RIGHT_ARROW_BLACK,
  THEME_CLASS_BT_LEFT_ARROW_BLACK_DISABLED,
  THEME_CLASS_BT_RIGHT_ARROW_BLACK_DISABLED
} from '../../../constant/button';

function TamanhoColeira(
  {
    tamanhos,
    tamanhoSelecionado,
    setTamanhoSelecionado
  }) {    

  const [arrowLeftDisabled, setArrowLeftDisabled] = useState(true);
  const [arrowRightDisabled, setArrowRightDisabled] = useState(false);

  const scroll = (direction) => {
    const bibliotecaScroll = document.getElementById('tamanho-coleira-scroll');
    bibliotecaScroll.scrollLeft+= (direction === 'RIGHT') ? 80 : -80;
    setArrowRightDisabled(bibliotecaScroll.scrollLeft >= (bibliotecaScroll.scrollWidth-bibliotecaScroll.clientWidth));
    setArrowLeftDisabled(bibliotecaScroll.scrollLeft <= 0);
  }

  return (
    <div className="container-tamanho-coleira">
      <div className="left-button">
        <Button
            style={{margin: '2px 2px 2px 0', width: '12px'}}
            padding={false}
            theme={arrowLeftDisabled? THEME_CLASS_BT_LEFT_ARROW_BLACK_DISABLED:THEME_CLASS_BT_LEFT_ARROW_BLACK}
            clickHandler={() => scroll('LEFT')}
            disabled={arrowLeftDisabled}
        />
      </div>
      <div id="tamanho-coleira-scroll" className="tamanho-coleiras">
        {tamanhos.map((item, index) =>
        <div className="item-rectangle"
          key={index}
          onClick={() => setTamanhoSelecionado(item)}>
            <div className={`size ${tamanhoSelecionado === item ? 'active':''}`}>{item}</div>
          </div>
        )}
      </div>
      <div className="right-button">
        <Button
          style={{margin: '2px 0 2px 2px', width: '12px'}}
            padding={false}
            theme={arrowRightDisabled? THEME_CLASS_BT_RIGHT_ARROW_BLACK_DISABLED:THEME_CLASS_BT_RIGHT_ARROW_BLACK}
            clickHandler={() => scroll('RIGHT')}
            disabled={arrowRightDisabled}
        />
      </div>
    </div>
  );
};

TamanhoColeira.propTypes = {
  tamanhos: PropTypes.arrayOf(PropTypes.number).isRequired,
  tamanhoSelecionado: PropTypes.number,
  setTamanhoSelecionado: PropTypes.func.isRequired
};

TamanhoColeira.defaultProps = {
  tamanhos: [],
};

export default TamanhoColeira;
