export const THEME_CLASS_BLUE = 'theme-blue';
export const THEME_CLASS_ORANGE = 'theme-orange';
export const THEME_CLASS_ORANGE_DISABLED = 'theme-orange-disabled';
export const THEME_CLASS_GREY = 'theme-grey';
export const THEME_CLASS_GREY_DISABLED = 'theme-grey-disabled';

export const THEME_CLASS_MENU_BOOK = 'bt-menu-book';
export const THEME_CLASS_MENU_BOOK_PRESSED = 'bt-menu-book-pressed';

export const THEME_CLASS_MENU_PHOTO = 'bt-menu-photo';
export const THEME_CLASS_MENU_PHOTO_PRESSED = 'bt-menu-photo-pressed';

export const THEME_CLASS_MENU_ADD_IMAGE = 'bt-menu-add-photo';
export const THEME_CLASS_MENU_ADD_IMAGE_ANIMATED = 'bt-menu-add-photo-animated';
export const THEME_CLASS_MENU = 'bt-menu-remove-photo';
export const THEME_CLASS_MENU_GROUP_IMAGE = 'bt-menu-group-photo';
export const THEME_CLASS_MENU_SORT_IMAGE = 'bt-menu-sort-photo';

export const THEME_CLASS_MENU_ADD_THEME_IMAGE = 'bt-menu-add-theme-photo';
export const THEME_CLASS_MENU_ADD_THEME_IMAGE_ANIMATED =
  'bt-menu-add-theme-photo-animated';

export const THEME_CLASS_MENU_GROUP_IMAGE_MASK = 'bt-menu-group-photo-mask';
export const THEME_CLASS_ICON_GROUP_ALL_PHOTOS_MASK =
  'icon-group-all-photos-mask';
export const THEME_CLASS_ICON_GROUP_USED_PHOTOS_MASK =
  'icon-group-used-photos-mask';
export const THEME_CLASS_ICON_GROUP_MULTI_USED_PHOTOS_MASK =
  'icon-group-multi-used-photos-mask';

export const THEME_CLASS_MENU_FILL_IMAGE = 'bt-menu-fill-photo';
export const THEME_CLASS_MENU_CLEAR_IMAGE = 'bt-menu-clear-photo';

export const THEME_CLASS_BT_TOP_ARROW_WHITE = 'bt-top-arrow-white';
export const THEME_CLASS_BT_BOTTOM_ARROW_WHITE = 'bt-bottom-arrow-white';

export const THEME_CLASS_BT_UP_DOUBLE_ARROW_WHITE = 'bt-up-double-arrow-white';

export const THEME_CLASS_BT_LEFT_ARROW_WHITE = 'bt-left-arrow-white';
export const THEME_CLASS_BT_LEFT_ARROW_WHITE_EXPAND =
  'bt-left-arrow-white-expand';
export const THEME_CLASS_BT_LEFT_ARROW_BLACK = 'bt-left-arrow-black';
export const THEME_CLASS_BT_LEFT_ARROW_BLACK_DISABLED =
  'bt-left-arrow-black-disabled';
export const THEME_CLASS_BT_LEFT_DOUBLE_ARROW_WHITE =
  'bt-left-double-arrow-white';

export const THEME_CLASS_BT_RIGHT_ARROW_WHITE = 'bt-right-arrow-white';
export const THEME_CLASS_BT_RIGHT_ARROW_WHITE_EXPAND =
  'bt-right-arrow-white-expand';
export const THEME_CLASS_BT_RIGHT_ARROW_WHITE_EXPAND_MODAL =
  'bt-right-arrow-white-expand-modal';
export const THEME_CLASS_BT_RIGHT_ARROW_BLACK = 'bt-right-arrow-black';
export const THEME_CLASS_BT_RIGHT_ARROW_BLACK_DISABLED =
  'bt-right-arrow-black-disabled';
export const THEME_CLASS_BT_RIGHT_DOUBLE_ARROW_WHITE =
  'bt-right-double-arrow-white';

export const THEME_CLASS_BT_UNDO_WHITE = 'bt-undo-white';
export const THEME_CLASS_BT_REDO_WHITE = 'bt-redo-white';

export const THEME_CLASS_BT_EDITOR_CLOSE = 'bt-editor-close';
export const THEME_CLASS_BT_EDITOR_CLOSE_SAVE = 'bt-editor-close-save';
export const THEME_CLASS_BT_EDITOR_REMOVE = 'bt-editor-remove';
export const THEME_CLASS_BT_EDITOR_ZOOM_IN = 'bt-editor-zoom-in';
export const THEME_CLASS_BT_EDITOR_ZOOM_OUT = 'bt-editor-zoom-out';
export const THEME_CLASS_BT_EDITOR_ROTATE = 'bt-editor-rotate';
export const THEME_CLASS_BT_EDITOR_DUPLICATE = 'bt-editor-duplicate';

export const THEME_CLASS_BT_GIRAR_MAIS_5 = 'bt-editor-girar-mais-5';
export const THEME_CLASS_BT_GIRAR_MENOS_5 = 'bt-editor-girar-menos-5';
export const THEME_CLASS_BT_GIRAR_90 = 'bt-editor-girar-90';

export const THEME_CLASS_BT_OVERVIEW_ADD = 'bt-overview-add';
export const THEME_CLASS_BT_OVERVIEW_ADD_INACTIVE = 'bt-overview-add-inactive';
export const THEME_CLASS_BT_OVERVIEW_MOVE_RIGHT = 'bt-overview-move-right';
export const THEME_CLASS_BT_OVERVIEW_MOVE_RIGHT_INACTIVE =
  'bt-overview-move-right-inactive';
export const THEME_CLASS_BT_OVERVIEW_MOVE_LEFT = 'bt-overview-move-left';
export const THEME_CLASS_BT_OVERVIEW_MOVE_LEFT_INACTIVE =
  'bt-overview-move-left-inactive';
export const THEME_CLASS_BT_EDITOR_REMOVE_INACTIVE =
  'bt-editor-remove-inactive';

export const THEME_CLASS_BT_SHUFFLE_PREVIOUS = 'bt-menu-shuffle-previous';
export const THEME_CLASS_BT_SHUFFLE_PREVIOUS_INACTIVE =
  'bt-menu-shuffle-previous-inactive';
export const THEME_CLASS_BT_MENU_STYLE_MANAGER = 'bt-menu-style-manager';
export const THEME_CLASS_BT_MENU_STYLE_MANAGER_PRESSED =
  'bt-menu-style-manager-pressed';
export const THEME_CLASS_BT_SHUFFLE_NEXT = 'bt-menu-shuffle-next';
export const THEME_CLASS_BT_SHUFFLE_NEXT_INACTIVE =
  'bt-menu-shuffle-next-inactive';

export const THEME_CLASS_ICON_SORT_ALFABETIC = 'icon-sort-alfabetic';
export const THEME_CLASS_ICON_SORT_DATE = 'icon-sort-date';

export const THEME_CLASS_ICON_GROUP_ALL_PHOTOS = 'icon-group-all-photos';
export const THEME_CLASS_ICON_GROUP_USED_PHOTOS = 'icon-group-used-photos';
export const THEME_CLASS_ICON_GROUP_MULTI_USED_PHOTOS =
  'icon-group-multi-used-photos';
export const THEME_CLASS_ICON_GROUP_NOT_USED_PHOTOS = 'icon-group-not-used';
export const THEME_CLASS_ICON_GROUP_MANUAL_PHOTOS = 'icon-group-manual-photos';

export const THEME_CLASS_ICON_CHECK = 'icon-check';

export const THEME_CLASS_ICON_TEXT_ALIGN_LEFT = 'icon-text-align-left';
export const THEME_CLASS_ICON_TEXT_ALIGN_LEFT_ACTIVE =
  'icon-text-align-left-active';
export const THEME_CLASS_ICON_TEXT_ALIGN_CENTER = 'icon-text-align-center';
export const THEME_CLASS_ICON_TEXT_ALIGN_CENTER_ACTIVE =
  'icon-text-align-center-active';
export const THEME_CLASS_ICON_TEXT_ALIGN_RIGHT = 'icon-text-align-right';
export const THEME_CLASS_ICON_TEXT_ALIGN_RIGHT_ACTIVE =
  'icon-text-align-right-active';
export const THEME_CLASS_ICON_TEXT_ALIGN_JUSTIFY = 'icon-text-align-justify';
export const THEME_CLASS_ICON_TEXT_ALIGN_JUSTIFY_ACTIVE =
  'icon-text-align-justify-active';
export const THEME_CLASS_ICON_TEXT_DECORATION_BOLD =
  'icon-text-decoration-bold';
export const THEME_CLASS_ICON_TEXT_DECORATION_BOLD_ACTIVE =
  'icon-text-decoration-bold-active';
export const THEME_CLASS_ICON_TEXT_DECORATION_ITALIC =
  'icon-text-decoration-italic';
export const THEME_CLASS_ICON_TEXT_DECORATION_ITALIC_ACTIVE =
  'icon-text-decoration-italic-active';
export const THEME_CLASS_ICON_TEXT_DECORATION_UNDERLINE =
  'icon-text-decoration-underline';
export const THEME_CLASS_ICON_TEXT_DECORATION_UNDERLINE_ACTIVE =
  'icon-text-decoration-underline-active';

export const THEME_CLASS_BT_FECHAR_BIBLIOTECA_TEMAS =
  'bt-fechar-biblioteca-temas';
export const THEME_CLASS_BT_DESSELECIONAR_BIBLIOTECA_TEMAS =
  'bt-desselecionar-biblioteca-temas';
export const THEME_CLASS_BT_SELECIONAR_BIBLIOTECA_TEMAS =
  'bt-selecionar-biblioteca-temas';

export const THEME_CLASS_LEGENDA = 'bt-bar-legenda';
export const THEME_CLASS_LEGENDA_PRESSED = 'bt-bar-legenda-pressed';
export const THEME_CLASS_LEGENDA_DISABLED = 'bt-bar-legenda-disabled';

export const THEME_CLASS_MOVER_BAIXO = 'bt-mover-baixo';
export const THEME_CLASS_MOVER_BAIXO_INACTIVE = 'bt-mover-baixo-inactive';
export const THEME_CLASS_MOVER_CIMA = 'bt-mover-cima';
export const THEME_CLASS_MOVER_CIMA_INACTIVE = 'bt-mover-cima-inactive';

export const THEME_CLASS_CONFIGURACAO = 'bt-configuracao';
export const THEME_CLASS_CONFIGURACAO_INACTIVE = 'bt-configuracao-inactive';

export const THEME_CLASS_FUNDO = 'bt-fundo';
export const THEME_CLASS_FUNDO_ACTIVE = 'bt-fundo-active';

export const THEME_CLASS_RESTORE = 'bt-bar-restore';
