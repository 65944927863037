export const POPUP_IDENTIFY = 'POPUP_IDENTIFY';
export const POPUP_IDENTIFY_EMAIL = 'POPUP_IDENTIFY_EMAIL';
export const POPUP_ID_MUTIPLE_TABS = 'POPUP_ID_MUTIPLE_TABS';
export const POPUP_SCREEN_SIZE_ERROR = 'POPUP_SCREEN_SIZE_ERROR';
export const POPUP_ID_NONE = 'POPUP_ID_NONE';
export const POPUP_ID_LOADING = 'POPUP_ID_LOADING';
export const POPUP_ID_SUBMITING = 'POPUP_ID_SUBMITING';
export const POPUP_ID_ERROR_LOAD = 'POPUP_ID_ERROR_LOAD';
export const POPUP_ID_REMOVE_ALL_IMAGES = 'POPUP_ID_REMOVE_ALL_IMAGES';
export const POPUP_ID_REMOVE_IMAGES = 'POPUP_ID_REMOVE_IMAGES';
export const POPUP_ID_REMOVE_PAGES = 'POPUP_ID_REMOVE_PAGES';
export const POPUP_ID_TOGGLE_REMOVE_PAGES = 'POPUP_ID_TOGGLE_REMOVE_PAGES';
export const POPUP_ID_CONFIRM_REMOVE_PAGES = 'POPUP_ID_CONFIRM_REMOVE_PAGES';
export const POPUP_ID_IMAGE_USED = 'POPUP_ID_IMAGE_USED';
export const POPUP_ID_FILL_PAGES_PHOTOS_NEEDED =
  'POPUP_ID_FILL_PAGES_PHOTOS_NEEDED';
export const POPUP_ID_FILL_PAGES_PAGES_NEEDED =
  'POPUP_ID_FILL_PAGES_PAGES_NEEDED';
export const POPUP_ID_CLEAR_BOOK = 'POPUP_ID_CLEAR_BOOK';
export const POPUP_ID_LAYOUT = 'POPUP_ID_LAYOUT';
export const POPUP_ID_UNABLE_LOAD_IMAGE = 'POPUP_ID_UNABLE_LOAD_IMAGE';
export const POPUP_ID_PAGE_NOT_COMPLETE_FILLED =
  'POPUP_ID_PAGE_NOT_COMPLETE_FILLED';
export const POPUP_ID_POSTER_NOT_COMPLETE_FILLED =
  'POPUP_ID_POSTER_NOT_COMPLETE_FILLED';
export const POPUP_ID_FINISH_BOOK = 'POPUP_ID_FINISH_BOOK';
export const POPUP_ID_SENDING_IMAGES = 'POPUP_ID_SENDING_IMAGES';
export const POPUP_ID_SENDING_METADATA = 'POPUP_ID_SENDING_METADATA';
export const POPUP_ID_SENDING_IMAGES_COMPLETE =
  'POPUP_ID_SENDING_IMAGES_COMPLETE';
export const POPUP_ID_UNABLE_SET_LAST_PAGE_LAYOUT =
  'POPUP_ID_UNABLE_SET_LAST_PAGE_LAYOUT';
export const POPUP_CONFIRM_UPDATE_APPLY_METHOD =
  'POPUP_CONFIRM_UPDATE_APPLY_METHOD';
export const POPUP_ID_ABANDON_PROJECT = 'POPUP_ID_ABANDON_PROJECT';
export const POPUP_ID_REVISAR_MUDACA_ORIENTACAO =
  'POPUP_ID_REVISAR_MUDACA_ORIENTACAO';
export const poupInvalidas = [
  POPUP_ID_LOADING,
  POPUP_ID_SENDING_IMAGES,
  POPUP_ID_SENDING_METADATA,
  POPUP_IDENTIFY,
  POPUP_IDENTIFY_EMAIL,
  POPUP_ID_SENDING_IMAGES_COMPLETE
];
export const POPUP_FIREFOX_ANONIMO = 'POPUP_FIREFOX_ANONIMO';
export const POPUP_ID_MAX_USER_SPACE = 'POPUP_ID_MAX_USER_SPACE';
