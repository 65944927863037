// ALL
export const ALL = 'ALL';
export const ACTIVE = 'ACTIVE';

// OPTIONS
export const ALIGN = 'ALIGN';
export const COLOR = 'COLOR';
export const SIZE = 'SIZE';
export const FAMILY = 'FAMILY';
export const FAMILY_SIZE = 'FAMILY-SIZE';

// TEXT STYLE
export const BOLD = 'BOLD';
export const ITALIC = 'ITALIC';
export const UNDERLINE = 'UNDERLINE';
export const LEFT = 'LEFT';
export const CENTER = 'CENTER';
export const RIGHT = 'RIGHT';
export const JUSTIFY = 'JUSTIFY';
