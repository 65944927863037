import { getProductCode } from '../../selector';
import store from '../../store';

export const backgroundName = (prodType, prodFormat) => {
  let bgName = 'mesa';
  let bgFormat = 'webp';

  switch (prodType) {
    case 'mask':
    case 'poster':
      bgName = 'parede';
      break;
    case 'calendar':
      if (prodFormat !== 'P') bgName = 'parede';
      break;
    case 'deck-cards':
      bgName = 'deck-cards';
      break;
    case 'dog-collar':
      const state = store.getState();
      const prodCode = state.library.product ? getProductCode(state) : '';

      if (prodCode === 'PR_75_04') bgName = 'dog-collar-preta';
      else bgName = 'dog-collar';
      break;
    case 'invitation':
      bgName = 'convite';
      break;
    default:
      break;
  }

  return `${bgName}.${bgFormat}`;
};

export const backgroundSize = (
  type,
  { photobookHeight, width, height },
  pageFormat
) => {
  switch (type) {
    case 'book':
      return {
        desktop: {
          A6: '2026px 1350px',
          A5: `auto ${calculaBookHeightA5(photobookHeight)}px`,
          A4: `auto ${calculaBookHeightA4(photobookHeight)}px`,
          A3: '759px 506px'
        },
        overview: {
          A6: '633px 422px',
          A5: '481px 321px',
          A4: '321px 214px',
          A3: '240px 160px'
        },
        imageOverview: {
          A6: '506.5px 337.5px',
          A5: '506.5px 337.5px',
          A4: '506.5px 337.5px',
          A3: '506.5px 337.5px'
        }
      };
    case 'calendar':
      return {
        desktop: {
          A4: `auto ${calculaCalendarHeightA4(photobookHeight)}px`,
          A3: `auto ${calculaCalendarHeightA3(photobookHeight)}px`,
          P: `auto ${calculaCalendarHeightP(photobookHeight)}px`
        },
        overview: {
          A4: 'auto 144px',
          A3: 'auto 112px',
          P: '963px 642px'
        },
        imageOverview: {
          A4: 'auto 154px',
          A3: 'auto 154px',
          P: '506.5px 337.5px'
        }
      };
    case 'mask':
      return {
        desktop: {
          A5: `auto ${calculaMaskHeightDesktop(photobookHeight)}px`
        },
        overview: {
          A5: `auto 136px`
        },
        imageOverview: {
          A5: 'auto 154px'
        }
      };
    case 'dog-collar':
      return {
        desktop: {
          P: `auto ${calculaCollarHeightDesktop(photobookHeight)}px`
        },
        overview: {
          P: `auto ${calculaCollarHeightDesktop(photobookHeight)}px`
        },
        imageOverview: {
          P: `auto 154px`
        }
      };
    case 'poster':
      return {
        desktop: {
          A3: `auto ${calculaPosterHeightA3(photobookHeight)}px`,
          A4: `auto ${calculaPosterHeightA4(photobookHeight)}px`
        },
        overview: {
          A3: 'auto 112px',
          A4: 'auto 144px'
        },
        imageOverview: {
          A3: 'auto 154px',
          A4: 'auto 154px'
        }
      };
    case 'deck-cards':
      return {
        desktop: {
          P: `auto ${0.666 * photobookHeight}px`
        },
        overview: {
          P: `auto 80px`
        },
        imageOverview: {
          P: 'auto 28px'
        }
      };
    case 'invitation':
      return {
        desktop: {
          A5: `auto ${photobookHeight}px`
        },
        imageOverview: {
          A5: `auto ${photobookHeight}px`
        }
      };
    default:
      return {};
  }
};

const calculaBookHeightA5 = photobookHeight => {
  return 2.25 * photobookHeight;
};

const calculaBookHeightA4 = photobookHeight => {
  return 1.5 * photobookHeight;
};

const calculaPosterHeightA3 = photobookHeight => {
  return 0.911 * photobookHeight;
};

const calculaPosterHeightA4 = photobookHeight => {
  return 1.244 * photobookHeight;
};

const calculaCalendarHeightA3 = photobookHeight => {
  return 0.911 * photobookHeight;
};

const calculaCalendarHeightA4 = photobookHeight => {
  return 1.244 * photobookHeight;
};

const calculaCalendarHeightP = photobookHeight => {
  return 4.5 * photobookHeight;
};

const calculaMaskHeightDesktop = photobookHeight => {
  return 1.1822 * photobookHeight;
};

const calculaCollarHeightDesktop = heightDefault => {
  let height = heightDefault;

  const mockpageElement = document.getElementById('dog-collar-image-fill');

  if (mockpageElement) {
    const pageCoordenates = mockpageElement.getBoundingClientRect();

    if (pageCoordenates && pageCoordenates.height > 0) {
      height = pageCoordenates.height * 10;
    }
  }

  return height;
};
