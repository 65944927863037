import * as R from 'ramda';
import * as actionGeneral from '../action/general';
import * as actionItem from '../action/item';
import * as actionUI from '../action/ui';
import { 
    all,
    put,
    select
  } from 'redux-saga/effects';

import { idbUpdateMetadata } from './index';
import { normalizePage } from '../schema/page';
import {
  mapFramesToRemove,
  addPage
} from './index';

import {
    bookHaveCover,
    getAllBookPagesData,
    getIDBStatus,
    getNavigationIndex,
    getProductType,
    getNextEmptyPage,
  } from '../selector';

import { getSetNumber } from '../selector/set';
import { deleteSingleType } from '../utils/separadorProdutos';
import { isPaginaVazia } from '../utils/calculosGerais';

export function* deleteImageFromBook({ payload: { id, pageIndex, movePage } }) {
    const state = yield select();
    const IDBStatus = getIDBStatus(state);
    const productType = getProductType(state);
    if(deleteSingleType.includes(productType) && !movePage)
        yield deleteImageMask(state, id);
    else
        yield deleteImage(state, id, pageIndex, movePage);

    if (IDBStatus) {
        yield idbUpdateMetadata();
    }
}

function* deleteImageMask(state, frameId){
    const pageIndex = getNavigationIndex(state);
    const allPages = R.prop('pages', getAllBookPagesData(state));

    const primeiraPaginaVazia = getNextEmptyPage(state);
    const maxIndex = primeiraPaginaVazia != null ? primeiraPaginaVazia.id :getSetNumber(state)-1;
    
    const pagesToChange = allPages.filter(({id, frames})=> id >= pageIndex && id <= maxIndex && frames != null && frames.length>0);
    
    yield removeFrames(pagesToChange);
    yield adicionarFramesMask(pagesToChange);

    yield put(actionGeneral.updateUsedImage(state.library.frame[frameId].image, 'DECREASE'));
    yield put(actionUI.updatePhotoEditor());
}

function* removeFrames(pagesToChange) {
  yield all(pagesToChange.map(({id, frames}) => removeFramesMask(id, frames)));
}

function* removeFramesMask(pageId, frames) {
  for(let i = 0; i < frames.length; i++){
    if(frames[i] && frames[i].id)
    {
      const frameId = frames[i].id;
      yield put(actionGeneral.removeFrameFromPage(pageId, frameId));
      yield put(actionGeneral.removeFrame(frameId));
    }
  }
}

function* adicionarFramesMask(pagesToChange) {
  if(pagesToChange.length > 1){
    for(let i = 0; i < pagesToChange.length-1; i++){
      const pagina = {...pagesToChange[i]};
      pagina.frames = [pagesToChange[i+1].frames[0]];
      const { result, entities } = normalizePage([pagina]);
      yield put(actionItem.addPages(result, entities));
    }
  }
}

export function* swapPoster({ payload: { side } }) {
  const state = yield select();
  const IDBStatus = getIDBStatus(state);
  const productType = getProductType(state);

  if(productType === 'poster'){
    const pageIndex = getNavigationIndex(state);
    const allPages = R.prop('pages', getAllBookPagesData(state));
    const newIndex = pageIndex+1;
    const mapPageIndexFrame = [];

    const currentPage = allPages.find(({id}) => id === pageIndex);
    const nextPage = allPages.find(({id}) => id === newIndex);

    if(isPaginaVazia(currentPage) && nextPage && (nextPage.frames.length > 0 || nextPage.texts.find((t) => t.richTextState.length > 0)))
    {
        allPages.forEach((page, id) => {
            if(id === pageIndex || id === newIndex){
                page.frames.map(frame =>
                    mapPageIndexFrame.push({ pageIndex: page.id, frameId: frame.id })
                );
                page.id = (id === pageIndex) ? newIndex : pageIndex;
                page.index = (id === pageIndex) ? newIndex+1 : pageIndex+1;
            }
        });

        const pagesAux = [
            allPages[newIndex],
            allPages[pageIndex],
        ];

        yield all(pagesAux.map(({ frames }) => frames.length > 0 && mapFramesToRemove(frames, mapPageIndexFrame)));
        yield all(pagesAux.map(page => addPage(page)));
        yield all(pagesAux.map(({ texts, id }) =>!texts.length && put(actionGeneral.removeTextsFromPage(id))));

        if (IDBStatus) {
          yield idbUpdateMetadata();
      }
    }
  }
}

function* deleteImage(state, id, pageIndex, isMovePage) {
    const frames = state.library.frame;
    const { pages, covers } = getAllBookPagesData(state);
    const haveCover = bookHaveCover(state);

    let pageId;
  
    if (haveCover) {
      pages.unshift(R.prop('cover', covers));
      pages.push(R.prop('back_cover', covers));
    }
  
    if (!pageIndex) {
      pages.forEach(page => {
        page.frames.map(frame => (frame.id === id ? (pageId = page.id) : null));
        if (page.frames.length > 1) {
          page.frames = R.filter(frame => id !== frame.id, page.frames);
        } else if (page.frames.length === 1) {
          if (page.frames[0].id === id) {
            page.frames = [];
          }
        }
      });
    }

    yield put(actionGeneral.removeFrameFromPage(pageIndex || pageId, id));
    yield put(actionGeneral.removeFrame(id));
  
    if (isMovePage) {
      yield put(actionItem.removePage(pageIndex || pageId));
    } else {
      yield put(actionGeneral.updateUsedImage(frames[id].image, 'DECREASE'));
    }

    if (!pageIndex) {
      yield put(actionUI.updatePhotoEditor());
    }
  }

  export function* deleteImageFramePoster(frameId, pageIndex) {
    const state = yield select();
    const IDBStatus = getIDBStatus(state);

    yield put(actionGeneral.removeFrameFromPage(pageIndex, frameId));
    yield put(actionGeneral.removeFrame(frameId));

    if (IDBStatus) {
        yield idbUpdateMetadata();
    }
  }