import PropTypes from 'prop-types';
import React from 'react';
import {
  LISTA_PRODUTOS_PERMITIDOS_TIPO1,
  LISTA_PRODUTOS_PERMITIDOS_TIPO2,
  buscaAssets,
  buscaAssetsInternos
} from '../../utils/buscaPermissoes';

const SombraInterna = ({
  width,
  height,
  prodType,
  prodCode,
  prodFormat,
  bleed,
  debru,
  isCover,
  isSpine,
  isLeftPage
}) => {
  const deslocamento = debru+bleed;
  const caminhoAsset = buscaAssets(prodCode, prodType, prodFormat);
  const caminhoAssetInternos = buscaAssetsInternos(prodCode, prodType, prodFormat);
  if(LISTA_PRODUTOS_PERMITIDOS_TIPO1.includes(prodCode) || LISTA_PRODUTOS_PERMITIDOS_TIPO2.includes(prodCode))
  {
    if(isCover)
    {
      if(!isSpine)
        return(
          <image
            x={isLeftPage ? width:0}
            y={isLeftPage ? deslocamento: deslocamento}
            style= {
              isLeftPage ? {
                transform: "rotate(180deg)",
                transformOrigin: width
              }:{}
            }
            width={width-deslocamento}
            height={height-2*deslocamento}
            pointerEvents= "none"
            href={require(`../../asset/sombra/${caminhoAssetInternos}/sombra-capa-interna.svg`)}/>
         );
      else
        return(
          <image
            y={deslocamento}
            width='100%'
            height={height-2*deslocamento}
            pointerEvents= "none"
            href={require(`../../asset/sombra/${caminhoAssetInternos}/sombra-lombada-interna.svg`)}/>
        );
    }
    else
    {
      return(
        <image
          style= {
            isLeftPage ? {
              transform: "rotate(180deg)",
              transformOrigin: "50% 50%"
            }:{}
          }
          height="100%"
          pointerEvents= "none"
          href={require(`../../asset/sombra/${caminhoAsset}/sombra-interna-miolo-direito.svg`)}/>
      );
    }
  }
  return null;
};

SombraInterna.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  prodType: PropTypes.string.isRequired,
  prodCode: PropTypes.string.isRequired,
  prodFormat: PropTypes.string.isRequired,
  bleed: PropTypes.number.isRequired,
  debru: PropTypes.number.isRequired,
  isCover: PropTypes.bool.isRequired,
  isSpine: PropTypes.bool.isRequired,
  isLeftPage: PropTypes.bool.isRequired,
};

export default SombraInterna;