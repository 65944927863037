// export function styleOverviewPhotobookPadding(width)
// {
//     const paddingTop = width * 0.016 - 10; 
//     const paddingRight = width * 0.015;
//     const padding = `${paddingTop}px ${paddingRight}px 0 ${paddingRight}px`;
//     console.log('styleOverviewPhotobookPadding', padding)
//     return { padding };
// }

import {ajustePaddingOverview} from './calculosGerais';

const ajusteTamanhoMaxWidthColeira = 0.98;
const espacamentoMinOverview = 0.023;

export function styleOverviewContainer(prodType, index, width, widthOverviewShadow, quantidadeRemocaoMargem, addMarginBottomNavigator)
{
    let marginTop = 2;
    let marginRight = 0;

    if(prodType !== 'dog-collar'){
        const photobook = document.getElementById('photobook-id');
        const photobookBoundary = photobook.getBoundingClientRect();
        const widthPhotobook = photobookBoundary.width*(1 - 2*ajustePaddingOverview);
        const quantidadeMaxColunas = Math.floor(widthPhotobook/(widthOverviewShadow+espacamentoMinOverview*widthPhotobook));
        const ultimaPagina = index%quantidadeMaxColunas === 0 ? 0:1;
        let marginRightCalc = quantidadeMaxColunas === 1 ? 0: (widthPhotobook-(quantidadeMaxColunas*widthOverviewShadow))/(quantidadeMaxColunas-1); 
        marginRight = ultimaPagina * (marginRightCalc+quantidadeRemocaoMargem); 
        marginTop = 0;
    }
    const marginBottom = width * 0.013 *addMarginBottomNavigator;
    const margin = `${marginTop}px ${marginRight}px ${marginBottom}px 0`;
    return { margin };
}

export function calculaWidthOverviewShadow(prodType, finalPageWidth, finalPageHeight, height, lines, finalCol, isSpineIndex, spineWidth)
{
    let width = (finalPageWidth * (finalCol - (isSpineIndex ? 1 : 0)) + (isSpineIndex ? spineWidth : 0)) / ((finalPageHeight * lines) / height);
    if(prodType === 'dog-collar')
        width = '100%';
    return width;
}

export function calculaDafaultWidthOverviewShadow(finalPageWidth, finalPageHeight, height, lines, finalCol)
{
    return (finalPageWidth * finalCol) / ((finalPageHeight * lines) / height);
}

export function calculaHeightOverviewShadow(prodType)
{
    let height = '120px';
    if(prodType === 'dog-collar')
        height = '100%';
    return height;
}

export function calculaWidthOverviewIndex(prodType, finalPageWidth, finalPageHeight, screenSizeWidth, height, lines, finalCol, isSpineIndex, spineWidth)
{
    const maxWidth = screenSizeWidth * ajusteTamanhoMaxWidthColeira;
    const width = (finalPageWidth * (finalCol - (isSpineIndex ? 1 : 0)) + (isSpineIndex ? spineWidth : 0)) / ((finalPageHeight * lines) / height);
    return width > maxWidth ? maxWidth : width;
}

export const calculaOverviewWidth = (width) =>
{
    return width * 0.993;
}

export const calculaBookNavigatorWidth = (width, height, lines, cols) =>
{
    const maxWidth = calcMaxNavigatorLine(width);
    const bookNavigatorWidth = (width * cols) / ((height * lines) / 120) + width * 0.023;
    return bookNavigatorWidth > maxWidth ? maxWidth : bookNavigatorWidth;
}

export const calcMaxNavigatorLine = (prodType, overviewWidth, bookNavigatorWidth) =>
{
    let aux = Math.floor(overviewWidth / bookNavigatorWidth);
    if(prodType === 'dog-collar')
        aux = 1;
    return aux;
}

export const calcMarginLeftNavigator = (prodType, overviewWidth, bookNavigatorWidth, maxNavigatorLine) =>
{
    let aux = (overviewWidth - bookNavigatorWidth * maxNavigatorLine) / (maxNavigatorLine - 1);
    if(prodType === 'dog-collar')
        aux = 0;
    return aux;
}

export const calcBottomPagesNumber = (pagesLength, maxNavigatorLine) =>
{
    const bottomPagesNumber = pagesLength % maxNavigatorLine === 0
      ? maxNavigatorLine
      : pagesLength % maxNavigatorLine;
    return bottomPagesNumber;
}

export const calcBottomPages = (bottomPagesNumber, pagesLength) =>
{
    const bottomPages = [];
    for (let i = 1; i <= bottomPagesNumber; i++) {
        bottomPages.push(pagesLength - i);
    }
    return bottomPages;
}

export const calcMarginBottomNavigator = (prodType, bottomPages, i) =>{
    let aux = bottomPages.includes(i) ? 0 : 1; 
    if(prodType === 'dog-collar')
        aux = 1;
    return aux;
}
