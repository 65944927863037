import './index.css';

import {
  THEME_CLASS_BT_EDITOR_REMOVE,
  THEME_CLASS_BT_EDITOR_REMOVE_INACTIVE,
  THEME_CLASS_BT_OVERVIEW_ADD,
  THEME_CLASS_BT_OVERVIEW_ADD_INACTIVE,
  THEME_CLASS_BT_OVERVIEW_MOVE_LEFT,
  THEME_CLASS_BT_OVERVIEW_MOVE_LEFT_INACTIVE,
  THEME_CLASS_BT_OVERVIEW_MOVE_RIGHT,
  THEME_CLASS_BT_OVERVIEW_MOVE_RIGHT_INACTIVE,
  THEME_CLASS_MOVER_BAIXO,
  THEME_CLASS_MOVER_BAIXO_INACTIVE,
  THEME_CLASS_MOVER_CIMA,
  THEME_CLASS_MOVER_CIMA_INACTIVE
} from '../../constant/button';
import { navigatorPropTypes, screenSizePropTypes } from '../../proptypes';

import {
  getTipoQuantidadePadraoMascara,
  getOverviewMover
} from '../../utils/textosVariaveis';

import Button from '../button';
import { NAV_NONE_SELECTED } from '../../constant/nav';
import { noOverviewOptions } from '../../utils/separadorProdutos';
import { POPUP_ID_TOGGLE_REMOVE_PAGES } from '../../constant/popup';
import { POPUP_TOGGLE_REMOVE_PAGES } from '../../constant/popup-message';
import PropTypes from 'prop-types';
import React from 'react';
import {
  inactiveTypes,
  overviewMove,
  moverVertical
} from '../../utils/separadorProdutos';

const checkMoveInactive = (
  productType,
  type,
  index,
  maxIndex,
  bookHaveCover,
  paginaVazia,
  proximaPaginaVazia,
  paginaAnteriorVazia
) => {
  //TODO corrigir essa bagaça
  if (!overviewMove.includes(productType)) {
    const halfMaxIndex = maxIndex / 2;
    const inactive = {
      left: [0, 1, bookHaveCover ? halfMaxIndex : halfMaxIndex - 1],
      right: [
        0,
        halfMaxIndex - 1,
        bookHaveCover ? halfMaxIndex : halfMaxIndex - 2
      ]
    };
    bookHaveCover && inactive.left.push(2) && inactive.right.push(1);
    return inactive[type.toLowerCase()].includes(index);
  }

  return paginaVazia ||
    maxIndex === 1 ||
    (type === 'RIGHT' && proximaPaginaVazia) ||
    (type === 'LEFT' && paginaAnteriorVazia)
    ? true
    : false;
};

const verificaPaginaVazia = (productType, allPagesData, pageIndex) => {
  let aux = true;

  if (
    allPagesData != null &&
    allPagesData.pages != null &&
    allPagesData.pages.length > 0 &&
    pageIndex != null
  ) {
    const paginaAtual = allPagesData.pages.find(({ id }) => id === pageIndex);

    if (productType === 'dog-collar')
      aux =
        paginaAtual == null ||
        paginaAtual.texts == null ||
        paginaAtual.texts.length <= 0 ||
        paginaAtual.texts.find(t => t.richTextState.length > 0) == null;
    else
      aux =
        paginaAtual == null ||
        paginaAtual.frames == null ||
        paginaAtual.frames.length <= 0;
  }

  return aux;
};

const BookOverviewOptions = ({
  addPages,
  allPagesData,
  bookHaveCover,
  currentNav,
  movePage,
  nextSetId,
  overviewRemovePages,
  pageIndex,
  previousSetId,
  productType,
  setId,
  setDif,
  screenSize,
  toggleRemovePages,
  updatePopup
}) => {
  const { active } = overviewRemovePages;
  const isInactive = inactiveTypes.includes(productType);
  const maxIndex =
    allPagesData != null &&
      allPagesData.pages != null &&
      allPagesData.pages.length > 0
      ? allPagesData.pages.length - 1
      : null;
  const paginaVazia = verificaPaginaVazia(productType, allPagesData, pageIndex);
  const proximaPaginaVazia = verificaPaginaVazia(
    productType,
    allPagesData,
    pageIndex >= maxIndex ? 0 : pageIndex + 1
  );
  const paginaAnteriorVazia = verificaPaginaVazia(
    productType,
    allPagesData,
    pageIndex === 0 ? maxIndex : pageIndex - 1
  );

  if (noOverviewOptions(productType))
    return (
      <div
        id="bookOverview"
        style={{
          paddingTop: currentNav === NAV_NONE_SELECTED ? '14px' : '108px'
        }}
      />
    );

  return (
    <div
      id="bookOverview"
      style={{
        paddingTop: currentNav === NAV_NONE_SELECTED ? '14px' : '108px'
      }}
    >
      <div className="bookOverview-options-container">
        <div
          className="bookOverview-options"
          style={{
            marginRight: `${screenSize.width * 0.018}px`,
            opacity: setId === nextSetId || active || isInactive ? 0.25 : 1
          }}
        >
          <Button
            padding={false}
            theme={
              setId === nextSetId || active || isInactive
                ? THEME_CLASS_BT_OVERVIEW_ADD_INACTIVE
                : THEME_CLASS_BT_OVERVIEW_ADD
            }
            clickHandler={
              setId === nextSetId || active || isInactive ? null : addPages
            }
          />
          <span>{`Adicionar ${getTipoQuantidadePadraoMascara(true)}`}</span>
        </div>
        <div
          className="bookOverview-options"
          style={{
            marginRight: `${screenSize.width * 0.018}px`,
            opacity:
              checkMoveInactive(
                productType,
                'LEFT',
                pageIndex,
                allPagesData.pages.length,
                bookHaveCover,
                paginaVazia,
                proximaPaginaVazia,
                paginaAnteriorVazia
              ) ||
                active ||
                isInactive
                ? 0.25
                : 1
          }}
        >
          <Button
            padding={false}
            theme={
              checkMoveInactive(
                productType,
                'LEFT',
                pageIndex,
                allPagesData.pages.length,
                bookHaveCover,
                paginaVazia,
                proximaPaginaVazia,
                paginaAnteriorVazia
              ) ||
                active ||
                isInactive
                ? moverVertical.includes(productType)
                  ? THEME_CLASS_MOVER_CIMA_INACTIVE
                  : THEME_CLASS_BT_OVERVIEW_MOVE_LEFT_INACTIVE
                : moverVertical.includes(productType)
                  ? THEME_CLASS_MOVER_CIMA
                  : THEME_CLASS_BT_OVERVIEW_MOVE_LEFT
            }
            clickHandler={() =>
              checkMoveInactive(
                productType,
                'LEFT',
                pageIndex,
                allPagesData.pages.length,
                bookHaveCover,
                paginaVazia,
                proximaPaginaVazia,
                paginaAnteriorVazia
              ) ||
                active ||
                isInactive
                ? null
                : movePage('LEFT')
            }
          />
          <span>{getOverviewMover(false)}</span>
        </div>
        <div
          className="bookOverview-options"
          style={{
            marginRight: `${screenSize.width * 0.018}px`,
            opacity:
              checkMoveInactive(
                productType,
                'RIGHT',
                pageIndex,
                allPagesData.pages.length,
                bookHaveCover,
                paginaVazia,
                proximaPaginaVazia,
                paginaAnteriorVazia
              ) ||
                active ||
                isInactive
                ? 0.25
                : 1
          }}
        >
          <Button
            padding={false}
            theme={
              checkMoveInactive(
                productType,
                'RIGHT',
                pageIndex,
                allPagesData.pages.length,
                bookHaveCover,
                paginaVazia,
                proximaPaginaVazia,
                paginaAnteriorVazia
              ) ||
                active ||
                isInactive
                ? moverVertical.includes(productType)
                  ? THEME_CLASS_MOVER_BAIXO_INACTIVE
                  : THEME_CLASS_BT_OVERVIEW_MOVE_RIGHT_INACTIVE
                : moverVertical.includes(productType)
                  ? THEME_CLASS_MOVER_BAIXO
                  : THEME_CLASS_BT_OVERVIEW_MOVE_RIGHT
            }
            clickHandler={() =>
              checkMoveInactive(
                productType,
                'RIGHT',
                pageIndex,
                allPagesData.pages.length,
                bookHaveCover,
                paginaVazia,
                proximaPaginaVazia,
                paginaAnteriorVazia
              ) ||
                active ||
                isInactive
                ? null
                : movePage('RIGHT')
            }
          />
          <span>{getOverviewMover()}</span>
        </div>
        <div
          className="bookOverview-options"
          style={{
            opacity: setId === previousSetId || isInactive ? 0.25 : 1
          }}
        >
          <Button
            padding={false}
            theme={
              setId === previousSetId || isInactive
                ? THEME_CLASS_BT_EDITOR_REMOVE_INACTIVE
                : THEME_CLASS_BT_EDITOR_REMOVE
            }
            clickHandler={() =>
              active
                ? toggleRemovePages()
                : setId !== previousSetId &&
                !isInactive &&
                updatePopup(
                  POPUP_ID_TOGGLE_REMOVE_PAGES,
                  true,
                  POPUP_TOGGLE_REMOVE_PAGES(setDif)
                )
            }
          />
          <span>{`Remover ${getTipoQuantidadePadraoMascara(true)}`}</span>
        </div>
      </div>
    </div>
  );
};

BookOverviewOptions.propTypes = {
  addPages: PropTypes.func.isRequired,
  allPagesData: navigatorPropTypes,
  bookHaveCover: PropTypes.bool.isRequired,
  currentNav: PropTypes.string.isRequired,
  movePage: PropTypes.func.isRequired,
  nextSetId: PropTypes.string.isRequired,
  overviewRemovePages: PropTypes.shape({
    active: PropTypes.bool.isRequired,
    pages: PropTypes.arrayOf(PropTypes.number).isRequired
  }).isRequired,
  pageIndex: PropTypes.number.isRequired,
  previousSetId: PropTypes.string.isRequired,
  productType: PropTypes.string.isRequired,
  setId: PropTypes.string.isRequired,
  setDif: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  screenSize: screenSizePropTypes,
  toggleRemovePages: PropTypes.func.isRequired,
  updatePopup: PropTypes.func.isRequired
};

export default BookOverviewOptions;
