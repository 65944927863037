import PropTypes from 'prop-types';
import React from 'react';
import WarningIcon from '../../../asset/icone_atencao.png';
import { positionPropTypes } from '../../../proptypes';
import { getAlertaBaixaQualidade } from '../../../utils/textosVariaveis';

const WarnIcon = ({
  id,
  width,
  height,
  screenRate,
  printSize,
  changePos,
  bleed,
  type,
  debru,
  photoEditorActive,
  updateWarning,
  prodType,
  prodFormat
}) => {
  const warnSize =
    width / screenRate < 24 || height / screenRate < 24
      ? Math.min(width, height)
      : screenRate * 24;

  const y =
    prodType === 'calendar' && prodFormat === 'P'
      ? height - warnSize
      : changePos.top
        ? debru
        : 0;

  return (
    <image
      href={WarningIcon}
      y={y}
      x={
        (type === 'ellipse' ? width / 2 - 12 * screenRate : 0) +
        (changePos.left ? debru : 0)
      }
      style={{
        width: `${warnSize}px`,
        height: `${warnSize}px`,
        display:
          printSize.height <
            height + (prodType === 'mask' ? 0 : bleed.top + bleed.bottom) ||
            printSize.width <
            width + (prodType === 'mask' ? 0 : bleed.left + bleed.right)
            ? 'block'
            : 'none',
        opacity: photoEditorActive ? 0.25 : 1
      }}
      className={`warning-icon cursor-help ${id}`}
      onMouseEnter={() =>
        !photoEditorActive && updateWarning(true, getAlertaBaixaQualidade())
      }
      onMouseLeave={() => updateWarning()}
    />
  );
};

WarnIcon.propTypes = {
  id: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  screenRate: PropTypes.number.isRequired,
  printSize: PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired
  }).isRequired,
  changePos: PropTypes.shape({
    top: PropTypes.bool.isRequired,
    left: PropTypes.bool.isRequired
  }).isRequired,
  bleed: positionPropTypes,
  type: PropTypes.string.isRequired,
  debru: PropTypes.number.isRequired,
  photoEditorActive: PropTypes.bool.isRequired,
  updateWarning: PropTypes.func.isRequired,
  prodFormat: PropTypes.string.isRequired,
  prodType: PropTypes.string.isRequired
};

export default WarnIcon;
