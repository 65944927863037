import {
  bookHaveCover,
  getAllBookPagesData,
  getBookNavigatorData,
  getDragId,
  getEanUrl,
  getFormats,
  getSelectedProduct,
  getIsBookOverviewExpanded,
  getIsDragging,
  getIsGalleryExpanded,
  getIsMenuTextSelected,
  getItemIs3D,
  getNavigationIndex,
  getOverviewRemovePages,
  getPDFCoverLength,
  getPhotoEditor,
  getPrintOptions,
  getProductCode,
  getProductFormat,
  getProductType,
  getIsModoImpressao,
  getIsModoSobFivela,
  getScreenRate,
  getScreenSize,
  getSetSpineMargin,
  getSpineIndex,
  getTextOptions,
  isPrint,
  isThumb,
  getCurrentNav,
  getTamanhoColeira,
  getProductStyles,
  getTipoFundo,
  getFundoColor,
  getLayoutsFilter,
} from '../../selector';
import {
  moveImage,
  sagaOverviewRemovePages,
  sagaSetRichText,
  sagaThumbDropImage
} from '../../action/general';
import { 
  setPageIndex,
  unsetPagesToRemove
 } from '../../action/item';

import {
  startDrag,
  stopDrag,
  updateBookOverviewExpanded,
  updatePhotoEditor,
  updateScreenRate,
  updateSelectedNav,
  updateTextOptions,
  updateWarning,
  setStateLegenda
} from '../../action/ui';

import { NAV_TEXT_SELECTED } from '../../constant/nav';
import Photobook from '../../component/photobook';
import { connect } from 'react-redux';
import { getSetNumber } from '../../selector/set';
import store from '../../store';

const getXY = (event, isTouch) => {
  if (isTouch) {
    return {
      x: event.nativeEvent.targetTouches[0].pageX,
      y: event.nativeEvent.targetTouches[0].pageY
    };
  }
  return { x: event.nativeEvent.pageX, y: event.nativeEvent.pageY };
};

const normalizePos = (pos, dim, shapeDim, rotate, bleed) => {
  const { top, bottom, left, right } = bleed;
  const minX = 0 - left;
  const minY = 0 - top;
  const maxX =
    right +
    (rotate === 0 || rotate === 180
      ? Math.abs(shapeDim.width - dim.width) * -1
      : Math.abs(shapeDim.height - dim.height) * -1);
  const maxY =
    bottom +
    (rotate === 0 || rotate === 180
      ? Math.abs(shapeDim.height - dim.height) * -1
      : Math.abs(shapeDim.width - dim.width) * -1);

  let result = { x: pos.x, y: pos.y };
  if (result.x > minX) {
    result = { x: minX, y: result.y };
  }
  if (result.y > minY) {
    result = { x: result.x, y: minY };
  }

  if (result.x < maxX) {
    result = { x: maxX, y: result.y };
  }

  if (result.y < maxY) {
    result = { x: result.x, y: maxY };
  }

  return result;
};

const fixPos = (dispatch, pos, dim, shapeDim, id, rotate, bleed) => {
  const normPos = normalizePos(
    { x: pos.x, y: pos.y },
    dim,
    shapeDim,
    rotate,
    bleed
  );
  dispatch(moveImage(id, normPos.x, normPos.y));
};

const moveHandler = (
  dispatch,
  event,
  isTouch,
  dim,
  shapeDim,
  id,
  rotate,
  bleed
) => {
  event.preventDefault();
  const state = store.getState();
  const currentMousePos = getXY(event, isTouch);
  const isDragging = getIsDragging(state);

  if (isDragging) {
    const { mousePos, pos } = state.ui.drag.start;

    if (currentMousePos.x !== mousePos.x || currentMousePos.y !== mousePos.y) {
      const photoPosX = pos.x + (currentMousePos.x - mousePos.x) * 2;
      const photoPosY = pos.y + (currentMousePos.y - mousePos.y) * 2;

      const normPos = normalizePos(
        { x: photoPosX, y: photoPosY },
        dim,
        shapeDim,
        rotate,
        bleed
      );

      dispatch(moveImage(id, normPos.x, normPos.y));
    }
  }
};

const moveKeyboardHandler = (
  dispatch,
  direction,
  step,
  pos,
  dim,
  shapeDim,
  id,
  rotate,
  bleed
) => {
  let photoPosX = pos.x;
  let photoPosY = pos.y;

  switch (direction) {
    case "up":
      photoPosY-=step;
      break;
    case "down":
      photoPosY+=step;
      break;
    case "right":
      photoPosX+=step;
      break;
    case "left":
      photoPosX-=step;
      break;
    default:
      break;
  }

  const normPos = normalizePos(
    { x: photoPosX, y: photoPosY },
    dim,
    shapeDim,
    rotate,
    bleed
  );

  dispatch(moveImage(id, normPos.x, normPos.y));
}

const mouseStartHandler = (dispatch, event, pos, isTouch, id) => {
  event.preventDefault();
  const { x, y } = getXY(event, isTouch);
  dispatch(startDrag(id, pos, { x, y }));
};

const textChange = (dispatch, id, pageId, textState, isFirst, x, y, l, a) => {
  dispatch(sagaSetRichText(id, pageId, textState, isFirst, false, x, y, l, a));
};

const mapStateToProps = state => ({
  screenSize: getScreenSize(state),
  screenRate: getScreenRate(state),
  isPrint: isPrint(state),
  isThumb: isThumb(state),
  isScodix: getItemIs3D(state),
  spineIndex: getSpineIndex(state),
  printOptions: getPrintOptions(state),
  spineMargin: getSetSpineMargin(state),
  pageIndex: getNavigationIndex(state),
  isGalleryExpanded: getIsGalleryExpanded(state),
  isBookOverviewExpanded: getIsBookOverviewExpanded(state),
  navigatorData: getBookNavigatorData(state),
  allPagesData: getAllBookPagesData(state),
  isDragging: getIsDragging(state),
  dragId: getDragId(state),
  pagesFormats: getFormats(state),
  selectedProduct: getSelectedProduct(state),
  isMenuTextSelected: getIsMenuTextSelected(state),
  textOptions: getTextOptions(state),
  photoEditor: getPhotoEditor(state),
  overviewRemovePages: getOverviewRemovePages(state),
  setNumber: getSetNumber(state),
  bookHaveCover: bookHaveCover(state),
  coverLengthPDF: getPDFCoverLength(state),
  ean: getEanUrl(state),
  prodCode: getProductCode(state),
  productType: getProductType(state),
  productFormat: getProductFormat(state),
  currentNav: getCurrentNav(state),
  isModoImpressao: getIsModoImpressao(state),
  isModoSobFivela: getIsModoSobFivela(state),
  tamanhoSelecionado: getTamanhoColeira(state),
  styles: getProductStyles(state),
  tipoFundo: getTipoFundo(state),
  fundoColor: getFundoColor(state),
  layoutsFilter: getLayoutsFilter(state),
});

const mapDispatchToProps = dispatch => ({
  onShapeDrop: (thumbId, shapeId, pageIndex, pageId, styleId) =>
    dispatch(sagaThumbDropImage(thumbId, shapeId, pageIndex, pageId, styleId)),
  mouseStartHandler: (event, pos, isTouch, id) =>
    mouseStartHandler(dispatch, event, pos, isTouch, id),
  mouseStopHandler: () => dispatch(stopDrag()),
  moveHandler: (event, isTouch, dim, shapeDim, id, rotate, bleed) =>
    moveHandler(dispatch, event, isTouch, dim, shapeDim, id, rotate, bleed),
  moveKeyboardHandler: (direction, step, pos, dim, shapeDim, id, rotate, bleed) =>
    moveKeyboardHandler(dispatch, direction, step, pos, dim, shapeDim, id, rotate, bleed),
  setPageIndex: index => dispatch(setPageIndex(index)),
  fixPos: (pos, dim, shapeDim, id, rotate, bleed) =>
    fixPos(dispatch, pos, dim, shapeDim, id, rotate, bleed),
  textChangeHandler: (id, pageId, textState, isFirst, x, y, l, a) =>
    textChange(dispatch, id, pageId, textState, isFirst, x, y, l, a),
  toggleNavText: () => dispatch(updateSelectedNav(NAV_TEXT_SELECTED)),
  updateTextOptions: (option, value, pageId) =>
    dispatch(updateTextOptions(option, value, pageId)),
  updatePhotoEditor: id => dispatch(updatePhotoEditor(id)),
  updateScreenRate: rate => dispatch(updateScreenRate(rate)),
  updateWarning: (value, text) => dispatch(updateWarning(value, text)),
  toggleBookOverviewExpanded: isBookOverviewExpanded =>
    dispatch(updateBookOverviewExpanded(!isBookOverviewExpanded)),
  setPageToRemove: pages => dispatch(sagaOverviewRemovePages(pages)),
  unsetPageToRemove: pages => dispatch(unsetPagesToRemove(pages)),
  setStateLegenda: state => dispatch(setStateLegenda(state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Photobook);
