export default function calculoRotate(anguloInicial = 0, quantidade)
{
    let rotate = 0;
    if(quantidade === 90)
    {
        const result = anguloInicial/90;
        switch (true) {
            case (result >= 0 && result < 1):
                rotate = 90;
                break;
            case (result >= 1 && result < 2):
                rotate = 180;
                break;
            case (result >= 2 && result < 3):
                rotate = 270;
                break;
            default:
                rotate = 0;
                break;
        }
    }
    else
        rotate = (anguloInicial + quantidade >= 360) ? 0 : 
            (anguloInicial + quantidade < 0) ? 
                anguloInicial + quantidade + 360: 
                anguloInicial + quantidade;

    return rotate;
}
