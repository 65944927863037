import './index.css';

import {
  THEME_CLASS_MENU_CLEAR_IMAGE,
  THEME_CLASS_MENU_FILL_IMAGE
} from '../../constant/button';

import NavItem from '../nav-item';
import PropTypes from 'prop-types';
import React from 'react';
import ReactTooltip from 'react-tooltip';
import { getRetiraImagens, getDataTipLimpar } from '../../utils/textosVariaveis';
import { screenSizePropTypes } from '../../proptypes';

const NavBook = ({
  screenSize,
  bookHaveImage,
  bookHaveText,
  insertImagesToBook,
  removeImagesFromBook,
  prodType
}) => (
  <div className="nav-book">
    {prodType !== 'dog-collar' && <NavItem
      theme={THEME_CLASS_MENU_FILL_IMAGE}
      label="Preencher"
      clickHandler={insertImagesToBook}
      screenSize={screenSize}
      dataTip={`Insere automaticamente todas as imagens ${getRetiraImagens()}`}
    />}
    <NavItem
      theme={THEME_CLASS_MENU_CLEAR_IMAGE}
      label="Limpar"
      clickHandler={() => ((prodType === 'dog-collar' && bookHaveText) || bookHaveImage) && removeImagesFromBook()}
      screenSize={screenSize}
      dataTip={getDataTipLimpar()}
      disabled={!((prodType === 'dog-collar' && bookHaveText) || bookHaveImage)}
    />
    <ReactTooltip />
  </div>
);

NavBook.propTypes = {
  screenSize: screenSizePropTypes,
  bookHaveImage: PropTypes.bool.isRequired,
  bookHaveText: PropTypes.bool.isRequired,
  insertImagesToBook: PropTypes.func.isRequired,
  removeImagesFromBook: PropTypes.func.isRequired,
  prodType: PropTypes.string.isRequired,
};

export default NavBook;
