import './index.css';
import '../../asset/atlas/atlas.css';
import '../../asset/icons/icons.css';

import React, { useState } from 'react';

import Coupon from '../../container/coupon';
import ErrorAlert from '../error-alert';
import Header from '../../container/header';
import MenuBook from '../../container/menu-book';
import ModalMesa1 from '../../component/modal';
import NavManager from '../../container/nav-manager';
import Photobook from '../../container/photobook';
import PopupManager from '../../container/popup-manager';
import PropTypes from 'prop-types';
import ToolbarPhoto from '../../container/toolbar-photo';
import Warning from '../../container/warning';
import { backgroundSize, backgroundName } from './backgroundSize';
import BibliotecaTemas from '../biblioteca-temas';
import { bibliotecaTemaPropTypes } from '../../proptypes';
import Legenda from '../legenda';
import BotaoLegenda from '../legenda/botaoLegenda';
import { photoEditorPropTypes, screenSizePropTypes } from '../../proptypes';
import { haveToolbarPhoto, haveLegenda } from '../../utils/separadorProdutos';
import CustomProductContext from '../../contexts/customProductContext';
import ModalConfig from '../../component/modal/change-format/modalConfig';
import ModalChangeFormat from '../modal/change-format/modalChangeFormat';
import BotaoRestore from '../restore';

const CouponProduct = ({ productCanvasSize }) => (
  <div className="product">
    <Header showTooltips />
    <Coupon width="100%" height={productCanvasSize.height} />
    <PopupManager />
  </div>
);

CouponProduct.propTypes = {
  productCanvasSize: PropTypes.shape({
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }).isRequired
};

const getBgName = (prodType, prodFormat) => {
  return backgroundName(prodType, prodFormat);
};

function PhotobookProduct({
  tutorialStatus,
  updateTutorialStatus,
  updateNav,
  isGalleryExpanded,
  isBookOverviewExpanded,
  pageFormat,
  productFormat,
  productType,
  photobookHeight,
  isPrint,
  bibliotecaTemasOpened,
  bibliotecaTemas,
  toggleBibliotecaTemas,
  addThemeImages,
  photoEditor,
  currentNav,
  toggleLegenda,
  isLegendaAberta,
  setStateLegenda,
  setTamanhoColeira,
  stateLegenda,
  screenSize,
  popupId,
  updatePopup,
  products,
  userProducts,
  setModalConfig,
  modalConfig,
  postMetadataFormat,
  postMetadataOrientation,
  itemId,
  isRevisarMudancaOrientacao,
  toggleRevisarMudancaOrientacao
}) {
  const [listaProdutos] = useState(products);
  const [listaProdutosUsuario] = useState(userProducts);

  return (
    <CustomProductContext.Provider
      value={{ listaProdutos, listaProdutosUsuario }}
    >
      <div
        className="product"
        style={{
          backgroundSize: isGalleryExpanded
            ? backgroundSize(productType, screenSize, pageFormat).imageOverview[
            pageFormat
            ]
            : isBookOverviewExpanded
              ? backgroundSize(productType, screenSize, pageFormat).overview[
              pageFormat
              ]
              : backgroundSize(productType, screenSize, pageFormat).desktop[
              pageFormat
              ],
          backgroundImage: isPrint
            ? ''
            : `url(${require(`../../asset/bg-${getBgName(
              productType,
              pageFormat
            )}`)})`
        }}
      >
        <Header />
        <NavManager />
        {haveToolbarPhoto(
          productType,
          isBookOverviewExpanded,
          photoEditor.active
        ) && <ToolbarPhoto />}
        <Photobook />
        <MenuBook />
        {!isPrint && <PopupManager />}
        <Warning></Warning>
        {!isPrint && process.env.NODE_ENV !== 'development' && (
          <ModalMesa1
            tutorialStatus={tutorialStatus}
            prodType={productType}
            updateTutorialStatus={updateTutorialStatus}
            updateNav={updateNav}
            setStateLegenda={setStateLegenda}
            setTamanhoColeira={setTamanhoColeira}
            updatePopup={updatePopup}
          />
        )}
        {!isPrint && bibliotecaTemasOpened && bibliotecaTemas.length && (
          <BibliotecaTemas
            prodType={productType}
            bibliotecaTemas={bibliotecaTemas}
            toggleBibliotecaTemas={toggleBibliotecaTemas}
            addThemeImages={addThemeImages}
            toggleLegenda={toggleLegenda}
            isLegendaAberta={isLegendaAberta}
            setStateLegenda={setStateLegenda}
          />
        )}
        {process.env.NODE_ENV === 'development' && (
          <BotaoRestore itemId={itemId} screenSize={screenSize} />
        )}
        {!isPrint && haveLegenda(productType) && (
          <BotaoLegenda
            prodType={productType}
            stateLegenda={stateLegenda}
            isBookOverviewExpanded={isBookOverviewExpanded}
            currentNav={currentNav}
            toggleLegenda={toggleLegenda}
            isLegendaAberta={isLegendaAberta}
            screenSize={screenSize}
            popupId={popupId}
            tutorialStatus={tutorialStatus}
            isGalleryExpanded={isGalleryExpanded}
            isPhotoEditorActive={photoEditor.active}
          />
        )}
        {!isPrint && haveLegenda(productType) && (
          <Legenda
            isBookOverviewExpanded={isBookOverviewExpanded}
            isGalleryExpanded={isGalleryExpanded}
            isPhotoEditorActive={photoEditor.active}
            currentNav={currentNav}
            toggleLegenda={toggleLegenda}
            isLegendaAberta={isLegendaAberta}
            setStateLegenda={setStateLegenda}
            stateLegenda={stateLegenda}
            prodType={productType}
          />
        )}
        {productType === 'poster' && (
          <ModalConfig
            prodType={productType}
            setModalConfig={setModalConfig}
            modalConfig={modalConfig}
            pageFormat={pageFormat}
            productFormat={productFormat}
            updatePopup={updatePopup}
            postMetadataFormat={postMetadataFormat}
            postMetadataOrientation={postMetadataOrientation}
            itemId={itemId}
          />
        )}
        {productType === 'poster' && (
          <ModalChangeFormat
            prodType={productType}
            setModalConfig={setModalConfig}
            modalConfig={modalConfig}
            pageFormat={pageFormat}
            productFormat={productFormat}
            updatePopup={updatePopup}
            postMetadataFormat={postMetadataFormat}
            postMetadataOrientation={postMetadataOrientation}
            itemId={itemId}
            isRevisarMudancaOrientacao={isRevisarMudancaOrientacao}
            toggleRevisarMudancaOrientacao={toggleRevisarMudancaOrientacao}
          />
        )}
      </div>
    </CustomProductContext.Provider>
  );
}

PhotobookProduct.propTypes = {
  productCanvasSize: PropTypes.shape({
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }).isRequired,
  tutorialStatus: PropTypes.string.isRequired,
  updateTutorialStatus: PropTypes.func.isRequired,
  updateNav: PropTypes.func.isRequired,
  isGalleryExpanded: PropTypes.bool.isRequired,
  isBookOverviewExpanded: PropTypes.bool.isRequired,
  pageFormat: PropTypes.string.isRequired,
  productFormat: PropTypes.string.isRequired,
  productType: PropTypes.string.isRequired,
  photobookHeight: PropTypes.number.isRequired,
  isPrint: PropTypes.bool.isRequired,
  bibliotecaTemasOpened: PropTypes.bool.isRequired,
  bibliotecaTemas: PropTypes.arrayOf(bibliotecaTemaPropTypes),
  toggleBibliotecaTemas: PropTypes.func.isRequired,
  addThemeImages: PropTypes.func.isRequired,
  photoEditor: photoEditorPropTypes,
  currentNav: PropTypes.string.isRequired,
  toggleLegenda: PropTypes.func.isRequired,
  isLegendaAberta: PropTypes.bool.isRequired,
  setStateLegenda: PropTypes.func.isRequired,
  setTamanhoColeira: PropTypes.func.isRequired,
  stateLegenda: PropTypes.string.isRequired,
  popupId: PropTypes.string.isRequired,
  screenSize: screenSizePropTypes,
  updatePopup: PropTypes.func.isRequired,
  setModalConfig: PropTypes.func.isRequired,
  modalConfig: PropTypes.string,
  postMetadataFormat: PropTypes.func.isRequired,
  postMetadataOrientation: PropTypes.func.isRequired,
  itemId: PropTypes.number,
  isRevisarMudancaOrientacao: PropTypes.bool.isRequired,
  toggleRevisarMudancaOrientacao: PropTypes.func.isRequired
};

const Product = ({
  screenSizeError,
  isFetched,
  isCoupon,
  isPrint,
  errorAlert,
  productCanvasSize,
  tutorialStatus,
  updateTutorialStatus,
  updateNav,
  isGalleryExpanded,
  isBookOverviewExpanded,
  pageFormat,
  productFormat,
  photobookHeight,
  productType,
  bibliotecaTemasOpened,
  bibliotecaTemas,
  toggleBibliotecaTemas,
  addThemeImages,
  photoEditor,
  currentNav,
  toggleLegenda,
  isLegendaAberta,
  setStateLegenda,
  setTamanhoColeira,
  stateLegenda,
  popupId,
  screenSize,
  updatePopup,
  products,
  userProducts,
  setModalConfig,
  modalConfig,
  postMetadataFormat,
  postMetadataOrientation,
  itemId,
  isRevisarMudancaOrientacao,
  toggleRevisarMudancaOrientacao
}) => {
  if (!screenSizeError) {
    if (errorAlert !== null) {
      if (errorAlert.display) {
        return <ErrorAlert errMsg={errorAlert.msg} />;
      }
    }
    if (isFetched) {
      if (isCoupon) {
        return <CouponProduct productCanvasSize={productCanvasSize} />;
      }
      return (
        <PhotobookProduct
          productCanvasSize={productCanvasSize}
          tutorialStatus={tutorialStatus}
          updateTutorialStatus={updateTutorialStatus}
          updateNav={updateNav}
          isGalleryExpanded={isGalleryExpanded}
          isBookOverviewExpanded={isBookOverviewExpanded}
          pageFormat={pageFormat}
          productFormat={productFormat}
          productType={productType}
          photobookHeight={photobookHeight}
          isPrint={isPrint}
          bibliotecaTemasOpened={bibliotecaTemasOpened}
          bibliotecaTemas={bibliotecaTemas}
          toggleBibliotecaTemas={toggleBibliotecaTemas}
          addThemeImages={addThemeImages}
          photoEditor={photoEditor}
          currentNav={currentNav}
          toggleLegenda={toggleLegenda}
          isLegendaAberta={isLegendaAberta}
          setStateLegenda={setStateLegenda}
          setTamanhoColeira={setTamanhoColeira}
          stateLegenda={stateLegenda}
          popupId={popupId}
          screenSize={screenSize}
          updatePopup={updatePopup}
          products={products}
          userProducts={userProducts}
          setModalConfig={setModalConfig}
          modalConfig={modalConfig}
          postMetadataFormat={postMetadataFormat}
          postMetadataOrientation={postMetadataOrientation}
          itemId={itemId}
          isRevisarMudancaOrientacao={isRevisarMudancaOrientacao}
          toggleRevisarMudancaOrientacao={toggleRevisarMudancaOrientacao}
        />
      );
    }
  }
  return <PopupManager />;
};

Product.propTypes = {
  isFetched: PropTypes.bool.isRequired,
  isCoupon: PropTypes.bool.isRequired,
  isPrint: PropTypes.bool.isRequired,
  errorAlert: PropTypes.object,
  productCanvasSize: PropTypes.shape({
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }).isRequired,
  tutorialStatus: PropTypes.string.isRequired,
  updateTutorialStatus: PropTypes.func.isRequired,
  updateNav: PropTypes.func.isRequired,
  isGalleryExpanded: PropTypes.bool.isRequired,
  isBookOverviewExpanded: PropTypes.bool.isRequired,
  pageFormat: PropTypes.string,
  productFormat: PropTypes.string,
  photobookHeight: PropTypes.number,
  productType: PropTypes.string,
  bibliotecaTemasOpened: PropTypes.bool.isRequired,
  bibliotecaTemas: PropTypes.arrayOf(bibliotecaTemaPropTypes),
  toggleBibliotecaTemas: PropTypes.func.isRequired,
  addThemeImages: PropTypes.func.isRequired,
  updatePopup: PropTypes.func.isRequired,
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
  userProducts: PropTypes.arrayOf(PropTypes.string).isRequired,
  postMetadataFormat: PropTypes.func.isRequired,
  postMetadataOrientation: PropTypes.func.isRequired,
  itemId: PropTypes.number,
  isRevisarMudancaOrientacao: PropTypes.bool.isRequired,
  toggleRevisarMudancaOrientacao: PropTypes.func.isRequired
};

Product.defaultProps = {
  isFetched: false,
  errorAlert: null,
  isCoupon: false
};

export default Product;
