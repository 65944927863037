import './index.css';

import React from 'react';
import WarningIcon from '../../asset/icone_atencao.png';

const Warning = ({ warning: { active, text } }) => (
  <div
    className={`warning-container ${active ? 'warning-enter' : 'warning-exit'}`}
  >
    <img src={WarningIcon} alt="Aviso"></img>
    <div className="warning-text">{text}</div>
  </div>
);

export default Warning;
