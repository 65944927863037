import { createSelector } from 'reselect';
import { getSet, getTotalPrice } from './set';
import { getItemIs3D, getSettings, getLibrary, getProductType } from './';

export const getPrice = createSelector(
  getSet,
  set => set.price
);

export const formatToCurrency = (iso, value) => {
  let strValue = value.toFixed(2);
  if (iso === 'BRL') {
    strValue = strValue.replace('.', ',');
  }

  return strValue;
};

const getCurrency = createSelector(
  getSettings,
  getLibrary,
  (settings, library) => library.currency[settings.currency]
);

const getCurrencyIso = createSelector(
  getCurrency,
  currency => currency.iso
);

const getItemScodixPrice = createSelector(
  getSettings,
  settings => settings.options3D.price
);

const getActiveScodixPrice = createSelector(
  getItemScodixPrice,
  getItemIs3D,
  (price, is3D) => (is3D ? price : 0)
);

const calculatePrice = createSelector(
  getProductType,
  getPrice,
  getActiveScodixPrice,
  getTotalPrice,
  (prodType, price, scodixPrice, totalPrice) => {
   let aux = price + scodixPrice;
   if(prodType === 'poster' && totalPrice > 0)
      aux = totalPrice;
   return aux;
  }
);

export const calculateTotalPrice = (userProducts, products) => {
  return userProducts.reduce((sum, prodId) => {
    let price = products.find(({id}) => id === prodId).price;
    return sum+price;
  }, 0);
}

const getCurrencySymbol = createSelector(
  getCurrency,
  currency => currency.symbol
);

const getFormatedPrice = createSelector(
  calculatePrice,
  getCurrencyIso,
  getCurrencySymbol,
  (price, iso, symbol) => `${symbol} ${formatToCurrency(iso, price)}`
);

export default getFormatedPrice;
