import * as R from 'ramda';
import * as actionGeneral from '../action/general';
import * as actionItem from '../action/item';
import * as actionUI from '../action/ui';
import { 
    put,
    select
  } from 'redux-saga/effects';

import { POPUP_ID_LOADING, POPUP_ID_NONE } from '../constant/popup';

import {
    idbUpdateMetadata,
    setPageIndex,
} from './index';

import {
    bookHaveCover,
    getAllBookPagesData,
    getIDBStatus,
    getNavigationIndex,
    getNextEmptyPage
  } from '../selector';

import { normalizePage } from '../schema/page';
import cuid from 'cuid';

export function* duplicatePage() {
    const state = yield select();
    const IDBStatus = getIDBStatus(state);

    yield put(actionUI.updatePopup(POPUP_ID_LOADING, true, 'Duplicando'));
    
    const pageId = getNavigationIndex(state) - (bookHaveCover(state) ? 1 : 0);
    const allPages = R.prop('pages', getAllBookPagesData(state));
    const proximaPagina = getNextEmptyPage(state);
    
    if(proximaPagina){
        const pageToCopy = allPages.find(page=>page.id === pageId);
        
        const frame = { ...pageToCopy.frames[0]};
        frame.id = cuid();
        proximaPagina.frames = [frame];

        const { result, entities } = normalizePage([proximaPagina]);

        yield put(actionItem.addPages(result, entities));
    
        yield put(actionGeneral.updateUsedImage(frame.image.id, 'INCREASE'));
    
        yield setPageIndex({ payload: { index: proximaPagina.id } });

        yield put(actionUI.updatePhotoEditor());

        if (IDBStatus) {
            yield idbUpdateMetadata();
        }
    }

    yield put(actionUI.updatePopup(POPUP_ID_NONE, false, ''));
}