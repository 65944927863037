import React from 'react';
import PropTypes from 'prop-types';
import './index.css';

const Mockup = ({ img }) => (
  <div className="mockup">
    <img src={img} alt="imagem do produto" />
  </div>
);

Mockup.propTypes = {
  img: PropTypes.string.isRequired
};

export default Mockup;
