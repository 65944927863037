import * as s from '../constant/sombra';
import {
    LISTA_PRODUTOS_PERMITIDOS_TIPO1,
    LISTA_PRODUTOS_PERMITIDOS_TIPO2,
} from '../utils/buscaPermissoes';

export function calculoYSombraLateral(top, height, prodCode, productType, prodFormat, isCover)
{
    //mockup calendario paisagem
    if(productType === 'calendar' && prodFormat === 'P'){
        return top+height*s.FATOR_AJUSTE_Y_SOMBRA_LATERAL_CALENDARIO_P;
    }
    else if(productType === 'calendar' && prodFormat === 'A3-P'){
        return top+height*s.FATOR_AJUSTE_Y_SOMBRA_LATERAL_CALENDARIO_A3_P;
    }
    else if(LISTA_PRODUTOS_PERMITIDOS_TIPO1.includes(prodCode) || LISTA_PRODUTOS_PERMITIDOS_TIPO2.includes(prodCode)){
        if(isCover)
            return top+height*s.FATOR_AJUSTE_Y_SOMBRA_LATERAL_CAPA_TIPO1;
        else{
            if(LISTA_PRODUTOS_PERMITIDOS_TIPO1.includes(prodCode))
                return top+(height*s.FATOR_AJUSTE_HEIGHT_DEBRU_BOOK_A4P)+(height*s.FATOR_AJUSTE_Y_SOMBRA_LATERAL_TIPO1);
            else if(LISTA_PRODUTOS_PERMITIDOS_TIPO2.includes(prodCode))
                return top+(height*3*s.FATOR_AJUSTE_DEBRU_HEIGHT_BOOK_A4R)+(height*s.FATOR_AJUSTE_Y_SOMBRA_LATERAL_TIPO2);
        }
    }

    return 0;
}

export function calculoHeightSombraLateral(height, prodCode, productType, prodFormat, isCover)
{
    //mockup calendario paisagem
    if(productType === 'calendar' && prodFormat === 'P'){
        return height*0.998*s.FATOR_AJUSTE_HEIGHT_SOMBRA_LATERAL_CALENDARIO_P;
    }
    else if(productType === 'calendar' && prodFormat === 'A3-P'){
        return height*0.999*s.FATOR_AJUSTE_HEIGHT_SOMBRA_LATERAL_CALENDARIO_A3_P;
    }
    else if(LISTA_PRODUTOS_PERMITIDOS_TIPO1.includes(prodCode) || LISTA_PRODUTOS_PERMITIDOS_TIPO2.includes(prodCode)){
        if(isCover)
        {
            return height*(1-s.FATOR_AJUSTE_Y_SOMBRA_LATERAL_CAPA_TIPO1);
        }
        else{
            const ajusteHeightDebru = (1+s.FATOR_AJUSTE_HEIGHT_DEBRU_BOOK_A4P)*2;
            return height*ajusteHeightDebru*s.FATOR_AJUSTE_Y_SOMBRA_LATERAL_MIOLO_BOOK_A4P;
        }
    }
    return height;
}

export function calculoWidthSombra(width, prodCode, productType, prodFormat, isCover)
{
    //mockup calendario paisagem
    if(productType === 'calendar' && prodFormat === 'P'){
        return width*s.FATOR_AUMENTO_WIDTH_SOMBRA;
    }
    else if(productType === 'calendar' && prodFormat === 'A3-P'){
        return width*s.FATOR_AUMENTO_WIDTH_SOMBRA_A3_P;
    }
    else if(LISTA_PRODUTOS_PERMITIDOS_TIPO1.includes(prodCode) || LISTA_PRODUTOS_PERMITIDOS_TIPO2.includes(prodCode)){
        if(isCover){
            return width*2*(1+s.FATOR_AJUSTE_SOMBRA_LATERAL_SOMBRA_BOOK_A4P)+(width/s.PROPORCAO_LOMBADA_WIDTH_CAPA);
        }
        else{
            const capaDebru = width*(1+s.FATOR_AJUSTE_DEBRU_LOMBADA_BOOK_A4P)*(1+s.FATOR_AJUSTE_SOMBRA_LATERAL_MIOLO_BOOK_A4P)
            const debru = width*s.FATOR_AJUSTE_DEBRU_LOMBADA_BOOK_A4P;
            return (capaDebru*2)+(debru*2);
        }
    }
    else if(productType === 'deck-cards'){
        return width*s.FATOR_AUMENTO_WIDTH_SOMBRA_DECK_CARDS;
    }

    return '100%';
}

export function calculoHeightSombraInferior(height, prodCode, productType, prodFormat, isCover)
{
    if(LISTA_PRODUTOS_PERMITIDOS_TIPO1.includes(prodCode) && !isCover){
        return height*0.15;
    }

    return 'auto';
}

export function calculoWidthSombraInferior(width, prodCode, productType, prodFormat, isCover)
{
    if(LISTA_PRODUTOS_PERMITIDOS_TIPO1.includes(prodCode) || 
        LISTA_PRODUTOS_PERMITIDOS_TIPO2.includes(prodCode)){
        if(isCover)
            return width*(1+s.FATOR_AJUSTE_SOMBRA_LATERAL_SOMBRA_BOOK_A4P);
        else{
            const ajusteMioloDebru = 1+s.FATOR_AJUSTE_SOMBRA_LATERAL_MIOLO_BOOK_A4P+s.FATOR_AJUSTE_DEBRU_LOMBADA_BOOK_A4P;
            return width*ajusteMioloDebru;
        }
    }

    return '100%';
}

export function calculoYSombraInferior(bottom, pageCoordenates, widthSombraInferior, prodCode, productType, prodFormat, isCover)
{
    if(productType === 'calendar' && prodFormat === 'P'){
        const width = pageCoordenates.width;
        const calcHeight = width*s.FATOR_AUMENTO_WIDTH_SOMBRA/s.RATIO_SOMBRA_INFERIOR;
        const top = bottom - calcHeight/2;
        return top;
    }
    else if(productType === 'calendar' && prodFormat === 'A3-P'){
        const width = pageCoordenates.width;
        const calcHeight = width*s.FATOR_AUMENTO_WIDTH_SOMBRA_A3_P/s.RATIO_SOMBRA_INFERIOR_A3_P;
        const top = bottom - calcHeight/2;
        return top;
    }
    else if(LISTA_PRODUTOS_PERMITIDOS_TIPO1.includes(prodCode)){
        if(isCover)
        {
            const calcHeight = widthSombraInferior/s.RATIO_SOMBRA_INFERIOR_BOOK_A4P;
            const top = bottom-calcHeight/2;
            return top;
        }
        else{
            const calcHeight = widthSombraInferior/s.RATIO_SOMBRA_INFERIOR_BOOK_A4P;
            const ajusteDebru = pageCoordenates.height*s.FATOR_AJUSTE_HEIGHT_DEBRU_BOOK_A4P;
            const top = bottom-(calcHeight/2)+ajusteDebru;
            return top;
        }
    }
    else if(LISTA_PRODUTOS_PERMITIDOS_TIPO2.includes(prodCode)){
        if(isCover)
        {
            const calcHeight = widthSombraInferior/s.RATIO_SOMBRA_INFERIOR_BOOK_A4R;
            const top = bottom-calcHeight/2;
            return top;
        }
        else{
            const calcHeight = widthSombraInferior/s.RATIO_SOMBRA_INFERIOR_BOOK_A4R;
            const ajusteDebru = pageCoordenates.height*s.FATOR_AJUSTE_DEBRU_HEIGHT_BOOK_A4R;
            const top = bottom-(calcHeight/2)+ajusteDebru;
            return top;
        }
    }

    return 0;
}

export function calculoXSombraInferior(pageCoordenates, prodCode, productType, prodFormat, isCover)
{
    if(LISTA_PRODUTOS_PERMITIDOS_TIPO1.includes(prodCode)  || 
        LISTA_PRODUTOS_PERMITIDOS_TIPO2.includes(prodCode)){
        if(isCover)
            return pageCoordenates.left-(pageCoordenates.width*s.FATOR_AJUSTE_SOMBRA_LATERAL_SOMBRA_BOOK_A4P);
        else{
            const ajusteMioloDebru = pageCoordenates.width*(s.FATOR_AJUSTE_SOMBRA_LATERAL_MIOLO_BOOK_A4P+s.FATOR_AJUSTE_DEBRU_LOMBADA_BOOK_A4P);
            const debru = pageCoordenates.width*s.FATOR_AJUSTE_DEBRU_LOMBADA_BOOK_A4P;
            return pageCoordenates.left-ajusteMioloDebru-debru;
        }
    }
    return 0;
}

export function calculoXSombraMioloDebru(pageCoordenates, prodCode)
{
    if(LISTA_PRODUTOS_PERMITIDOS_TIPO1.includes(prodCode)){
        const height = pageCoordenates.height*s.FATOR_AJUSTE_HEIGHT_SOMBRA_DEBRU_BOOK_A4P;
        const width = height*s.RATIO_SOMBRA_MIOLO_DEBRU_TODO_BOOK_A4P;
        const deslocamentoEsquerda = width-pageCoordenates.width*2;
        return pageCoordenates.left-deslocamentoEsquerda/2;
    }
    else if(LISTA_PRODUTOS_PERMITIDOS_TIPO2.includes(prodCode)){
        const deslocamentoEsquerda = pageCoordenates.height*s.FATOR_AJUSTE_DEBRU_HEIGHT_BOOK_A4R;
        return pageCoordenates.left-deslocamentoEsquerda;
    }
    return 0;
}