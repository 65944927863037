import {
    getProductType,
    getTamanhoColeira,
    getIsModoSobFivela
} from '../selector';

import { getPrice, formatToCurrency } from '../selector/price';
import { enablePrecoUnitario } from './separadorProdutos';

import { getSetNumber } from '../selector/set';
import getTitle from '../constant/title';
import store from '../store';

const produtosMasc = ['poster'];

export function getTipoPag(plural = false) {
    const state = store.getState();
    const prodType = state.library.product ? getProductType(state) : '';

    let pag = plural ? 'as páginas' : 'a página';

    if (prodType === 'poster') {
        pag = plural ? 'aos Pôsteres' : 'ao Pôster';
    }

    return pag;
}

export function getTipoPagina(plural = false) {
    const state = store.getState();
    const prodType = state.library.product ? getProductType(state) : '';

    let pag = plural ? 'páginas' : 'página';

    if (prodType === 'poster') {
        pag = plural ? 'Pôsteres' : 'Pôster';
    } else if (prodType === 'deck-cards') {
        pag = plural ? 'cartas' : 'carta';
    }

    return pag;
}

export function getTipoQuantidade(maiusculo, plural) {
    const state = store.getState();
    const prodType = state.library.product ? getProductType(state) : '';
    const primeiraLetra = geraPrimeiraLetra(prodType, maiusculo);
    let pluralFinal = plural != null && plural === true ? 's' : '';

    if (prodType === 'mask') {
        return primeiraLetra + 'áscara' + pluralFinal;
    } else if (prodType === 'dog-collar') {
        return primeiraLetra + 'oleira' + pluralFinal;
    } else if (prodType === 'poster') {
        pluralFinal = plural != null && plural === true ? 'es' : '';
        return primeiraLetra + 'ôster' + pluralFinal;
    } else if (prodType === 'deck-cards') {
        pluralFinal = plural != null && plural === true ? 'as' : 'a';
        if (!maiusculo && !plural) return 'Cartas';
        return primeiraLetra + 'art' + pluralFinal;
    } else if (prodType === 'invitation') {
        if (!maiusculo && !plural) return 'Convite';
        return primeiraLetra + 'onvite' + pluralFinal;
    }

    return primeiraLetra + 'ágina' + pluralFinal;
}

export const getTipoQuantidadeMenuEsq = () => {
    const state = store.getState();
    const prodType = state.library.product ? getProductType(state) : '';

    switch (prodType) {
        case 'mask':
            return 'Máscara';
        case 'dog-collar':
            return 'Coleira';
        case 'poster':
            return 'Pôster';
        case 'deck-cards':
            const tipoCarta = getIsModoSobFivela(state) ? ' Verso ' : ' Frente ';
            return 'Carta' + tipoCarta;
        default:
            return 'Página';
    }
};

export function getAdicionarProduct(prodType) {
    let aux = 'Adicionar Pôster';

    if (prodType !== 'poster' && prodType !== 'mask') {
        aux = `Adicionar ${getTipoQuantidade(true, true)}`;
    } else if (prodType !== 'poster' && prodType === 'mask') {
        aux = `Adicionar ${getTipoQuantidade(true, false)}`;
    }

    return aux;
}

const geraPrimeiraLetra = (prodType, maiusculo) => {
    let primeiraLetra = maiusculo ? 'P' : 'p';
    if (prodType === 'mask') primeiraLetra = maiusculo ? 'M' : 'm';
    else if (
        prodType === 'dog-collar' ||
        prodType === 'deck-cards' ||
        prodType === 'invitation'
    )
        primeiraLetra = maiusculo ? 'C' : 'c';
    return primeiraLetra;
};

export function getTipoQuantidadePadraoMascara(maiusculo) {
    const state = store.getState();
    const prodType = state.library.product ? getProductType(state) : '';

    const primeiraLetra = geraPrimeiraLetra(prodType, maiusculo);

    if (prodType === 'mask') {
        return primeiraLetra + 'áscara';
    } else if (prodType === 'dog-collar') {
        return primeiraLetra + 'oleira';
    } else if (prodType === 'poster') {
        return primeiraLetra + 'ôster';
    } else if (prodType === 'deck-cards') {
        return primeiraLetra + 'arta';
    } else if (prodType === 'invitation') {
        return primeiraLetra + 'onvites';
    }

    return primeiraLetra + 'áginas';
}

export function getTipoQuantidadePadrao() {
    const state = store.getState();
    const prodType = state.library.product ? getProductType(state) : '';

    if (prodType === 'mask') {
        return 'Máscaras';
    } else if (prodType === 'dog-collar') {
        return 'Coleiras';
    } else if (prodType === 'poster') {
        return 'Pôsteres';
    } else if (prodType === 'deck-cards') {
        return 'Cartas';
    } else if (prodType === 'invitation') {
        return 'Convite';
    }

    return 'páginas';
}

export function getVoltarOverview() {
    const state = store.getState();
    const prodType = state.library.product ? getProductType(state) : '';

    if (prodType === 'mask') {
        return 'Ver uma Máscara';
    } else if (prodType === 'dog-collar') {
        return 'Ver uma Coleira';
    } else if (prodType === 'poster') {
        return 'Ver um Pôster';
    } else if (prodType === 'deck-cards') {
        return 'Ver uma Carta';
    } else if (prodType === 'invitation') {
        return 'Ver um lado do Convite';
    }

    return 'Ver em página dupla';
}

export function getVoltarOverviewImagem() {
    const state = store.getState();
    const prodType = state.library.product ? getProductType(state) : '';

    if (prodType === 'mask') {
        return 'Recolhe as imagens e exibe a Máscara';
    } else if (prodType === 'poster') {
        return 'Recolhe as imagens e exibe o Pôster';
    } else if (prodType === 'calendar') {
        return 'Recolhe as imagens e exibe o Calendário';
    } else if (prodType === 'dog-collar') {
        return 'Recolhe as imagens e exibe a Coleira';
    } else if (prodType === 'deck-cards') {
        return 'Recolhe as imagens e exibe o Baralho';
    } else if (prodType === 'invitation') {
        return 'Recolhe as imagens e exibe o Convite';
    }

    return 'Recolhe as imagens e exibe o FotoLivro';
}

export function getFinaliza() {
    const state = store.getState();
    const prodType = state.library.product ? getProductType(state) : '';

    if (prodType === 'mask') {
        return 'sua Máscara';
    } else if (prodType === 'dog-collar') {
        return 'sua Coleira';
    }

    return `seu ${getTitle()}`;
}

export function getRetiraImagens(dMaisA) {
    const state = store.getState();
    const prodType = state.library.product ? getProductType(state) : '';
    const d = dMaisA != null && dMaisA === true ? 'd' : 'n';

    if (prodType === 'mask') {
        return d + 'a Máscara';
    } else if (prodType === 'dog-collar') {
        return d + 'a Coleira';
    }

    return `${d}o ${getTitle()}`;
}

export const getDataTipLimpar = () => {
    const state = store.getState();
    const prodType = state.library.product ? getProductType(state) : '';

    if (prodType === 'dog-collar') {
        return 'Retira todos os textos da Coleira';
    }

    return `Retira todas as imagens ${getRetiraImagens(true)}`;
};

export function getAlertaBaixaQualidade() {
    const state = store.getState();
    const prodType = state.library.product ? getProductType(state) : '';

    if (prodType === 'mask') {
        return 'Imagem com baixa qualidade: Diminua o zoom ou substitua sua imagem.';
    }

    return 'Imagem com baixa qualidade: Mude para um layout com janela de imagem menor que a atual ou substitua sua imagem.';
}

export function getBookContainerDataTip() {
    const state = store.getState();
    const prodType = state.library.product ? getProductType(state) : '';

    if (prodType === 'mask' || prodType === 'dog-collar') {
        return `Menu ${getTitle()}: Funções relacionadas à ${getTitle()}`;
    }

    return `Menu ${getTitle()}: Funções relacionadas ao ${getTitle()}`;
}

export function getTipoGeneroSubProduto(texto = '', temPlural) {
    const state = store.getState();
    const prodType = state.library.product ? getProductType(state) : '';
    let tipoGenero = 'a';
    const plural = temPlural != null && temPlural === true ? 's' : '';

    if (prodType === 'poster') {
        tipoGenero = 'o';
    }

    return texto + tipoGenero + plural;
}

export function getTipoGeneroProduto(texto = '', temPlural) {
    const state = store.getState();
    const prodType = state.library.product ? getProductType(state) : '';
    let tipoGenero = 'o';
    const plural = temPlural != null && temPlural === true ? 's' : '';

    if (prodType === 'mask' || prodType === 'dog-collar') {
        tipoGenero = 'a';
    }

    return texto + tipoGenero + plural;
}

export function getPreco(preco) {
    const state = store.getState();
    const prodType = state.library.product ? getProductType(state) : '';
    let texto = `Preço: ${preco}`;

    if (enablePrecoUnitario(prodType)) {
        const quantidadeNumero = getSetNumber(state);
        const precoNumero = getPrice(state);
        const precoUnitario = precoNumero / quantidadeNumero;
        const auxPrecoUnitario = ` (Preço unitário: R$ ${formatToCurrency(
            'BRL',
            precoUnitario
        )})`;
        texto += auxPrecoUnitario;
    } else if (prodType === 'dog-collar') {
        const quantidadeNumero = getSetNumber(state);
        const precoNumero = getPrice(state);
        const precoUnitario = precoNumero / quantidadeNumero;
        const tamanhoColeira = getTamanhoColeira(state);
        const auxPrecoUnitario = ` (Preço unitário: R$ ${formatToCurrency(
            'BRL',
            precoUnitario
        )}), Medida do pescoço do pet: ${tamanhoColeira}`;
        texto += auxPrecoUnitario;
    }

    return texto;
}

export function getTipoLegendaMesa(plural = false) {
    const state = store.getState();
    const prodType = state.library.product ? getProductType(state) : '';

    if (prodType === 'mask') {
        return plural ? 'Máscaras' : 'Máscara';
    } else if (prodType === 'dog-collar') {
        return plural ? 'Coleiras' : 'Coleira';
    } else if (prodType === 'poster') {
        return plural ? 'Pôsteres' : 'Pôster';
    } else if (prodType === 'deck-cards') {
        return plural ? 'Cartas' : 'Carta';
    }

    return plural ? 'Páginas' : 'Página';
}

export function produtosEditaveis() {
    const state = store.getState();
    const prodType = state.library.product ? getProductType(state) : '';
    const produtosEditaveis = ['mask', 'invitation'];
    return prodType.length > 0 && produtosEditaveis.includes(prodType);
}

export function getOverviewMover(direita = true) {
    const state = store.getState();
    const prodType = state.library.product ? getProductType(state) : '';
    let direcao = direita ? 'direita' : 'esquerda';

    if (prodType === 'dog-collar') direcao = direita ? 'baixo' : 'cima';

    return `Mover para ${direcao}`;
}

export function getWarnMsgTexto() {
    const state = store.getState();
    const prodType = state.library.product ? getProductType(state) : '';
    let aux =
        'Texto muito longo: Reduza o tamanho da fonte, mude o layout ou reduza a quantidade do seu texto.';

    if (prodType === 'dog-collar')
        aux =
            'Texto muito longo: Reduza o tamanho da fonte ou reduza a quantidade do seu texto.';

    return aux;
}

export function getGeneroProduto(prodType, plural = false) {
    let aux = plural ? 'as' : 'a';

    if (produtosMasc.includes(prodType)) aux = plural ? 'os' : 'o';

    return aux;
}

export function applyMethodTodos() {
    const state = store.getState();
    const prodType = state.library.product ? getProductType(state) : '';
    let aux = 'em todos os Pôsteres';

    if (prodType !== 'poster') aux = `ao ${getTitle()} todo`;

    return aux;
}

export function getHeaderChangeFormat() {
    let aux = 'Selecione o formato do seu produto';
    return aux;
}

export function getListChangeFormat() {
    let aux = [
        // {
        //   format: 'A5',
        //   size: '20cm x 15cm',
        //   price: 'R$ 15,00',
        // },
        {
            comparator: 'A4',
            title: 'A4',
            size: '30cm x 20cm',
            price: 'R$ 8,90'
        },
        {
            comparator: 'A3',
            title: 'A3',
            size: '40cm x 30cm',
            price: 'R$ 14,90'
        }
        // {
        //   format: 'A2',
        //   size: '60cm x 40cm',
        //   price: 'R$ 30,00',
        // },
        // {
        //   format: 'A1',
        //   size: '80cm x 60cm',
        //   price: 'R$ 40,00',
        // },
        // {
        //   format: 'A0',
        //   size: '90cm x 70cm',
        //   price: 'R$ 50,00',
        // },
    ];

    return aux;
}

export function getHeaderChangeOrientation() {
    // const state = store.getState();
    // const format = state.library.product ? getProductFormat(state) : '';
    let aux = 'Selecione a orientação do seu produto';
    return aux;
}

export const getListChangeOrientation = pageFormat => {
    let format = pageFormat ? pageFormat : 'A4';
    let aux = [
        {
            comparator: 'P',
            title: `${format}-Paisagem`,
            size: '30cm x 20cm',
            price: 'R$ 20,00'
        },
        {
            comparator: 'R',
            title: `${format}-Retrato`,
            size: '20cm x 30cm',
            price: 'R$ 20,00'
        }
    ];
    return aux;
};

export const getSelecaoFundosAplicar = isModoSobFivela => {
    return isModoSobFivela
        ? 'a todas as cartas verso'
        : 'a todas as cartas frente';
};

export const setaEsquerdaDataTip = prodType => {
    const tipoQtdProd = getTipoQuantidade(true);
    if (prodType === 'invitation') return 'Ver outro lado do Convite';
    return `${tipoQtdProd} Anterior`;
};

export const setaDireitaDataTip = prodType => {
    const tipoQtdProd = getTipoQuantidade(true);
    const generoProd = getGeneroProduto(prodType);
    if (prodType === 'invitation') return 'Ver outro lado do Convite';
    return `Próxim${generoProd} ${tipoQtdProd}`;
};

export function textoOverviewExpand(prodType) {
    const generoProdPlural = getGeneroProduto(prodType, true);
    if (prodType === 'invitation') return 'Ver os dois lados do Convite';
    return `Ver tod${generoProdPlural} ${generoProdPlural} ${getTipoQuantidade(
        true,
        true
    )}`;
}

//Comandos pelo teclado:
export function anteriorLegendaMesa(prodType) {
    let legenda = `${getTipoLegendaMesa()} Anterior`;
    // if (prodType === 'invitation') legenda = 'Ver outro lado do Convite';
    return legenda;
}

export function proximaLegendaMesa(prodType) {
    let legenda = `Próxim${getTipoGeneroSubProduto()} ${getTipoLegendaMesa()}`;
    // if (prodType === 'invitation') legenda = 'Ver outro lado do Convite';
    return legenda;
}

export function overviewLegendaMesa(prodType) {
    const artigoProdutoPlural = getTipoGeneroSubProduto('', true);
    let legenda = `Ver tod${artigoProdutoPlural} ${artigoProdutoPlural} ${getTipoLegendaMesa(
        true
    )}`;
    if (prodType === 'invitation') legenda = 'Overview do Convite';
    return legenda;
}

export function overviewLabelText(prodType, index, label) {
    if (prodType === 'invitation')
        return index === 1 ? 'Convite Frente' : 'Convite Verso';
    return label ? `${label}` : index < 10 ? `0${index}` : index;
}
