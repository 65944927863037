import './bibliotecaImagens.css';
import PropTypes from 'prop-types';
import { imagemPropTypes } from '../../proptypes';
import React, { useState, useEffect } from 'react';
import Button from '../button';
import {
  THEME_CLASS_BT_LEFT_ARROW_BLACK,
  THEME_CLASS_BT_LEFT_ARROW_BLACK_DISABLED,
  THEME_CLASS_BT_RIGHT_ARROW_BLACK,
  THEME_CLASS_BT_RIGHT_ARROW_BLACK_DISABLED
} from '../../constant/button';

function BibliotecaImagens(
  {
    selectedImages,
    changeSelected
  }) {

  const [arrowLeftDisabled, setArrowLeftDisabled] = useState(true);
  const [arrowRightDisabled, setArrowRightDisabled] = useState(true);
  const ajustesGap = 0.8;

  useEffect(() => {
    const bibliotecaScroll = document.getElementById('biblioteca-scroll');
    setArrowRightDisabled((bibliotecaScroll.scrollLeft+ajustesGap) >= (bibliotecaScroll.scrollWidth-bibliotecaScroll.clientWidth));
    setArrowLeftDisabled(bibliotecaScroll.scrollLeft <= 0);
  }, [selectedImages]);

  const scroll = (direction) => {
    const bibliotecaScroll = document.getElementById('biblioteca-scroll');
    bibliotecaScroll.scrollLeft+= (direction === 'RIGHT') ? 80.2 : -80.2;
    setArrowRightDisabled((bibliotecaScroll.scrollLeft+ajustesGap) >= (bibliotecaScroll.scrollWidth-bibliotecaScroll.clientWidth));
    setArrowLeftDisabled(bibliotecaScroll.scrollLeft <= 0);
  }

  const conversorNumero = (numero) => {
    if(numero < 10)
      return '0'+numero;
    return numero;
  }

  return (
    <div className="container-biblioteca">
      <div className="left-button">
        <Button
            style={{margin: '2px 2px 2px 0', width: '12px'}}
            padding={false}
            theme={arrowLeftDisabled? THEME_CLASS_BT_LEFT_ARROW_BLACK_DISABLED:THEME_CLASS_BT_LEFT_ARROW_BLACK}
            clickHandler={() => scroll('LEFT')}
            disabled={arrowLeftDisabled}
        />
      </div>
      <div id="biblioteca-scroll" className="biblioteca-imagens">
        {selectedImages.map((item, index) =>
        <div className="item-rectangle" 
            key={index}>
            <div className="contador-img"/>
            <div className="item-img"> 
              <img
                alt="subitem-thumb"
                height="60px"
                width="auto"
                src={item.thumb.image}
              />
              <div className="botao-delete">
                <div className="bt-menu-delete" onClick={() => changeSelected(false, item)}/>
              </div>
            </div>
            <div className="contador-img">
              <div className="contador-img-number">{conversorNumero(index+1)}</div>
            </div>
          </div>
        )}
      </div>
      <div className="right-button">
        <Button
          style={{margin: '2px 0 2px 2px', width: '12px'}}
            padding={false}
            theme={arrowRightDisabled? THEME_CLASS_BT_RIGHT_ARROW_BLACK_DISABLED:THEME_CLASS_BT_RIGHT_ARROW_BLACK}
            clickHandler={() => scroll('RIGHT')}
            disabled={arrowRightDisabled}
        />
      </div>
    </div>
  );
};

BibliotecaImagens.propTypes = {
  selectedImages: PropTypes.arrayOf(imagemPropTypes),
  changeSelected: PropTypes.func.isRequired
};

BibliotecaImagens.defaultProps = {
  selectedImages: [],
};

export default BibliotecaImagens;
