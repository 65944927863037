import FakeEan from '../../asset/fake_ean.jpg';
import LogoLast from '../../asset/prin-last.svg';
import LogoSpine from '../../asset/logo-p.svg';
import PropTypes from 'prop-types';
import React from 'react';
import { pagesFormatPropTypes } from '../../proptypes';
import Mockup from '../mockup';
import MockupInvitation from '../mockupInvitation';
import Logo from '../logo';
import SombraInterna from '../sombra/sombraInterna';
import { haveBarCodeFunc } from '../../utils/separadorProdutos';

import {
  calculoYMockPage,
  calculoYMockPageBleed,
  calculoHeightMockPage,
  calculoHeightMockPageBleed
} from '../../utils/calculosMockup';

import { calculoXBarcode } from '../../utils/calculosBarcode';
import { calculoY } from '../../utils/calculosImpressao';
import { checaFundoAbstrato } from '../../utils/calculosFundos';

const Page = ({
  id,
  width,
  height,
  background,
  children,
  isLeftPage,
  isLastPage,
  isPrint,
  isThumb,
  opacity,
  eanUrl,
  debru,
  spineLogoShouldIncrement,
  bookHaveCover,
  pagesFormats,
  bleed,
  bleedPattern,
  photoEditorActive,
  prodType,
  prodFormat,
  pageIndex,
  navigationIndex,
  isModoImpressao,
  isModoSobFivela,
  prodCode,
  isCover,
  tamanhoSelecionado,
  fundoColor,
  textareasProduct,
  updateTextOptions,
  texts,
  textOptions,
  textChangeHandler,
  toggleNavText,
  bleedPrint,
  rate,
  isBookOverviewExpanded,
  isMenuTextSelected
}) => {
  const { cover, page, spine } = pagesFormats;
  const logo = id === 'back_cover' ? cover.logo : page.logo;
  const ean = id === 'back_cover' ? cover.ean : page.ean;
  const rectHeight = calculoHeightMockPage(
    height,
    isPrint,
    prodType,
    prodFormat
  );

  return (
    <svg
      style={{ background, overflow: 'visible' }}
      viewBox={`0  ${calculoY(
        0,
        isPrint,
        prodType,
        prodFormat
      )} ${width} ${height}`}
    >
      <defs></defs>
      {prodType !== 'dog-collar' &&
        prodType !== 'deck-cards' &&
        prodType !== 'invitation' && (
          <rect
            id={isLeftPage ? 'product-page' : ''}
            x="0"
            y={calculoYMockPage(height, isPrint, prodType, prodFormat)}
            width={width}
            height={rectHeight}
            fill={background}
            opacity={opacity}
          />
        )}
      {prodType === 'deck-cards' && (
        <React.Fragment>
          <rect
            id={isLeftPage ? 'product-page' : ''}
            x="0"
            y={calculoYMockPage(height, isPrint, prodType, prodFormat)}
            width={width}
            height={rectHeight}
            fill={background}
            opacity={opacity}
          />
          {checaFundoAbstrato(isModoSobFivela, fundoColor) ? (
            <image
              id={'product-page'}
              x={-bleedPattern}
              y={-bleedPattern}
              width={width + bleedPattern * 2}
              height={rectHeight + bleedPattern * 2}
              href={require(`../../asset/fundos/abstrato/${fundoColor}.jpg`)}
            />
          ) : (
            <></>
          )}
        </React.Fragment>
      )}
      {pageIndex && prodType === 'calendar' && (
        <image
          x={bleed}
          y={calculoYMockPageBleed(
            height,
            bleed,
            isPrint,
            prodType,
            prodFormat
          )}
          width={width - bleed * 2}
          height={calculoHeightMockPageBleed(
            height,
            bleed,
            isPrint,
            prodType,
            prodFormat
          )}
          href={require(`../../asset/page-fill/${prodType}/${prodFormat}/${pageIndex}.jpg`)}
        />
      )}
      {pageIndex && prodType === 'dog-collar' && (
        <image
          id="dog-collar-image-fill"
          x={bleed}
          y={calculoYMockPageBleed(
            height,
            bleed,
            isPrint,
            prodType,
            prodFormat
          )}
          width={width - bleed * 2}
          height={calculoHeightMockPageBleed(
            height,
            bleed,
            isPrint,
            prodType,
            prodFormat
          )}
          href={
            isPrint && !isThumb
              ? require(`../../asset/page-fill/${prodType}/${tamanhoSelecionado}_Print.png`)
              : isModoSobFivela
                ? require(`../../asset/page-fill/${prodType}/${prodCode}/${tamanhoSelecionado}_B.png`)
                : require(`../../asset/page-fill/${prodType}/${prodCode}/${tamanhoSelecionado}_A.png`)
          }
        />
      )}
      {prodType === 'invitation' && (
        <React.Fragment>
          <rect
            id="product-page"
            x="0"
            y={calculoYMockPage(height, isPrint, prodType, prodFormat)}
            width={width}
            height={rectHeight}
            fill={background}
            opacity={opacity}
          />
          <image
            x={-bleedPattern}
            y={-bleedPattern}
            width={width + bleedPattern * 2}
            height={rectHeight + bleedPattern * 2}
            href={require(`../../asset/page-fill/${prodType}/${prodCode}.jpg`)}
            opacity={photoEditorActive ? 0.25 : 1}
          />
        </React.Fragment>
      )}
      {children}
      {// TODO Logolast size
        prodType === 'book' &&
        ((bookHaveCover && id === 'back_cover') ||
          (!bookHaveCover && isLastPage)) && (
          <image
            width={logo.width}
            height={logo.height}
            x={logo.left + debru + bleed}
            y={logo.top + debru + bleed}
            href={LogoLast}
            opacity={photoEditorActive ? 0.25 : 1}
          />
        )}
      {// TODO Barcode size
        (isLastPage || id === 'back_cover') && haveBarCodeFunc(prodType) && (
          <image
            width={ean.width}
            height={ean.height}
            x={calculoXBarcode(isPrint, prodCode, ean.left, debru, bleed)}
            y={ean.top + debru + bleed}
            href={eanUrl || FakeEan}
            opacity={photoEditorActive ? 0.25 : 1}
          />
        )}
      {id === 'spine' && (
        <image
          width={spine.logo.width}
          height={spine.logo.height}
          x={spine.logo.left - (spineLogoShouldIncrement ? debru * 0.1 : 0)}
          y={spine.logo.top + debru}
          href={LogoSpine}
          opacity={photoEditorActive ? 0.25 : 1}
        ></image>
      )}

      {prodType === 'invitation' && (
        <MockupInvitation
          id={id}
          navigationIndex={navigationIndex}
          width={width}
          isPrint={isPrint}
          textareasProduct={textareasProduct}
          texts={texts}
          textOptions={textOptions}
          textChangeHandler={textChangeHandler}
          toggleNavText={toggleNavText}
          bleedPrint={bleedPrint}
          rate={rate}
          updateTextOptions={updateTextOptions}
          photoEditorActive={photoEditorActive}
          isBookOverviewExpanded={isBookOverviewExpanded}
          isMenuTextSelected={isMenuTextSelected}
        />
      )}

      <Mockup
        id={id}
        width={width}
        height={calculoHeightMockPage(height, isPrint, prodType, prodFormat)}
        prodCode={prodCode}
        prodType={prodType}
        prodFormat={prodFormat}
        isCover={isCover}
        isPrint={isPrint}
        isModoImpressao={isModoImpressao}
        isModoSobFivela={isModoSobFivela}
        isLeftPage={isLeftPage}
        tamanhoSelecionado={tamanhoSelecionado}
        navigationIndex={navigationIndex}
      />

      <Logo
        id={id}
        prodType={prodType}
        isModoSobFivela={isModoSobFivela}
        fundoColor={fundoColor}
        bleed={bleed}
      />

      {!isPrint && (
        <SombraInterna
          width={width}
          height={height}
          prodType={prodType}
          prodCode={prodCode}
          prodFormat={prodFormat}
          bleed={bleed}
          debru={debru}
          isCover={isCover}
          isSpine={id === 'spine'}
          isLeftPage={isLeftPage}
        />
      )}
    </svg>
  );
};

Page.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  background: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  hasShadow: PropTypes.bool.isRequired,
  isLeftPage: PropTypes.bool.isRequired,
  isLastPage: PropTypes.bool.isRequired,
  isPrint: PropTypes.bool.isRequired,
  isThumb: PropTypes.bool.isRequired,
  opacity: PropTypes.number.isRequired,
  eanUrl: PropTypes.string.isRequired,
  debru: PropTypes.number.isRequired,
  spineLogoShouldIncrement: PropTypes.bool.isRequired,
  bookHaveCover: PropTypes.bool.isRequired,
  pagesFormats: pagesFormatPropTypes,
  bleed: PropTypes.number.isRequired,
  bleedPattern: PropTypes.number.isRequired,
  photoEditorActive: PropTypes.bool.isRequired,
  prodType: PropTypes.string.isRequired,
  prodFormat: PropTypes.string.isRequired,
  isModoImpressao: PropTypes.bool.isRequired,
  isModoSobFivela: PropTypes.bool.isRequired,
  pageIndex: PropTypes.number.isRequired,
  navigationIndex: PropTypes.number.isRequired,
  isCover: PropTypes.bool.isRequired,
  prodCode: PropTypes.string.isRequired,
  isBookOverviewExpanded: PropTypes.bool.isRequired,
  tamanhoSelecionado: PropTypes.number,
  tipoFundo: PropTypes.string.isRequired,
  fundoColor: PropTypes.string.isRequired,
  layoutsFilter: PropTypes.shape({
    layoutSelected: PropTypes.number
  }).isRequired
};

Page.defaultProps = {
  background: '#fff',
  hasShadow: true,
  isLeftPage: true,
  children: undefined,
  opacity: 1
};

export default Page;
