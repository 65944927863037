import * as actionGeneral from '../action/general';
import * as actionItem from '../action/item';
import * as actionUI from '../action/ui';

import {
  bookHaveCover,
  getAllBookPagesData,
  getLayoutsFilter,
  getMaxMinShapeNumbers,
  getNavigationIndex,
  getProductType,
  getSelectedProduct,
  getCustomProducts
} from '../selector';

import {
  getSetDif,
  getNextSetId,
  getPreviousSetId,
  getSetNumber,
  getSetId
} from '../selector/set';

import NumericStepper from '../component/numeric-stepper';
import { POPUP_ID_REMOVE_PAGES } from '../constant/popup';
import { REMOVE_PAGES } from '../constant/popup-message';
import { connect } from 'react-redux';
import store from '../store';
import {
  isCustomProduct,
  onePageProduct,
  isOneEditingProduct
} from '../utils/separadorProdutos';

const formatAmount = amount =>
  amount < 10 ? '0' + amount.toString() : amount.toString();

const mapStateToProps = state => ({
  layoutsFilter: getLayoutsFilter(state).photos,
  value: formatAmount(getSetNumber(state)),
  valueId: formatAmount(getSetId(state)),
  nextValue: getNextSetId(state),
  previousValue: getPreviousSetId(state),
  maxMinShapes: getMaxMinShapeNumbers(state)
});

let interval = 0;

const getLastPageIndex = (prodType, allPages) => {
  let index = allPages.length;
  if (!onePageProduct(prodType)) index = index - 1;
  return index;
};

const updateToNextValue = (dispatch, increase) => {
  const state = store.getState();
  const prodType = getProductType(state);
  let nextValue;
  let pages = [];
  let setDif = 0;

  if (increase) {
    if (isCustomProduct(prodType)) setUserProductEvent('ADD', dispatch);
    nextValue = getNextSetId(state);
  } else {
    // Verificar se as paginas a serem removidas estao limpas
    const allPages = getAllBookPagesData(state).pages;
    setDif = getSetDif(state);
    const lastPageIndex = getLastPageIndex(prodType, allPages);
    if (prodType === 'dog-collar') {
      const i = lastPageIndex;
      if (
        allPages[i] != null &&
        allPages[i].texts != null &&
        allPages[i].texts.length > 0 &&
        allPages[i].texts.find(t => t.richTextState.length > 0) != null
      )
        pages.push(allPages[i].index);
    } else if (!isOneEditingProduct(prodType)) {
      for (let i = lastPageIndex - setDif; i < lastPageIndex; i++) {
        if (allPages[i].frames.length > 0) pages.push(allPages[i].index);
      }
    }
    nextValue = getPreviousSetId(state);
  }

  if (pages.length === 0) {
    dispatch(actionItem.updatePageSetId(nextValue));

    if (!increase) {
      const haveCover = bookHaveCover(state);
      const index = getNavigationIndex(state) - (haveCover ? 1 : 0);
      const setIndex = getSetNumber(state) / 2;
      if (isCustomProduct(prodType)) setUserProductEvent('REMOVE', dispatch);
      if (index === setIndex || index + 1 === setIndex) {
        dispatch(actionGeneral.sagaLastPageIndex(true));
      }
    }
    dispatch(actionItem.updatePageIDB());
  } else {
    dispatch(actionUI.setStateLegenda('POPUP'));
    dispatch(
      actionUI.updatePopup(
        POPUP_ID_REMOVE_PAGES,
        true,
        REMOVE_PAGES(`0${setDif} últimas páginas`, pages)
      )
    );
  }
};

const stopTimer = () => {
  clearInterval(interval);
};

const handleChange = (dispatch, increase) => () => {
  stopTimer();
  interval = setInterval(() => {
    updateToNextValue(dispatch, increase);
  }, 200);
};

const setUserProductEvent = (command, dispatch) => {
  const state = store.getState();
  let index = getNavigationIndex(state);

  const product = getSelectedProduct(state);
  if (product) {
    if (command === 'REMOVE') {
      const allPages = getAllBookPagesData(state).pages;
      const prodType = getProductType(state);
      index = getLastPageIndex(prodType, allPages);
    }
    const products = getCustomProducts(state);

    dispatch(actionItem.setUserProduct(index, product.id, products, command));
  }
};

const mapDispatchToProps = dispatch => ({
  increaseHandler: handleChange(dispatch, true),
  decreaseHandler: handleChange(dispatch, false),
  increaseClickHandler: () => updateToNextValue(dispatch, true),
  decreaseClickHandler: () => updateToNextValue(dispatch, false),
  mouseUpHandler: stopTimer,
  increaseLayoutFilterPhotoNumber: () =>
    dispatch(actionItem.sagaUpdateLayoutFilterPhotoNumber('INCREASE')),
  decreaseLayoutFilterPhotoNumber: () =>
    dispatch(actionItem.sagaUpdateLayoutFilterPhotoNumber('DECREASE'))
});

export default connect(mapStateToProps, mapDispatchToProps)(NumericStepper);
