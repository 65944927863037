import { denormalize, normalize, schema } from 'normalizr';

const sourceSchema = new schema.Entity('source');
const metadata = new schema.Entity('metadata');
export const image = new schema.Entity('image', {
  metadata,
  source: {
    thumb: sourceSchema,
    image: sourceSchema
  }
});

const imgSchema = [image];

export const normalizeImg = data => normalize(data, imgSchema);
export const denormalizeImg = (result, entities) =>
  denormalize(result, imgSchema, entities);
