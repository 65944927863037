import { normalize, schema } from 'normalizr';

const textarea = new schema.Entity('textarea');
export const shape = new schema.Entity('shape');
const set = new schema.Entity('set');
const currency = new schema.Entity('currency', {}, { idAttribute: 'iso' });
const products = new schema.Entity('products');

export const style = new schema.Entity('style', {
  shape: [shape],
  textarea: [textarea]
});

const normalizeServerItem = data => {
  const description = new schema.Entity(
    'description',
    {},
    { idAttribute: 'code' }
  );

  const product = new schema.Entity(
    'product',
    {
      settings: {
        description,
        currency,
        pages: {
          set: {
            entries: [set]
          }
        }
      },

      template: {
        styles: [style]
      }
    },
    {
      processStrategy: entity => ({
        ...entity,
        settings: {
          ...entity.settings,
          pages: {
            ...entity.settings.pages,
            set: {
              ...entity.settings.pages.set,
              default: `${entity.settings.description.code}-${entity.settings.pages.set.default}`,
              entries: entity.settings.pages.set.entries.map(setItem => ({
                ...setItem,
                id: `${entity.settings.description.code}-${setItem.id}`
              }))
            }
          }
        }
      })
    }
  );

  const normalizedData = normalize(data, product, products);
  return normalizedData;
};

export default normalizeServerItem;
