export const UPDATE_SCREEN_SIZE_ERROR = 'ACTION_UPDATE_SCREEN_SIZE_ERROR';
export const UPDATE_FETCH_STATUS = 'ACTION_UPDATE_FETCH_STATUS';
export const UPDATE_IDB_STATUS = 'ACTION_UPDATE_IDB_STATUS';
export const UPDATE_TUTORIAL_STATUS = 'ACTION_UPDATE_TUTORIAL_STATUS';
export const UPDATE_POPUP = 'ACTION_UPDATE_POPUP';
export const UPDATE_SCREEN_SIZE = 'ACTION_UPDATE_SCREEN_SIZE';
export const UPDATE_SCREEN_RATE = 'ACTION_UPDATE_SCREEN_RATE';
export const UPDATE_NAV_SELECTED = 'ACTION_UPDATE_NAV_SELECTED';
export const UPDATE_GALLERY_EXPANDED = 'ACTION_UPDATE_GALLERY_EXPANDED';
export const UPDATE_BOOK_OVERVIEW_EXPANDED =
  'ACTION_UPDATE_BOOK_OVERVIEW_EXPANDED';
export const TOGGLE_MODO_IMPRESSAO = 'ACTION_TOGGLE_MODO_IMPRESSAO';
export const TOGGLE_BIBLIOTECA_TEMAS = 'ACTION_TOGGLE_BIBLIOTECA_TEMAS';
export const UPDATE_THUMB_EXPAND = 'ACTION_UPDATE_THUMB_EXPAND';
export const UPDATE_THUMB_EXPAND_IDB = 'ACTION_UPDATE_THUMB_EXPAND_IDB';
export const UPDATE_PHOTO_EDITOR = 'ACTION_UPDATE_PHOTO_EDITOR';
export const UPDATE_TOOLBAR_PHOTO = 'ACTION_UPDATE_TOOLBAR_PHOTO';
export const SET_START_DRAG = 'ACTION_SET_START_DRAG';
export const SET_STOP_DRAG = 'ACTION_SET_STOP_DRAG';
export const UPDATE_TEXT_OPTION = 'ACTION_UPDATE_TEXT_OPTION';
export const UPDATE_WARNING = 'ACTION_UPDATE_WARNING';
export const TOGGLE_LEGENDA = 'ACTION_TOGGLE_LEGENDA';
export const SET_STATE_LEGENDA = 'ACTION_SET_STATE_LEGENDA';
export const SET_MODAL_CONFIG = 'ACTION_SET_MODAL_CONFIG';
