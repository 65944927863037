import PropTypes from 'prop-types';
import React from 'react';
import {
  FATOR_AJUSTE_WIDTH_LINHA_MIOLO,
  FATOR_AJUSTE_WIDTH_LINHA_MIOLO_A4R
} from '../../constant/mockup';
import {
  LISTA_PRODUTOS_PERMITIDOS_TIPO1,
  LISTA_PRODUTOS_PERMITIDOS_TIPO2,
  buscaAssets
} from '../../utils/buscaPermissoes';

const Mockup = ({
  width,
  height,
  prodCode,
  prodType,
  prodFormat,
  isCover,
  isPrint,
  isModoImpressao,
  isModoSobFivela,
  isLeftPage,
  tamanhoSelecionado,
  navigationIndex
}) => {
  const caminhoAsset = buscaAssets(prodCode, prodType, prodFormat);
  const template = 1;
  if (!isPrint && prodType === 'calendar' && prodFormat === 'P')
    return (
      <image
        width={width}
        pointerEvents="none"
        href={require(`../../asset/mockup/${prodType}/${prodFormat}/espiral.svg`)}
      />
    );
  else if (!isPrint && prodType === 'calendar' && prodFormat === 'A3-P')
    return (
      <image
        width={width}
        pointerEvents="none"
        href={require(`../../asset/mockup/${prodType}/A3_P/espiral.svg`)}
      />
    );
  else if (prodType === 'mask')
    return (
      <image
        id="mockup-mask"
        width={width}
        pointerEvents="none"
        href={
          isPrint
            ? require(`../../asset/mockup/${prodType}/molde-print.svg`)
            : isModoImpressao
              ? require(`../../asset/mockup/${prodType}/modo-impressao.svg`)
              : require(`../../asset/mockup/${prodType}/rosto.svg`)
        }
      />
    );
  else if (prodType === 'deck-cards') {
    if (!isPrint)
      return isModoSobFivela ? (
        <image
          id="mockup-deck-cards"
          width={width}
          pointerEvents="none"
          href={require(`../../asset/mockup/${prodType}/cantos-arredondados.svg`)}
        />
      ) : (
        <React.Fragment>
          <svg
            width={width}
            height="100%"
            viewBox={`37.5 27.5 ${width - 75} ${height - 75}`}
          >
            <image
              id="page-fill-deck-cards"
              width={width}
              pointerEvents="none"
              href={require(`../../asset/page-fill/${prodType}/${template}/${navigationIndex}.svg`)}
            />
          </svg>
          <image
            id="mockup-deck-cards"
            width={width}
            pointerEvents="none"
            href={require(`../../asset/mockup/${prodType}/cantos-arredondados.svg`)}
          />
        </React.Fragment>
      );
    else if (!isModoSobFivela)
      return (
        <image
          id="page-fill-deck-cards"
          width={width}
          pointerEvents="none"
          href={require(`../../asset/page-fill/${prodType}/${template}/${navigationIndex}.svg`)}
        />
      );
  } else if (
    LISTA_PRODUTOS_PERMITIDOS_TIPO1.includes(prodCode) &&
    !isPrint &&
    !isCover
  ) {
    const x = isLeftPage ? width - width * FATOR_AJUSTE_WIDTH_LINHA_MIOLO : 0;
    return (
      <image
        style={{
          mixBlendMode: 'multiply'
        }}
        x={x}
        height="100%"
        width={width * FATOR_AJUSTE_WIDTH_LINHA_MIOLO}
        pointerEvents="none"
        href={require(`../../asset/mockup/${caminhoAsset}/linha-miolo.svg`)}
      />
    );
  } else if (
    LISTA_PRODUTOS_PERMITIDOS_TIPO2.includes(prodCode) &&
    !isPrint &&
    !isCover
  ) {
    const x = isLeftPage
      ? width - width * FATOR_AJUSTE_WIDTH_LINHA_MIOLO_A4R
      : 0;
    return (
      <image
        style={{
          mixBlendMode: 'multiply'
        }}
        x={x}
        height="100%"
        // width={width*FATOR_AJUSTE_WIDTH_LINHA_MIOLO}
        pointerEvents="none"
        href={require(`../../asset/mockup/${caminhoAsset}/linha-miolo.svg`)}
      />
    );
  } else if (!isPrint && prodType === 'dog-collar' && isModoSobFivela) {
    return (
      <image
        id="mockup-mask"
        width={width}
        pointerEvents="none"
        href={require(`../../asset/mockup/dog-collar/${prodCode}/${tamanhoSelecionado}.png`)}
      />
    );
  }
  return null;
};

Mockup.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  prodCode: PropTypes.string.isRequired,
  prodType: PropTypes.string.isRequired,
  prodFormat: PropTypes.string.isRequired,
  isCover: PropTypes.bool.isRequired,
  isPrint: PropTypes.bool.isRequired,
  isModoImpressao: PropTypes.bool.isRequired,
  isModoSobFivela: PropTypes.bool.isRequired,
  isLeftPage: PropTypes.bool.isRequired,
  tamanhoSelecionado: PropTypes.number,
  navigationIndex: PropTypes.number.isRequired
};

export default Mockup;
