import IMAGEM_MODAL_MASK_PASSO_1 from '../asset/mesa/mask-passo1.jpg';
import IMAGEM_MODAL_MASK_PASSO_2 from '../asset/mesa/mask-passo2.jpg';
import IMAGEM_MODAL_MASK_PASSO_3 from '../asset/mesa/mask-passo3.jpg';

const TITULO_MODAL_MASK_PASSO_1 = "1º Passo para criar sua Máscara: fotografar perfeitamente";
const TITULO_MODAL_MASK_PASSO_2 = "Agora é hora de: posicionar sua imagem corretamente";
const TITULO_MODAL_MASK_PASSO_3 = "Por fim, o último passo: visualizar sua Máscara impressa";

const MENSAGEM_MODAL_MASK_PASSO_1 = "Para uma ótima qualidade de imagem na impressão, fotografe com seu celular ou câmera fotográfica, apenas seu rosto. Sua imagem deve exibir ele totalmente, como mostrado no exemplo acima.";
const MENSAGEM_MODAL_MASK_PASSO_2 = "Após o envio da imagem para o seu computador, adicione ela na Mesa e insira na Máscara. Edite sua imagem, alinhando o máximo possível com o contorno azul, priorizando: nariz, boca e queixo.";
// const MENSAGEM_MODAL_MASK_PASSO_3_anterior = "Com a imagem perfeitamente posicionada e ajustada, clique em \"Ver Máscara impressa\", para ver como você irá recebê-la em sua casa. Caso necessite, você pode editar sua imagem nessa visualização.";
const MENSAGEM_MODAL_MASK_PASSO_3 = "Com a imagem perfeitamente posicionada e ajustada, clique em \"Ver Máscara impressa\", para ver como ela ficará em seu rosto. Caso necessite, você pode editar sua imagem nessa visualização.";

export const TITULOS_MODAL_MASK = [TITULO_MODAL_MASK_PASSO_1, TITULO_MODAL_MASK_PASSO_2, TITULO_MODAL_MASK_PASSO_3];
export const MENSAGENS_MODAL_MASK = [MENSAGEM_MODAL_MASK_PASSO_1, MENSAGEM_MODAL_MASK_PASSO_2, MENSAGEM_MODAL_MASK_PASSO_3];
export const IMAGENS_MODAL_MASK = [IMAGEM_MODAL_MASK_PASSO_1, IMAGEM_MODAL_MASK_PASSO_2, IMAGEM_MODAL_MASK_PASSO_3];
export const MAX_PASSOS_MODAL_MASK = 2;