import './index.css';

import * as R from 'ramda';

import React, { Component } from 'react';
import {
  THEME_CLASS_BT_LEFT_ARROW_BLACK,
  THEME_CLASS_BT_RIGHT_ARROW_BLACK
} from '../../constant/button';
import {
  pagesFormatPropTypes,
  screenSizePropTypes,
  stylePropTypes
} from '../../proptypes';

import Button from '../button';
import PropTypes from 'prop-types';
import { THEME_CLASS_ICON_CHECK } from '../../constant/button';
import { ajusteTamanhoTextArea } from '../../utils/separadorProdutos';

const getPageDimensions = (formats, style) => {
  const {
    cover: { width: coverWidth, height: coverHeight },
    page: { width, height }
  } = formats;
  const pageWidth =
    style && (style.cover || style.back_cover) ? coverWidth : width;
  const pageHeight =
    style && (style.cover || style.back_cover) ? coverHeight : height;
  return { pageWidth, pageHeight };
};

const filterStyles = (styles, { text, photos }) =>
  styles.filter(
    ({ textarea, shape }) =>
      ((text === 'com' && textarea) || (text === 'sem' && !textarea)) &&
      shape &&
      shape.length === photos
  );

const ShapeFormat = ({ width, height, type, points }) => {
  switch (type) {
    case 'ellipse':
      return (
        <ellipse
          className="shape"
          cx={width / 2}
          cy={height / 2}
          rx={width / 2}
          ry={height / 2}
        />
      );
    case 'triangle':
      return (
        <polygon
          className="shape"
          points={`0,0 ${width},0 0,${height}`}
        ></polygon>
      );
    case 'custom':
      return <polygon className="shape" points={points}></polygon>;
    default:
      return (
        <rect x="0" y="0" width={width} height={height} className="shape" />
      );
  }
};

class LayoutsBox extends Component {
  state = {
    layoutsNumber: 0,
    maxLayouts: 0,
    start: 0,
    end: 0,
    margin: 0
  };

  PrepareLayoutsBox = (screenSize, { pageWidth, pageHeight }, index = 0) => {
    const layoutsContainer = document.querySelector('.layouts-container');
    const minMargin = screenSize.width * 0.006;

    if (layoutsContainer) {
      const layoutsContainerWidth = layoutsContainer.getBoundingClientRect()
        .width;
      const layoutBoxWidth =
        Math.floor((pageWidth * 40) / pageHeight) + minMargin;
      const maxLayouts = Math.floor(layoutsContainerWidth / layoutBoxWidth);
      const marginSum = layoutsContainerWidth - layoutBoxWidth * maxLayouts;
      const margin = marginSum / (maxLayouts - 1) + minMargin;

      this.setState({
        start: index > maxLayouts ? index - maxLayouts + 1 : 0,
        end: index > maxLayouts ? index + 1 : maxLayouts,
        maxLayouts,
        margin
      });
    }
  };

  scrollLayoutsContainer = direction => {
    const { layoutsNumber, start, end } = this.state;
    if (direction === 'RIGHT' && end !== layoutsNumber) {
      this.setState({ start: start + 1, end: end + 1 });
    }
    if (direction === 'LEFT' && start !== 0) {
      this.setState({ start: start - 1, end: end - 1 });
    }
  };

  resetLayoutContainer = () =>
    this.setState({ start: 0, end: this.state.maxLayouts });

  componentDidUpdate({
    layoutsFilter: { photos: prevPhotos, text: prevText }
  }) {
    const {
      layoutsFilter: { photos, text },
      styles
    } = this.props;
    if (prevPhotos !== photos || prevText !== text) {
      this.resetLayoutContainer();
      const stylesFiltered = filterStyles(styles, { photos, text });
      this.setState({ layoutsNumber: stylesFiltered.length });
    }
  }

  componentDidMount() {
    const { screenSize, pagesFormats, styles, layoutsFilter } = this.props;
    const stylesFiltered = filterStyles(styles, layoutsFilter);
    const pageDim = getPageDimensions(pagesFormats, styles[0]);
    const layoutSelectedIndex = R.findIndex(
      R.propEq('id', layoutsFilter.layoutSelected)
    )(stylesFiltered);

    this.PrepareLayoutsBox(screenSize, pageDim, layoutSelectedIndex);
    this.setState({ layoutsNumber: stylesFiltered.length });

    window.addEventListener('resize', () =>
      this.PrepareLayoutsBox(screenSize, pageDim)
    );
  }

  render() {
    const {
      styles,
      screenSize,
      layoutsFilter,
      pagesFormats,
      setLayout,
      setNavNone,
      prodType
    } = this.props;
    const { layoutsNumber, maxLayouts, start, end, margin } = this.state;
    const { pageWidth, pageHeight } = getPageDimensions(
      pagesFormats,
      styles[0]
    );

    const stylesFiltered = filterStyles(styles, layoutsFilter);

    return (
      <div
        className="layouts-box"
        style={{ maxWidth: `${screenSize.layoutsContainerWidth}px` }}
      >
        <Button
          padding={false}
          theme={THEME_CLASS_BT_LEFT_ARROW_BLACK}
          clickHandler={() => this.scrollLayoutsContainer('LEFT')}
          style={
            layoutsNumber <= maxLayouts || start === 0 ? { opacity: 0.05 } : {}
          }
          disabled={layoutsNumber <= maxLayouts || start === 0}
        />
        <div
          className="layouts-container"
          style={{ margin: `0px ${screenSize.width * 0.016}px` }}
        >
          {/* eslint-disable-next-line*/}
          {stylesFiltered.map(({ textarea, shape, id }, idx) => {
            const shapeWidth = Math.floor((pageWidth * 40) / pageHeight);
            return (
              idx >= start &&
              idx <= end - 1 && (
                <div key={id}>
                  {layoutsFilter.layoutSelected === id ? (
                    <div
                      className={`checked ${THEME_CLASS_ICON_CHECK}`}
                      style={{
                        marginLeft:
                          idx === end - 1
                            ? 'calc(50% - 8.5px)'
                            : `calc(50% - ${margin / 2}px - 8.5px)`
                      }}
                    />
                  ) : null}
                  <div
                    className={`${layoutsFilter.layoutSelected === id
                        ? 'shape-box active'
                        : 'shape-box'
                      }`}
                    style={{
                      width: `${shapeWidth}px`,
                      marginRight: `${idx === end - 1 ? 0 : margin}px`
                    }}
                    key={id}
                    onClick={() => setLayout(id)}
                    onDoubleClick={setNavNone}
                  >
                    <svg
                      width={shapeWidth}
                      height="40"
                      viewBox={`0 0 ${pageWidth} ${pageHeight}`}
                    >
                      <svg
                        x="0"
                        y="0"
                        width={pageWidth}
                        height={pageHeight}
                        viewBox={`0 0 ${pageWidth} ${pageHeight}`}
                      >
                        {shape.map(shape => (
                          <svg
                            x={shape.left}
                            y={shape.top}
                            width={shape.width}
                            height={shape.height}
                            key={shape.id}
                          >
                            <ShapeFormat
                              type={shape.type}
                              width={shape.width}
                              height={shape.height}
                              points={shape.params}
                            ></ShapeFormat>
                          </svg>
                        ))}
                        {textarea &&
                          textarea.map(textarea => (
                            <foreignObject
                              x={textarea.left}
                              y={textarea.top}
                              width={textarea.width}
                              height={ajusteTamanhoTextArea(
                                prodType,
                                textarea.height
                              )}
                              key={textarea.id}
                            >
                              <div className="text-area"></div>
                            </foreignObject>
                          ))}
                      </svg>
                    </svg>
                  </div>
                </div>
              )
            );
          })}
        </div>
        <Button
          padding={false}
          theme={THEME_CLASS_BT_RIGHT_ARROW_BLACK}
          clickHandler={() => this.scrollLayoutsContainer('RIGHT')}
          style={
            layoutsNumber <= maxLayouts || end === layoutsNumber
              ? { opacity: 0.05 }
              : {}
          }
          disabled={layoutsNumber <= maxLayouts || end === layoutsNumber}
        />
      </div>
    );
  }
}

LayoutsBox.propType = {
  styles: PropTypes.arrayOf(stylePropTypes).isRequired,
  screenSize: screenSizePropTypes,
  layoutsFilter: PropTypes.shape({
    applyMethod: PropTypes.string.isRequired,
    layoutSelected: PropTypes.number.isRequired,
    menuApplyMethod: PropTypes.bool.isRequired,
    menuText: PropTypes.bool.isRequired,
    pages: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
    ).isRequired,
    photos: PropTypes.number.isRequired,
    side: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired
  }).isRequired,
  pagesFormats: pagesFormatPropTypes,
  setLayout: PropTypes.func.isRequired,
  setNavNone: PropTypes.func.isRequired,
  prodType: PropTypes.string.isRequired
};

export default LayoutsBox;
