// import metadata from './metadata.json';
// import json16 from './json16.json';

export const getItem = (productId, pages) => {
  const urlPages = pages ? '?pages=' + pages : '';
  // if (productId === '16') return json16;
  return fetch(`/api/xml/${productId}/json` + urlPages).then(response =>
    response.json()
  );
};

export const getMetadatas = itemId => {
  // if (itemId === 26070084) return metadata;
  return fetch(`/api/items/${itemId}/metadata`).then(response =>
    response.json()
  );
};

export const getServerImage = (itemId, fileId) => {
  return fetch(`/api/items/${itemId}/file/${fileId}`).then(r => r.blob());
};

export const resetServerImage = (itemId, fileId) =>
  fetch(`/api/items/${itemId}/file/${fileId}/reset`, {
    method: 'POST',
    credentials: 'same-origin'
  }).then(r => r.json());

export const postLog = (msg) => {
  try {
    fetch('/api/log', {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'Content-type': 'application/json',
        Accept: 'application/json'
      },
      body: JSON.stringify({
        type: "info",
        msg: JSON.stringify(msg)
      })
    });
  }
  catch (e) {
    console.log('Log error');
  }
};

export const postItem = async (url, data, img) => {
  const postOptions = {
    method: 'POST',
    credentials: 'same-origin',
    headers: img || {
      'Content-type': 'application/json',
      Accept: 'application/json'
    },
    body: img ? data : JSON.stringify(data)
  };

  try {
    postLog({
      itemID: url ? url.split('/')[3] : '-',
      imageHash: url ? url.split('/')[5] : '-',
      imageName: (data && data.name) || '-',
      postUrl: url || '-',
      img: img || '-',
      timestamp: new Date(),
    });
  }
  catch (e) {
    console.log('Error generating log data');
  }

  return fetch(url, postOptions).then(response => {
    if (response.ok) {
      return response.json();
    }
    return Promise.reject();
  });
};

export const fetchMaxUserDiskSpace = async itemId => {
  return fetch(`/api/items/${itemId}/status`)
    .then(async response => {
      if (response.ok) {
        const result = await response.json();
        return result.maxUserDiskSpace ? Number(result.maxUserDiskSpace) : null;
      }
      return null;
    })
    .catch(() => null);
};
