export const LISTA_PRODUTOS_PERMITIDOS_TIPO1 = ['PR_10_24','PR_10_25'];
export const LISTA_PRODUTOS_PERMITIDOS_TIPO2 = ['PR_10_12', 'PR_10_13'];
export const LISTA_PRODFORMAT_TIPO1 = ['A4-P','A5-P'];
export const LISTA_PRODFORMAT_TIPO2 = ['A4-R', 'A5-R'];

export const hasDebru = (prodCode) => (LISTA_PRODUTOS_PERMITIDOS_TIPO1.includes(prodCode) || LISTA_PRODUTOS_PERMITIDOS_TIPO2.includes(prodCode))

export function buscaAssets(prodCode, prodType, prodFormat) {
    if(LISTA_PRODUTOS_PERMITIDOS_TIPO1.includes(prodCode))
    {
        if(prodType === 'book' && LISTA_PRODFORMAT_TIPO1.includes(prodFormat))
        {
            return 'book/A4-P';
        }
    }
    else if(LISTA_PRODUTOS_PERMITIDOS_TIPO2.includes(prodCode))
    {
        if(prodType === 'book' && LISTA_PRODFORMAT_TIPO2.includes(prodFormat))
        {
            return 'book/A4-R';
        }
    }
    else if(prodType === 'deck-cards')
    {
            return 'deck-cards';
    }
}

export function buscaAssetsInternos(prodCode, prodType, prodFormat) {
    if(LISTA_PRODUTOS_PERMITIDOS_TIPO1.includes(prodCode))
    {
        if(prodType === 'book' && prodFormat === 'A4-P')
        {
            return 'book/A4-P';
        }
        else if(prodType === 'book' && prodFormat === 'A5-P')
        {
            return 'book/A5-P';
        }
    }
    else if(LISTA_PRODUTOS_PERMITIDOS_TIPO2.includes(prodCode))
    {
        if(prodType === 'book' && prodFormat === 'A4-R')
        {
            return 'book/A4-R';
        }
        else if(prodType === 'book' && prodFormat === 'A5-R')
        {
            return 'book/A5-R';
        }
    }
}