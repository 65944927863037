import PropTypes from 'prop-types';
import React from 'react';
import { getFundoOpacity } from '../../utils/calculosFundos';

const getCssRegexValues = cssNumber =>
  /([0-9]+)?(px|%|em|vh|vw|pt|mm|cm|in)?/g.exec(cssNumber);
const getCssValues = cssNumber => {
  const [, strValue, unit = 'px'] = getCssRegexValues(cssNumber);
  return [parseInt(strValue, 10), unit];
};

const calculateComponentsBounds = (
  width,
  height,
  scale,
  addLeft = 0,
  addTop = 0
) => {
  const [widthValue, widthUnit] = getCssValues(width);
  const [heightValue, heightUnit] = getCssValues(height);

  const imgWidth = `${widthValue * scale}${widthUnit}`;
  const imgHeight = `${heightValue * scale}${heightUnit}`;

  const invertedScale = 1 - scale;
  const imgHalfWidth = `${(widthValue * invertedScale) / 2 +
    addLeft}${widthUnit}`;
  const imgHalfHeight = `${(heightValue * invertedScale) / 2 +
    addTop}${heightUnit}`;

  return { imgWidth, imgHeight, imgHalfWidth, imgHalfHeight };
};

const PlaceholderEl = ({
  type,
  width,
  height,
  photoEditorActive,
  isBookOverviewExpanded,
  isOver,
  canDrop,
  background,
  points
}) => {
  switch (type) {
    case 'ellipse':
      return (
        <ellipse
          cx={width / 2}
          cy={height / 2}
          rx={width / 2}
          ry={height / 2}
          style={{
            fill:
              canDrop && !isOver && !isBookOverviewExpanded
                ? '#a7a7a7'
                : background,
            transition: photoEditorActive ? '' : 'fill 250ms'
          }}
        />
      );
    case 'triangle':
      return (
        <polygon
          points={`0,0 ${width},0 0,${height}`}
          style={{
            fill:
              canDrop && !isOver && !isBookOverviewExpanded
                ? '#a7a7a7'
                : background,
            transition: photoEditorActive ? '' : 'fill 250ms'
          }}
        ></polygon>
      );
    case 'custom':
      return (
        <polygon
          points={points}
          style={{
            fill:
              canDrop && !isOver && !isBookOverviewExpanded
                ? '#a7a7a7'
                : background,
            transition: photoEditorActive ? '' : 'fill 250ms'
          }}
        ></polygon>
      );
    default:
      return (
        <rect
          x="0"
          y="0"
          style={{
            fill:
              canDrop && !isOver && !isBookOverviewExpanded
                ? '#a7a7a7'
                : background,
            transition: photoEditorActive ? '' : 'fill 250ms'
          }}
          width={width}
          height={height}
        />
      );
  }
};

const PhotoPlaceholder = ({
  width,
  height,
  x,
  y,
  type,
  resizeMountain,
  shape,
  background,
  fill,
  scale,
  connectDropTarget,
  canDrop,
  isOver,
  photoEditorActive,
  isBookOverviewExpanded,
  points
}) => {
  const { addLeft, addTop } = resizeMountain;
  const {
    imgWidth,
    imgHeight,
    imgHalfWidth,
    imgHalfHeight
  } = calculateComponentsBounds(
    shape.width,
    shape.height,
    scale,
    addLeft,
    addTop
  );

  return connectDropTarget(
    <svg
      x={x}
      y={y}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      className="photo-placeholder"
      opacity={getFundoOpacity()}
    >
      <PlaceholderEl
        type={type}
        width={width}
        height={height}
        photoEditorActive={photoEditorActive}
        isBookOverviewExpanded={isBookOverviewExpanded}
        isOver={isOver}
        canDrop={canDrop}
        background={background}
        points={points}
      ></PlaceholderEl>
      <svg
        width={imgWidth}
        height={imgHeight}
        viewBox="0 0 48 36"
        x={imgHalfWidth}
        y={imgHalfHeight}
      >
        <defs>
          <path d="M12 4 L28 24 L36 18 L48 36 L0 36 L12 4Z" id="c" />
        </defs>
        <circle cx="42" cy="6" r="6" style={{ fill }} />
        <use xlinkHref="#c" style={{ fill }} />
      </svg>
    </svg>
  );
};

PhotoPlaceholder.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  x: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  y: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string.isRequired,
  resizeMountain: PropTypes.shape({
    addLeft: PropTypes.number.isRequired,
    addTop: PropTypes.number.isRequired
  }).isRequired,
  shape: PropTypes.object.isRequired,
  background: PropTypes.string.isRequired,
  fill: PropTypes.string.isRequired,
  scale: PropTypes.number.isRequired,
  connectDropTarget: PropTypes.func.isRequired,
  canDrop: PropTypes.bool.isRequired,
  isOver: PropTypes.bool.isRequired,
  photoEditorActive: PropTypes.bool.isRequired,
  isBookOverviewExpanded: PropTypes.bool.isRequired,
  points: PropTypes.string
};

PhotoPlaceholder.defaultProps = {
  scale: 0.75,
  x: 0,
  y: 0,
  background: '#E6E6E6',
  fill: '#fff'
};

export default PhotoPlaceholder;
