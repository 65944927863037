import './index.css';

import {
  THEME_CLASS_BT_BOTTOM_ARROW_WHITE,
  THEME_CLASS_BT_LEFT_ARROW_WHITE,
  THEME_CLASS_BT_LEFT_DOUBLE_ARROW_WHITE,
  THEME_CLASS_BT_MENU_STYLE_MANAGER,
  THEME_CLASS_BT_MENU_STYLE_MANAGER_PRESSED,
  THEME_CLASS_BT_RIGHT_ARROW_WHITE,
  THEME_CLASS_BT_RIGHT_DOUBLE_ARROW_WHITE,
  THEME_CLASS_BT_TOP_ARROW_WHITE,
  THEME_CLASS_MENU_BOOK,
  THEME_CLASS_MENU_BOOK_PRESSED,
  THEME_CLASS_FUNDO,
  THEME_CLASS_FUNDO_ACTIVE,
  THEME_CLASS_BT_SHUFFLE_NEXT,
  THEME_CLASS_BT_SHUFFLE_NEXT_INACTIVE,
  THEME_CLASS_BT_SHUFFLE_PREVIOUS,
  THEME_CLASS_BT_SHUFFLE_PREVIOUS_INACTIVE
} from '../../constant/button';
import { pagePropTypes, screenSizePropTypes } from '../../proptypes';

import {
  getTipoQuantidade,
  getTipoQuantidadeMenuEsq,
  getVoltarOverview,
  getBookContainerDataTip,
  getGeneroProduto,
  getTipoQuantidadePadrao,
  textoOverviewExpand,
  setaEsquerdaDataTip,
  setaDireitaDataTip
} from '../../utils/textosVariaveis';
import MenuBookDireito from './MenuBookDireito';
import { getShuffleLayoutActive } from '../../utils/calculosGerais';
import {
  enableNavigation,
  getMaxIndex,
  isOverViewDisabled
} from '../../utils/separadorProdutos';

import Button from '../button';
import PropTypes from 'prop-types';
import React from 'react';
import ReactTooltip from 'react-tooltip';
import KeyboardEventHandler from 'react-keyboard-event-handler';

const toogleMenuBookPropTypes = {
  toogleMenuBook: PropTypes.func.isRequired
};

const isMenuBookSelectedPropTypes = {
  isMenuBookSelected: PropTypes.bool
};

const isMenuBookSelectedDefautProps = {
  isMenuBookSelected: false
};

const toogleMenuLayoutsPropTypes = {
  toogleMenuLayouts: PropTypes.func.isRequired
};

const isMenuLayoutsSelectedPropTypes = {
  isMenuLayoutsSelected: PropTypes.bool
};

const isMenuLayoutsSelectedDefautProps = {
  isMenuLayoutsSelected: false
};

const toogleMenuFundosPropTypes = {
  toogleMenuFundos: PropTypes.func.isRequired
};

const isMenuFundosSelectedPropTypes = {
  isMenuFundosSelected: PropTypes.bool
};

const isMenuFundosSelectedDefautProps = {
  isMenuFundosSelected: false
};

const BtContainer = ({
  clickHandler,
  theme,
  margin,
  direction,
  overview,
  disabled,
  dataTip
}) => (
  <div
    className={`bt-container${overview && ' bt-container-overview'}`}
    data-tip={disabled ? '' : dataTip}
    style={
      direction === 'LEFT'
        ? { marginLeft: `${margin - 4}px` }
        : direction === 'RIGHT'
          ? { marginRight: `${margin - 4}px` }
          : { marginBottom: `2px` }
    }
  >
    <Button
      padding={false}
      theme={theme}
      clickHandler={clickHandler}
      style={disabled ? { opacity: 0.25 } : {}}
      disabled={disabled}
    />
  </div>
);

BtContainer.propTypes = {
  isLeftDirection: PropTypes.bool,
  clickHandler: PropTypes.func,
  theme: PropTypes.string,
  margin: PropTypes.number.isRequired,
  direction: PropTypes.string,
  overview: PropTypes.bool,
  dataTip: PropTypes.string.isRequired
};

BtContainer.defaultProps = {
  isLeftDirection: false
};

const BtBookContainer = ({ toogleMenuBook, isMenuBookSelected, margin }) => (
  <div
    className="bt-menu-book-container"
    data-tip={getBookContainerDataTip()}
    style={{ margin: `1px ${margin - 6}px 0` }}
  >
    <Button
      padding={false}
      theme={
        isMenuBookSelected
          ? THEME_CLASS_MENU_BOOK_PRESSED
          : THEME_CLASS_MENU_BOOK
      }
      clickHandler={function () {
        toogleMenuBook(isMenuBookSelected);
      }}
    />
  </div>
);

BtBookContainer.propTypes = {
  ...toogleMenuBookPropTypes,
  ...isMenuBookSelectedPropTypes,
  isLeftDirection: PropTypes.bool
};

BtBookContainer.defaultProps = {
  ...isMenuBookSelectedDefautProps,
  isLeftDirection: false
};

const Arrow = ({ isLeftDirection, disabled }) => (
  <Button
    padding={false}
    theme={
      isLeftDirection
        ? THEME_CLASS_BT_LEFT_ARROW_WHITE
        : THEME_CLASS_BT_RIGHT_ARROW_WHITE
    }
    style={disabled ? { opacity: 0.25 } : {}}
    disabled={disabled}
  />
);

Arrow.propTypes = {
  isLeftDirection: PropTypes.bool,
  disabled: PropTypes.bool
};

Arrow.defaultProps = {
  isLeftDirection: false,
  disabled: false
};

const PageNavigator = ({
  isLeftDirection,
  clickHandler,
  screenSize,
  disabled,
  prodType
}) => {
  return (
    <div
      className={`page-navigator`}
      onClick={clickHandler}
      style={
        isLeftDirection
          ? {
            marginLeft: `${screenSize.width * 0.015 - 2}px`,
            justifyContent: 'flex-start'
          }
          : {
            marginRight: `${screenSize.width * 0.015 - 2}px`,
            justifyContent: 'flex-end'
          }
      }
    >
      <div
        className="arrow-container"
        data-place={isLeftDirection ? 'right' : 'left'}
        data-tip={
          disabled
            ? ''
            : isLeftDirection
              ? setaEsquerdaDataTip(prodType)
              : setaDireitaDataTip(prodType)
        }
      >
        <Arrow isLeftDirection={isLeftDirection} disabled={disabled} />
      </div>
    </div>
  );
};

PageNavigator.propTypes = {
  isLeftDirection: PropTypes.bool,
  clickHandler: PropTypes.func.isRequired,
  screenSize: screenSizePropTypes,
  disabled: PropTypes.bool,
  prodType: PropTypes.string
};

PageNavigator.defaultProps = {
  isLeftDirection: false
};

const formatNumber = number => (number > 9 ? number : `0${number}`);
const mostrarQuantidadeTotal = (prodType, isModoSobFivela) =>
  !(prodType === 'deck-cards' && isModoSobFivela);

const MenuBook = ({
  pages,
  toogleMenuBook,
  isMenuBookSelected,
  toogleMenuLayouts,
  toogleMenuFundos,
  toggleBookOverviewExpanded,
  isMenuLayoutsSelected,
  isMenuFundosSelected,
  isGalleryExpanded,
  isBookOverviewExpanded,
  bookHaveCover,
  visiblePages,
  pageIndex,
  maxIndex: defaultMaxIndex,
  incrementNavigationIndex,
  decrementNavigationIndex,
  firstPage,
  lastPage,
  setPage,
  screenSize,
  suffleLayout,
  adjustLayoutsFilter,
  layoutsFilter,
  updateLayoutFilterSide,
  isPhotoEditorActive,
  isThumbExpandedActive,
  prodType,
  prodFormat,
  toggleModoImpressao,
  toggleModoSobFivela,
  isModoImpressao,
  isModoSobFivela,
  isPopupOpened,
  isTutorial,
  isOverviewRemovePages,
  toggleRemovePages,
  tamanhoSelecionado,
  isMenuTextSelected
}) => {
  const generoProd = getGeneroProduto(prodType);
  const tipoQtdProd = getTipoQuantidade(true);
  const disableControls = () => prodType === 'deck-cards' && isModoSobFivela;
  const mostradorNumeroPagina =
    prodType === 'deck-cards' && !isModoSobFivela
      ? pageIndex + 1
      : pages[0].index;
  const shuffleLayoutActive = getShuffleLayoutActive(
    prodType,
    pages[0],
    layoutsFilter.pages
  );

  const maxIndex = getMaxIndex(prodType, defaultMaxIndex);

  return (
    <div
      className="menu-book"
      style={
        isGalleryExpanded
          ? { display: 'none' }
          : isBookOverviewExpanded
            ? {
              zIndex: isPhotoEditorActive || isThumbExpandedActive ? 1 : 5,
              flexGrow: 1,
              display: 'flex',
              alignItems: 'flex-end'
            }
            : { zIndex: isPhotoEditorActive || isThumbExpandedActive ? 1 : 5 }
      }
    >
      <KeyboardEventHandler
        handleKeys={['left', 'right', 'up', 'down', 'esc', 'enter']}
        handleFocusableElements={true}
        onKeyEvent={key => {
          if (
            !isPhotoEditorActive &&
            !isPopupOpened &&
            !isTutorial &&
            !isMenuTextSelected
          ) {
            if (prodType === 'dog-collar') {
              if (
                key === 'left' &&
                !isBookOverviewExpanded &&
                !isOverviewRemovePages
              )
                decrementNavigationIndex(pageIndex);
              else if (
                key === 'right' &&
                !isBookOverviewExpanded &&
                !isOverviewRemovePages
              )
                incrementNavigationIndex();
              else if (
                key === 'down' &&
                isBookOverviewExpanded &&
                !isOverviewRemovePages
              )
                incrementNavigationIndex(pageIndex);
              else if (key === 'up' && !isOverviewRemovePages) {
                if (isBookOverviewExpanded) decrementNavigationIndex();
                else toggleBookOverviewExpanded(isBookOverviewExpanded);
              } else if (
                (key === 'esc' || key === 'enter') &&
                isBookOverviewExpanded
              ) {
                toggleBookOverviewExpanded(isBookOverviewExpanded);
                if (isOverviewRemovePages) toggleRemovePages();
              }
            } else {
              if (key === 'left' && !isOverviewRemovePages)
                decrementNavigationIndex(pageIndex);
              else if (key === 'right' && !isOverviewRemovePages)
                incrementNavigationIndex();
              else if (
                key === 'up' &&
                !isOverViewDisabled(prodType) &&
                !isBookOverviewExpanded
              )
                toggleBookOverviewExpanded(isBookOverviewExpanded);
              else if (
                (key === 'down' || key === 'esc' || key === 'enter') &&
                isBookOverviewExpanded
              ) {
                toggleBookOverviewExpanded(isBookOverviewExpanded);
                if (isOverviewRemovePages) toggleRemovePages();
              }
            }
          }
        }}
      />
      <ReactTooltip />
      <div className="content">
        <PageNavigator
          isLeftDirection
          clickHandler={decrementNavigationIndex}
          screenSize={screenSize}
          disabled={maxIndex === 0 || disableControls()}
          prodType={prodType}
        />
        <div className="btn-label-container">
          {prodType === 'invitation' ? (
            <div className="padd-num" data-tip="Lado Atual do Convite">
              <span className="label-modo-visualizacao">
                {pageIndex === 0 ? 'Convite Frente' : 'Convite Verso'}
              </span>
            </div>
          ) : (
            <div className="padd-num" data-tip={`${tipoQtdProd} Atual`}>
              <span className="label">
                {visiblePages === 1 && `${getTipoQuantidadeMenuEsq()} `}
                {pages.length > 0 &&
                  mostrarQuantidadeTotal(prodType, isModoSobFivela) &&
                  (pages[0].label || formatNumber(mostradorNumeroPagina))}
                {visiblePages === 1 &&
                  mostrarQuantidadeTotal(prodType, isModoSobFivela) && (
                    <span style={{ opacity: 0.5 }}>
                      {' '}
                      de {`${maxIndex + 1}`.padStart(2, '0')}
                    </span>
                  )}
              </span>
            </div>
          )}
          {(visiblePages > 1 || prodType === 'deck-cards') && (
            <div
              className="page-btns"
              style={{
                display:
                  pages.length > 0 && pages[0].label !== 'Guarda'
                    ? 'block'
                    : 'none',
                paddingLeft: '7px'
              }}
            >
              <Button
                theme={
                  shuffleLayoutActive
                    ? THEME_CLASS_BT_SHUFFLE_PREVIOUS
                    : THEME_CLASS_BT_SHUFFLE_PREVIOUS_INACTIVE
                }
                clickHandler={() =>
                  shuffleLayoutActive
                    ? suffleLayout(pages[0].id, 'DECREASE')
                    : null
                }
                dataTip={
                  shuffleLayoutActive
                    ? 'Layout Anterior: Quantidade de imagens igual ou menor'
                    : ''
                }
                style={{
                  cursor: `${shuffleLayoutActive ? 'pointer' : 'auto'}`,
                  marginRight: `${screenSize.width * 0.008 - 9}px`
                }}
              />
              <Button
                theme={
                  isMenuLayoutsSelected && layoutsFilter.side === 'LEFT'
                    ? THEME_CLASS_BT_MENU_STYLE_MANAGER_PRESSED
                    : THEME_CLASS_BT_MENU_STYLE_MANAGER
                }
                clickHandler={function () {
                  toogleMenuLayouts(isMenuLayoutsSelected);
                  updateLayoutFilterSide('LEFT');
                  adjustLayoutsFilter();
                }}
                style={{ marginRight: `${screenSize.width * 0.008 - 9}px` }}
                dataTip={`Escolher Layout: Exibe todos os layouts e ${getGeneroProduto(
                  prodType,
                  true
                )} ${getTipoQuantidadePadrao()} que deseja aplicar`}
              />
              <Button
                theme={
                  shuffleLayoutActive
                    ? THEME_CLASS_BT_SHUFFLE_NEXT
                    : THEME_CLASS_BT_SHUFFLE_NEXT_INACTIVE
                }
                clickHandler={() =>
                  shuffleLayoutActive
                    ? suffleLayout(pages[0].id, 'INCREASE')
                    : null
                }
                dataTip={
                  shuffleLayoutActive
                    ? 'Próximo Layout: Quantidade de imagens igual ou maior'
                    : ''
                }
                style={{
                  cursor: `${shuffleLayoutActive ? 'pointer' : 'auto'}`
                }}
              />
              {prodType === 'deck-cards' && isModoSobFivela && (
                <Button
                  theme={
                    isMenuFundosSelected
                      ? THEME_CLASS_FUNDO_ACTIVE
                      : THEME_CLASS_FUNDO
                  }
                  dataTip={`Escolher Fundo: Exibe todos os fundos e ${getGeneroProduto(
                    prodType,
                    true
                  )} ${getTipoQuantidadePadrao()} que deseja aplicar`}
                  clickHandler={function () {
                    toogleMenuFundos(isMenuFundosSelected);
                  }}
                  style={{ marginTop: '-1px' }}
                />
              )}
            </div>
          )}
        </div>
        <div className="menu-container-outside">
          <BtContainer
            theme={
              isBookOverviewExpanded
                ? THEME_CLASS_BT_BOTTOM_ARROW_WHITE
                : THEME_CLASS_BT_TOP_ARROW_WHITE
            }
            overview
            dataTip={
              isBookOverviewExpanded
                ? getVoltarOverview()
                : textoOverviewExpand(prodType)
            }
            margin={screenSize.width * 0.006}
            disabled={isOverViewDisabled(prodType) || disableControls()}
            clickHandler={() => {
              toggleBookOverviewExpanded(isBookOverviewExpanded);
              if (isOverviewRemovePages) toggleRemovePages();
            }}
          />
          <div className="menu-container">
            {enableNavigation(prodType) && (
              <BtContainer
                clickHandler={firstPage}
                theme={THEME_CLASS_BT_LEFT_DOUBLE_ARROW_WHITE}
                margin={screenSize.width * 0.006}
                direction={'LEFT'}
                dataTip={`Primeir${generoProd} ${tipoQtdProd}`}
                disabled={(!bookHaveCover && !pageIndex) || disableControls()}
              />
            )}
            <BtContainer
              clickHandler={() => decrementNavigationIndex(pageIndex)}
              theme={THEME_CLASS_BT_LEFT_ARROW_WHITE}
              margin={screenSize.width * 0.006}
              direction={'LEFT'}
              dataTip={setaEsquerdaDataTip(prodType)}
              disabled={maxIndex === 0 || disableControls()}
            />
            <BtBookContainer
              toogleMenuBook={toogleMenuBook}
              isMenuBookSelected={isMenuBookSelected}
              margin={screenSize.width * 0.006}
            />
            <BtContainer
              clickHandler={incrementNavigationIndex}
              theme={THEME_CLASS_BT_RIGHT_ARROW_WHITE}
              margin={screenSize.width * 0.006}
              direction={'RIGHT'}
              dataTip={setaDireitaDataTip(prodType)}
              disabled={maxIndex === 0 || disableControls()}
            />
            {enableNavigation(prodType) && (
              <BtContainer
                clickHandler={lastPage}
                theme={THEME_CLASS_BT_RIGHT_DOUBLE_ARROW_WHITE}
                margin={screenSize.width * 0.006}
                direction={'RIGHT'}
                dataTip={`Últim${generoProd} ${tipoQtdProd}`}
                disabled={
                  (!bookHaveCover && pageIndex === maxIndex) ||
                  disableControls()
                }
              />
            )}
          </div>
        </div>
        <MenuBookDireito
          pages={pages}
          toogleMenuLayouts={toogleMenuLayouts}
          isMenuLayoutsSelected={isMenuLayoutsSelected}
          isMenuFundosSelected={isMenuFundosSelected}
          visiblePages={visiblePages}
          screenSize={screenSize}
          suffleLayout={suffleLayout}
          adjustLayoutsFilter={adjustLayoutsFilter}
          layoutsFilter={layoutsFilter}
          updateLayoutFilterSide={updateLayoutFilterSide}
          prodType={prodType}
          prodFormat={prodFormat}
          toggleModoImpressao={toggleModoImpressao}
          toggleModoSobFivela={toggleModoSobFivela}
          isModoImpressao={isModoImpressao}
          isModoSobFivela={isModoSobFivela}
          tamanhoSelecionado={tamanhoSelecionado}
          isBookOverviewExpanded={isBookOverviewExpanded}
          pageIndex={pageIndex}
          firstPage={firstPage}
          setPage={setPage}
        />
        <PageNavigator
          clickHandler={() => incrementNavigationIndex(pageIndex)}
          screenSize={screenSize}
          disabled={maxIndex === 0 || disableControls()}
          prodType={prodType}
        />
      </div>
    </div>
  );
};

MenuBook.propTypes = {
  pages: PropTypes.arrayOf(pagePropTypes).isRequired,
  ...toogleMenuBookPropTypes,
  ...isMenuBookSelectedPropTypes,
  ...toogleMenuLayoutsPropTypes,
  ...toogleMenuFundosPropTypes,
  toggleBookOverviewExpanded: PropTypes.func.isRequired,
  ...isMenuLayoutsSelectedPropTypes,
  ...isMenuFundosSelectedPropTypes,
  isGalleryExpanded: PropTypes.bool.isRequired,
  isBookOverviewExpanded: PropTypes.bool.isRequired,
  bookHaveCover: PropTypes.bool.isRequired,
  visiblePages: PropTypes.number.isRequired,
  pageIndex: PropTypes.number.isRequired,
  maxIndex: PropTypes.number.isRequired,
  incrementNavigationIndex: PropTypes.func.isRequired,
  decrementNavigationIndex: PropTypes.func.isRequired,
  firstPage: PropTypes.func.isRequired,
  lastPage: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
  screenSize: screenSizePropTypes,
  suffleLayout: PropTypes.func.isRequired,
  adjustLayoutsFilter: PropTypes.func.isRequired,
  layoutsFilter: PropTypes.shape({
    layoutSelected: PropTypes.number,
    pages: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    ),
    side: PropTypes.string
  }).isRequired,
  updateLayoutFilterSide: PropTypes.func.isRequired,
  isPhotoEditorActive: PropTypes.bool.isRequired,
  isThumbExpandedActive: PropTypes.bool.isRequired,
  prodType: PropTypes.string.isRequired,
  prodFormat: PropTypes.string.isRequired,
  toggleModoImpressao: PropTypes.func.isRequired,
  toggleModoSobFivela: PropTypes.func.isRequired,
  isModoImpressao: PropTypes.bool.isRequired,
  isModoSobFivela: PropTypes.bool.isRequired,
  isOverviewRemovePages: PropTypes.bool.isRequired,
  toggleRemovePages: PropTypes.func.isRequired,
  tamanhoSelecionado: PropTypes.number,
  isMenuTextSelected: PropTypes.bool.isRequired
};

MenuBook.defaultProps = {
  ...isMenuBookSelectedDefautProps,
  ...isMenuLayoutsSelectedDefautProps,
  ...isMenuFundosSelectedDefautProps
};

export default MenuBook;
