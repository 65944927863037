import './index.css';

import React from 'react';
import PropTypes from 'prop-types';

import { mostrarQtdUtilizada } from '../../utils/separadorProdutos';

const IconeUtilizacaoImagem = ({
  source,
  prodType,
  usedImage,
  fImgWidth,
  fImgHeight
}) => {
  if(source)
    return (
      <div
        className={
          mostrarQtdUtilizada.includes(prodType) && usedImage > 0 ? 'icon-quantidade-utilizada':
          `selected-btn bt-menu-selected${usedImage > 1 ? '-double':''} ${usedImage<= 0?'displayNone':''}`
        }
        style={{
          left: `${Math.max((90 - fImgWidth) / 2 - 1, 2)}px`,
          top: `${Math.max((60 - fImgHeight) / 2 - 1, 1)}px`
        }}
      >
        {mostrarQtdUtilizada.includes(prodType) && usedImage > 0 && <div className="quantidade-utilizada"
          style={{
            fontSize: usedImage <100 ? '9.75px': '7px'
          }}
          >
            {usedImage}
          </div>}
      </div>
    );
  return null
};

IconeUtilizacaoImagem.propTypes = {
  source: PropTypes.string.isRequired,
  prodType: PropTypes.string.isRequired,
  usedImage: PropTypes.number.isRequired,
  fImgWidth: PropTypes.number.isRequired,
  fImgHeight: PropTypes.number.isRequired,
};

export default IconeUtilizacaoImagem;
