import {calculaFinalPageWidth} from '../utils/calculosGerais';

export const buscaIndexStyle = (prodType, tamanhoColeira, textareas, styles) => {
  if(prodType === 'dog-collar')
  {
    let buscaIndex = styles.findIndex(({textarea}) => textarea != null && textarea.length > 0 && textarea[0].collar_size === tamanhoColeira);
    const index = buscaIndex < 0 ? 0:buscaIndex;
    return styles[index].textarea;
  }

  return textareas;
}

export function paddingTopColeira(family, size)
{
    let paddingTop = 0;
    return paddingTop;
}

export function lineHeight(prodType, family, size)
{
    let lineHeight = 'auto';
    if(prodType === 'dog-collar'){
        switch (family) {
            case 'Abril Fatface': lineHeight = '140px'; break;
            case 'Arvo': lineHeight = '147px'; break;
            case 'Blokletters Balpen': lineHeight = '162px'; break;
            case 'Cookie': lineHeight = '158px'; break;
            case 'Gandhi Sans': lineHeight = '160px'; break;
            case 'GoodDog': lineHeight = '140px'; break;
            case 'Grand Hotel': lineHeight = '160px'; break;
            case 'Josefin Sans': lineHeight = '170px'; break;
            case 'Liberation Sans': lineHeight = '148px'; break;
            case 'Liberation Serif': lineHeight = '144px'; break;
            case 'Lobster Two': lineHeight = '144px'; break;
            case 'Walkway': lineHeight = '144px'; break;
            default: break;
        }
    }
    return lineHeight;
}

export function calculaLineHeightColeira(family)
{
    let lineHeight = 140;
    switch (family) {
        case 'Abril Fatface': lineHeight = 140; break;
        case 'Arvo': lineHeight = 147; break;
        case 'Blokletters Balpen': lineHeight = 162; break;
        case 'Cookie': lineHeight = 158; break;
        case 'Gandhi Sans': lineHeight = 160; break;
        case 'GoodDog': lineHeight = 140; break;
        case 'Grand Hotel': lineHeight = 160; break;
        case 'Josefin Sans': lineHeight = 170; break;
        case 'Liberation Sans': lineHeight = 148; break;
        case 'Liberation Serif': lineHeight = 144; break;
        case 'Lobster Two': lineHeight = 144; break;
        case 'Walkway': lineHeight = 144; break;
        default: break;
    }
    return lineHeight;
}

export function fontSize(placeholder, editMode, prodType, family, size){
    let fontSize = (placeholder && !editMode) ? 64: size * 4;
    if(prodType === 'deck-cards') fontSize = size*5;
    else if(prodType === 'dog-collar' && !(placeholder && !editMode)){
        switch (family) {
            case 'Arvo':
                if(size === 24) fontSize = 96;
                else if(size === 25) fontSize = 100;
                else if(size === 26) fontSize = 104;
                else if(size === 27) fontSize = 108; 
                break;
            case 'Blokletters Balpen': 
                if(size === 21) fontSize = 84; 
                break;
            case 'Cookie': 
                if(size === 27) fontSize = 108;
                else if(size === 29) fontSize = 116;
                break;
            case 'Gandhi Sans': 
                if(size === 27) fontSize = 108;
                else if(size === 29) fontSize = 116;
                break;
            case 'GoodDog':
                if(size === 33) fontSize = 132; 
                break;
            case 'Grand Hotel':
                if(size === 23) fontSize = 92; 
                break;
            case 'Liberation Serif':
                if(size === 31) fontSize = 124; 
                break;
            case 'Walkway':
                if(size === 29) fontSize = 116;
                else if(size === 31) fontSize = 124;
                break;
            default: break;
        }
    }
    return fontSize;
}

const pixelToMm = 0.101626016260163;

export function calcularDimensaoTextoColeira(prodType, pageWidth, finalHeight, tamanhoSelecionado, textoColeira, family, size, textSize){
  const prodSize = document.getElementById('dog-collar-image-fill');
  const richTextSpan = document.getElementById('rich-text-span-id');

  if(prodType === 'dog-collar' && prodSize && richTextSpan && textoColeira.length > 0){
    const prodBoundary = prodSize.getBoundingClientRect();
    const spanBoundary = richTextSpan.getBoundingClientRect();
    const lineHeight = calculaLineHeightColeira(family);

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = `${textSize}px ${family}`;

    const medidas = context.measureText(textoColeira);

    const x = spanBoundary.x - prodBoundary.x - medidas.actualBoundingBoxLeft;
    // const l = medidas.width; //Tava ficando zoado...
    const l = spanBoundary.width;

    const baseline = baseLineDogCollar(family, textSize);
    const medidaY = baseline - medidas.actualBoundingBoxAscent;
    const medidaAltura = medidas.actualBoundingBoxAscent+medidas.actualBoundingBoxDescent;
    
    const actualHeight = prodBoundary.height;
    const actualWidth = prodBoundary.width;

    const a = conversorLineHeight(medidaAltura, lineHeight, actualHeight);
    const y = conversorLineHeight(medidaY, lineHeight, actualHeight);

    const finalWidth = calculaFinalPageWidth(false, pageWidth, prodType, false, false, 0, 0, tamanhoSelecionado);
    let xmm = ajusteProporcao(actualWidth, finalWidth, x)*pixelToMm;
    let ymm = ajusteProporcao(actualHeight, finalHeight, y)*pixelToMm;
    let lmm = ajusteProporcao(actualWidth, finalWidth, l)*pixelToMm;
    let amm = ajusteProporcao(actualHeight, finalHeight, a)*pixelToMm;

    switch (family) {
      case 'Abril Fatface': amm-=0.4; xmm-=0.5; break;
      case 'Arvo': xmm-=0.6; break;
      case 'Blokletters Balpen': amm+=0.8; xmm-=0.8; break;
      case 'Cookie': amm+=0.5; ymm-=0.3; xmm-=0.3; break;
      case 'Gandhi Sans': amm+=0.7; xmm-=1.5; break;
      case 'GoodDog': amm-=1.3; ymm+=0.4; break;
      case 'Grand Hotel': lmm+=0.8; amm+=0.7; xmm-=0.4; break;
      case 'Josefin Sans': amm+=1.2; xmm-=1.5; break;
      case 'Liberation Sans': xmm-=1.2; break;
      case 'Liberation Serif': xmm*=0.99; break;
      case 'Lobster Two': xmm-=0.4; break;
      case 'Walkway': xmm-=1.3; break;
      default: break;
    }

    return { x: xmm, y: ymm, l: lmm, a: amm };
  }
}

const conversorLineHeight = (medidaAltura, lineHeight, actualHeight) => {
  return medidaAltura*actualHeight/lineHeight;
}

const ajusteProporcao = (atual, final, value) => value*(final/atual);

export function baseLineDogCollar(family, size)
{
    let baseLine = 113;
    switch (family) {
      case 'Abril Fatface': 
        if(size === 26) baseLine = 111;
        else if(size === 28) baseLine = 113;
        break;
      case 'Arvo':
        if(size === 25) baseLine = 111;
        else if(size === 27) baseLine = 114;
        break;
      case 'Blokletters Balpen': 
        if(size === 19) baseLine = 112;
        else if(size === 21) baseLine = 116;
        break;
      case 'Cookie': 
        if(size === 27) baseLine = 107;
        else if(size === 29) baseLine = 110;
        break;
      case 'Gandhi Sans':
        if(size === 27) baseLine = 110;
        else if(size === 29) baseLine = 113;
        break;
      case 'GoodDog': 
        if(size === 30) baseLine = 113;
        else if(size === 33) baseLine = 117;
        break;
      case 'Grand Hotel': 
        if(size === 23) baseLine = 109;
        else if(size === 24) baseLine = 111;
        break;
      case 'Josefin Sans': 
        if(size === 26) baseLine = 111;
        else if(size === 28) baseLine = 114;
        break;
      case 'Liberation Sans': 
        if(size === 28) baseLine = 113;
        else if(size === 30) baseLine = 115;
        break;
      case 'Liberation Serif': 
        if(size === 28) baseLine = 111;
        else if(size === 31) baseLine = 115;
        break;
      case 'Lobster Two': 
        if(size === 24) baseLine = 111;
        else if(size === 26) baseLine = 114;
        break;
      case 'Walkway': 
        if(size === 29) baseLine = 113;
        else if(size === 31) baseLine = 116;
        break;
      default: break;
    }
  return baseLine;
}

const topPR = (P, R) => ({ P, R });

export const fontSpineOpt = {
    AbrilFatface: {
      size: 69,
      top: {
        A4: topPR(8.6, 10.8),
        A5: topPR(9.2, 9.8)
      }
    },
    Arvo: {
      size: 65.5,
      top: {
        A4: topPR(11.6, 13.3),
        A5: topPR(11.1, 12.3)
      }
    },
    BloklettersBalpen: {
      size: 52.75,
      top: {
        A4: topPR(21.1, 23.4),
        A5: topPR(21.1, 21.9)
      }
    },
    Cookie: {
      size: 81.75,
      top: {
        A4: topPR(9.9, 12.2),
        A5: topPR(9.9, 10.9)
      }
    },
    GandhiSans: {
      size: 72.5,
      top: {
        A4: topPR(13.4, 15.4),
        A5: topPR(13.4, 14.2)
      }
    },
    GoodDog: {
      size: 76.25,
      top: {
        A4: topPR(7.7, 9.4),
        A5: topPR(7.7, 8.5)
      }
    },
    GrandHotel: {
      size: 69.25,
      top: {
        A4: topPR(14.8, 16.7),
        A5: topPR(14.8, 15.5)
      }
    },
    GreatVibes: {
      size: 61,
      top: {
        A4: topPR(22.2, 23.7),
        A5: topPR(22.2, 23)
      }
    },
    JosefinSans: {
      size: 68.25,
      top: {
        A4: topPR(16.4, 18.4),
        A5: topPR(16.4, 17.2)
      }
    },
    KomikaAxis: {
      size: 55.25,
      top: {
        A4: topPR(11.2, 13.2),
        A5: topPR(11.2, 12.2)
      }
    },
    LiberationSans: {
      size: 70.75,
      top: {
        A4: topPR(10.7, 12.9),
        A5: topPR(10.2, 11.5)
      }
    },
    LiberationSerif: {
      size: 74.25,
      top: {
        A4: topPR(9.1, 10.9),
        A5: topPR(9.1, 9.8)
      }
    },
    LobsterTwo: {
      size: 64.75,
      top: {
        A4: topPR(12.6, 15.2),
        A5: topPR(12.6, 13.4)
      }
    },
    Walkway: {
      size: 71.5,
      top: {
        A4: topPR(9.7, 11.6),
        A5: topPR(9.7, 10.3)
      }
    }
  };

  export const haveAdjustment = (prodType) => {
    let aux = false;
    const haveList = ['dog-collar'];
    if(haveList.includes(prodType))
        aux = true;
    return aux;
}