import './index.css';

import * as R from 'ramda';

import {
  navigatorPropTypes,
  pagesFormatPropTypes,
  photoEditorPropTypes,
  printOptionsPropTypes,
  textOptionsPropTypes,
  stylePropTypes
} from '../../proptypes';

import { hasDebru } from '../../utils/buscaPermissoes';
import MockPage from '../mock-page';
import PropTypes from 'prop-types';

import React, { useState, useEffect } from 'react';

import { SombraLateral, SombraInferior } from '../sombra';
import { Debru } from '../debru';
import {
  calculaWidthOverviewShadow,
  calculaDafaultWidthOverviewShadow,
  calculaHeightOverviewShadow,
  calculaWidthOverviewIndex,
  styleOverviewContainer
} from '../../utils/calculosOverview';

import {
  calculaWidthProduto,
  calculaHeightProduto,
  calculaFinalPageWidth,
  calculaFinalPageHeight,
  calcularWidthViewBox,
  calcularHeightViewBox,
  calcularWidthBookNavigatorPrint,
  calcularHeightBookNavigatorPrint
} from '../../utils/calculosGerais';
import { isCustomProduct } from '../../utils/separadorProdutos';
import { overviewLabelText } from '../../utils/textosVariaveis';

const checkDraggingPageFrame = (id, list) =>
  R.reduce(
    (acc, i) => (acc ? R.T() : R.equals(R.prop('id', i), id)),
    false,
    R.prop('frames', list)
  );

const getNavigatorIndexes = ({ pages }) => {
  const indexes = [];
  pages.forEach(page => indexes.push(page.index));
  return indexes;
};

const checkPagesToRemove = (
  pagesToRemove = [],
  { pages },
  prodType,
  isCover
) => {
  if (checkDisabledPages(pages, isCover)) return false;
  let i = 0;

  pages.forEach(page => {
    if (pagesToRemove.includes(page.index)) {
      i += 1;
    }
  });

  if (prodType === 'mask') return i === 1 ? true : false;
  return i === 2 ? true : false;
};

const checkDisabledPages = (pages, isCover) =>
  isCover ||
  R.prop('label', R.head(pages)) ||
  R.prop('label', R.prop(R.length(pages) - 1, pages));

const testOverview = ({ pages }, avtivesID) => {
  let found = 0;
  pages.forEach(page => avtivesID.includes(page.id) && found++);
  return found ? true : false;
};

function BookNavigator({
  width,
  height,
  pageWidth,
  pageHeight,
  pagesFormats,
  pageIndex,
  spineIndex,
  navigatorData,
  onShapeDrop,
  mouseStartHandler,
  mouseStopHandler,
  isDragging,
  dragId,
  moveHandler,
  moveKeyboardHandler,
  setPageIndex,
  textChangeHandler,
  isMenuTextSelected,
  textOptions,
  toggleNavText,
  updateTextOptions,
  photoEditor,
  updatePhotoEditor,
  fixPos,
  isBookOverviewExpanded,
  isGalleryExpanded,
  addMarginBottomNavigator,
  isOverviewRemovePagesActive,
  pagesToRemove,
  setPageToRemove,
  unsetPageToRemove,
  toggleBookOverviewExpanded,
  screenSize,
  isPrint,
  isThumb,
  isScodix,
  printOptions,
  screenRate,
  spineMargin,
  setNumber,
  bookHaveCover,
  coverLengthPDF,
  isCover,
  avtivesID,
  updateWarning,
  ean,
  prodCode,
  productType,
  productFormat,
  currentNav,
  isModoImpressao,
  isModoSobFivela,
  setStateLegenda,
  tamanhoSelecionado,
  styles,
  defaultPageWidth,
  defaultPageHeight,
  selectedProduct,
  tipoFundo,
  fundoColor,
  layoutsFilter
}) {
  let dragPage;
  const {
    cover: { debru },
    spine: spineFormat
  } = pagesFormats;
  const {
    pages,
    cols,
    lines,
    covers: { cover, back_cover }
  } = navigatorData;

  const {
    pdfWidth,
    pdfHeight,
    bleed,
    pdfBleed,
    pdfCoverWidth,
    pdfCoverHeight,
    pdfSpineWidth,
    debru: { cover: coverDebru, back_cover: backCoverDebru }
  } = printOptions;
  const isSpineIndex = isCover && pageIndex === spineIndex;
  const spineWidth =
    isPrint && coverLengthPDF > 1 ? spineFormat.width * 0.9 : spineFormat.width;
  const finalPageWidth = calculaFinalPageWidth(
    isThumb,
    pageWidth,
    productType,
    isPrint,
    isCover,
    debru,
    bleed,
    tamanhoSelecionado
  );
  const finalPageHeight = calculaFinalPageHeight(
    isThumb,
    isPrint,
    isCover,
    productType,
    pageHeight,
    debru,
    bleed
  );
  let finalCol = cols;
  if (coverLengthPDF === 1 && isSpineIndex && isPrint && !isThumb) {
    pages.unshift(back_cover);
    finalCol += 1;
  }
  if (isThumb && isCover) {
    pages.shift();
    finalCol = 1;
  }

  const widthOverviewShadow = calculaWidthOverviewShadow(
    productType,
    finalPageWidth,
    finalPageHeight,
    height,
    lines,
    finalCol,
    isSpineIndex,
    spineWidth
  );
  const defaultWidthOverviewShadow = calculaDafaultWidthOverviewShadow(
    defaultPageWidth,
    defaultPageHeight,
    height,
    lines,
    isCover ? finalCol - 1 : finalCol
  );
  const quantidadeRemocaoMargem =
    defaultWidthOverviewShadow - widthOverviewShadow;

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div
      className="book-navigator-container"
      style={
        isBookOverviewExpanded
          ? styleOverviewContainer(
            productType,
            pageIndex + 1,
            screenSize.width,
            defaultWidthOverviewShadow,
            isCover ? quantidadeRemocaoMargem : 0,
            addMarginBottomNavigator
          )
          : photoEditor.active
            ? { zIndex: 6 }
            : {}
      }
    >
      <div
        className={`book-navigator${isBookOverviewExpanded
            ? isOverviewRemovePagesActive
              ? checkDisabledPages(navigatorData.pages, isCover)
                ? ''
                : checkPagesToRemove(
                  pagesToRemove,
                  navigatorData,
                  productType,
                  isCover
                )
                  ? ' active'
                  : ' overview'
              : testOverview(navigatorData, avtivesID)
                ? ' active'
                : ' overview'
            : ''
          }${isOverviewRemovePagesActive ? ' remove-active' : ''}`}
      >
        {isBookOverviewExpanded && (
          <div
            className="overview-shadow"
            style={{
              width: widthOverviewShadow,
              height: calculaHeightOverviewShadow(productType)
            }}
            onClick={() =>
              isOverviewRemovePagesActive
                ? checkPagesToRemove(
                  pagesToRemove,
                  navigatorData,
                  productType,
                  isCover
                )
                  ? unsetPageToRemove(getNavigatorIndexes(navigatorData))
                  : !checkDisabledPages(navigatorData.pages, isCover) &&
                  setPageToRemove(getNavigatorIndexes(navigatorData))
                : setPageIndex(pageIndex)
            }
            onDoubleClick={() => {
              !isOverviewRemovePagesActive &&
                toggleBookOverviewExpanded(isBookOverviewExpanded);
            }}
          ></div>
        )}
        {isPrint && isCover && !isSpineIndex && (
          <div className="print-marks">
            <div className="mark"></div>
            <div className="mark"></div>
          </div>
        )}
        {isPrint && isScodix && isCover && isSpineIndex && (
          <div className="scodix-marks">
            <div className="scodix-line">
              <div className="mark"></div>
              <div className="mark"></div>
            </div>
            <div className="scodix-line">
              <div className="mark"></div>
              <div className="mark"></div>
            </div>
          </div>
        )}
        <svg
          viewBox={`${productType === 'mask' && isPrint ? '271' : '0'} 0 ${bookHaveCover && isSpineIndex && !isThumb
              ? finalPageWidth * (finalCol - 1) + spineWidth
              : calcularWidthViewBox(
                isThumb,
                productType,
                finalPageWidth,
                finalCol
              )
            } ${calcularHeightViewBox(
              isThumb,
              productType,
              finalPageHeight,
              lines
            )}`}
          width={
            isPrint
              ? calcularWidthBookNavigatorPrint(
                isThumb,
                isCover,
                isSpineIndex,
                productType,
                pdfWidth,
                pdfCoverWidth,
                pdfSpineWidth,
                pdfBleed,
                coverLengthPDF,
                tamanhoSelecionado,
                width,
                isBookOverviewExpanded,
                screenSize ? screenSize.width : width
              )
              : calculaWidthProduto(
                width,
                productType,
                isBookOverviewExpanded,
                screenSize ? screenSize.width : width
              )
          }
          height={
            isPrint
              ? calcularHeightBookNavigatorPrint(
                isThumb,
                isCover,
                productType,
                pdfHeight,
                pdfCoverHeight,
                pdfBleed,
                tamanhoSelecionado,
                height
              )
              : calculaHeightProduto(productType, height)
          }
          style={
            isPrint && !isThumb && productType === 'book'
              ? pageIndex % 2
                ? isCover
                  ? {
                    margin: `${coverDebru.top}mm ${coverDebru.right}mm ${coverDebru.bottom}mm ${coverDebru.left}mm`
                  }
                  : { marginLeft: `${spineMargin}mm` }
                : isCover
                  ? {
                    margin: `${backCoverDebru.top}mm ${backCoverDebru.right}mm ${backCoverDebru.bottom}mm ${backCoverDebru.left}mm`
                  }
                  : { marginRight: `${spineMargin}mm` }
              : {}
          }
        >
          {pages.map((page, index) => {
            const lineIndex = Math.floor(index / finalCol);
            const colIndex = index % finalCol;
            const usedShapes = R.map(
              R.path(['shape', 'id']),
              R.prop('frames', page)
            );

            let nonUsedShapes =
              R.path(['style', 'shape'], page) &&
              R.filter(
                x => R.not(R.contains(R.prop('id', x), usedShapes)),
                R.path(['style', 'shape'], page)
              );

            if (
              isCustomProduct(productType) &&
              selectedProduct &&
              selectedProduct.layouts &&
              selectedProduct.layouts.length > 0
            )
              nonUsedShapes = selectedProduct.layouts[0].shape;

            const mockPage = (
              <MockPage
                id={page.id}
                key={index}
                x={
                  isSpineIndex && page.id === 'cover' && !isThumb
                    ? (colIndex - 1) * finalPageWidth + spineWidth
                    : colIndex * finalPageWidth
                }
                y={lineIndex * finalPageHeight}
                width={page.id === 'spine' ? spineWidth : finalPageWidth}
                height={finalPageHeight}
                originalWidth={pageWidth}
                originalHeight={pageHeight}
                pagesFormats={pagesFormats}
                bleed={!isCover ? bleed : 0}
                opacity={page.style.opacity}
                isLeftPage={isCover ? index === 0 : colIndex % 2 === 0}
                shapes={nonUsedShapes}
                textareas={page.style.textarea}
                styles={styles}
                pageIndex={page.index}
                navigationIndex={pageIndex + 1}
                frames={page.frames}
                texts={page.texts}
                styleId={page.style.id.toString()}
                onShapeDrop={onShapeDrop}
                mouseStartHandler={mouseStartHandler}
                mouseStopHandler={mouseStopHandler}
                textChangeHandler={textChangeHandler}
                isDragging={isDragging}
                dragId={dragId}
                moveHandler={moveHandler}
                moveKeyboardHandler={moveKeyboardHandler}
                fixPos={fixPos}
                isMenuTextSelected={isMenuTextSelected}
                textOptions={textOptions}
                rate={finalPageWidth / (width * 0.5)}
                toggleNavText={toggleNavText}
                screenRate={screenRate}
                updateTextOptions={updateTextOptions}
                photoEditor={photoEditor}
                updatePhotoEditor={updatePhotoEditor}
                isBookOverviewExpanded={isBookOverviewExpanded}
                isCover={isCover}
                isPrint={isPrint}
                isThumb={isThumb}
                bookHaveCover={bookHaveCover}
                setNumber={setNumber}
                eanUrl={ean}
                isScodix={isScodix}
                coverLengthPDF={coverLengthPDF}
                updateWarning={updateWarning}
                productFormat={productFormat}
                productType={productType}
                isModoImpressao={isModoImpressao}
                isModoSobFivela={isModoSobFivela}
                prodCode={prodCode}
                setStateLegenda={setStateLegenda}
                tamanhoSelecionado={tamanhoSelecionado}
                tipoFundo={tipoFundo}
                fundoColor={fundoColor}
                layoutsFilter={layoutsFilter}
              />
            );

            const isDraggingPageFrame = checkDraggingPageFrame(dragId, page);
            if (isDraggingPageFrame) {
              dragPage = mockPage;
              return false;
            }

            return mockPage;
          })}
          {dragPage && dragPage}
        </svg>
      </div>

      {isBookOverviewExpanded && (
        <div
          className={`book-navigator-indexes${isOverviewRemovePagesActive
              ? checkDisabledPages(navigatorData.pages, isCover)
                ? ''
                : checkPagesToRemove(
                  pagesToRemove,
                  navigatorData,
                  productType,
                  isCover
                )
                  ? ' active'
                  : ' overview'
              : testOverview(navigatorData, avtivesID)
                ? ' active'
                : ''
            }`}
          style={{
            width: calculaWidthOverviewIndex(
              productType,
              finalPageWidth,
              finalPageHeight,
              screenSize.width,
              height,
              lines,
              finalCol,
              isSpineIndex,
              spineWidth
            ),
            height: '20px'
          }}
        >
          {pages.map(
            page =>
              page.id !== 'spine' && (
                <span
                  key={page.id}
                  style={{
                    fontSize: productType === 'invitation' ? '12px' : '14px'
                  }}
                >
                  {overviewLabelText(productType, page.index, page.label)}
                </span>
              )
          )}
        </div>
      )}

      {!isPrint && (
        <SombraLateral
          isBookOverviewExpanded={isBookOverviewExpanded}
          isGalleryExpanded={isGalleryExpanded}
          isPhotoEditorActive={photoEditor.active}
          prodCode={prodCode}
          prodType={productType}
          prodFormat={productFormat}
          currentNav={currentNav}
          isCover={isCover}
          windowSize={windowSize}
        />
      )}

      {!isPrint && (
        <SombraInferior
          isBookOverviewExpanded={isBookOverviewExpanded}
          isGalleryExpanded={isGalleryExpanded}
          isPhotoEditorActive={photoEditor.active}
          prodCode={prodCode}
          prodType={productType}
          prodFormat={productFormat}
          currentNav={currentNav}
          isCover={isCover}
          windowSize={windowSize}
        />
      )}

      {!isPrint && !isCover && hasDebru(prodCode) && (
        <Debru
          isBookOverviewExpanded={isBookOverviewExpanded}
          isGalleryExpanded={isGalleryExpanded}
          isPhotoEditorActive={photoEditor.active}
          prodCode={prodCode}
          prodType={productType}
          prodFormat={productFormat}
          currentNav={currentNav}
          frameCover={cover.frames}
          frameBackCover={back_cover.frames}
          windowSize={windowSize}
        />
      )}
    </div>
  );
}

BookNavigator.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  pageWidth: PropTypes.number.isRequired,
  pageHeight: PropTypes.number.isRequired,
  pagesFormats: pagesFormatPropTypes,
  pageIndex: PropTypes.number.isRequired,
  spineIndex: PropTypes.number.isRequired,
  navigatorData: navigatorPropTypes,
  onShapeDrop: PropTypes.func.isRequired,
  mouseStartHandler: PropTypes.func.isRequired,
  mouseStopHandler: PropTypes.func.isRequired,
  isDragging: PropTypes.bool.isRequired,
  dragId: PropTypes.string.isRequired,
  moveHandler: PropTypes.func.isRequired,
  moveKeyboardHandler: PropTypes.func.isRequired,
  setPageIndex: PropTypes.func,
  textChangeHandler: PropTypes.func.isRequired,
  isMenuTextSelected: PropTypes.bool.isRequired,
  textOptions: textOptionsPropTypes,
  toggleNavText: PropTypes.func.isRequired,
  updateTextOptions: PropTypes.func.isRequired,
  photoEditor: photoEditorPropTypes,
  updatePhotoEditor: PropTypes.func.isRequired,
  fixPos: PropTypes.func.isRequired,
  isBookOverviewExpanded: PropTypes.bool.isRequired,
  isGalleryExpanded: PropTypes.bool.isRequired,
  addMarginBottomNavigator: PropTypes.number,
  isOverviewRemovePagesActive: PropTypes.bool,
  pagesToRemove: PropTypes.array,
  setPageToRemove: PropTypes.func.isRequired,
  unsetPageToRemove: PropTypes.func.isRequired,
  isPrint: PropTypes.bool.isRequired,
  isThumb: PropTypes.bool.isRequired,
  screenRate: PropTypes.number.isRequired,
  isScodix: PropTypes.bool,
  printOptions: printOptionsPropTypes,
  spineMargin: PropTypes.number,
  setNumber: PropTypes.number.isRequired,
  bookHaveCover: PropTypes.bool.isRequired,
  isCover: PropTypes.bool.isRequired,
  avtivesID: PropTypes.array,
  updateWarning: PropTypes.func.isRequired,
  prodCode: PropTypes.string.isRequired,
  productType: PropTypes.string.isRequired,
  productFormat: PropTypes.string.isRequired,
  currentNav: PropTypes.string.isRequired,
  isModoImpressao: PropTypes.bool.isRequired,
  isModoSobFivela: PropTypes.bool.isRequired,
  setStateLegenda: PropTypes.func.isRequired,
  tamanhoSelecionado: PropTypes.number,
  styles: PropTypes.arrayOf(stylePropTypes),
  defaultPageWidth: PropTypes.number,
  defaultPageHeight: PropTypes.number,
  selectedProduct: PropTypes.object,
  fundoColor: PropTypes.string.isRequired,
  layoutsFilter: PropTypes.object.isRequired
};

BookNavigator.defaultProps = {
  isDragging: false,
  dragId: undefined
};

export default BookNavigator;
