import {
    FATOR_DIMINUICAO_HEIGHT_MOCKUP_CALENDARIO_P,
    FATOR_AJUSTE_Y_MOCKUP_CALENDARIO_P,
    FATOR_AJUSTE_Y_MOCKUP_CALENDARIO_A3_P,
    FATOR_DIMINUICAO_HEIGHT_MOCKUP_CALENDARIO_A3_P,
    AJUSTE_TOP_LINHA_MIOLO,
    AJUSTE_HEIGHT_LINHA_MIOLO,
} from '../constant/mockup';
import {LISTA_PRODUTOS_PERMITIDOS_TIPO1} from '../utils/buscaPermissoes';

export function calculoYMockPage(height, isPrint, productType, productFormat)
{
    if(!isPrint)
    {
        //mockup calendario paisagem
        if(productType === 'calendar' && productFormat === 'P'){
            return height*FATOR_AJUSTE_Y_MOCKUP_CALENDARIO_P;
        }
        else if(productType === 'calendar' && productFormat === 'A3-P'){
            return FATOR_AJUSTE_Y_MOCKUP_CALENDARIO_A3_P;
        }
    }

    return 0;
}

export function calculoYMockPageBleed(height, bleed, isPrint, productType, productFormat)
{
    if(!isPrint)
    {
        //mockup calendario paisagem
        if(productType === 'calendar' && productFormat === 'P'){
            return height*FATOR_AJUSTE_Y_MOCKUP_CALENDARIO_P;
        }
        else if(productType === 'calendar' && productFormat === 'A3-P'){
            return FATOR_AJUSTE_Y_MOCKUP_CALENDARIO_A3_P;
        }
    }

    return bleed;
}

export function calculoHeightMockPage(height, isPrint, productType, productFormat)
{
    if(!isPrint)
    {
        //mockup calendario paisagem
        if(productType === 'calendar' && productFormat === 'P'){
            return height*FATOR_DIMINUICAO_HEIGHT_MOCKUP_CALENDARIO_P;
        }
        else if(productType === 'calendar' && productFormat === 'A3-P'){
            return height*FATOR_DIMINUICAO_HEIGHT_MOCKUP_CALENDARIO_A3_P;
        }
    }

    return height;
}

export function calculoHeightMockPageBleed(height, bleed, isPrint, productType, productFormat)
{
    if(!isPrint)
    {
        //mockup calendario paisagem
        if(productType === 'calendar' && productFormat === 'P'){
            return height*FATOR_DIMINUICAO_HEIGHT_MOCKUP_CALENDARIO_P;
        }
        else if(productType === 'calendar' && productFormat === 'A3-P'){
            return height*FATOR_DIMINUICAO_HEIGHT_MOCKUP_CALENDARIO_A3_P;
        }
    }

    return height - bleed * 2;
}

export function calculoWidthMockPage(width, isPrint, productType, productFormat)
{
    if(!isPrint)
    {
        //mockup calendario paisagem
        if(productType === 'calendar' && productFormat === 'P'){
            return width*FATOR_DIMINUICAO_HEIGHT_MOCKUP_CALENDARIO_P;
        }
        else if(productType === 'calendar' && productFormat === 'A3-P'){
            return width*FATOR_DIMINUICAO_HEIGHT_MOCKUP_CALENDARIO_A3_P;
        }
    }

    return width;
}

export function calculoHeightPage(height, isPrint, productType, productFormat)
{
    return height;
}

export function calculoTopLinhaMiolo(pageCoordenates, prodCode)
{
    if(LISTA_PRODUTOS_PERMITIDOS_TIPO1.includes(prodCode)){
        return -pageCoordenates.height/AJUSTE_TOP_LINHA_MIOLO;
    }
    return 0;
}

export function calculoHeightLinhaMiolo(pageCoordenates, prodCode)
{
    if(LISTA_PRODUTOS_PERMITIDOS_TIPO1.includes(prodCode)){
        const height = pageCoordenates.height;
        return height*AJUSTE_HEIGHT_LINHA_MIOLO
    }
    return 0;
}


