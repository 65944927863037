import * as actionUI from '../action/ui';

import { NAV_NONE_SELECTED } from '../constant/nav';
import NavManager from '../component/nav-manager';
import { connect } from 'react-redux';
import { getCurrentNav } from '../selector';

const mapStateToProps = state => ({
  current: getCurrentNav(state)
});

const mapDispatchToProps = dispatch => ({
  setNavNone: () => dispatch(actionUI.updateSelectedNav(NAV_NONE_SELECTED))
});

export default connect(mapStateToProps, mapDispatchToProps)(NavManager);
