import { DragSource, DropTarget } from 'react-dnd';

import Thumb from '../component/thumb';

const thumbDragSource = {
  beginDrag(props) {
    return {
      thumbId: props.thumbId
    };
  }
};

const thumbDragCollect = (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging()
});

const thumbDropTarget = {
  drop(props, monitor) {
    const item = monitor.getItem();
    props.updateIncMoveIndex(item.thumbId, props.thumbId);
  }
};

const thumbDropCollect = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  canDrop: monitor.canDrop(),
  isOver: monitor.isOver()
});

const dragSource = DragSource(
  'THUMB',
  thumbDragSource,
  thumbDragCollect
)(Thumb);

const dropTarget = DropTarget(
  'THUMB',
  thumbDropTarget,
  thumbDropCollect
)(dragSource);

export default dropTarget;
