import './index.css';

import Button from '../button';
import PropTypes from 'prop-types';
import React from 'react';
import { screenSizePropTypes } from '../../proptypes';

const NavItem = ({ theme, label, clickHandler, screenSize, dataTip, disabled = false }) => (
  <label
    className="nav-item"
    onClick={clickHandler}
    style={{ 
      marginRight: `${screenSize.width * 0.019}px`, 
      opacity: disabled ? "0.25":"1", 
      pointerEvents: disabled ?'none':'auto' 
    }}
    data-tip={dataTip}
    data-place="right"
  >
    <Button theme={theme} disabled={disabled} />
    <p className="label">{label}</p>
  </label>
);

NavItem.propTypes = {
  theme: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  clickHandler: PropTypes.func.isRequired,
  screenSize: screenSizePropTypes,
  dataTip: PropTypes.string
};

NavItem.defaultProps = {
  theme: '',
  label: '',
  clickHandler: undefined
};

export default NavItem;
