import './index.css';
import React, { Component } from 'react';
import {
  navigatorPropTypes,
  pagesFormatPropTypes,
  screenSizePropTypes,
} from '../../proptypes';

import ColorBox from '../color-box';
import PropTypes from 'prop-types';
import Select from '../select';
import { THEME_CLASS_ICON_CHECK } from '../../constant/button';
import { getSelecaoFundosAplicar } from '../../utils/textosVariaveis';
import { 
  setDefaultFundoColor,
  opcoesFundo
} from '../../utils/calculosFundos';

class NavFundos extends Component {
  state = {
    menuStyleOpen: false,
    menuApplyOpen: false,
    menuApplySelect: getSelecaoFundosAplicar(this.props.isModoSobFivela)
  };

  closeNav = () => {};

  setFundoContainerWidth = () => {
    const { screenSize, updateScreenSize } = this.props;
    const { width, height, headerHeight, photobookHeight } = screenSize;
    const filterLayouts = document.querySelector('.filter-layouts').clientWidth;

    updateScreenSize(
      width,
      height,
      headerHeight,
      photobookHeight,
      width - width * 0.058 - filterLayouts
    );
  };

  componentDidMount() {
    window.addEventListener('click', this.closeNav);
    this.setFundoContainerWidth();
  }

  render() {
    const {
      screenSize,
      pagesFormats,
      fundoColor,
      tipoFundo,
      setFundoColor,
      setTipoFundo,
      setNavNone,
      prodType,
      isModoSobFivela
    } = this.props;

    return (
      <div className="nav-layouts">
        <div className="filter-layouts">
          <h3 className="title">Fundos</h3>
          <div className="line">
            <span>
              Exibir os fundos do estilo:{' '}
              <Select
                name="group"
                label={tipoFundo}
                checkedId={tipoFundo}
                options={opcoesFundo(isModoSobFivela)}
                screenSize={screenSize}
                changeHandler={ value => {
                  setDefaultFundoColor(setFundoColor, value, fundoColor);
                  setTipoFundo(value);
                }}
                selectedIcon={THEME_CLASS_ICON_CHECK}
                layoutsFilter
                type={'layouts-large-text'}
                menu={this.state.menuStyleOpen}
                togleMenu={()=>{this.setState({ menuStyleOpen: !this.state.menuStyleOpen })}}
              />{' '}
            </span>
          </div>
          <div className="line">
            <span>
              Aplicar{' '}
              <Select
                name="group"
                label={this.state.menuApplySelect}
                checkedId={this.state.menuApplySelect}
                options={
                  [
                    {id: this.state.menuApplySelect}
                  ]
                }
                screenSize={screenSize}
                changeHandler={ value => {
                  setDefaultFundoColor(setFundoColor, value, fundoColor);
                  setTipoFundo(value);
                }}
                selectedIcon={THEME_CLASS_ICON_CHECK}
                layoutsFilter
                type={'layouts-text'}
                menu={this.state.menuApplyOpen}
                togleMenu={()=>{this.setState({ menuApplyOpen: !this.state.menuApplyOpen })}}
              />{' '}
            </span>
          </div>
        </div>
        <div
          style={{
            margin: `18px ${screenSize.width * 0.014}px 0 ${screenSize.width *
              0.014}px`
          }}
          className="line-divisor"
        ></div>
        <div className="layouts-box-container">
          <ColorBox
            screenSize={screenSize}
            fundoColor={fundoColor}
            tipoFundo={tipoFundo}
            setFundoColor={setFundoColor}
            setNavNone={setNavNone}
            pagesFormats={pagesFormats}
            prodType={prodType}
            isModoSobFivela={isModoSobFivela}
          ></ColorBox>
        </div>
      </div>
    );
  }
}

NavFundos.propType = {
  fundoColor: PropTypes.string.required,
  screenSize: screenSizePropTypes,
  navigatorData: navigatorPropTypes,
  pagesFormats: pagesFormatPropTypes,
  maxPage: PropTypes.number.isRequired,
  haveCover: PropTypes.bool.isRequired,
  updateLayoutFilterText: PropTypes.func,
  updateLayoutFilterApplyMethod: PropTypes.func,
  toggleMenuLayouts: PropTypes.func,
  setFundoColor: PropTypes.func.isRequired,
  setTipoFundo: PropTypes.func.isRequired,
  setNavNone: PropTypes.func.isRequired,
  prodType: PropTypes.string.isRequired,
  isModoSobFivela: PropTypes.bool.isRequired
};

export default NavFundos;