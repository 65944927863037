import { FATOR_DIMINUICAO_HEIGHT_MOCKUP_CALENDARIO_A3_P } from '../constant/mockup'

export function calculoXBarcode(isPrint, prodCode, left, debru, bleed)
{
    let x= left + debru + bleed;

    if(!isPrint && prodCode === 'PR_40_20')
    {
        x*=FATOR_DIMINUICAO_HEIGHT_MOCKUP_CALENDARIO_A3_P;
    }

    return x;
}