import PropTypes from 'prop-types';
import { framePropTypes, windowSizePropTypes } from '../../proptypes';
import React, { useState, useEffect, useRef } from 'react';
import {
  LISTA_PRODUTOS_PERMITIDOS_TIPO1,
  LISTA_PRODUTOS_PERMITIDOS_TIPO2,
  buscaAssets
} from '../../utils/buscaPermissoes';

import * as s from '../../constant/sombra'
import DebruTipo1 from './DebruTipo1'
import DebruTipo2 from './DebruTipo2'

function Debru(
  {
    isBookOverviewExpanded,
    isGalleryExpanded,
    isPhotoEditorActive,
    prodCode,
    prodType,
    prodFormat,
    frameCover,
    frameBackCover,
    currentNav,
    windowSize,
  }){

  const [prevMockpageCoordenates, setPrevMockpageCoordenates] = useState(null);
  const [prevIsGalleryExpanded, setPrevIsGalleryExpanded] = useState(false);

  const mounted = useRef();

  useEffect(() => {
    const mockpageElement = document.getElementById('product-page');

    if (!mounted.current && !isBookOverviewExpanded && !isGalleryExpanded && !isPhotoEditorActive && 
          (mockpageElement != null && prevMockpageCoordenates !== mockpageElement.getBoundingClientRect()))
    {
      setPrevMockpageCoordenates(mockpageElement.getBoundingClientRect());
      mounted.current = true;
    } else{
      if(mockpageElement != null && prevMockpageCoordenates !== mockpageElement.getBoundingClientRect()){
        setPrevMockpageCoordenates(mockpageElement.getBoundingClientRect());
      }
      setPrevIsGalleryExpanded(isGalleryExpanded);
    }
  },[isGalleryExpanded, isBookOverviewExpanded, isPhotoEditorActive, currentNav, windowSize]);

  const mockpageElement = document.getElementById('product-page');
  const caminhoAsset = buscaAssets(prodCode, prodType, prodFormat);
  if(mockpageElement != null && !isBookOverviewExpanded && !isGalleryExpanded && !isPhotoEditorActive)
  {
    const imagemCapa = frameCover.length >= 1 ? frameCover[0].image.source.image.blobUrl:null;
    const imagemCapaVerso = frameBackCover.length >= 1 ? frameBackCover[0].image.source.image.blobUrl:null;
    const pageCoordenates = mockpageElement.getBoundingClientRect();
    const scrollY = prevIsGalleryExpanded ? s.FATOR_AJUSTE_Y_SOMBRA_GALLERY_EXPANDED_CALENDAR : window.scrollY;      

    if(LISTA_PRODUTOS_PERMITIDOS_TIPO1.includes(prodCode))
      return(
        <DebruTipo1
          prodCode={prodCode}
          caminhoAsset={caminhoAsset}
          pageCoordenates={pageCoordenates}
          scrollY={scrollY}
          imagemCapa={imagemCapa}
          imagemCapaVerso={imagemCapaVerso}
        />
      );
    else if(LISTA_PRODUTOS_PERMITIDOS_TIPO2.includes(prodCode))
      return(
        <DebruTipo2
          prodCode={prodCode}
          caminhoAsset={caminhoAsset}
          pageCoordenates={pageCoordenates}
          scrollY={scrollY}
          imagemCapa={imagemCapa}
          imagemCapaVerso={imagemCapaVerso}
        />
      );
  }
  return null
}

Debru.propTypes = {
  isBookOverviewExpanded: PropTypes.bool.isRequired,
  isGalleryExpanded: PropTypes.bool.isRequired,
  isPhotoEditorActive: PropTypes.bool.isRequired,
  prodCode: PropTypes.string.isRequired,
  prodType: PropTypes.string.isRequired,
  prodFormat: PropTypes.string.isRequired,
  currentNav: PropTypes.string.isRequired,
  frameCover: PropTypes.arrayOf(framePropTypes),
  frameBackCover: PropTypes.arrayOf(framePropTypes),
  windowSize: windowSizePropTypes,
};

export {Debru};
