import VideoMesa from '../asset/mesa/mesa-tutorial.mp4';
import CapaVideoMesa from '../asset/mesa/mesa-tutorial-capa.webp';
import VideoDogCollar from '../asset/dog-collar/dog-collar-tutorial.mp4';
import CapaVideoDogCollar from '../asset/dog-collar/dog-collar-tutorial-capa.jpg';

export const noEndpapers = [
  'calendar',
  'mask',
  'dog-collar',
  'poster',
  'deck-cards',
  'invitation'
];

export const oneCols = [
  'calendar',
  'mask',
  'dog-collar',
  'poster',
  'deck-cards',
  'invitation'
];

export const singlePageStyles = [
  'calendar',
  'mask',
  'dog-collar',
  'poster',
  'deck-cards',
  'invitation'
];

export const inactiveTypes = ['calendar'];

export const thumbDropImageIdx = ['calendar'];

export const mostrarQtdUtilizada = ['mask', 'poster'];

export const deleteSingleType = ['mask'];

export const haveDuplicar = prodType => {
  let aux = false;
  const haveList = ['mask'];
  if (haveList.includes(prodType)) aux = true;
  return aux;
};

export const haveToolbarPhoto = (
  prodType,
  isBookOverviewExpanded,
  isPhotoEditorActive
) => {
  let aux = true;
  const notHaveList = ['dog-collar'];
  if (
    notHaveList.includes(prodType) &&
    !isBookOverviewExpanded &&
    !isPhotoEditorActive
  )
    aux = false;
  return aux;
};

export const haveBarCodeFunc = prodType => {
  let aux = true;
  const notHaveList = [
    'mask',
    'dog-collar',
    'poster',
    'deck-cards',
    'invitation'
  ];
  if (notHaveList.includes(prodType)) aux = false;
  return aux;
};

export const overviewMove = ['mask', 'dog-collar', 'poster', 'deck-cards'];

export const moverVertical = ['dog-collar'];

export const notBreakWord = ['dog-collar'];

export const movePageCircular = ['mask', 'dog-collar'];

export const movePageCircularLayout = ['poster'];

export const diferencaStepper = ['mask', 'dog-collar', 'poster', 'invitation'];

export const sizeErrorEnabled = id => {
  const notHaveList = ['POPUP_IDENTIFY', 'POPUP_FIREFOX_ANONIMO'];
  return !notHaveList.includes(id);
};

export const notFixedWidthPopup = id => {
  const notFixedWidth = ['POPUP_SCREEN_SIZE_ERROR', 'POPUP_FIREFOX_ANONIMO'];
  return notFixedWidth.includes(id);
};

export const completarProdutoObrigatorio = prodType => {
  let aux = false;
  const haveList = ['mask', 'dog-collar', 'deck-cards', 'invitation'];
  if (haveList.includes(prodType)) aux = true;
  return aux;
};

export const notUploadImage = ['dog-collar'];

export const isCorCaixaTextoPadrao = prodCode => {
  let aux = true;
  const notHaveList = ['PR_75_03', 'PR_75_05'];
  if (notHaveList.includes(prodCode)) aux = false;
  return aux;
};

export const videoTutorial = prodType => {
  let video = VideoMesa;
  if (prodType === 'dog-collar') video = VideoDogCollar;
  return video;
};

export const capaVideoTutorial = prodType => {
  let capa = CapaVideoMesa;
  if (prodType === 'dog-collar') capa = CapaVideoDogCollar;
  return capa;
};

export const isCustomProduct = prodType => {
  let aux = false;
  const haveList = ['poster'];
  if (haveList.includes(prodType)) aux = true;
  return aux;
};

export const isOneEditingProduct = prodType => {
  let aux = false;
  const haveList = ['invitation'];
  if (haveList.includes(prodType)) aux = true;
  return aux;
};

export const samePageLayout = prodType => {
  let aux = true;
  const haveList = ['calendar', 'poster'];
  if (haveList.includes(prodType)) aux = false;
  return aux;
};

export const habilitarBiblioteca = prodType => {
  let aux = false;
  const haveList = ['mask', 'poster'];
  if (haveList.includes(prodType)) aux = true;
  return aux;
};

export const haveLastPageWarning = prodType => {
  let aux = true;
  const notHaveList = ['calendar', 'poster', 'deck-cards'];
  if (notHaveList.includes(prodType)) aux = false;
  return aux;
};

export const onePageProduct = prodType => {
  let aux = false;
  const haveList = ['mask', 'poster'];
  if (haveList.includes(prodType)) aux = true;
  return aux;
};

export const dropImageEmptySimplePage = prodType => {
  let aux = false;
  const haveList = ['mask'];
  if (haveList.includes(prodType)) aux = true;
  return aux;
};

export const dropImageEmptyPage = prodType => {
  let aux = false;
  const haveList = ['poster'];
  if (haveList.includes(prodType)) aux = true;
  return aux;
};

export const calcLastPageFlex = prodType => {
  let aux = true;
  const haveList = ['poster'];
  if (haveList.includes(prodType)) aux = false;
  return aux;
};

export const disablePasteText = prodType => {
  let aux = false;
  const haveList = ['dog-collar'];
  if (haveList.includes(prodType)) aux = true;
  return aux;
};

export const espelharProduto = (prodType, styleId, styles) => {
  let aux = false;
  const productList = ['deck-cards'];

  const style = styles.find(({ id }) => id.toString() === styleId);

  if (productList.includes(prodType) && style && style.flip) aux = true;

  return aux;
};

export const ajusteTamanhoTextArea = (prodType, heightTextArea) => {
  let height = heightTextArea;
  const productList = ['deck-cards'];

  if (productList.includes(prodType) && heightTextArea < 70) height = 70;

  return height;
};

export const fillPagesNotAddImage = prodType => {
  let notAddImage = false;
  const notList = ['calendar', 'deck-cards', 'invitation'];

  if (notList.includes(prodType)) notAddImage = true;

  return notAddImage;
};

export const isCustomRichText = prodType => {
  const productList = ['invitation'];
  return productList.includes(prodType);
};

export const isNavTextDisabled = prodType => {
  const productList = ['invitation'];
  return productList.includes(prodType);
};

export const pageDisableTextSearch = prodType => {
  const productList = ['invitation'];
  return productList.includes(prodType);
};

export const enableNavigation = prodType => {
  const notHaveList = ['invitation'];
  return !notHaveList.includes(prodType);
};

export const noOverviewOptions = prodType => {
  const haveList = ['invitation'];
  return haveList.includes(prodType);
};

export const getMaxIndex = (prodType, defaultMaxIndex) => {
  let maxIndex = defaultMaxIndex;
  const haveList = ['invitation'];
  if (haveList.includes(prodType)) maxIndex = 0;
  return maxIndex;
};

export const haveLegenda = () => {
  let have = process.env.NODE_ENV !== 'development';
  return have;
};

export const isOverViewDisabled = prodType => {
  let disabled = false;
  const haveList = ['invitation'];
  if (haveList.includes(prodType)) disabled = true;
  return disabled;
};

export const enablePrecoUnitario = prodType => {
  let aux = false;
  const haveList = ['mask', 'invitation'];
  if (haveList.includes(prodType)) aux = true;
  return aux;
};

export const hasNavegacao = prodType => {
  const notHaveList = ['invitation'];
  return !notHaveList.includes(prodType);
};
