import './index.css';
import React, { useState, useEffect } from 'react';
import {
  THEME_CLASS_BT_LEFT_ARROW_BLACK,
  THEME_CLASS_BT_RIGHT_ARROW_BLACK
} from '../../constant/button';
import {
  pagesFormatPropTypes,
  screenSizePropTypes,
} from '../../proptypes';

import Button from '../button';
import PropTypes from 'prop-types';
import { THEME_CLASS_ICON_CHECK } from '../../constant/button';
import { 
  getArrayFundo,
  getFundoThumbImage,
  getFundoBackgroundColor
} 
from '../../utils/calculosFundos';

const getPageDimensions = (formats) => {
  const { page: { width, height } } = formats;
  return { pageWidth: width, pageHeight: height };
};

function ColorBox ({
  screenSize,
  fundoColor,
  pagesFormats,
  setFundoColor,
  setNavNone,
  tipoFundo,
  isModoSobFivela
}){
  const [maxLayouts, setMaxLayouts] = useState(0);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(0);
  const [margin, setMargin] = useState(0);
  const [colorArray, setColorArray] = useState(getArrayFundo(tipoFundo, isModoSobFivela));

  const PrepareColorBox = (screenSize, { pageWidth, pageHeight }, index = 0) => {
    const layoutsContainer = document.querySelector('.layouts-container');
    const minMargin = screenSize.width * 0.006;

    if (layoutsContainer) {
      const layoutsContainerWidth = layoutsContainer.getBoundingClientRect().width;
      const layoutBoxWidth = Math.floor((pageWidth * 40) / pageHeight) + minMargin;
      const maxLayouts = Math.floor(layoutsContainerWidth / layoutBoxWidth);
      const marginSum = layoutsContainerWidth - layoutBoxWidth * maxLayouts;
      const margin = marginSum / (maxLayouts - 1) + minMargin;

      setStart(index > maxLayouts ? index - maxLayouts + 1 : 0);
      setEnd(index > maxLayouts ? index + 1 : maxLayouts);
      setMaxLayouts(maxLayouts);
      setMargin(margin)
    }
  };

  const scrollLayoutsContainer = direction => {
    if (direction === 'RIGHT' && end !== colorArray.length) {
      setStart(start + 1);
      setEnd(end + 1);
    }
    if (direction === 'LEFT' && start !== 0) {
      setStart(start - 1);
      setEnd(end - 1);
    }
  };

  useEffect(() => {
    const pageDim = getPageDimensions(pagesFormats);
    const colorIndex = colorArray.findIndex(({color}) => color === fundoColor);

    PrepareColorBox(screenSize, pageDim, colorIndex);

    window.addEventListener('resize', () => PrepareColorBox(screenSize, pageDim));

    return () => {
      window.removeEventListener('resize', () => PrepareColorBox(screenSize, pageDim));
  };
  }, []);

  useEffect(() => {
    const colorArrayAux = getArrayFundo(tipoFundo, isModoSobFivela);
    setColorArray(colorArrayAux);

    const pageDim = getPageDimensions(pagesFormats);
    const colorIndex = colorArrayAux.findIndex(({color}) => color === fundoColor);

    PrepareColorBox(screenSize, pageDim, colorIndex);
    
  }, [tipoFundo]);

  const { pageWidth, pageHeight } = getPageDimensions(pagesFormats);

  return (
    <div
      className="layouts-box"
      style={{ maxWidth: `${screenSize.layoutsContainerWidth}px` }}
    >
      <Button
        padding={false}
        theme={THEME_CLASS_BT_LEFT_ARROW_BLACK}
        clickHandler={() => scrollLayoutsContainer('LEFT')}
        style={
          colorArray.length <= maxLayouts || start === 0 ? { opacity: 0.05 } : {}
        }
        disabled={colorArray.length <= maxLayouts || start === 0}
      />
      <div
        className="layouts-container"
        style={{ margin: `0px ${screenSize.width * 0.016}px` }}
      >
        {/* eslint-disable-next-line*/}
        
        {colorArray.map(({ color }, idx) => {
          const fundoWidth = Math.floor((pageWidth * 40) / pageHeight);
          
          return (
            idx >= start &&
            idx <= end - 1 && (
              <div key={idx}>
                {fundoColor === color ? (
                  <div
                    className={`checked ${THEME_CLASS_ICON_CHECK}`}
                    style={{
                      marginLeft: idx === end - 1 ? 'calc(50% - 8.5px)': `calc(50% - ${margin / 2}px - 8.5px)`
                    }}
                  />
                ) : null}
                <div
                  className={`${fundoColor === color ? 'shape-box active' : 'shape-box'}`}
                  style={{
                    width: `${fundoWidth}px`,
                    marginRight: `${idx === end - 1 ? 0 : margin}px`,
                    backgroundColor: getFundoBackgroundColor(color),
                    backgroundImage: getFundoThumbImage(color)
                  }}
                  key={idx}
                  onClick={() => setFundoColor(color)}
                  onDoubleClick={setNavNone}
                />
              </div>
            )
          );
        })}
      </div>
      <Button
        padding={false}
        theme={THEME_CLASS_BT_RIGHT_ARROW_BLACK}
        clickHandler={() => scrollLayoutsContainer('RIGHT')}
        style={
          colorArray.length <= maxLayouts || end === colorArray.length
            ? { opacity: 0.05 }
            : {}
        }
        disabled={colorArray.length <= maxLayouts || end === colorArray.length}
      />
    </div>
  );
}

ColorBox.propType = {
  screenSize: screenSizePropTypes,
  fundoColor: PropTypes.string.isRequired,
  tipoFundo: PropTypes.string.isRequired,
  pagesFormats: pagesFormatPropTypes,
  setFundoColor: PropTypes.func.isRequired,
  setNavNone: PropTypes.func.isRequired,
  prodType: PropTypes.string.isRequired,
  isModoSobFivela: PropTypes.bool.isRequired
};

export default ColorBox;
