import * as actionGeneral from '../action/general';
import * as actionUI from '../action/ui';

import { NAV_IMAGE_SELECTED, NAV_NONE_SELECTED } from '../constant/nav';
import {
  getAllImagesThumb,
  getIsBookOverviewExpanded,
  getIsGalleryExpanded,
  getIsMenuPhotoSelected,
  getPhotoEditor,
  getScreenSize,
  getSortMethod,
  getThumbExpand,
  getThumbExpandImage,
  getToolbarPhoto,
  getToolbarPhotoSize,
  getUsedImage,
  getProductType
} from '../selector';

import { SORT_MANUAL } from '../constant/sort';
import ToolbarPhoto from '../component/toolbar-photo';
import { connect } from 'react-redux';

const mapStateToProps = state => ({
  isGalleryExpanded: getIsGalleryExpanded(state),
  isManualSort: getSortMethod(state) === SORT_MANUAL,
  isMenuPhotoSelected: getIsMenuPhotoSelected(state),
  thumbs: getAllImagesThumb(state),
  getUsedImage: getUsedImage(state),
  toolbarPhoto: getToolbarPhoto(state),
  toolbarPhotoSize: getToolbarPhotoSize(state),
  thumbExpand: getThumbExpand(state),
  thumbExpandImage: getThumbExpandImage(state),
  screenSize: getScreenSize(state),
  isBookOverviewExpanded: getIsBookOverviewExpanded(state),
  isPhotoEditorActive: getPhotoEditor(state).active,
  prodType: getProductType(state),
});

const toggleMenuPhotoHandler = (dispatch, isMenuPhotoSelected) => {
  if (isMenuPhotoSelected) {
    dispatch(actionUI.updateSelectedNav(NAV_NONE_SELECTED));
  } else {
    dispatch(actionUI.updateSelectedNav(NAV_IMAGE_SELECTED));
  }
};

const updateIncMoveIndexHandler = () => {
  // console.log('TODO MOVE ORDER');
};

const mapDispatchToProps = dispatch => ({
  updateIncMoveIndex: (dragDataId, targetDataId) =>
    updateIncMoveIndexHandler(dispatch, dragDataId, targetDataId),
  toggleGalleryExpanded: isGalleryExpanded =>
    dispatch(actionUI.updateGalleryExpanded(!isGalleryExpanded)),
  toogleMenuPhoto: isMenuPhotoSelected =>
    toggleMenuPhotoHandler(dispatch, isMenuPhotoSelected),
  deleteThumb: id => dispatch(actionGeneral.sagaUnstageImage(id)),
  toggleThumbExpand: (status, id) =>
    dispatch(actionUI.updateThumbExpand(status, id)),
  updateThumbExpandIDB: () => dispatch(actionUI.updateThumbExpandIDB()),
  updateToolbarPhoto: (
    maxThumbs,
    thumbsLength,
    prevThumbLenth,
    thumbListLeft,
    thumbListRight,
    thumbsListExpanded
  ) =>
    dispatch(
      actionUI.updateToolbarPhoto(
        maxThumbs,
        thumbsLength,
        prevThumbLenth,
        thumbListLeft,
        thumbListRight,
        thumbsListExpanded
      )
    ),
  updateThumbExpand: type => dispatch(actionGeneral.sagaUpdateThumbExpand(type))
});

export default connect(mapStateToProps, mapDispatchToProps)(ToolbarPhoto);
