import * as actionGeneral from '../constant/action-type/general';

export const updateToFetchedData = fetchedData => ({
  type: actionGeneral.MERGE,
  mergeLib: true,
  payload: fetchedData
});

export const sagaFetchData = (id, pages, blankProduct, isFirefox) => ({
  type: actionGeneral.SAGA_FETCH_DATA,
  payload: { id, pages, blankProduct, isFirefox }
});

export const startMetadataLoop = id => ({
  type: actionGeneral.SAGA_START_METADATA_LOOP,
  payload: { id }
});

export const sagaPostMetadata = (id, attempt = 1) => ({
  type: actionGeneral.SAGA_POST_METADATA,
  payload: { id, attempt }
});

export const startImageLoop = () => ({
  type: actionGeneral.SAGA_START_IMAGE_LOOP
});

export const sagaPostImage = (id, img) => ({
  type: actionGeneral.SAGA_POST_IMAGE,
  payload: { id, img }
});

export const fetchFromIDB = (item, ui, library) => ({
  type: actionGeneral.FETCH_FROM_IDB,
  payload: { item, ui, library }
});

export const updateBlobURL = (id, url) => ({
  type: actionGeneral.UPDATE_BLOB_URL,
  payload: { id, url }
});

export const sagaSubmitOrder = (page, finished) => ({
  type: actionGeneral.SAGA_SUBMIT,
  payload: { page, finished }
});

export const sagaInputFileChange = files => ({
  type: actionGeneral.SAGA_INPUT_FILE_CHANGED,
  payload: { files }
});

export const sagaAddThemeImages = themeImages => ({
  type: actionGeneral.SAGA_ADD_THEME_IMAGE,
  payload: { themeImages }
});

export const sagaThumbDropImage = (
  thumbId,
  shapeId,
  pageIndex,
  pageId,
  styleId,
  fillPages,
  shuffleLayout
) => ({
  type: actionGeneral.SAGA_THUMB_DROP_IMAGE,
  payload: {
    thumbId,
    shapeId,
    pageIndex,
    pageId,
    styleId,
    fillPages,
    shuffleLayout
  }
});

export const sagaDeleteImageFromBook = (id, pageIndex, movePage) => ({
  type: actionGeneral.SAGA_DELETE_IMAGE_FROM_BOOK,
  payload: { id, pageIndex, movePage }
});

export const sagaRemovePages = () => ({
  type: actionGeneral.SAGA_REMOVE_PAGES
});

export const sagaRemoveTexts = () => ({
  type: actionGeneral.SAGA_REMOVE_TEXTS
});

export const sagaInsertPages = () => ({
  type: actionGeneral.SAGA_INSERT_PAGES
});

export const removePages = () => ({
  type: actionGeneral.REMOVE_PAGES
});

export const deleteAllImages = () => ({
  type: actionGeneral.DELETE_ALL_IMAGES
});

export const moveImage = (id, posX, posY) => ({
  type: actionGeneral.MOVE_IMAGE,
  payload: { id, posX, posY }
});

export const updateUsedImage = (id, type) => ({
  type: actionGeneral.UPDATE_USED_IMAGE,
  payload: { id, type }
});

export const setStageImage = (id, isStaged) => ({
  type: actionGeneral.SET_STAGE_IMAGE,
  payload: { id, isStaged }
});

export const setUploadImage = (id, isUploaded) => ({
  type: actionGeneral.SET_UPLOAD_IMAGE,
  payload: { id, isUploaded }
});

export const sagaUnstageImage = id => ({
  type: actionGeneral.SAGA_UNSTAGE_STAGE_IMAGE,
  payload: { id }
});

export const sagaIncreasePageIndex = () => ({
  type: actionGeneral.SAGA_INCREASE_PAGE_INDEX
});

export const sagaDecreasePageIndex = () => ({
  type: actionGeneral.SAGA_DECREASE_PAGE_INDEX
});

export const sagaSetPageIndex = index => ({
  type: actionGeneral.SAGA_SET_PAGE_INDEX,
  payload: { index }
});

export const sagaMovePage = side => ({
  type: actionGeneral.SAGA_MOVE_PAGE,
  payload: { side }
});

export const sagaFirstPageIndex = () => ({
  type: actionGeneral.SAGA_FIRST_PAGE_INDEX
});

export const sagaLastPageIndex = last => ({
  type: actionGeneral.SAGA_LAST_PAGE_INDEX,
  payload: { last }
});

export const sagaSetLayout = (layoutId, keepGoing) => ({
  type: actionGeneral.SAGA_SET_LAYOUT,
  payload: { layoutId, keepGoing }
});

export const sagaShuffleLayout = (id, type) => ({
  type: actionGeneral.SAGA_SHUFFLE_LAYOUT,
  payload: { id, type }
});

export const sagaSetRichText = (
  richText,
  pageId,
  richTextState,
  isFirst = false,
  spineFill = false,
  x,
  y,
  l,
  a
) => ({
  type: actionGeneral.SAGA_SET_RICH_TEXT,
  payload: { richText, pageId, richTextState, isFirst, spineFill, x, y, l, a }
});

export const removeTextsFromPage = pageId => ({
  type: actionGeneral.REMOVE_TEXTS_FROM_PAGE,
  payload: { pageId }
});

export const removeFrameFromPage = (pageId, frameId) => ({
  type: actionGeneral.REMOVE_FRAME_FROM_PAGE,
  payload: { pageId, frameId }
});

export const removeFrame = id => ({
  type: actionGeneral.SAGA_REMOVE_FRAME,
  payload: { id }
});

export const updateZoom = (id, type) => ({
  type: actionGeneral.UPDATE_ZOOM,
  payload: { id, type }
});

export const rotateImage = (id, quantidade) => ({
  type: actionGeneral.ROTATE_IMAGE,
  payload: { id, quantidade }
});

export const duplicatePage = id => ({
  type: actionGeneral.DUPLICATE_PAGE,
  payload: { id }
});

export const sagaOverviewRemovePages = (pages, remove) => ({
  type: actionGeneral.SAGA_OVERVIEW_REMOVE_PAGES,
  payload: { pages, remove }
});

export const sagaUpdateThumbExpand = type => ({
  type: actionGeneral.SAGA_UPDATE_THUMB_EXPAND,
  payload: { type }
});

// ServerImages

export const sagaStoreServerImage = img => ({
  type: actionGeneral.SAGA_STORE_SERVER_IMAGE,
  payload: { img }
});

export const sagaRemoveStoredServerImage = id => ({
  type: actionGeneral.SAGA_REMOVE_STORED_SERVER_IMAGE,
  payload: { id }
});

export const sagaAddFailUploadImage = id => ({
  type: actionGeneral.SAGA_ADD_FAIL_UPLOAD_IMAGE,
  payload: { id }
});

export const sagaAddServerImageUploading = id => ({
  type: actionGeneral.SAGA_ADD_SERVER_IMAGE_UPLOADING,
  payload: { id }
});

export const sagaRemoveServerImageUploading = id => ({
  type: actionGeneral.SAGA_REMOVE_SERVER_IMAGE_UPLOADING,
  payload: { id }
});

export const sagaAddServerImageUploadEnable = images => ({
  type: actionGeneral.SAGA_ADD_SERVER_IMAGE_UPLOAD_ENABLE,
  payload: { images }
});

export const setCustomProducts = products => ({
  type: actionGeneral.SET_CUSTOM_PRODUCTS,
  payload: { products }
});

export const sagaSwapPoster = side => ({
  type: actionGeneral.SAGA_SWAP_POSTER,
  payload: { side }
});

export const postMetadataFormat = (id, productFormat, pageFormat) => ({
  type: actionGeneral.SAGA_POST_METADATA_FORMAT,
  payload: { id, productFormat, pageFormat }
});

export const postMetadataOrientation = (id, productFormat, pageFormat) => ({
  type: actionGeneral.SAGA_POST_METADATA_ORIENTATION,
  payload: { id, productFormat, pageFormat }
});

export const toggleRevisarMudancaOrientacao = () => ({
  type: actionGeneral.TOGGLE_REVISAR_MUDANCA_ORIENTACAO
});
