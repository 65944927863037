import './index.css';

import * as R from 'ramda';

import React, { Component } from 'react';
import {
  navigatorPropTypes,
  pagesFormatPropTypes,
  photoEditorPropTypes,
  printOptionsPropTypes,
  screenSizePropTypes,
  textOptionsPropTypes,
  stylePropTypes
} from '../../proptypes';

import BookNavigator from '../book-navigator';
import BookOverviewOptions from '../../container/book-overview-options';
import PhotoEditor from '../../container/photo-editor';
import PropTypes from 'prop-types';
import * as calcOver from '../../utils/calculosOverview';
import {
  calcularPhotobookWidth,
  calcularPhotobookHeight,
  photobookPadding,
  photobookJustify,
  calculaLastNavigatorOnLine
} from '../../utils/calculosGerais';
import { isCustomProduct } from '../../utils/separadorProdutos';

const prepareBookOverview = (
  pagesFormats,
  isBookOverviewExpanded,
  isGalleryExpanded,
  navigatorData,
  allPagesData,
  onShapeDrop,
  isDragging,
  dragId,
  mouseStartHandler,
  mouseStopHandler,
  moveHandler,
  moveKeyboardHandler,
  setPageIndex,
  fixPos,
  textChangeHandler,
  isMenuTextSelected,
  textOptions,
  toggleNavText,
  updateTextOptions,
  photoEditor,
  updatePhotoEditor,
  overviewRemovePages,
  setPageToRemove,
  unsetPageToRemove,
  toggleBookOverviewExpanded,
  screenSize,
  screenRate,
  isPrint,
  isThumb,
  spineIndex,
  bookHaveCover,
  printOptions,
  setNumber,
  updateWarning,
  ean,
  prodCode,
  productType,
  productFormat,
  currentNav,
  isModoImpressao,
  isModoSobFivela,
  setStateLegenda,
  tamanhoSelecionado,
  styles,
  selectedProduct,
  tipoFundo,
  fundoColor,
  layoutsFilter
) => {
  const { page: pageFormat, cover: coverFormat } = pagesFormats;
  const pagesLength = allPagesData.pages.length / 2 + (bookHaveCover ? 1 : 0);
  const bookNavigators = [];
  const avtivesID = R.map(R.prop('id'), navigatorData.pages);

  // Margin left
  const overviewWidth = calcOver.calculaOverviewWidth(screenSize.width);
  const bookNavigatorWidth = calcOver.calculaBookNavigatorWidth(
    pageFormat.width,
    pageFormat.height,
    allPagesData.lines,
    allPagesData.cols
  );
  const maxNavigatorLine = calcOver.calcMaxNavigatorLine(
    productType,
    overviewWidth,
    bookNavigatorWidth
  );
  // Margin bottom
  const bottomPagesNumber = calcOver.calcBottomPagesNumber(
    pagesLength,
    maxNavigatorLine
  );
  const bottomPages = calcOver.calcBottomPages(bottomPagesNumber, pagesLength);

  if (bookHaveCover) {
    const coversPages = [];

    R.map(c => coversPages.push(c), allPagesData.covers);
    bookNavigators.push(
      <BookNavigator
        height={120}
        // TODO Dynamic width and height
        pageWidth={coverFormat.width}
        pageHeight={coverFormat.height}
        pageIndex={0}
        spineIndex={spineIndex}
        navigatorData={{
          ...allPagesData,
          pages: coversPages,
          cols: coversPages.length
        }}
        pagesFormats={pagesFormats}
        onShapeDrop={onShapeDrop}
        mouseStartHandler={mouseStartHandler}
        mouseStopHandler={mouseStopHandler}
        isDragging={isDragging}
        textChangeHandler={textChangeHandler}
        dragId={dragId}
        moveHandler={moveHandler}
        moveKeyboardHandler={moveKeyboardHandler}
        setPageIndex={setPageIndex}
        fixPos={fixPos}
        isMenuTextSelected={isMenuTextSelected}
        textOptions={textOptions}
        toggleNavText={toggleNavText}
        updateTextOptions={updateTextOptions}
        photoEditor={photoEditor}
        updatePhotoEditor={updatePhotoEditor}
        isBookOverviewExpanded={isBookOverviewExpanded}
        isGalleryExpanded={isGalleryExpanded}
        addMarginBottomNavigator={1}
        isOverviewRemovePagesActive={overviewRemovePages.active}
        pagesToRemove={overviewRemovePages.pages}
        setPageToRemove={setPageToRemove}
        unsetPageToRemove={unsetPageToRemove}
        toggleBookOverviewExpanded={toggleBookOverviewExpanded}
        screenSize={screenSize}
        bookHaveCover={bookHaveCover}
        printOptions={printOptions}
        screenRate={screenRate}
        isCover
        isPrint={isPrint}
        isThumb={isThumb}
        setNumber={setNumber}
        avtivesID={avtivesID}
        updateWarning={updateWarning}
        ean={ean}
        prodCode={prodCode}
        productType={productType}
        productFormat={productFormat}
        currentNav={currentNav}
        isModoImpressao={isModoImpressao}
        isModoSobFivela={isModoSobFivela}
        setStateLegenda={setStateLegenda}
        tamanhoSelecionado={tamanhoSelecionado}
        styles={styles}
        key={0}
        defaultPageWidth={pageFormat.width}
        defaultPageHeight={pageFormat.height}
        selectedProduct={selectedProduct}
        tipoFundo={tipoFundo}
        fundoColor={fundoColor}
        layoutsFilter={layoutsFilter}
      />
    );
  }

  for (let i = 0; i < allPagesData.pages.length / navigatorData.cols; i++) {
    bookNavigators.push(
      <BookNavigator
        height={120}
        pageWidth={pageFormat.width}
        pageHeight={pageFormat.height}
        pageIndex={bookHaveCover ? i + 1 : i}
        spineIndex={spineIndex}
        navigatorData={{
          ...allPagesData,
          pages: allPagesData.pages.filter((_page, index) =>
            navigatorData.cols > 1
              ? i * 2 === index || i * 2 + 1 === index
              : index === i
          )
        }}
        pagesFormats={pagesFormats}
        onShapeDrop={onShapeDrop}
        mouseStartHandler={mouseStartHandler}
        mouseStopHandler={mouseStopHandler}
        isDragging={isDragging}
        textChangeHandler={textChangeHandler}
        dragId={dragId}
        moveHandler={moveHandler}
        moveKeyboardHandler={moveKeyboardHandler}
        setPageIndex={setPageIndex}
        fixPos={fixPos}
        isMenuTextSelected={isMenuTextSelected}
        textOptions={textOptions}
        toggleNavText={toggleNavText}
        updateTextOptions={updateTextOptions}
        photoEditor={photoEditor}
        updatePhotoEditor={updatePhotoEditor}
        isBookOverviewExpanded={isBookOverviewExpanded}
        isGalleryExpanded={isGalleryExpanded}
        lastNavigatorOnLine={calculaLastNavigatorOnLine(
          productType,
          bookHaveCover,
          maxNavigatorLine,
          i
        )}
        addMarginBottomNavigator={calcOver.calcMarginBottomNavigator(
          productType,
          bottomPages,
          i
        )}
        isOverviewRemovePagesActive={overviewRemovePages.active}
        pagesToRemove={overviewRemovePages.pages}
        setPageToRemove={setPageToRemove}
        unsetPageToRemove={unsetPageToRemove}
        toggleBookOverviewExpanded={toggleBookOverviewExpanded}
        screenSize={screenSize}
        isCover={false}
        isPrint={isPrint}
        isThumb={isThumb}
        setNumber={setNumber}
        screenRate={screenRate}
        printOptions={printOptions}
        bookHaveCover={bookHaveCover}
        avtivesID={avtivesID}
        updateWarning={updateWarning}
        ean={ean}
        prodCode={prodCode}
        productType={productType}
        productFormat={productFormat}
        currentNav={currentNav}
        isModoImpressao={isModoImpressao}
        isModoSobFivela={isModoSobFivela}
        setStateLegenda={setStateLegenda}
        tamanhoSelecionado={tamanhoSelecionado}
        styles={styles}
        key={bookHaveCover ? i + 1 : i}
        defaultPageWidth={pageFormat.width}
        defaultPageHeight={pageFormat.height}
        selectedProduct={selectedProduct}
        tipoFundo={tipoFundo}
        fundoColor={fundoColor}
        layoutsFilter={layoutsFilter}
      />
    );
  }

  return bookNavigators;
};

class Photobook extends Component {
  getRate = svg => {
    const {
      clientHeight: height,
      clientWidth: width,
      viewBox: {
        baseVal: { width: vWidth, height: vHeight }
      }
    } = svg;
    const rate = Math.max(vWidth / width, vHeight / height).toFixed(2);
    return parseFloat(rate);
  };

  checkRate = () => {
    const { isBookOverviewExpanded, screenRate, updateScreenRate } = this.props;
    const bookNav = document.querySelector('.book-navigator svg');
    const rate =
      !!bookNav && !isBookOverviewExpanded ? this.getRate(bookNav) : 4;
    rate !== screenRate && updateScreenRate(rate);
  };

  componentDidUpdate(prevProps) {
    const {
      screenSize: { width: previousWidth, height: previousHeight },
      pageIndex: previousPageIndex
    } = prevProps;
    const {
      screenSize: { width, height },
      pageIndex,
      isBookOverviewExpanded
    } = this.props;

    (previousWidth !== width ||
      previousHeight !== height ||
      previousPageIndex !== pageIndex) &&
      !isBookOverviewExpanded &&
      this.checkRate();
  }

  componentDidMount() {
    this.checkRate();
  }

  render() {
    const {
      allPagesData,
      bookHaveCover,
      coverLengthPDF,
      dragId,
      ean,
      fixPos,
      isBookOverviewExpanded,
      isDragging,
      isGalleryExpanded,
      isMenuTextSelected,
      isPrint,
      isThumb,
      isScodix,
      mouseStartHandler,
      mouseStopHandler,
      moveHandler,
      moveKeyboardHandler,
      navigatorData,
      onShapeDrop,
      overviewRemovePages,
      pageIndex,
      pagesFormats,
      selectedProduct,
      photoEditor,
      printOptions,
      productFormat,
      currentNav,
      isModoImpressao,
      isModoSobFivela,
      setStateLegenda,
      tamanhoSelecionado,
      styles,
      prodCode,
      productType,
      screenRate,
      screenSize,
      setNumber,
      setPageIndex,
      setPageToRemove,
      spineIndex,
      spineMargin,
      textChangeHandler,
      textOptions,
      toggleBookOverviewExpanded,
      toggleNavText,
      unsetPageToRemove,
      updatePhotoEditor,
      updateTextOptions,
      updateWarning,
      tipoFundo,
      fundoColor,
      layoutsFilter
    } = this.props;

    let { page: pageFormat, cover: coverFormat } = pagesFormats;

    if (
      isCustomProduct(productType) &&
      selectedProduct &&
      selectedProduct.format
    ) {
      pageFormat = selectedProduct.format.page;
      coverFormat = selectedProduct.format.cover;
    }

    return (
      <div
        id="photobook-id"
        className={`photobook${isGalleryExpanded ? ' hidden' : ''}${isBookOverviewExpanded ? ' bookOverviewExpanded' : ''
          }`}
        style={{
          padding: photobookPadding(
            productType,
            screenSize,
            isBookOverviewExpanded
          ),
          justifyContent: photobookJustify(productType, isBookOverviewExpanded),
          flexGrow: isBookOverviewExpanded || isPrint ? 0 : 1
        }}
      >
        {photoEditor.active && <PhotoEditor></PhotoEditor>}
        {isBookOverviewExpanded && <BookOverviewOptions></BookOverviewOptions>}
        {isDragging && <div className="navigator-modal" />}
        {isBookOverviewExpanded ? (
          prepareBookOverview(
            pagesFormats,
            isBookOverviewExpanded,
            isGalleryExpanded,
            navigatorData,
            allPagesData,
            onShapeDrop,
            isDragging,
            dragId,
            mouseStartHandler,
            mouseStopHandler,
            moveHandler,
            moveKeyboardHandler,
            setPageIndex,
            fixPos,
            textChangeHandler,
            isMenuTextSelected,
            textOptions,
            toggleNavText,
            updateTextOptions,
            photoEditor,
            updatePhotoEditor,
            overviewRemovePages,
            setPageToRemove,
            unsetPageToRemove,
            toggleBookOverviewExpanded,
            screenSize,
            screenRate,
            isPrint,
            isThumb,
            spineIndex,
            bookHaveCover,
            printOptions,
            setNumber,
            updateWarning,
            ean,
            prodCode,
            productType,
            productFormat,
            currentNav,
            isModoImpressao,
            isModoSobFivela,
            setStateLegenda,
            tamanhoSelecionado,
            styles,
            selectedProduct,
            tipoFundo,
            fundoColor,
            layoutsFilter
          )
        ) : (
          <BookNavigator
            width={calcularPhotobookWidth(screenSize.width, screenSize.height)}
            height={calcularPhotobookHeight(
              productType,
              isPrint,
              screenSize.photobookHeight
            )}
            pageWidth={
              bookHaveCover && pageIndex <= spineIndex
                ? coverFormat.width
                : pageFormat.width
            }
            pageHeight={
              bookHaveCover && pageIndex <= spineIndex
                ? coverFormat.height
                : pageFormat.height
            }
            pagesFormats={pagesFormats}
            pageIndex={pageIndex}
            spineIndex={spineIndex}
            navigatorData={navigatorData}
            onShapeDrop={onShapeDrop}
            mouseStartHandler={mouseStartHandler}
            mouseStopHandler={mouseStopHandler}
            isDragging={isDragging}
            textChangeHandler={textChangeHandler}
            dragId={dragId}
            moveHandler={moveHandler}
            moveKeyboardHandler={moveKeyboardHandler}
            fixPos={fixPos}
            isMenuTextSelected={isMenuTextSelected}
            textOptions={textOptions}
            toggleNavText={toggleNavText}
            updateTextOptions={updateTextOptions}
            photoEditor={photoEditor}
            updatePhotoEditor={updatePhotoEditor}
            isBookOverviewExpanded={isBookOverviewExpanded}
            isGalleryExpanded={isGalleryExpanded}
            setPageToRemove={setPageToRemove}
            unsetPageToRemove={unsetPageToRemove}
            overviewRemovePages={overviewRemovePages}
            isPrint={isPrint}
            isThumb={isThumb}
            isScodix={isScodix}
            printOptions={printOptions}
            spineMargin={spineMargin}
            setNumber={setNumber}
            bookHaveCover={bookHaveCover}
            coverLengthPDF={coverLengthPDF}
            isCover={pageIndex <= spineIndex}
            screenRate={screenRate}
            updateWarning={updateWarning}
            ean={ean}
            prodCode={prodCode}
            productType={productType}
            productFormat={productFormat}
            currentNav={currentNav}
            isModoImpressao={isModoImpressao}
            isModoSobFivela={isModoSobFivela}
            setStateLegenda={setStateLegenda}
            tamanhoSelecionado={tamanhoSelecionado}
            styles={styles}
            defaultPageWidth={pageFormat.width}
            defaultPageHeight={pageFormat.height}
            selectedProduct={selectedProduct}
            tipoFundo={tipoFundo}
            fundoColor={fundoColor}
            layoutsFilter={layoutsFilter}
          />
        )}
      </div>
    );
  }
}

Photobook.propTypes = {
  allPagesData: navigatorPropTypes,
  bookHaveCover: PropTypes.bool.isRequired,
  coverLengthPDF: PropTypes.number.isRequired,
  dragId: PropTypes.string.isRequired,
  ean: PropTypes.string,
  fixPos: PropTypes.func.isRequired,
  isBookOverviewExpanded: PropTypes.bool,
  isDragging: PropTypes.bool.isRequired,
  isGalleryExpanded: PropTypes.bool.isRequired,
  isMenuTextSelected: PropTypes.bool.isRequired,
  isPrint: PropTypes.bool.isRequired,
  isThumb: PropTypes.bool.isRequired,
  isScodix: PropTypes.bool.isRequired,
  mouseStartHandler: PropTypes.func.isRequired,
  mouseStopHandler: PropTypes.func.isRequired,
  moveHandler: PropTypes.func.isRequired,
  moveKeyboardHandler: PropTypes.func.isRequired,
  navigatorData: navigatorPropTypes,
  onShapeDrop: PropTypes.func.isRequired,
  overviewRemovePages: PropTypes.shape({
    active: PropTypes.bool.isRequired,
    pages: PropTypes.arrayOf(PropTypes.number).isRequired
  }).isRequired,
  pageIndex: PropTypes.number.isRequired,
  pagesFormats: pagesFormatPropTypes,
  selectedProduct: PropTypes.object,
  photoEditor: photoEditorPropTypes,
  printOptions: printOptionsPropTypes,
  prodCode: PropTypes.string.isRequired,
  productFormat: PropTypes.string.isRequired,
  productType: PropTypes.string.isRequired,
  currentNav: PropTypes.string.isRequired,
  isModoImpressao: PropTypes.bool.isRequired,
  isModoSobFivela: PropTypes.bool.isRequired,
  setStateLegenda: PropTypes.func.isRequired,
  tamanhoSelecionado: PropTypes.number,
  styles: PropTypes.arrayOf(stylePropTypes),
  screenRate: PropTypes.number.isRequired,
  screenSize: screenSizePropTypes,
  setNumber: PropTypes.number.isRequired,
  setPageIndex: PropTypes.func.isRequired,
  setPageToRemove: PropTypes.func.isRequired,
  spineIndex: PropTypes.number.isRequired,
  spineMargin: PropTypes.number.isRequired,
  textChangeHandler: PropTypes.func.isRequired,
  textOptions: textOptionsPropTypes,
  toggleBookOverviewExpanded: PropTypes.func.isRequired,
  toggleNavText: PropTypes.func.isRequired,
  unsetPageToRemove: PropTypes.func.isRequired,
  updatePhotoEditor: PropTypes.func.isRequired,
  updateScreenRate: PropTypes.func.isRequired,
  updateTextOptions: PropTypes.func.isRequired,
  updateWarning: PropTypes.func.isRequired,
  tipoFundo: PropTypes.string.isRequired,
  fundoColor: PropTypes.string.isRequired,
  layoutsFilter: PropTypes.object.isRequired
};

Photobook.defaultProps = {
  isGalleryExpanded: false,
  isDragging: false,
  dragId: undefined
};

export default Photobook;
