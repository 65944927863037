import * as actionGeneral from '../action/general';
import * as actionItem from '../action/item';
import * as actionUI from '../action/ui';
import store from '../store';

import {
  NAV_BOOK_SELECTED,
  NAV_LAYOUTS_SELECTED,
  NAV_FUNDOS_SELECTED,
  NAV_NONE_SELECTED
} from '../constant/nav';
import {
  bookHaveCover,
  getBookNavigatorData,
  getBookNavigatorVisiblePagesLength,
  getEndpapersLength,
  getIsBookOverviewExpanded,
  getIsGalleryExpanded,
  getIsMenuBookSelected,
  getIsMenuLayoutsSelected,
  getIsMenuFundosSelected,
  getLayoutsFilter,
  getNavigationIndex,
  getScreenSize,
  getPhotoEditor,
  getThumbExpand,
  getProductType,
  getProductFormat,
  getIsModoImpressao,
  getIsModoSobFivela,
  getIsPopupOpened,
  getTutorialStatus,
  getOverviewRemovePages,
  getTamanhoColeira,
  getIsMenuTextSelected
} from '../selector';

import MenuBook from '../component/menu-book';
import { connect } from 'react-redux';
import { getSetNumber } from '../selector/set';

const mapStateToProps = state => ({
  pages: getBookNavigatorData(state).pages,
  pageIndex: getNavigationIndex(state),
  visiblePages: getBookNavigatorVisiblePagesLength(state),
  isMenuBookSelected: getIsMenuBookSelected(state),
  isGalleryExpanded: getIsGalleryExpanded(state),
  isBookOverviewExpanded: getIsBookOverviewExpanded(state),
  isMenuLayoutsSelected: getIsMenuLayoutsSelected(state),
  isMenuFundosSelected: getIsMenuFundosSelected(state),
  isMenuTextSelected: getIsMenuTextSelected(state),
  maxIndex:
    getSetNumber(state) / getBookNavigatorVisiblePagesLength(state) -
    (!getEndpapersLength(state) ? 1 : 0),
  screenSize: getScreenSize(state),
  bookHaveCover: bookHaveCover(state),
  layoutsFilter: getLayoutsFilter(state),
  isPhotoEditorActive: getPhotoEditor(state).active,
  isThumbExpandedActive: getThumbExpand(state).active.length > 0,
  prodType: getProductType(state),
  prodFormat: getProductFormat(state),
  isModoImpressao: getIsModoImpressao(state),
  isModoSobFivela: getIsModoSobFivela(state),
  isPopupOpened: getIsPopupOpened(state),
  isTutorial: getTutorialStatus(state) !== '',
  isOverviewRemovePages: getOverviewRemovePages(state).active,
  tamanhoSelecionado: getTamanhoColeira(state)
});

const toggleMenuBookHandler = (dispatch, isMenuBookSelected) => {
  if (isMenuBookSelected) {
    dispatch(actionUI.updateSelectedNav(NAV_NONE_SELECTED));
  } else {
    dispatch(actionUI.updateSelectedNav(NAV_BOOK_SELECTED));
  }
};

const toggleMenuLayoutsHandler = (dispatch, isMenuLayoutsSelected) => {
  if (isMenuLayoutsSelected) {
    dispatch(actionUI.updateSelectedNav(NAV_NONE_SELECTED));
  } else {
    dispatch(actionUI.updateSelectedNav(NAV_LAYOUTS_SELECTED));
  }
};

const toggleMenuFundosHandler = (dispatch, isMenuFundosSelected) => {
  if (isMenuFundosSelected) {
    dispatch(actionUI.updateSelectedNav(NAV_NONE_SELECTED));
  } else {
    dispatch(actionUI.updateSelectedNav(NAV_FUNDOS_SELECTED));
  }
};

const toggleModoSobFivelaHandler = dispatch => {
  const state = store.getState();
  const prodType = getProductType(state);
  if (prodType === 'deck-cards') {
    const isModoSobFivela = getIsModoSobFivela(state);
    dispatch(
      actionItem.resetLayoutFilterApplyMethod(
        !isModoSobFivela
          ? 'a todas as cartas verso'
          : 'a todas as cartas frente'
      )
    );
  }
  dispatch(actionItem.toggleModoSobFivela());
};

const mapDispatchToProps = dispatch => ({
  toogleMenuBook: isMenuBookSelected =>
    toggleMenuBookHandler(dispatch, isMenuBookSelected),
  toogleMenuLayouts: isMenuLayoutsSelected =>
    toggleMenuLayoutsHandler(dispatch, isMenuLayoutsSelected),
  toogleMenuFundos: isMenuFundosSelected =>
    toggleMenuFundosHandler(dispatch, isMenuFundosSelected),
  toggleBookOverviewExpanded: isBookOverviewExpanded =>
    dispatch(actionUI.updateBookOverviewExpanded(!isBookOverviewExpanded)),
  incrementNavigationIndex: () =>
    dispatch(actionGeneral.sagaIncreasePageIndex()),
  decrementNavigationIndex: () =>
    dispatch(actionGeneral.sagaDecreasePageIndex()),
  firstPage: () => dispatch(actionGeneral.sagaFirstPageIndex()),
  lastPage: () => dispatch(actionGeneral.sagaLastPageIndex()),
  setPage: pageIndex => dispatch(actionGeneral.sagaSetPageIndex(pageIndex)),
  suffleLayout: (id, type) =>
    dispatch(actionGeneral.sagaShuffleLayout(id, type)),
  updateLayoutFilterSide: value =>
    dispatch(actionItem.updateLayoutFilterSide(value)),
  adjustLayoutsFilter: () => dispatch(actionItem.sagaAdjustLayoutsFilter()),
  toggleModoImpressao: () => dispatch(actionUI.toggleModoImpressao()),
  toggleModoSobFivela: () => toggleModoSobFivelaHandler(dispatch),
  toggleRemovePages: () => dispatch(actionItem.toggleRemovePages())
});

export default connect(mapStateToProps, mapDispatchToProps)(MenuBook);
