import './index.css';

import React, { useContext } from 'react';
import CustomProductContext from '../../contexts/customProductContext';
import Logo from '../../asset/logo.svg';
import NumericStepper from '../../container/page-stepper';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import Switch from '../switch';
import {
  THEME_CLASS_GREY,
  THEME_CLASS_GREY_DISABLED,
  THEME_CLASS_CONFIGURACAO
} from '../../constant/button';
import { getFinaliza } from '../../utils/textosVariaveis';
import { screenSizePropTypes } from '../../proptypes';
import { getTipoQuantidade, getPreco } from '../../utils/textosVariaveis';
import { isCustomProduct } from '../../utils/separadorProdutos';

const TooltipScodix = () => (
  <div className="green-arrow arrow-3d">
    <div className="green-arrow-3d" />
    <div>
      <span>Escolha a opção de capa 3D</span>
    </div>
  </div>
);

const TooltipDone = () => (
  <div className="green-arrow arrow-done">
    <div className="green-arrow-done buy-arrow-loop" />
    <div>
      <span>
        <strong>Compre seu cupom</strong>
      </span>
    </div>
  </div>
);

function Header({
  name,
  price,
  hasScodix,
  isOn,
  onSwitch,
  submitHandler,
  showTooltips,
  screenSize,
  bookHaveImage,
  bookHaveText,
  galleryExpanded,
  bookOverviewExpanded,
  currentNav,
  updateTutorialStatus,
  prodType,
  setStateLegenda,
  selectedProduct,
  setSelectedProduct,
  setUserProduct,
  setModalConfig,
  isFinished
}) {
  const { listaProdutos } = useContext(CustomProductContext);

  const mudancaProduto = produtoId => {
    const produto = listaProdutos.find(({ id }) => id === produtoId);
    setSelectedProduct(produto);
    setUserProduct(produtoId);
  };

  const disabledDone =
    isFinished ||
    (!isFinished &&
      ((prodType === 'dog-collar' ? !bookHaveText : !bookHaveImage) ||
        prodType === 'deck-cards'));

  return (
    <header className="header" id="product-header">
      <div className="content">
        <div className="logo">
          <img src={Logo} alt="logotipo da empresa" width="45" height="46" />
        </div>
        <div className="info">
          <div className="headline">
            <h1 className="product-name">
              {isCustomProduct(prodType) && selectedProduct
                ? selectedProduct.description.short
                : name}
            </h1>
            {hasScodix ? (
              <div className="switch-container">
                <Switch isOn={isOn} onSwitch={onSwitch} />
                {showTooltips ? <TooltipScodix /> : false}
              </div>
            ) : (
              false
            )}
            {prodType === 'poster' && (
              <input
                className={`button ${THEME_CLASS_CONFIGURACAO} bt-config`}
                data-tip="Configurações: Altera o tipo de produto, o formato, a orientação e o acabamento"
                data-place="bottom"
                type="button"
                style={{
                  width: '13px',
                  height: '13px',
                  padding: 0,
                  margin: '0 0 0 0.6%'
                }}
                onClick={() => setModalConfig('config')}
              />
            )}
          </div>
          <div>
            <span>{`Quantidade de ${getTipoQuantidade(true, true)}:`}</span>
            <div className="page-info">
              <NumericStepper screenSize={screenSize} />
            </div>
          </div>
          <div className="price">{getPreco(price)}</div>
        </div>
        {isCustomProduct(prodType) &&
          listaProdutos &&
          listaProdutos.length > 0 &&
          selectedProduct && (
            <div className="custom-product">
              <select
                onChange={e => {
                  mudancaProduto(e.target.value);
                }}
                value={selectedProduct.id}
              >
                {listaProdutos.map(product => (
                  <option key={product.id} value={product.id}>
                    {product.description.short}
                  </option>
                ))}
              </select>
            </div>
          )}
        <div className="form">
          <form onSubmit={submitHandler}>
            <input
              className={`button ${!bookOverviewExpanded
                  ? THEME_CLASS_GREY
                  : THEME_CLASS_GREY_DISABLED
                }`}
              value="?"
              type="button"
              data-tip={bookOverviewExpanded ? '' : 'Tutorial'}
              data-place="bottom"
              style={{
                marginLeft: `${screenSize.width * 0.006}px`,
                width: '42px',
                height: '38px'
              }}
              onClick={() => {
                updateTutorialStatus(
                  prodType === 'mask' ? 'PASSO-TUTORIAL' : 'VIDEO-TUTORIAL'
                );
                if (prodType === 'mask') setStateLegenda('TUTORIAL');
              }}
            />
            <input
              id="submit-item"
              type="submit"
              className="done"
              value="Pronto"
              data-tip={
                disabledDone ? '' : `Finaliza ${getFinaliza()} e faz o pedido`
              }
              data-place="bottom"
              style={{
                marginLeft: `${screenSize.width * 0.006}px`,
                width: '126px',
                height: '38px'
              }}
              disabled={disabledDone ? 'disabled' : ''}
            />
          </form>
          {showTooltips ? <TooltipDone /> : false}
        </div>
      </div>
      <ReactTooltip />
    </header>
  );
}

Header.propTypes = {
  name: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  hasScodix: PropTypes.bool,
  isOn: PropTypes.bool,
  onSwitch: PropTypes.func,
  submitHandler: PropTypes.func.isRequired,
  updateTutorialStatus: PropTypes.func.isRequired,
  showTooltips: PropTypes.bool,
  screenSize: screenSizePropTypes,
  bookHaveImage: PropTypes.bool.isRequired,
  bookHaveText: PropTypes.bool.isRequired,
  galleryExpanded: PropTypes.bool.isRequired,
  bookOverviewExpanded: PropTypes.bool.isRequired,
  isPhotoEditorActive: PropTypes.bool.isRequired,
  currentNav: PropTypes.string.isRequired,
  toggleLegenda: PropTypes.func.isRequired,
  isLegendaAberta: PropTypes.bool.isRequired,
  setStateLegenda: PropTypes.func.isRequired,
  selectedProduct: PropTypes.object,
  setSelectedProduct: PropTypes.func,
  setUserProduct: PropTypes.func,
  setModalConfig: PropTypes.func,
  isFinished: PropTypes.bool.isRequired
};

Header.defaultProps = {
  hasScodix: false,
  showTooltips: false,
  isOn: false,
  onSwitch: undefined
};

export default Header;
