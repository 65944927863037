import React, { Component } from 'react';

import PropTypes from 'prop-types';

const numberToPoints = (number, symbol) => {
  let points = '';
  for (let i = 0; i < number; i++) {
    points += symbol;
  }

  return points;
};

class TextSpinner extends Component {
  constructor(props) {
    super(props);
    this.state = { counter: 0 };
  }

  tick() {
    this.setState({
      counter: this.state.counter + 1
    });
  }

  componentWillUnmount() {
    clearInterval(this.timerCount);
  }

  componentDidMount() {
    this.timerCount = setInterval(() => this.tick(), 1000);
  }

  render() {
    const { msg, length, symbol } = this.props;
    const numPoints = this.state.counter % (length + 1);
    const points = numberToPoints(numPoints, symbol);

    return <span className="text-dot">{`${msg}${points}`}</span>;
  }
}

TextSpinner.propTypes = {
  msg: PropTypes.string.isRequired,
  symbol: PropTypes.string.isRequired,
  length: PropTypes.number.isRequired
};

TextSpinner.defaultProps = {
  symbol: '.',
  length: 3
};

export default TextSpinner;
