import * as actionUI from '../action/ui';
import * as actionItem from '../action/item';
import * as actionGeneral from '../action/general';

import {
  getIsBookOverviewExpanded,
  getIsGalleryExpanded,
  getPaperSize,
  getProductCanvasSize,
  getPhotobookHeight,
  getProductType,
  getProductFormat,
  getScreenSizeError,
  getTutorialStatus,
  isCoupon,
  isPrint,
  getBibliotecaTemasOpened,
  getBibliotecaTemas,
  getPhotoEditor,
  getCurrentNav,
  getIsLegendaAberta,
  getStateLegenda,
  getScreenSize,
  getCustomProducts,
  getUserProducts,
  getModalConfig,
  getItemId,
  getIsRevisarMudancaOrientacao
} from '../selector';
import { getPopupId } from '../selector/popup';

import { updateSelectedNav } from '../action/ui';

import Product from '../component/product';
import { connect } from 'react-redux';

const mapStateToProps = state => ({
  screenSizeError: getScreenSizeError(state),
  isGalleryExpanded: getIsGalleryExpanded(state),
  isBookOverviewExpanded: getIsBookOverviewExpanded(state),
  pageFormat: getPaperSize(state),
  productFormat: getProductFormat(state),
  productType: getProductType(state),
  photobookHeight: getPhotobookHeight(state),
  isCoupon: isCoupon(state),
  isPrint: isPrint(state),
  productCanvasSize: getProductCanvasSize(state),
  tutorialStatus: getTutorialStatus(state),
  isFetched: state.ui.dataFetched,
  errorAlert: state.ui.errorAlert,
  bibliotecaTemasOpened: getBibliotecaTemasOpened(state),
  bibliotecaTemas: getBibliotecaTemas(state),
  photoEditor: getPhotoEditor(state),
  currentNav: getCurrentNav(state),
  isLegendaAberta: getIsLegendaAberta(state),
  stateLegenda: getStateLegenda(state),
  screenSize: getScreenSize(state),
  popupId: getPopupId(state),
  products: getCustomProducts(state),
  userProducts: getUserProducts(state),
  modalConfig: getModalConfig(state),
  itemId: getItemId(state),
  isRevisarMudancaOrientacao: getIsRevisarMudancaOrientacao(state),
});

const mapDispatchToProps = dispatch => ({
  updateTutorialStatus: status => dispatch(actionUI.updateTutorialStatus(status)),
  updateNav: value => dispatch(updateSelectedNav(value)),
  toggleBibliotecaTemas: () => dispatch(actionUI.toggleBibliotecaTemas()),
  setModalConfig: (modalConfig) => dispatch(actionUI.setModalConfig(modalConfig)),
  addThemeImages: images =>  dispatch(actionGeneral.sagaAddThemeImages(images)),
  toggleLegenda: () => dispatch(actionUI.toggleLegenda()),
  setStateLegenda: state => dispatch(actionUI.setStateLegenda(state)),
  setTamanhoColeira: tamanho => dispatch(actionItem.setTamanhoColeira(tamanho)),
  updatePopup: (id, overlay, msg) => dispatch(actionUI.updatePopup(id, overlay, msg)),
  postMetadataFormat: (id, productFormat, pageFormat) => dispatch(actionGeneral.postMetadataFormat(id, productFormat, pageFormat)),
  postMetadataOrientation: (id, productFormat, pageFormat) => dispatch(actionGeneral.postMetadataOrientation(id, productFormat, pageFormat)),
  toggleRevisarMudancaOrientacao: () => dispatch(actionGeneral.toggleRevisarMudancaOrientacao()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Product);
