import PropTypes from 'prop-types';
import React from 'react';
import {calculoXSombraMioloDebru} from '../../utils/calculosSombra';
import * as s from '../../constant/sombra'

const DebruTipo1 = ({ 
  prodCode,
  caminhoAsset,
  pageCoordenates,
  scrollY,
  imagemCapa,
  imagemCapaVerso
  }) => 
{
  const deslocamentoLombada = pageCoordenates.width*s.FATOR_AJUSTE_DEBRU_LOMBADA_BOOK_A4P;
  const top = pageCoordenates.top+scrollY-pageCoordenates.height*s.FATOR_AJUSTE_HEIGHT_DEBRU_BOOK_A4P;

  return(
    <div>
      {/* PAGINA ESQUERDA */}
      <div
        style={{
          top: top,
          left: pageCoordenates.left-deslocamentoLombada,
          position: 'absolute',
          width: pageCoordenates.width,
          height: pageCoordenates.height*s.FATOR_AJUSTE_HEIGHT_DEBRU_BOOK_A4P,
          backgroundColor: 'rgb(230, 230, 230)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'top',
          backgroundImage: `url(${imagemCapa})`,
          zIndez:-1,
        }}/>
      <div 
        style={{
          top: pageCoordenates.top+scrollY,
          left: pageCoordenates.left-deslocamentoLombada,
          position: 'absolute',
          width: deslocamentoLombada,
          height: pageCoordenates.height,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'left',
          backgroundColor: 'rgb(230, 230, 230)',
          backgroundImage: `url(${imagemCapa})`,
          zIndez:-1,
        }}
      />
      <div 
        style={{
          top: pageCoordenates.bottom+scrollY,
          left: pageCoordenates.left-deslocamentoLombada,
          position: 'absolute',
          width: pageCoordenates.width,
          height: pageCoordenates.height*s.FATOR_AJUSTE_HEIGHT_DEBRU_BOOK_A4P,
          backgroundColor: 'rgb(230, 230, 230)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'bottom',
          backgroundImage: `url(${imagemCapa})`,
          zIndez:-1,
        }}
      />
      {/* LOMBADA */}
      <div 
        style={{
          top: pageCoordenates.bottom+scrollY,
          left: pageCoordenates.right-deslocamentoLombada,
          position: 'absolute',
          width: (deslocamentoLombada*2),
          height: pageCoordenates.height*s.FATOR_AJUSTE_HEIGHT_DEBRU_BOOK_A4P,
          backgroundPosition: 'top',
          backgroundColor: 'white',
          zIndez:-1,
        }}
      />
      <div 
        style={{
          top: top,
          left: pageCoordenates.right-deslocamentoLombada,
          position: 'absolute',
          width: (deslocamentoLombada*2),
          height: pageCoordenates.height*s.FATOR_AJUSTE_HEIGHT_DEBRU_BOOK_A4P,
          backgroundPosition: 'top',
          backgroundColor: 'white',
          zIndez:-1,
        }}
      />
      {/* SOMBRA LOMBADA */}
      <div
        style={{
            top: top,
            left: pageCoordenates.right-deslocamentoLombada,
            position: 'absolute',
            zIndez:4,
          }}>
        <img
          alt=""
          height={pageCoordenates.height+((pageCoordenates.height*s.FATOR_AJUSTE_HEIGHT_DEBRU_BOOK_A4P)*2)}
          width={deslocamentoLombada*2}
          src={require(`../../asset/sombra/${caminhoAsset}/sombra-lombada-debru-interna.svg`)}
        />
      </div>
      {/* PAGINA DIREITA */}
      <div 
        style={{
          top: top,
          left: pageCoordenates.right+deslocamentoLombada,
          position: 'absolute',
          width: pageCoordenates.width,
          height: pageCoordenates.height*s.FATOR_AJUSTE_HEIGHT_DEBRU_BOOK_A4P,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'top',
          backgroundColor: 'rgb(230, 230, 230)',
          backgroundImage: `url(${imagemCapaVerso})`,
          zIndez:-1,
        }}
      />
      <div 
        style={{
          top: pageCoordenates.top+scrollY,
          left: pageCoordenates.right+pageCoordenates.width,
          position: 'absolute',
          width: deslocamentoLombada,
          height: pageCoordenates.height,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'left',
          backgroundColor: 'rgb(230, 230, 230)',
          backgroundImage: `url(${imagemCapaVerso})`,
          zIndez:-1,
        }}
      />
      <div 
        style={{
          top: pageCoordenates.bottom+scrollY,
          left: pageCoordenates.right+deslocamentoLombada,
          position: 'absolute',
          width: pageCoordenates.width,
          height: pageCoordenates.height*s.FATOR_AJUSTE_HEIGHT_DEBRU_BOOK_A4P,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'top',
          backgroundColor: 'rgb(230, 230, 230)',
          backgroundImage: `url(${imagemCapaVerso})`,
          zIndez:-1,
        }}
      />
      {/* SOMBRA TODO */}
      <div
        style={{
            top: top,
            left: pageCoordenates.left-deslocamentoLombada,
            position: 'absolute',
          }}>
        <img
          alt=""
          height={pageCoordenates.height+((pageCoordenates.height*s.FATOR_AJUSTE_HEIGHT_DEBRU_BOOK_A4P)*2)}
          width={pageCoordenates.width*2+deslocamentoLombada*2}
          src={require(`../../asset/sombra/${caminhoAsset}/sombra-interna-debru-todo.svg`)}
        />
      </div>
      {/* SOMBRA DEBRU MIOLO TODO */}
      <div
        style={{
            top: pageCoordenates.top+scrollY+(pageCoordenates.height*(1-s.FATOR_AJUSTE_HEIGHT_SOMBRA_DEBRU_BOOK_A4P)/2),
            left: calculoXSombraMioloDebru(pageCoordenates, prodCode),
            position: 'absolute',
          }}>
        <img
          alt=""
          width="auto"
          height={pageCoordenates.height*s.FATOR_AJUSTE_HEIGHT_SOMBRA_DEBRU_BOOK_A4P}
          src={require(`../../asset/sombra/${caminhoAsset}/sombra-miolo-debru.svg`)}
        />
      </div>
      {/* Linhas cantos*/}
      <div
        style={{
            top: top,
            left: pageCoordenates.left-deslocamentoLombada,
            position: 'absolute',
            display:'flex',
          }}>
        <img
          alt=""
          height={pageCoordenates.height*s.FATOR_AJUSTE_HEIGHT_LINHA_CANTO}
          width="auto"
          src={require(`../../asset/mockup/debru/linhas-cantos.svg`)}
        />
      </div>
      <div
        style={{
            top: top,
            right: pageCoordenates.right-pageCoordenates.width-deslocamentoLombada,
            position: 'absolute',
            display:'flex',
            transform: "rotate(-90deg)",
            transformOrigin: "50% 50%"
          }}>
        <img
          alt=""
          height={pageCoordenates.height*s.FATOR_AJUSTE_HEIGHT_LINHA_CANTO}
          width="auto"
          src={require(`../../asset/mockup/debru/linhas-cantos.svg`)}
        />
      </div>
      <div
        style={{
            top: pageCoordenates.bottom+scrollY-pageCoordenates.height*s.FATOR_AJUSTE_HEIGHT_DEBRU_BOOK_A4P*s.FATOR_AUMENTO_HEIGHT_LINHA_CANTO,
            left: pageCoordenates.left-deslocamentoLombada,
            position: 'absolute',
            display:'flex',
            transform: "rotate(90deg)",
            transformOrigin: "50% 50%"
          }}>
        <img
          alt=""
          height={pageCoordenates.height*s.FATOR_AJUSTE_HEIGHT_LINHA_CANTO}
          width="auto"
          src={require(`../../asset/mockup/debru/linhas-cantos.svg`)}
        />
      </div>
      <div
        style={{
            top: pageCoordenates.bottom+scrollY-pageCoordenates.height*s.FATOR_AJUSTE_HEIGHT_DEBRU_BOOK_A4P*s.FATOR_AUMENTO_HEIGHT_LINHA_CANTO,
            right: pageCoordenates.right-pageCoordenates.width-deslocamentoLombada,
            position: 'absolute',
            display:'flex',
            transform: "rotate(-180deg)",
            transformOrigin: "50% 50%"
          }}>
        <img
          alt=""
          height={pageCoordenates.height*s.FATOR_AJUSTE_HEIGHT_LINHA_CANTO}
          width="auto"
          src={require(`../../asset/mockup/debru/linhas-cantos.svg`)}
        />
      </div>
    </div>
  );
}

DebruTipo1.propTypes = {
  prodCode: PropTypes.string.isRequired,
  caminhoAsset: PropTypes.string.isRequired,
  pageCoordenates: PropTypes.object.isRequired,
  scrollY: PropTypes.number.isRequired,
  imagemCapa: PropTypes.string,
  imagemCapaVerso: PropTypes.string,
};

export default DebruTipo1;
