import * as actionGeneral from '../action/general';
import * as actionItem from '../action/item';
import * as actionUI from '../action/ui';

import {
  bookHaveCover,
  bookHaveImage,
  getBookNavigatorData,
  getFormats,
  getLayoutsFilter,
  getProductStyles,
  getScreenSize,
  getProductType,
  getIsModoSobFivela
} from '../selector';

import { CONFIRM_UPDATE_APPLY_METHOD } from '../constant/popup-message';
import { NAV_NONE_SELECTED } from '../constant/nav';
import NavLayouts from '../component/nav-layouts';
import { POPUP_CONFIRM_UPDATE_APPLY_METHOD } from '../constant/popup';
import { connect } from 'react-redux';
import { getSetNumber } from '../selector/set';
import store from '../store';
import { updateScreenSize } from '../action/ui';
import {getTipoPag} from '../utils/textosVariaveis';

const mapStateToProps = state => ({
  layoutsFilter: getLayoutsFilter(state),
  screenSize: getScreenSize(state),
  styles: getProductStyles(state),
  navigatorData: getBookNavigatorData(state),
  pagesFormats: getFormats(state),
  maxPage: getSetNumber(state),
  haveCover: bookHaveCover(state),
  prodType: getProductType(state),
  isModoSobFivela: getIsModoSobFivela(state)
});

const handleTogleMenuLayouts = (dispatch, value) => {
  const state = store.getState();
  const layoutsFilter = getLayoutsFilter(state);

  switch (value) {
    case 'TEXT':
      if (layoutsFilter.menuApplyMethod) {
        dispatch(actionItem.toggleMenuLayouts('APPLYMETHOD'));
      }
      break;
    case 'APPLYMETHOD':
      if (layoutsFilter.menuText) {
        dispatch(actionItem.toggleMenuLayouts('TEXT'));
      }
      break;
    default:
      break;
  }

  dispatch(actionItem.toggleMenuLayouts(value));
};

const updateApplyMethod = (type, dispatch) =>
  type === `somente ${(getTipoPag())} atual` ||
  type === `${getTipoPag()} dupla` ||
  !bookHaveImage(store.getState())
    ? dispatch(actionItem.updateLayoutFilterApplyMethod(type))
    : (dispatch(actionUI.setStateLegenda('POPUP')), dispatch(
        actionUI.updatePopup(
          POPUP_CONFIRM_UPDATE_APPLY_METHOD,
          true,
          CONFIRM_UPDATE_APPLY_METHOD(type)
        )
      ))

const mapDispatchToProps = dispatch => ({
  updateLayoutFilterText: value =>
    dispatch(actionItem.updateLayoutFilterText(value)),
  updateLayoutFilterApplyMethod: value => updateApplyMethod(value, dispatch),
  toggleMenuLayouts: value => handleTogleMenuLayouts(dispatch, value),
  setLayout: layoutId => dispatch(actionGeneral.sagaSetLayout(layoutId)),
  setNavNone: () => dispatch(actionUI.updateSelectedNav(NAV_NONE_SELECTED)),
  updateScreenSize: (
    width,
    height,
    headerHeight,
    photobookHeight,
    layoutsContainerWidth
  ) =>
    dispatch(
      updateScreenSize(
        width,
        height,
        headerHeight,
        photobookHeight,
        layoutsContainerWidth
      )
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(NavLayouts);
